export const trackTitlesWithPreview = [
  "Stand by Me",
  "Space Oddity",
  "Dreams",
  "I Say a Little Prayer",
  "(Sittin' On) the Dock of the Bay",
  "Hotel California",
  "Stars",
  "Hungry Like the Wolf",
  "Against All Odds (Take a Look at Me Now)",
  "Heroes",
  "Yellow",
  "Thinking out Loud",
  "Fast Car",
  "Wicked Game",
  "Take on Me",
  "Crazy",
  "The Best",
  "I Want to Know What Love Is",
  "Fool (If You Think It's Over)",
  "Bad Day",
  "Edge of Seventeen",
  "Move on Up",
  "Last Request",
  "Respect",
  "You're so Vain",
  "Go Your Own Way",
  "How You Remind Me",
  "Take It Easy",
  "Ain't Nobody",
  "Feel It Still",
  "Don't Start Now",
  "A Little Respect",
  "Just the Way You Are",
  "Somewhere in My Heart",
  "Young Hearts Run Free",
  "Wings",
  "Golden Brown",
  "We Built This City",
  "Pure Shores",
  "Love Will Tear Us Apart",
  "This Charming Man",
  "You Can't Hurry Love",
  "Nothing's Gonna Stop Us Now",
  "Groove Is in the Heart",
  "Kiss from a Rose",
  "You're Beautiful",
  "Kiss",
  "True",
  "It Must Have Been Love",
  "Once in a Lifetime",
  "Can't Get You out of My Head",
  "West End Girls",
  "Pack Up",
  "Love Shack",
  "It's My Life ",
  "Running Up That Hill",
  "I'll Be There",
  "Just the Two of Us",
  "Drive",
  "Breathless",
  "Soul Man",
  "I'm Yours",
  "Cannonball",
  "Brass in Pocket",
  "When a Man Loves a Woman",
  "Baker Street",
  "Cry to Me",
  "Black Velvet",
  "Blue Monday '88",
  "Walking in Memphis",
  "Wish You Were Here",
  "Perfect",
  "Harvest Moon",
  "Constant Craving",
  "You've Got a Friend",
  "Knock on Wood",
  "She Drives Me Crazy",
  "Riptide",
  "Sleeping Satellite",
  "Sitting Down Here",
  "Rather Be",
  "Love Changes (Everything)",
  "I Got You Babe",
  "Sweet Love",
  "Into the Mystic",
  "Wasn't Expecting That",
  "Daydream Believer",
  "Smile",
  "I Love You Always Forever",
  "Owner of a Lonely Heart",
  "Viva La Vida",
  "Iris",
  "The Universal",
  "Under the Bridge",
  "A Thousand Years",
  "Rise Up",
  "Love Town",
  "You're the One for Me",
  "Forget Me Nots",
  "Imagination",
  "Native Son",
  "I Feel for You",
  "(Feels Like) Heaven",
  "Dancing In the Dark",
  "Hysteria",
  "Don't Stop Believin'",
  "One More Time",
  "Get Lucky",
  "I Don't Feel Like Dancin'",
  "Dreamer",
  "U Sure Do ",
  "Celebration",
  "Love Come Down",
  "Lets Groove",
  "Free",
  "Push The Feeling On",
  "Bitter Sweet Symphony",
  "And the Beat Goes On",
  "Ain't No Stoppin' Us Now",
  "Got to Be Real",
  "I Can't Wait",
  "Never Give up on a Good Thing",
  "I Can Make You Feel Good",
  "Give Me the Reason",
  "Lost in Music",
  "We Are Family",
  "Can You Feel It",
  "Not That Kind",
  "I'm Outta Love",
  "I'm So Excited",
  "Good Times",
  "I'm Gonna Be (500 Miles)",
  "Reach Out I'll Be There",
  "Boogie Wonderland",
  "Who Let The Dogs Out",
  "Hey, Little Girl",
  "What Is Love?",
  "Killer / Papa Was a Rollin' Stone",
  "Here Comes the Rain Again",
  "Thorn In My Side",
  "Love Is A Stranger",
  "What's Love Got to Do with It",
  "Sometimes",
  "We Don't Have To Take Our Clothes Off",
  "Nineteen",
  "Bad",
  "Just Can't Get Enough",
  "Pretty Green Eyes",
  "Dakota",
  "Don't Go",
  "All Around the World",
  "I Have Nothing",
  "Call Me",
  "The Sun Always Shines on T.V.",
  "Another Night",
  "Heaven",
  "9 PM - Till I come",
  "Silence (feat. Sarah McLachlan)",
  "L'Amour Toujours",
  "Love & Pride",
  "Encore Une Fois",
  "Ecuador",
  "Be My Lover",
  "For An Angel",
  "Party Rock Anthem",
  "Sexy And I Know It",
  "Sorry For Party Rocking",
  "Halo",
  "Flames",
  "Alive",
  "Smalltown Boy",
  "Crazy 'Bout My Baby",
  "Cry for You",
  "The Rhythm of the Night",
  "The Key, The Secret",
  "Bring Me To Life",
  "Jar of Hearts",
  "Don't Stop The Music",
  "Only Girl (In The World)",
  "Shut Up And Drive",
  "Airplanes",
  "Jai Ho! (You Are My Destiny)",
  "Better in Time",
  "Stereo Love",
  "Infinity 2008",
  "Don't Stop Me Now",
  "Under Pressure",
  "Radio Ga Ga",
  "I Want To Break Free",
  "Eye of the Tiger",
  "Don't Know Much",
  "Unchained Melody",
  "My Name Is",
  "(Everything I Do) I Do It For You",
  "Summer Of '69",
  "My Heart Will Go On",
  "All You Need Is Love",
  "Digital Love",
  "Firestarter",
  "Purple Rain",
  "1999",
  "The Most Beautiful Girl In the World",
  "Gangnam Style",
  "Waiting for a Star to Fall",
  "Shake It Off",
  "There Must Be an Angel (Playing with My Heart)",
  "I'd Do Anything For Love (But I Won't Do That)",
  "What's Up?",
  "I Will Always Love You",
  "Barbie Girl",
  "Nothing Else Matters",
  "Always",
  "Losing My Religion",
  "...Baby One More Time",
  "They Don't Care About Us",
  "Desert Rose",
  "Believe",
  "More Than Words",
  "Linger",
  "Maria Maria",
  "Wonderwall",
  "When You Believe",
  "Enter Sandman",
  "Livin' la Vida Loca",
  "The Man Who Sold The World",
  "Wannabe",
  "No Scrubs",
  "I Don't Want To Miss A Thing",
  "All Star",
  "Coco Jamboo",
  "Scar Tissue",
  "Bed Of Roses",
  "Earth Song",
  "Come As You Are",
  "Thank You",
  "Don't Cry",
  "Smooth",
  "Black or White",
  "The Kids Aren't Alright",
  "I'll Be Missing You",
  "You Are Not Alone",
  "The Boy Is Mine",
  "Freestyler",
  "Cotton Eye Joe",
  "Scatman (ski-ba-bop-ba-dop-bop)",
  "All The Small Things",
  "Basket Case",
  "Torn",
  "Amazing",
  "Hero",
  "Without You",
  "Return of the Mack",
  "Ironic",
  "Heal the World",
  "All I Wanna Do Is Make Love To You",
  "One Headlight",
  "Send Me An Angel",
  "Truly Madly Deeply",
  "Save Tonight",
  "If You Had My Love",
  "Insane in the Brain",
  "Black Hole Sun",
  "Boom, Boom, Boom, Boom!!",
  "Men In Black",
  "Why Don't You Get A Job",
  "Sweat (A La La La La Long)",
  "When You Say Nothing At All",
  "More And More",
  "Everlong",
  "Candy Rain",
  "Remember the Time",
  "Bailamos",
  "Lithium",
  "Dragula",
  "Everybody Hurts",
  "Always Be My Baby",
  "Nobody",
  "Down In A Hole",
  "Mr. Jones",
  "Insomnia",
  "Song 2",
  "Pretty Fly (For A White Guy)",
  "California Love",
  "I Knew I Loved You",
  "Lovefool",
  "Nookie",
  "Fantasy",
  "Get A Way",
  "Freedom! '90",
  "This Is How We Do It",
  "Ordinary World",
  "Even Flow",
  "Return To Innocence",
  "Gonna Make You Sweat",
  "Runaway Train",
  "We like to Party! (The Vengabus)",
  "Stairway to Heaven",
  "Stayin' Alive",
  "Born to Be Alive",
  "Don't Stop 'Til You Get Enough",
  "Dance Hall Days",
  "Urgent",
  "Turn Me Loose",
  "The Logical Song",
  "Breakfast In America",
  "Rosanna",
  "Africa",
  "Tragedy",
  "Walking On Sunshine",
  "Sweet Dreams (Are Made of This)",
  "The Final Countdown",
  "YMCA",
  "Jump",
  "You Win Again",
  "Say Say Say",
  "Hope Of Deliverance",
  "One Night In Bangkok",
  "Say It Right",
  "Maneater",
  "Hung Up",
  "Give It To Me",
  "All The Things She Said",
  "Just Dance",
  "Valerie (feat. Amy Winehouse)",
  "Billie Jean",
  "Beat It",
  "Thriller",
  "Bohemian Rhapsody",
  "Smoke on the Water",
  "My Sharona",
  "Das Model",
  "Dancing Queen",
  "Highway to Hell",
  "Heart Of Glass",
  "I Feel Love",
  "You Shook Me All Night Long",
  "Livin' On A Prayer",
  "Pour Some Sugar On Me",
  "Girls Just Want to Have Fun",
  "The Way You Make Me Feel",
  "(I've Had) The Time Of My Life",
  "Crazy Little Thing Called Love",
  "Take Me Home Tonight",
  "Red Red Wine",
  "I Love Rock 'N Roll",
  "Another One Bites The Dust",
  "Smooth Criminal",
  "Rock with You",
  "The Safety Dance",
  "All Night Long",
  "Wanna Be Startin' Somethin'",
  "Wake Me Up Before You Go-Go",
  "It's Raining Men",
  "Back In Black",
  "Man in the Mirror",
  "Pump Up The Jam",
  "Uptown Girl",
  "Conga!",
  "Glory Days",
  "Walk Like an Egyptian",
  "You're the Inspiration",
  "Tainted Love",
  "Rhythm Is A Dancer",
  "Ice Ice Baby",
  "U Can't Touch This",
  "I Like To Move It",
  "Everybody Dance Now",
  "Dirty Diana",
  "Another Part of Me",
  "Mambo No. 5",
  "Love Is All Around",
  "The Lady In Red",
  "Un-Break My Heart",
  "Everybody (Backstreet's Back)",
  "Blue (Da Ba Dee)",
  "The Sweet Escape",
  "Mr. Vain",
  "Easy Lover",
  "Don't Lose My Number",
  "Invisible Touch",
  "You're the Voice",
  "Juke Box Hero",
  "Karma Chameleon",
  "When the Rain Begins to Fall",
  "Upside Down",
  "What A Feeling",
  "Major Tom",
  "Der Kommissar",
  "The Loco-Motion",
  "I Gotta Feeling",
  "What Is Love",
  "Live Is Life",
  "Brother Louie",
  "You're My Heart, You're My Soul",
  "Cheri Cheri Lady",
  "Sing Hallelujah!",
  "The Ketchup Song",
  "Where Do You Go",
  "Daddy Cool",
  "Ma Baker",
  "Gimme! Gimme! Gimme! (A Man After Midnight)",
  "Video Killed The Radio Star",
  "La Isla Bonita",
  "Voyage voyage",
  "Big in Japan",
  "Hips Don't Lie",
  "Bailando",
  "Meet Me Halfway",
  "Haus am See",
  "Alles neu",
  "In the Year 2525",
  "Skandal im Sperrbezirk",
  "I've Been Thinking About You",
  "Go West",
  "Joyride",
  "Sleeping in My Car",
  "Waterloo",
  "Kung Fu Fighting",
  "Back To Black",
  "Rehab",
  "Family Affair",
  "Fallin'",
  "Umbrella",
  "Boat On The River",
  "Don't Let Me Be Misunderstood",
  "Walking on Broken Glass",
  "Have I Told You Lately",
  "Rhythm of My Heart",
  "Rhythm Divine",
  "Absolutely Everybody",
  "Butterfly",
  "Jesus He Knows Me",
  "Frozen",
  "MfG",
  "November Rain",
  "All You Zombies",
  "Shadow On The Wall",
  "Egoist",
  "Just Another Day",
  "I Was Born To Love You",
  "Enjoy the Silence",
  "Keep The Faith",
  "Everytime We Touch",
  "Out Of The Dark",
  "Maria",
  "Larger Than Life",
  "You'll Be In My Heart",
  "Breakfast At Tiffany's",
  "Baby, I Love Your Way",
  "Kiss Me",
  "Chiquitita",
  "Red Blooded Woman",
  "Ka-Ching!",
  "La Bamba",
  "Holding Out for a Hero",
  "All Good Things (Come To An End)",
  "I Should Be So Lucky",
  "She Works Hard For The Money",
  "We're Not Gonna Take It",
  "Midnight Blue",
  "Midnight Lady",
  "Whenever, Wherever",
  "Alejandro",
  "Can't Fight The Moonlight",
  "Californication",
  "Love Don't Cost a Thing",
  "Oops!...I Did It Again",
  "Bye Bye Bye",
  "Eternal Flame",
  "Poison",
  "Bed of Nails",
  "St. Elmos Fire (Man in Motion)",
  "Here I Go Again",
  "Hey (Nah Neh Nah)",
  "You Keep Me Hangin On",
  "Tell It to My Heart",
  "It's a Sin",
  "Le Freak",
  "Superstar",
  "Down Under",
  "Tearin' up My Heart",
  "September",
  "Lay All Your Love On Me",
  "Sweat",
  "The River of Dreams",
  "How Will I Know",
  "Alive And Kicking",
  "Voulez-Vous",
  "Rebel Yell",
  "Rain In May",
  "Goldener Reiter",
  "Such a Shame",
  "Shut Up",
  "Push The Button",
  "Jenny from the Block",
  "The Riddle",
  "Johnny B.",
  "The One and Only",
  "Carrie",
  "Wind Of Change",
  "One Of Us",
  "Money, Money, Money",
  "Dance into the Light",
  "No Son Of Mine",
  "A Kind Of Magic",
  "Don't Dream It's Over",
  "Fernando",
  "That's the Way It Is",
  "That's The Way Love Goes",
  "One Night Love Affair",
  "Up Where We Belong",
  "True Colors",
  "She Wolf",
  "I Engineer",
  "Lemon Tree",
  "Gold",
  "Something Got Me Started",
  "Femme Like U",
  "If You Don't Know Me by Now",
  "Self Control",
  "New York Groove",
  "Axel F",
  "Summer In The City",
  "You Can Leave Your Hat On",
  "Easy",
  "Right Here",
  "I Love Your Smile",
  "Moving on Up",
  "I Feel Lonely",
  "Shiny Happy People",
  "The Shoop Shoop Song (It's In His Kiss)",
  "Come Along",
  "Supreme",
  "Whole Again",
  "Miss California",
  "Don't Pay The Ferryman",
  "Little Lies",
  "Summer Wine",
  "'74-'75",
  "Mmm Mmm Mmm Mmm",
  "Cats In The Cradle",
  "Moonlight Shadow",
  "Gimme All Your Lovin'",
  "Americanos",
  "Kopf verloren",
  "Lonely",
  "Played-A-Live (The Bongo Song)",
  "Heartlight",
  "Rivers of Babylon",
  "We Didn't Start the Fire",
  "Centerfold",
  "Born in the U.S.A.",
  "Hungry Heart",
  "The Boys Of Summer",
  "The Wild Boys",
  "Liberian Girl",
  "Leave Me Alone",
  "ABC",
  "Suspicious Minds",
  "Jailhouse Rock",
  "MMMBop",
  "Smells Like Teen Spirit",
  "Mamma Mia",
  "Isn't She Lovely",
  "Crazy In Love (feat. Jay-Z)",
  "Single Ladies (Put a Ring on It)",
  "Roar",
  "Firework",
  "Jolene",
  "9 to 5",
  "Take Your Mama",
  "Shape of You",
  "In the Air Tonight",
  "You're The First, The Last, My Everything",
  "Maggie May",
  "CAN'T STOP THE FEELING!",
  "Cry Me a River",
  "Feeling Good",
  "(Something Inside) So Strong",
  "I'm Still Standing",
  "Tiny Dancer",
  "Your Song",
  "My Girl",
  "Good Vibrations",
  "Barbara Ann",
  "Uptown Funk (feat. Bruno Mars)",
  "Creep",
  "Fireflies",
  "Where Is The Love?",
  "Cupid",
  "I Wanna Dance with Somebody (Who Loves Me)",
  "Vincent",
  "What A Wonderful World",
  "Stay With Me",
  "Titanium",
  "All About That Bass",
  "Call Me Maybe",
  "Empire State of Mind",
  "Wherever You Will Go",
  "If You're Not The One",
  "Gotta Get Thru This",
  "Son Of A Preacher Man",
  "Spooky",
  "American Pie",
  "Kiss The Rain",
  "Somebody That I Used To Know",
  "Waterfalls",
  "Unpretty",
  "Running on Empty",
  "I Try",
  "Hey Ya!",
  "Writing's On The Wall",
  "One Day Like This",
  "Marry You",
  "Don't Worry Be Happy",
  "Do Your Thing",
  "Wind Beneath My Wings",
  "Feel Good Inc.",
  "DARE",
  "We All Stand Together",
  "Moondance",
  "Beyond the Sea",
  "The Power Of Love",
  "Raspberry Beret",
  "Price Tag",
  "Last Friday Night (T.G.I.F.)",
  "If I Were a Boy",
  "Shackles (Praise You)",
  "Sweet Child O' Mine",
  "Higher And Higher",
  "No One",
  "Leave Right Now",
  "Since U Been Gone",
  "Breakaway",
  "Behind These Hazel Eyes",
  "SexyBack",
  "LoveStoned / I Think She Knows (Interlude)",
  "Sweetest Girl (Dollar Bill) (feat. Akon, Lil' Wayne & Niia)",
  "Where I Wanna Be",
  "(You Drive Me) Crazy",
  "I'm a Slave 4 U",
  "If There's Any Justice",
  "Because of You",
  "Stupid Girls",
  "U + Ur Hand",
  "That's My Goal",
  "No Promises",
  "I'm Sprung",
  "Your Love Alone Is Not Enough (feat. Nina Persson)",
  "Here (In Your Arms)",
  "Beautiful Liar",
  "Deja Vu (feat. Jay-Z)",
  "Shiver",
  "Daughters",
  "Caught Up",
  "Almost Here",
  "We Come 1",
  "Unwritten",
  "In the Morning",
  "Hips Don't Lie (feat. Wyclef Jean)",
  "Get Up (feat. Chamillionaire)",
  "The Rose",
  "He Wasn't Man Enough",
  "Differences",
  "Oh (feat. Ludacris)",
  "1, 2 Step (feat. Missy Elliott)",
  "Let Me Love You",
  "Incomplete",
  "Let Me Hold You (feat. Omarion)",
  "Like You (feat. Ciara)",
  "You Raise Me Up",
  "Left Outside Alone",
  "Ordinary People",
  "Girlfriend",
  "Bills, Bills, Bills",
  "Jumpin', Jumpin'",
  "Say My Name",
  "Soldier (feat. T.I. & Lil' Wayne)",
  "Cater 2 U",
  "Girl",
  "My Prerogative",
  "Lucky",
  "Run It! (Featuring Juelz Santana)",
  "With You",
  "Thank God I Found You",
  "Naughty Girl",
  "Yeah!",
  "Burn (Confession Special Edition Version)",
  "My Boo",
  "I Don't Want to Be",
  "Perfect Gentleman (feat. Hope)",
  "As I Lay Me Down",
  "Hemorrhage (In My Hands)",
  "100 Years",
  "Play",
  "I'm Real (feat. Ja Rule) (Murder Remix)",
  "You Rock My World (with intro)",
  "Don't Think I'm Not",
  "She's so High",
  "Promise",
  "Absolutely (Story of a Girl)",
  "He Can't Love U",
  "Irresistible (Album Version)",
  "Let's Get Married",
  "So Anxious",
  "No More",
  "Miss You Love",
  "The Way You Move (feat. Sleepy Brown)",
  "Roses",
  "Fiesta (feat. Jay-Z, Boo & Gotti) (Remix)",
  "Karma",
  "If I Ain't Got You",
  "It's Gonna Be Me",
  "You're Making Me High",
  "Gone",
  "Sick and Tired",
  "Rockstar",
  "Slow Jamz (feat. Kanye West & Jamie Foxx)",
  "Lonely No More",
  "Lose Control (feat. Ciara & Fat Man Scoop)",
  "Grind With Me",
  "1973",
  "Diamonds and Pearls",
  "Cream",
  "One Minute Man (feat. Ludacris)",
  "Get Ur Freak On",
  "Saltwater",
  "In the End",
  "Welcome to the Black Parade",
  "Teenagers",
  "Shadow of the Day",
  "What I've Done",
  "Numb / Encore",
  "Numb",
  "Rain",
  "Sorry",
  "Snow (Hey Oh)",
  "Everything",
  "Turn Me On",
  "Cupid's Chokehold / Breakfast in America",
  "Runaway",
  "Another Day in Paradise (R&B-Version)",
  "Remember the Name (feat. Styles of Beyond)",
  "Vogue",
  "Justify My Love",
  "Around the World",
  "Otherside",
  "Road Trippin'",
  "When It's Over (David Kahne Main)",
  "American Idiot",
  "Wake Me up When September Ends",
  "Strong Enough",
  "Low (feat. T-Pain)",
  "Damaged",
  "4 Minutes (feat. Justin Timberlake and Timbaland)",
  "Are You Gonna Be My Girl",
  "Come to Me (feat. Nicole Scherzinger)",
  "Fit but You Know It",
  "Dry Your Eyes",
  "She Bangs (English Version)",
  "No More (Baby I'ma Do Right)",
  "Tha Crossroads",
  "I Need To Know",
  "Photograph",
  "Teenage Dirtbag",
  "Bounce With Me (feat. Xscape) (Edited Album Version)",
  "Love Song",
  "As Good As I Once Was",
  "Fergalicious",
  "Clumsy",
  "London Bridge",
  "Big Girls Don't Cry (Personal)",
  "Let It Go",
  "Last Night",
  "Love",
  "Ghetto Gospel",
  "Don't Phunk With My Heart",
  "Let Me Blow Ya Mind",
  "All About You",
  "Mustang Sally",
  "DONTTRUSTME",
  "Starz In Their Eyes",
  "About You Now",
  "Thnks fr th Mmrs",
  "Too Lost In You",
  "Makes Me Wonder",
  "Livin' On The Edge",
  "Cryin'",
  "No Diggity",
  "Stop And Stare",
  "Apologize (feat. One Republic)",
  "Dance, Dance",
  "Sugar, We're Goin Down",
  "It's Like That",
  "Fields Of Gold",
  "Shape Of My Heart",
  "So Sick",
  "Hate That I Love You",
  "Disturbia",
  "Take A Bow",
  "Round Here",
  "Patience",
  "Good Luck (feat. Lisa Kekaula)",
  "The Only One I Know",
  "Paper Planes",
  "It Wasn't Me",
  "Thong Song",
  "Mr. Brightside",
  "Rollin' (Air Raid Vehicle)",
  "Kryptonite",
  "Love Today",
  "Happy Ending",
  "Foundations",
  "Hit 'Em Up Style (Oops!)",
  "Angel of Mine",
  "Music (feat. Marvin Gaye)",
  "If I Let You Go",
  "Here's to the Night",
  "Flying Without Wings",
  "It's Not Right But It's Okay",
  "Here With Me",
  "My Love Is Your Love",
  "Ms. Jackson",
  "Genie in a Bottle",
  "My Own Worst Enemy",
  "Summer Girls",
  "U Remind Me",
  "Beep",
  "Sexy Love",
  "Touch My Body",
  "What You Waiting For?",
  "Rich Girl",
  "Cool",
  "Waiting On the World to Change",
  "My Love",
  "Let's Dance",
  "Survivor",
  "Dancing in the Moonlight",
  "Anything",
  "Killing Me Softly With His Song",
  "Another Sad Love Song",
  "Diggin' On You",
  "Breathe Again",
  "Said I Loved You...But I Lied",
  "Vision of Love",
  "Someday",
  "How Can We Be Lovers",
  "Step by Step",
  "Tonight",
  "Promise Me",
  "Bubbly",
  "Drop It Like It's Hot",
  "I Tried",
  "My Band",
  "Pieces Of Me",
  "Only U (No Intro)",
  "Because Of You",
  'Blaze Of Glory (From "Young Guns II" Soundtrack)',
  "Just My Imagination",
  "Through The Wire",
  "Stan",
  "Shake That",
  "Like Toy Soldiers",
  "The Real Slim Shady",
  "Mockingbird",
  "When I'm Gone",
  "When You Were Young",
  "Rule The World",
  "America",
  "Can't Tell Me Nothing",
  "Fat Lip",
  "In Too Deep",
  "Chelsea Dagger",
  "Until The End Of Time",
  "Purple Pills",
  "Locked Up",
  "Better Than Me",
  "Lips Of An Angel",
  "Make Me Better",
  "Everytime We Touch (Remix)",
  "Case Of The Ex (Whatcha Gonna Do)",
  "Get It On Tonite (Album Version)",
  "Grace Kelly",
  "Sit Down",
  "Take A Look Around",
  "Laid",
  "Insensitive",
  "Don't Cha",
  "Stickwitu",
  "Buttons",
  "Be Without You (Kendu Mix)",
  "She Will Be Loved",
  "Put It On Me",
  "Sacrifice",
  "Live And Let Die",
  "Don't Cry (Original)",
  "This Ain't A Scene, It's An Arms Race",
  "We Belong Together",
  "Too Little, Too Late (Album Version)",
  "Ridin'",
  "When You're Gone",
  "The Reason",
  "Lost Without U",
  "Get It Shawty",
  "Just A Girl",
  "Tease Me",
  "She Don't Let Nobody",
  "Twist And Shout",
  "I'm The Only One",
  "Come To My Window",
  "Beverly Hills",
  "Gold Digger",
  "Diamonds From Sierra Leone (Remix)",
  "Chasing Cars",
  "Shake It",
  "It Never Rains (In Southern California)",
  "Be With You",
  "Life Is A Rollercoaster",
  "Lovin' Each Day",
  "In The Morning",
  "No Tomorrow",
  "SOS (Album Version)",
  "Unfaithful (Album Version)",
  "The First Cut Is The Deepest",
  "Shine",
  "Money Maker",
  "Runaway Love",
  "Wonderful World",
  "You Give Me Something",
  "Glamorous",
  "What's The Difference",
  "Forgot About Dre",
  "The Next Episode",
  "The Only Thing That Looks Good On Me Is You",
  "Let's Make A Night To Remember",
  "Where My Girls At",
  "No More Drama",
  "Ms. Fat Booty",
  "Boombastic",
  "Angel",
  "Bag Lady",
  "Lollipop",
  "Heard It All Before",
  "Missing (Todd Terry Remix)",
  "Far Away",
  "Air Hostess",
  "When I Grow Up",
  "Live Your Life (feat. Rihanna)",
  "Everytime",
  "Here Without You",
  "Back At One",
  "Baby, I'm Back",
  "Tears Dry On Their Own",
  "Southern Hospitality (Featuring Pharrell)",
  "Spotlight",
  "Outta Control",
  "I Like The Way",
  "God Gave Rock 'N' Roll To You II (Live/1992)",
  "You Needed Me",
  "When The Going Gets Tough",
  "Dangerous",
  "I Wanna Know",
  "Stutter (feat. Mystikal) (Double Take Remix)",
  "A Little Time",
  "AM To PM",
  "Rhythm Of Life",
  "Get Here",
  "What Would You Do?",
  "Disco Inferno",
  "Don't Forget About Us",
  'Slow Down (12" Version)',
  "Dirty Little Secret",
  "Hey Mama",
  "Break It Down Again",
  "Contagious",
  "You're All I Have",
  "So What",
  "Everyday I Love You Less And Less",
  "I Predict A Riot",
  "Teardrops On My Guitar",
  "Our Song",
  "Kool Thing",
  "Learning To Fly",
  "Into The Great Wide Open",
  "Gotta Get Thru This (D'N'D Radio Edit)",
  "Gotta Tell You",
  "Vertigo",
  "Better Together",
  "Banana Pancakes",
  "Dizzy",
  "Just Lose It",
  "Salt Shaker",
  "Wait (The Whisper Song)",
  "Touch The Sky",
  "Switch",
  "Hollaback Girl",
  "Love Generation",
  "Promiscuous",
  "Let's Get It Started (Spike Mix)",
  "Move Along",
  "It Ends Tonight",
  "Don't Lie",
  "My Humps",
  "Not In Love",
  "Found Out About You",
  "Sexbomb",
  "Tell Me",
  "Give Me One Reason",
  "Bleeding Love",
  "A Moment Like This",
  "If I Had No Loot",
  "Southside",
  "Signs",
  "Suga Suga",
  "What's Love Got To Do With It",
  "Be Like That",
  "One (Album Version)",
  "I Just Wanna Love U (Give It 2 Me)",
  'Touch Me (All Night Long) (7" Mix)',
  "Thunder In My Heart Again",
  "Control Myself",
  "Fill My Little World",
  "Lean Back",
  "Rock This Party (Everybody Dance Now)",
  "Island In The Sun",
  "Love Machine",
  "Stay (Faraway, So Close!)",
  "Big Pimpin'",
  "1 Thing",
  "Starlight",
  "Snap Yo Fingers",
  "Move Ya Body",
  "Missing You",
  "When You Tell Me That You Love Me",
  "One Shining Moment",
  'Bailamos (From "Wild Wild West")',
  "Do You Know? (The Ping Pong Song)",
  "Hanging By A Moment",
  "Let It Rock",
  "Heartless (Album Version)",
  "White Flag",
  "Romeo",
  "Drops of Jupiter",
  "Gives You Hell",
  "Closer",
  "Miss Independent",
  "JCB Song",
  "Call On Me",
  "Me & U",
  "I Wish",
  "Hot Boyz",
  "You Found Me",
  "Takin' Back My Love",
  "Love Sex Magic (feat. Justin Timberlake)",
  "Sugar (feat. Wynter)",
  "Kiss Me Thru The Phone",
  "Pon de Replay",
  "Party Up",
  "Get Up (Before The Night Is Over)",
  "Livin' It Up",
  "21 Guns",
  "Keep Coming Back",
  "What Took You So Long?",
  "She Wants To Move",
  "Have You Seen Her",
  "Hold On",
  "Release Me",
  "Impulsive",
  "You're In Love",
  "In Your Eyes",
  "She's The One",
  "Clint Eastwood",
  "Rock DJ",
  "Fix You",
  "Speed of Sound",
  "Are You Gonna Go My Way",
  "Right Here Right Now",
  "One Call Away",
  "Bossy",
  "She Moves In Her Own Way",
  "Face Down",
  "Beautiful Soul",
  "Put Your Records On",
  "Tripping",
  "Black Horse And The Cherry Tree (Radio Version)",
  "Suddenly I See",
  "Fall At Your Feet",
  "Close To You",
  "Candy",
  "Still Got The Blues",
  "Galvanize",
  "Santa Monica",
  "Naked and Sacred",
  "I Live For You",
  "Hey Boy Hey Girl",
  "Goodbye",
  "Lola's Theme",
  "Let There Be Love",
  "Always On The Run",
  "Stand By My Woman",
  "It Ain't Over 'Til It's Over",
  "Spinning Around",
  "Power of a Woman",
  "All Rise",
  "If You Come Back",
  "I Touch Myself",
  "Here I Am / Small Axe (Come And Take Me)",
  "Higher Ground",
  "(I Can't Help) Falling In Love With You",
  "This Is Why I'm Hot",
  "Trick Me",
  "Milkshake",
  "Millionaire",
  "Fly Away",
  "Naive",
  "Don't Worry",
  "Come Undone",
  "I Don't Wanna Fight",
  "All For You",
  "Doesn't Really Matter",
  "I Kissed A Girl",
  "Waking Up In Vegas",
  "Thinking Of You",
  "Hot N Cold",
  "This House",
  "Unskinny Bop",
  "Something To Believe In",
  "Wonderful",
  "I'll Be Your Baby Tonight (feat. UB40)",
  "Get A Life",
  "Oh Carolina",
  "I Like It",
  "Opposites Attract",
  "Rush Rush",
  "Sailing On The Seven Seas",
  "Pandora's Box",
  "That's Why You Go Away",
  "Fade Into You",
  "That Girl",
  "Fascinating Rhythm",
  "I Turn To You",
  "Never Be The Same Again",
  "Play That Funky Music",
  "Baby, Baby",
  "Every Heartbeat",
  "That's What Love Is For",
  "Spaceman",
  "Something To Talk About",
  "I Can't Make You Love Me",
  "Ms. New Booty",
  "Do It To It",
  "When Love Takes Over (feat. Kelly Rowland)",
  "Again",
  "Happening All Over Again",
  "Epic",
  "Supermassive Black Hole",
  "There You'll Be",
  "Closer to Free",
  "It's Been Awhile",
  "Twisted",
  "Nobody (feat. Athena Cage)",
  "New Shoes",
  "Shawty (feat. T Pain)",
  "Hotel Room Service",
  "She Talks To Angels",
  "Flat Beat",
  "Follow Me",
  "What's It Gonna Be?! (feat. Janet Jackson)",
  "Misery Business",
  "From a Distance",
  "Dance with Me",
  "Just the Girl",
  "Informer",
  "All I Want (Sunship Radio Edit)",
  "Laffy Taffy",
  "Sunshine (feat. Anthony Hamilton)",
  "I'm a Thug",
  "Desperately Wanting",
  "(When You Gonna) Give It Up to Me (feat. Keyshia Cole) (Radio Version)",
  "Pony (Single Version)",
  "Maria Maria (feat. The Product G&B)",
  "Battlefield",
  "No Air (feat. Chris Brown)",
  "Bulletproof",
  "Take Me To The Clouds Above (LMC Vs. U2 / Radio Edit)",
  "Any Dream Will Do",
  "Bootylicious",
  "Like a Boy",
  "Heaven Is a Halfpipe (If I Die)",
  "Youth of the Nation",
  "All Summer Long",
  "I Wanna Sex You Up (Single Mix)",
  "All 4 Love",
  "Take a Picture",
  "So Fine",
  "Knock You Down",
  "Down",
  "Right Round",
  "We Be Burnin'",
  "Temperature",
  "One Time",
  "Happily Ever After",
  "Beautiful Stranger",
  "I Know You Want Me (Calle Ocho)",
  "Day 'N' Nite (Crookers Remix)",
  "Party In The U.S.A.",
  "Obsessed",
  "Every Morning",
  "I'll Be There for You (Theme from 'Friends')",
  "The Climb",
  "Ego",
  "The Pretender",
  "Learn to Fly",
  "Do You Want To",
  "I Bet You Look Good On The Dancefloor",
  "When The Sun Goes Down",
  "Brianstorm",
  "Fluorescent Adolescent",
  "Crying Lightning",
  "Take Me Out",
  "22",
  "Hate It Or Love It",
  "How We Do",
  "Boom Boom Pow",
  "Somewhere Only We Know",
  "Love Story",
  "You Belong With Me",
  "Dip It Low",
  "Te Amo (Album Version)",
  "Bad Romance",
  "Paparazzi",
  "Wrap Me Up (Original Mix)",
  "BedRock",
  "I Write Sins Not Tragedies",
  "Whatcha Say",
  "Don't Walk Away",
  "Round and Round (Soul Mix Edit)",
  "I'm Too Sexy",
  "Independent Women, Pt. 1",
  "Pictures Of You",
  "Sometimes When We Touch",
  "Dear Maria, Count Me In",
  "Replay",
  "Got To Have Your Love",
  "Crying in the Rain",
  "Don't Give Up",
  "Escapade",
  "Black Cat",
  "Come Back To Me",
  "Never Let You Go",
  "Bleed It Out",
  "Boulevard of Broken Dreams",
  "Show Me Love",
  "Bust It Baby, Pt. 2 (feat. Ne-Yo)",
  "Better Days (And the Bottom Drops Out)",
  "Breathe",
  "Don't Stop Movin'",
  "Pure & Simple",
  "E.T.",
  "Rise",
  "Flowers (Sunship Edit)",
  "Right Now (Na Na Na)",
  "My Place",
  "Ride Wit Me",
  "I Hate This Part",
  "Loca",
  "Show Me The Way",
  "Pump It",
  "Is It Any Wonder?",
  "Everything You Want",
  "That Don't Impress Me Much (Album Version)",
  "Silent Lucidity",
  "There You Go",
  "Who Knew",
  "Raise Your Glass",
  "Move Any Mountian",
  "Izzo (H.O.V.A.)",
  "I'm Like A Bird",
  "Over My Head (Cable Car)",
  "How to Save a Life",
  "Last Kiss",
  "I'm Every Woman",
  "The Time (Dirty Bit)",
  "Who's That Chick? (feat. Rihanna)",
  "Steal My Sunshine",
  "Coming Home",
  "Stop Me (feat. Daniel Merriweather)",
  "Valerie (feat. Amy Winehouse) (Version Revisited)",
  "The Lazy Song",
  "(Mucho Mambo) Sway",
  "Rolling in the Deep",
  "Rumour Has It",
  "Set Fire to the Rain",
  "Someone Like You",
  "With Every Heartbeat (Album Version)",
  "Fastlove, Pt. 1",
  "I'm Your Baby Tonight",
  "Higher",
  "On The Floor",
  "I Need A Doctor",
  "Look At Me Now",
  "Sweat (Remix)",
  "Just Can’t Get Enough",
  "Can't Stop",
  "Golden Skans",
  "These Words",
  "Give Me Everything (feat. Ne-Yo, Afrojack & Nayer)",
  "Don't Let the Sun Go Down on Me",
  "Judas",
  "Run the World (Girls)",
  "Black and Yellow",
  "The Edge Of Glory",
  "Rapture (feat.Nadia Ali)",
  "Touch Me [feat. Cassandra]",
  "Feels Like Heaven",
  "Pocketful of Sunshine",
  "International Love (feat. Chris Brown)",
  "Too Close",
  'Moves Like Jagger (Studio Recording From "The Voice" Performance)',
  "The Horses",
  "Man Down",
  "Skyscraper",
  "Candyman",
  "Irreplaceable",
  "Goodbye Mr A",
  "What a Girl Wants",
  "Come on over Baby (All I Want Is You) (Radio Version)",
  "I'm Not Alone",
  "Children",
  "Check On It (feat. Slim Thug)",
  "Summer Love",
  "Acceptable in the 80's",
  "Me, Myself and I",
  "Say",
  "Gimme That",
  "(God Must Have Spent) A Little More Time On You (Remix)",
  "I Want It That Way",
  "Hey Ya! (Radio Mix / Club Mix)",
  "Summertime",
  "Before He Cheats",
  "Ice Box",
  "Goodies (feat. Petey Pablo)",
  "Your Body Is a Wonderland",
  "All or Nothing",
  "Cold as Ice",
  "Wifey",
  "Sweet Lady",
  "Pop, Lock & Drop It (Video Edit)",
  "Headlines",
  "Two Princes",
  "S&M",
  "Don't Wanna Go Home",
  "The A Team",
  "Drunk",
  "Lego House",
  "Keep Holding On",
  "Yo (Excuse Me Miss)",
  "Confessions Part II",
  "She's So Lovely",
  "We Found Love",
  "Night People",
  "Stronger (What Doesn't Kill You)",
  "Paradise",
  "Every Teardrop Is a Waterfall",
  "Princess of China",
  "The Fly",
  "Mysterious Ways",
  "Mr. Saxobeat (Extended Version)",
  "Got 2 Luv U (feat. Alexis Jordan)",
  "Where Have You Been (Album Version)",
  "Grenade",
  "Love Is",
  "Until It Sleeps (LP Version)",
  "Now That We Found Love",
  "Danza Kuduro",
  "The Creeps",
  "What's Left Of Me (Main Version)",
  "The Animal Song",
  "Dreamlover",
  "Guerrilla Radio",
  "Little L",
  "Wild Ones (feat. Sia)",
  "Hey Baby",
  "Sunshine (feat. Lea)",
  "Lose My Breath",
  "Freek-A-Leek",
  "Toxic",
  "Shape of My Heart",
  "La Tortura (feat. Alejandro Sanz) (Album)",
  "Video Games",
  "What is Love",
  "Life",
  "My Love (feat. T.I.)",
  "What Goes Around.../...Comes Around (Interlude)",
  "Love in This Club (feat. Young Jeezy)",
  "Use Somebody",
  "Ex-Factor",
  "Gimme More",
  "Piece of Me",
  "Give In to Me",
  "Will You Be There",
  "Heartbreaker",
  "She's All I Ever Had",
  "Don't Tell Me",
  "One Night in Heaven",
  "Boom! Shake the Room",
  "Where the Streets Have No Name (I Can't Take My Eyes off You)",
  "Disarm",
  "What Makes You Beautiful",
  "Summertime Sadness",
  "Want U Back",
  "Praying for Time",
  "As",
  "Some Nights",
  "We Are Young (feat. Janelle Monáe)",
  "Starships",
  "She Doesn't Mind",
  "Wide Awake",
  "Take Care",
  "The Motto",
  "Beneath Your Beautiful (feat. Emeli Sandé)",
  "Little Talks",
  "Everybody Talks",
  "I Won't Give Up",
  "Drive By",
  "I Do (Cherish You)",
  "Move Your Body (D.J.Gabry Ponte Original Radio Edit)",
  "All This Time",
  "So Good",
  "Whistle",
  "Put Your Hands Up for Detroit",
  "Beautiful Life",
  "You",
  "Silhouettes",
  "Ruby",
  "Pound The Alarm",
  "Got to Get It",
  "I Believe In You",
  "Levels",
  "This Love",
  "Oh Girl",
  "Boyfriend",
  "Beauty And A Beat",
  "Tender",
  "Coffee & TV",
  "Payphone",
  "Turn Up the Music",
  "Don't Wake Me Up",
  "True Love",
  "Kingston Town",
  "One More Night",
  "Try Again",
  "Spectrum",
  "Good Time",
  "I Love It (feat. Charli XCX)",
  "How We Do (Party)",
  "Hall of Fame (feat. will.i.am)",
  "Blow Me (One Last Kiss)",
  "Candy Shop",
  "Just A Lil Bit",
  "Gangnam Style (강남스타일)",
  "I Will Wait",
  "Something",
  "Don't You Worry Child",
  "Die Young",
  "Clarity",
  "Diamonds",
  "Feel So Close",
  "I Need Your Love",
  "Sweet Nothing",
  "Thinking About You",
  "Unfinished Sympathy",
  "U Got 2 Let The Music",
  "Can't Hold Us (feat. Ray Dalton)",
  "Thrift Shop (feat. Wanz)",
  "Same Love (feat. Mary Lambert)",
  "Girl on Fire",
  "Live While We're Young",
  "Come With Me : Come With Me",
  "Let Me Love You (Until You Learn To Love Yourself)",
  "Let Her Go",
  "Leave (Get Out)",
  "Ooh Aah (Just a Little Bit)",
  "Everytime You Need Me (Radio Version)",
  "Stay (Album Version)",
  "Ain't No Other Man",
  "Something Kinda Ooooh",
  "Scream & Shout",
  "Star Girl",
  "Five Colours In Her Hair (U.S Version)",
  "Work Hard, Play Hard",
  "I'm Free",
  "Ayo Technology",
  "Locked out of Heaven",
  "Treasure",
  "When I Was Your Man",
  "Titanium (feat. Sia)",
  "She Wolf (Falling to Pieces) [feat. Sia]",
  "Without You (feat. Usher)",
  "Where Them Girls At (feat. Nicki Minaj & Flo Rida)",
  "Pompeii",
  "Radioactive",
  "It's Over Now",
  "Peaches & Cream",
  "Harlem Shake",
  "Heart Attack",
  "Most Girls",
  "My Happy Ending",
  "Good Feeling",
  "Suit & Tie (feat. Jay-Z)",
  "Mirrors",
  "Hard To Handle",
  "Another Love",
  "Counting Stars",
  "Who Am I (What's My Name)?",
  "We Fly High",
  "Cha Cha Slide",
  "Love On Top",
  "Best Thing I Never Had",
  "Porcelain",
  "Natural Blues",
  "Safe And Sound",
  "Come & Get It",
  "My Songs Know What You Did In The Dark (Light Em Up)",
  "Waiting All Night (feat. Ella Eyre)",
  "Get Lucky (feat. Pharrell Williams & Nile Rodgers)",
  "Young And Beautiful",
  "Don't Call Me Baby",
  "Sexual Healing",
  "La La La",
  "Lisztomania",
  "Really Don't Care",
  "Love Me Again",
  "White Noise",
  "You & Me",
  "Counting Blue Cars (Studio Version)",
  "We Can't Stop",
  "Backseat Freestyle",
  "808",
  "Mouth",
  "Gonna Make You Sweat (Everybody Dance Now)",
  "Black Skinhead",
  "Burnin' Up (Album Version)",
  "1979",
  "Beautiful People (feat. Benny Benassi)",
  "Summertime Sadness (Lana Del Rey Vs. Cedric Gervais) (Cedric Gervais Remix)",
  "Not an Addict",
  "Everybody Everybody",
  "All of Me",
  "Wrecking Ball",
  "Lady (Hear Me Tonight)",
  "Things That Make You Go Hmmmm.... (feat. Freedom Williams)",
  "Wasting My Young Years",
  "Wake Me Up",
  "Do I Wanna Know?",
  "Why'd You Only Call Me When You're High?",
  "Blurred Lines",
  "Royals",
  "Team",
  "Hey Porsche",
  "The Power of Love",
  "Ho Hey",
  "Automatic Lover (Call For Love)",
  "It's the Way You Make Me Feel",
  "I Wish (LP Version)",
  "Dark Horse",
  "Take You There",
  "Sober",
  "My Life Would Suck Without You",
  "The Monster",
  "Next To Me",
  "Say Something",
  "Lily Was Here (feat. Candy Dulfer)",
  "Timber (feat. Ke$ha)",
  "This Is What It Feels Like",
  "Best Song Ever",
  "Story of My Life",
  "Right in the Night (feat. Plavka) (Fall in Love with Music)",
  "Can We Dance",
  'Happy (From "Despicable Me 2")',
  "Turn Down for What",
  "Drunk in Love (feat. Jay-Z)",
  "Rather Be (feat. Jess Glynne)",
  "In These Arms",
  "Stay High (Habits Remix)",
  "Latch",
  "Best Day Of My Life",
  "Magic",
  "Live Like You Were Dying",
  "Who Do U Love",
  "She Wolf (Album)",
  "Dare (La La La)",
  "Sweet Dreams",
  "I Will Never Let You Down",
  "The Other Side",
  "Crazy for You",
  "Fancy",
  "Black Widow",
  "Problem",
  "Shower",
  "Cheerleader (Felix Jaehn Remix)",
  "Love Never Felt So Good",
  "I'm Not The Only One",
  "Colorblind",
  "Ugly Heart",
  "Prayer in C (Robin Schulz Remix) [Radio Edit]",
  "Maps",
  "Chandelier",
  "Boom Clap",
  "Rude",
  "Don't",
  "Bloodstream",
  "Stay The Night (Featuring Hayley Williams Of Paramore)",
  "She Looks So Perfect",
  "Hideaway",
  "Call Me When You're Sober",
  "Paralyzer",
  "Fake It",
  "Break Free",
  "Bang Bang",
  "Womanizer",
  "Circus",
  "Smooth (feat. Rob Thomas)",
  "Cool Kids",
  "Amnesia",
  "Talking Body",
  "Take Me To Church",
  "Calling Elvis",
  "Heavy Fuel",
  "Budapest",
  "A Sky Full of Stars",
  "Am I Wrong",
  "Dangerous (feat. Sam Martin)",
  "I Promised Myself",
  "Sweet Like Chocolate",
  "Tipsy (Club Mix)",
  "Summer",
  "Sandstorm",
  "Cry For You",
  "Blank Space",
  "Style",
  "Jealous",
  "Are You With Me",
  "Let It Go (From 'Frozen' / Single Version)",
  "Mama Do (Uh Oh, Uh Oh)",
  "Steal My Girl",
  "Shut Up and Dance",
  "Infinity",
  "I Still Haven't Found What I'm Looking For",
  "Nobody Knows",
  "Firestone",
  "Around The Way Girl",
  "Dani California",
  "Anaconda",
  "Trap Queen",
  "There's Nothing I Won't Do",
  "Wait for You",
  "Thunderstruck",
  "Moneytalks",
  "Uma Thurman",
  "Heartbeat Song",
  "Uncover",
  "Headsprung",
  "All That She Wants",
  "Worth It (feat. Kid Ink)",
  "Truffle Butter",
  "Marvin Gaye (feat. Meghan Trainor)",
  "Break Stuff",
  "Love Me Like You Do",
  "Ex's & Oh's",
  "See You Again (feat. Charlie Puth)",
  "Where Are Ü Now (with Justin Bieber)",
  "FourFiveSeconds",
  "Ain't Nobody (Loves Me Better)",
  'It Must Have Been Love (From the Film "Pretty Woman")',
  "Don't Worry (with Ray Dalton)",
  "Stitches",
  "Reach",
  "Have You Ever",
  "One Sweet Day",
  "Thinking About Your Love",
  "I Still Believe",
  "Roses (feat. ROZES)",
  "Stressed Out",
  "Sugar",
  "Want to Want Me",
  "Watch Me (Whip / Nae Nae)",
  "Jubel",
  "How Deep is Your Love (feat. Disciples)",
  "Hold My Hand",
  "Back Here",
  "The Hills",
  "Can't Feel My Face",
  "Let's Talk About Sex",
  "Waiting For Love",
  "Runnin' (Lose It All)",
  "Sugar (feat. Francesco Yates)",
  "Regret",
  'Writing\'s On The Wall (From "Spectre" Soundtrack)',
  "Skyfall",
  "Hands To Myself",
  "Good For You",
  "Cool for the Summer",
  "Bang My Head (feat. Sia & Fetty Wap)",
  "Black Magic",
  "What Do You Mean?",
  "Love Yourself",
  "Drag Me Down",
  "Lean On (feat. MØ & DJ Snake)",
  "Lips Are Movin",
  "Hymn for the Weekend",
  "Adventure of a Lifetime",
  "I can't stand it",
  "Never Be Like You",
  "We Don't Talk Anymore (feat. Selena Gomez)",
  "Work",
  "Needed Me",
  "Love On The Brain",
  "Chocolate",
  "It's Not Over",
  "Feels Like Tonight",
  "Since I Don't Have You",
  "Panda",
  "King",
  "Here",
  "Scars To Your Beautiful",
  "Pop Ya Collar",
  "This Girl (Kungs Vs Cookin' On 3 Burners)",
  "PILLOWTALK",
  "7 Years",
  "Aloha",
  "Ain't Your Mama",
  "I Wish I Was a Punk Rocker (with Flowers in My Hair)",
  "Low Life (feat. The Weeknd)",
  "All My Friends (feat. Tinashe & Chance the Rapper)",
  'Just Like Fire (From the Original Motion Picture "Alice Through The Looking Glass")',
  "Hotter Than Hell",
  "This Is What You Came For",
  "Alarm",
  "CAN'T STOP THE FEELING! (from DreamWorks Animation's \"TROLLS\")",
  "This One's for You (feat. Zara Larsson) (Official Song UEFA EURO 2016)",
  "One Dance",
  "Too Good",
  "Hotline Bling",
  "Final Song",
  "Side To Side",
  "Dangerous Woman",
  "Do You Remember?",
  "Something Happened on the Way to Heaven",
  "I Wish It Would Rain Down",
  "Work from Home (feat. Ty Dolla $ign)",
  "Heathens",
  "What's My Age Again?",
  "Adam's Song",
  "Drinking in L.A.",
  "Starving",
  "Life is a Highway",
  "Cold Water (feat. Justin Bieber & MØ)",
  "In the Name of Love",
  "Middle",
  "I Don't Wanna Know (feat. Enya & P. Diddy)",
  "Black Beatles",
  "Love Lockdown (Album Version)",
  "LUV",
  'Still Falling For You (From "Bridget Jones\'s Baby")',
  "Nine Million Bicycles",
  "The Closest Thing to Crazy",
  "The Time Is Now",
  "Broccoli (feat. Lil Yachty)",
  "Get Ready",
  "My Way",
  "Hey There Delilah",
  "Good as Hell",
  "Perfect Illusion",
  "Rockabye (feat. Sean Paul & Anne-Marie)",
  "Cheap Thrills (feat. Sean Paul)",
  "The Greatest (feat. Kendrick Lamar)",
  "No Limit",
  "Say You Won't Let Go",
  "Cool Girl",
  "Shout Out to My Ex",
  "Policy of Truth",
  "24K Magic",
  "Cake By The Ocean",
  "Starboy",
  "I Feel It Coming",
  "Ready 2 Go",
  "I Don’t Wanna Live Forever (Fifty Shades Darker)",
  "Just Hold On",
  "Hello",
  "Send My Love (To Your New Lover)",
  "From Paris To Berlin",
  "Because I Got High",
  "For Whom The Bell Tolls",
  "Issues",
  "Faded",
  "Scared to Be Lonely",
  "Human",
  "Skin",
  "Groovy Train",
  "Ciao Adios",
  "Life Is A Highway",
  "It Ain't Me",
  "Mask Off",
  "Something Just Like This",
  "Swalla (feat. Nicki Minaj & Ty Dolla $ign)",
  "Stay",
  "Fill Me In",
  "7 Days",
  "Don't Love You No More (I'm Sorry)",
  "Young Dumb & Broke",
  "Pretty Girl (Cheat Codes X CADE Remix)",
  "Never Forget You",
  "Symphony (feat. Zara Larsson)",
  "Do You Really Like It?",
  "Passionfruit",
  "No Promises (feat. Demi Lovato)",
  "Paris",
  "Secret Love",
  "(Can't Live Without Your) Love And Affection",
  "HUMBLE.",
  "Despacito (Remix)",
  "The Cure",
  "There's Nothing Holdin' Me Back",
  "Cradle Of Love",
  "Mama",
  "1-800-273-8255",
  "NO",
  "I Like Me Better",
  "Sign of the Times",
  "Glycerine",
  "Bad Liar",
  "Strip That Down",
  "Chantaje (feat. Maluma)",
  "IDGAF",
  "New Rules",
  "Unbelievable",
  "2U (feat. Justin Bieber)",
  "Bon appétit",
  "Green Light",
  "Bad Blood",
  "Wild Thoughts (feat. Rihanna & Bryson Tiller)",
  "I'm the One",
  "Slide (feat. Frank Ocean & Migos)",
  "Feels (feat. Pharrell Williams, Katy Perry & Big Sean)",
  "Mi Gente",
  "Unforgettable (feat. Swae Lee)",
  "Dragostea Din Tei",
  "Bring Em Out",
  "Live Your Life",
  "Whatever You Like",
  "Dead And Gone",
  "Gasolina",
  "Proper Education",
  "The Middle",
  "Praying",
  "Silence (feat. Khalid)",
  "Glorious (feat. Skylar Grey)",
  "Dusk Till Dawn (feat. Sia)",
  "rockstar",
  "One Wish",
  "1, 2, 3, 4 (Sumpin' New)",
  "This Is Your Night",
  "What About Us",
  "Mine",
  "Slow Hands",
  "Wolves",
  "Too Good At Goodbyes",
  "What Lovers Do",
  "No More Sad Songs",
  "Deep",
  "Step On",
  "Exterminate (Endzeit 7) [feat. Niki Haris]",
  "The Power",
  "Ooops Up",
  "Delicate",
  "Look What You Made Me Do",
  "Sweet Harmony",
  "Love Will Lead You Back",
  "Never Be the Same",
  "Havana (feat. Young Thug)",
  "Two Steps Behind (String / Acoustic Version)",
  "Say Something (feat. Chris Stapleton)",
  "FRIENDS",
  "1, 2, 3 (feat. Jason Derulo & De La Ghetto)",
  "Love Lies",
  "American Boy",
  "X",
  "Dancing On My Own",
  "Remind Me to Forget",
  "Alien",
  "Disappear",
  "Bodak Yellow",
  "One Kiss",
  "Youngblood",
  "lovely",
  "Psycho",
  "Better Now",
  "Make Me Feel",
  "Girls Like You (Cardi B Version)",
  "Attention",
  "Audio (feat. Sia, Diplo & Labrinth)",
  "Back To You",
  "Start Again",
  "Jackie Chan",
  "Forget to Forget",
  "In My Blood",
  "Let Me Down Slowly",
  "In My Mind",
  "Knockin' On Heaven's Door",
  "Girls Like You",
  "Don't Wanna Know",
  "Ocean (feat. Khalid)",
  "Turn Around",
  "I'm a Mess",
  "High Hopes",
  "Mad Love",
  "Happy Now",
  "Believer",
  "Thunder",
  "All Falls Down",
  "Jesus Walks",
  "I Knew You Were Trouble.",
  "We Are Never Ever Getting Back Together",
  "Body",
  "If I Ever Lose My Faith In You",
  "No Brainer (feat. Justin Bieber, Chance the Rapper & Quavo)",
  "God's Plan",
  "In My Feelings",
  "Whatta Man",
  "Shoop",
  "Sweet but Psycho",
  "Do Me Right",
  "Save Up All Your Tears",
  "Love And Understanding",
  "Give It To You",
  "Happier",
  "Promises",
  "God is a woman",
  "no tears left to cry",
  "My My My!",
  "My Blood",
  "Electricity (feat. Diplo & Mark Ronson)",
  "Don't Leave Me Alone (feat. Anne-Marie)",
  "Let You Love Me",
  "Taki Taki",
  "Without Me",
  "Shallow",
  "Son of Man",
  "Strangers Like Me",
  "Falling",
  "3 Nights",
  "MIA (feat. Drake)",
  "Missing U",
  "thank u, next",
  "Woman Like Me (feat. Nicki Minaj)",
  "Someone You Loved",
  "It Feels So Good",
  "Solo (feat. Demi Lovato)",
  "Eastside",
  "New Soul",
  "Boom Boom Boom",
  "It's Alright (The Guvnor Mix)",
  "Grillz",
  "Get Right",
  "Dancing With A Stranger",
  "i'm so tired...",
  "Giant",
  "The World I Know",
  "Don't Call Me Up",
  "Con Calma",
  "Separated",
  "The Hardest Thing",
  "Piece Of Your Heart",
  "Applause",
  "7 rings",
  "break up with your girlfriend, i'm bored",
  "We R Who We R",
  "Flashing Lights",
  "Best I Ever Had",
  "Can't Stop This Thing We Started",
  "Please Forgive Me",
  "All Day And Night",
  "bad guy",
  "when the party's over",
  "21 Seconds",
  "Old Town Road (feat. Billy Ray Cyrus) (Remix)",
  "Let Me Think About It",
  "Truth Hurts",
  "SOS",
  "Party For One",
  "Wish You Well",
  "Post Malone (feat. RANI)",
  "The London (feat. J. Cole & Travis Scott)",
  "Ritual",
  "Never Really Over",
  "Ransom",
  "Sucker",
  "On A Roll",
  "Nothing Breaks Like a Heart (feat. Miley Cyrus)",
  "Señorita",
  "Old Town Road",
  "Instagram",
  "Higher Love",
  "Lalala",
  "One Day / Reckoning Song (Wankelmut Remix)",
  "Ride It",
  "Beautiful People (feat. Khalid)",
  "I Don't Care",
  "Killer",
  "How Do You Sleep?",
  "Like You",
  "China",
  "Where Does My Heart Beat Now",
  "Loco Contigo",
  "Innuendo",
  "The Show Must Go On",
  "Hot Girl Summer (feat. Nicki Minaj & Ty Dolla $ign)",
  "Dance Monkey",
  "Yo x Ti, Tu x Mi",
  "hot girl bummer",
  "Poker Face",
  "Teeth",
  "Lover",
  "You Need To Calm Down",
  "Never Seen The Rain",
  "Circles",
  "Wanted",
  "Liar",
  "Goodbyes",
  "Heartbreak Hotel (feat. Faith Evans & Kelly Price)",
  "Graveyard",
  "Stack It Up",
  "Memories",
  "Trampoline",
  "Playing Games",
  "Outnumbered",
  "Do Me!",
  "HIGHEST IN THE ROOM",
  "Lose Control",
  "The Git Up",
  "RITMO (Bad Boys For Life)",
  "Make It Right (feat. Lauv)",
  "Lose You To Love Me",
  "What Hurts The Most",
  "Takeaway (feat. Lennon Stella)",
  "Tusa",
  "Say So",
  "It's You",
  "everything i wanted",
  "ROXANNE",
  "Before You Go",
  "Better Off Alone",
  "Baila Baila Baila",
  "Nothin' My Love Can't Fix",
  "The Box",
  "Alone, Pt. II",
  "Watermelon Sugar",
  "Adore You",
  "Breaking Me",
  "Another Chance",
  "Life Is Good (feat. Drake)",
  "Pray",
  "Relight My Fire (feat. Lulu)",
  "Babe",
  "Heartless",
  "Know Your Worth",
  "death bed (coffee for your head)",
  "Love to Hate You",
  "Intentions",
  "Yummy",
  "ily (i love you baby)",
  "Make It Rain",
  "Kings & Queens",
  "Blinding Lights",
  "How Do You Do!",
  "Almost Unreal",
  "Fading Like A Flower (Every Time You Leave)",
  "Spending My Time",
  "Toosie Slide",
  "Sensitivity",
  "My, My, My",
  "you broke me first",
  "ROCKSTAR",
  "Somebody Dance with Me",
  "Loverboy",
  "Some Say",
  "Stupid Love",
  "Rain On Me",
  "Go Let It Out",
  "Lyla",
  "Savage Love (Laxed - Siren Beat)",
  "MAMACITA",
  "Everybody's Free",
  "Salt",
  "Calma",
  "Holy",
  "Waves (Robin Schulz Radio Edit)",
  "Que Calor",
  'Show Me Heaven (From "Days Of Thunder")',
  "Physical",
  "Break My Heart",
  "Supalonely",
  "Super Bass",
  "What Time Is Love? (Live at Trancentral)",
  "3AM Eternal (Live at the S.S.L.)",
  "Last Train To Trancentral (Live From The Lost Continent)",
  "Justified & Ancient",
  "Gett Off",
  "Dub Be Good To Me",
  "See You Again",
  "Love Story (Taylor's Version)",
  "Cry for Help",
  "Mood (feat. iann dior)",
  "Sorry Not Sorry",
  "Nobody's Love",
  "Glad You Came",
  "Whoomp! There It Is",
  "Dynamite",
  "FAKE LOVE",
  "Hip Hop Hooray",
  "Come Baby Come",
  "(We Want) The Same Thing",
  "Mercy",
  "Hole Hearted",
  "Everybody Plays The Fool",
  "Nothing Compares 2 U",
  "The King of Wishful Thinking",
];

export interface Track {
  title: string;
  artist: string;
  id: number;
  release_date: string;
  genre: string;
  preview: string;
  cover: string;
}

export const tracks: Track[] = [
  {
    title: "Stand by Me",
    artist: "Ben E. King",
    id: 80728352,
    release_date: "1962-08-20",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-919119b281524b0b9d45303e284e39f6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dcea225fa1c17ad5bab1241bff4d0acf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Space Oddity",
    artist: "David Bowie",
    id: 107465566,
    release_date: "1969-09-25",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77202dfcef8698e120c5231d245cdf2f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bd4a046f3d8c8895d9aff00a3b7c2df/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(Sittin' On) the Dock of the Bay",
    artist: "Otis Redding",
    id: 90859071,
    release_date: "1968-01-08",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-583565678527f4c0fedd2d421b491793-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e32d56c61a5914589c57cfd71c5f312e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Say a Little Prayer",
    artist: "Aretha Franklin",
    id: 904747,
    release_date: "1967-10-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3d1c7458b93d8cbe5b390e0404f62a9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/825419030587ba289ee683382b652136/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hotel California",
    artist: "Eagles",
    id: 68095470,
    release_date: "1977-02-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8af9cfb9a0454481e21989618e7c5779-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/208c9a0b2fcc579be8fb7200c9cc0d4a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stars",
    artist: "Simply Red",
    id: 2551829,
    release_date: "1991-10-24",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fb3477a2dccd5a8fdaaa975a6853c17-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2cb6d75e25263be723e1b31f9238978/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hungry Like the Wolf",
    artist: "Duran Duran",
    id: 4127465,
    release_date: "1982-09-07",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aaf155c63e00c0a34382615a61129b97-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3ef1ffaad88a51594e210a1075c2f1c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Against All Odds (Take a Look at Me Now)",
    artist: "Phil Collins",
    id: 134036230,
    release_date: "1981-10-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-06f5e92ba64dc2807a18075fa2eddb77-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heroes",
    artist: "David Bowie",
    id: 461043312,
    release_date: "1977-09-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71c489fdf2c00071ba9aba8543727d00-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fb91018679f65199308256be3c584ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Yellow",
    artist: "Coldplay",
    id: 3128096,
    release_date: "2000-07-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ad17e32a652aa4531403e11a66b77ef-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a31bfbc6678856d2cfe10dbb22b8a24/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Thinking out Loud",
    artist: "Ed Sheeran",
    id: 79875064,
    release_date: "2014-06-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c5807cf009b065b576c7781973241ef-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1d763da698c38bde6a526c8220ca0ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fast Car",
    artist: "Tracy Chapman",
    id: 2271563,
    release_date: "1988-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b70ecbdce6aad48d01bad0cab6d0f0f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9990f3c2febfc83c1d7218da7474cb2/250x250-000000-80-0-0.jpg",
    genre: "Folk",
  },
  {
    title: "Wicked Game",
    artist: "Chris Isaak",
    id: 1391362322,
    release_date: "1981-06-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-845c66660e9aace80f26cb8acce74822-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eeae28ccbcbb62f5cd43565812429e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take on Me",
    artist: "a-ha",
    id: 664107,
    release_date: "1985-06-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e817b793ead610762756f4f3a48dfcd9-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0ce8977ab98d73bcea00fc838ece034/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy",
    artist: "Gnarls Barkley",
    id: 1235037902,
    release_date: "2007-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9b4a9c15c40881c44e432769941ed47-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Best",
    artist: "Tina Turner",
    id: 3167295,
    release_date: "1989-01-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a31ef64abb5addac92367da7ec68fe21-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42024f50dc501575ae920ccf506fa4dd/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Want to Know What Love Is",
    artist: "Foreigner",
    id: 675818,
    release_date: "1984-01-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be34ea8d2d2224219eef1b3a62c24e14-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9ff017e261498ee3b7fd3707763b211/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fool (If You Think It's Over)",
    artist: "Chris Rea",
    id: 424000782,
    release_date: "1988-10-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aa7cd7b9b7e22416e0701067d94c828-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1872b42859b17538573fd64b8c6b93a3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bad Day",
    artist: "Daniel Powter",
    id: 771897,
    release_date: "2005-02-22",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eead6af2083365dd890a44d264834eb4-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0676a2ca21cc85832288b8b43f02482e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Like a Prayer",
    artist: "Madonna",
    id: 4209640,
    release_date: "1989-03-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25482c226fb821a0c118df859dc8d7c2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/195d3d2c1196b20711cfb6ad76e1c2ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Edge of Seventeen",
    artist: "Stevie Nicks",
    id: 135376666,
    release_date: "1976-11-04",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-649b47490aa2b9a8110185beed19886a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ff23019f5d481542c19706995c7980c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Move on Up",
    artist: "Curtis Mayfield",
    id: 115195420,
    release_date: "1970-12-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92db1a86df405d599f8d6c9223895654-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93637763db9f47b7a8f8c1e31ff11c0e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Last Request",
    artist: "Paolo Nutini",
    id: 3657960,
    release_date: "2007-01-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c2584db06f643efb3d2e57cff841e07-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f43d26dc4fa2d28279dd3665af1dad83/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Respect",
    artist: "Aretha Franklin",
    id: 904732,
    release_date: "1967-04-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-09922a19e087310a13616e955fb80552-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e9ba9115d0fb62879d1bb672ee67c07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're so Vain",
    artist: "Carly Simon",
    id: 688346,
    release_date: "1972-01-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85497dfe10d1c229b3c4450ed23e0a19-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ee8f9b6f3ba4d17c17cf3f6b43b24c85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Go Your Own Way",
    artist: "Fleetwood Mac",
    id: 67549191,
    release_date: "1977-12-04",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94b0f2ca53b19e6e5ccb22b730d536c6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/df696ab6f33650f6d8e32dda8adf5264/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "How You Remind Me",
    artist: "Nickelback",
    id: 810179,
    release_date: "2001-09-10",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-abb9372d51cf773c9817c8a32ef28a4c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61395db55522768a7a42e085c487d045/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take It Easy",
    artist: "Eagles",
    id: 68094695,
    release_date: "1972-04-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4956811d065e816988a60e767ad34cb2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b7a45d37330235f99a01a2e1e8106d1f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ain't Nobody",
    artist: "Chaka Khan",
    id: 776220,
    release_date: "1983-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b59804c0cf364ec2ef36bf6e1a0722e1-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84ecee5f0d598c5d0b5da405f6a3fa90/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Feel It Still",
    artist: "Portugal. The Man",
    id: 143171642,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5be7ed5b78972bf8362a628a0fa9bc6f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f204aa755d4354a8d548334f193fd86/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Don't Start Now",
    artist: "Dua Lipa",
    id: 793163542,
    release_date: "2019-10-31",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9bb595a44d64e7bdbf1a14fa9b2f8879-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2330b746089b5f6f4507cb84cc35d4d3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "A Little Respect",
    artist: "Erasure",
    id: 37210341,
    release_date: "1985-06-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42f7a2736e344e873f082321ea298c8f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e58e0507ac0a284381b3d52d3f9b4ae3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just the Way You Are",
    artist: "Bruno Mars",
    id: 7084642,
    release_date: "2010-07-20",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-714a5aeb851cd57eb56143a9f7501f20-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fca132eaf0b297e95aca78a2739a0f33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Somewhere in My Heart",
    artist: "Aztec Camera",
    id: 1235037862,
    release_date: "1987-01-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe58a17148bb34eb45689dcb0814eee2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Young Hearts Run Free",
    artist: "Candi Staton",
    id: 720737,
    release_date: "1976-02-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0199b1cff120177e7a1f777e96c94228-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a3cad753bd8e46f528d5d339f9f0e57/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Wings",
    artist: "Birdy",
    id: 69466071,
    release_date: "2013-07-29",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88f83bbbb58547db3550aafcf3cf91fd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d24aeaaa8ddb94c9e71b36ab1ac690c/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Golden Brown",
    artist: "The Stranglers",
    id: 3152622,
    release_date: "1981-10-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-832db1bcf0abf673abe381912c7e42b8-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/510f299a5e25a267f0dde2dcfd41d0f1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "We Built This City",
    artist: "Starship",
    id: 602137972,
    release_date: "1985-09-10",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-232990c8f476a53b8e8f1d21456a9a7c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/15cf311226db704fd9925c4362628ac8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Love Will Tear Us Apart",
    artist: "Joy Division",
    id: 1235037822,
    release_date: "1981-02-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3dafb75a952e5e5d5131331488609ee-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Charming Man",
    artist: "The Smiths",
    id: 13776514,
    release_date: "1981-06-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d09c9b9af7180a658dfc029d551e88c6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57d3a0dd210f59a53b889d2afb20c402/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "You Can't Hurry Love",
    artist: "Phil Collins",
    id: 119121832,
    release_date: "1982-11-05",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed5aae42f04e0fd8e699f1b108a3af9b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ac7ba5cf3b11fabf4e6779e40cfce16d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing's Gonna Stop Us Now",
    artist: "Starship",
    id: 602137932,
    release_date: "1991-05-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-55222cd34e7d2fba5afb1744f930a148-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b23cca2bd9985e39cb1909c3cc90cc9d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Groove Is in the Heart",
    artist: "Deee-Lite",
    id: 4099864,
    release_date: "1990-04-19",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-298b2d5caecc9cfab10490644dc719d1-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bb9cb815727f484385b813037fdb6c2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss from a Rose",
    artist: "Seal",
    id: 730428,
    release_date: "1987-11-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b6f225c283d8fbbcd51dc4a92f5a964-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c895d110af6fb22aa99c2d2bf995a0d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss",
    artist: "Prince",
    id: 664178,
    release_date: "1986-03-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88bda5b7d6476ed5b7b5d4c335958ead-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/07d3890b48303d09025f92ba7b9d67ac/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "True",
    artist: "Spandau Ballet",
    id: 3130319,
    release_date: "1984-12-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9007a8ca87de15e9bd9ba6c93bec1591-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b88d3c3ad23435fb5b8d52038551c1f6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It Must Have Been Love",
    artist: "Roxette",
    id: 3098301,
    release_date: "1981-07-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-087ef667466625cc3010ff8036798c77-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04a8fc1d885619f9418aab478cccf881/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Once in a Lifetime",
    artist: "Talking Heads",
    id: 5093672,
    release_date: "1980-10-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ab593fcf9ba97958c3fc0f7108dbf46-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f829d18efbdb8a54fbca7701d5098a9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "West End Girls",
    artist: "Pet Shop Boys",
    id: 3310512,
    release_date: "1986-06-06",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4b506d168bb3364db29d1b5f6cc3270-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/393d2ce1ba348485b895213e226bd9da/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Pack Up",
    artist: "Eliza Doolittle",
    id: 6719344,
    release_date: "2010-07-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0fc5756038a0a6bc0c89c5ff42c83589-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/db89f647fd33aad7fb446423ecc759d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Shack",
    artist: "The B-52's",
    id: 701763002,
    release_date: "1989-08-19",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9c8fd8a5a38d2e30272577fb13b01aae-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6818c7381d798e48f789709b882a77b4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's My Life ",
    artist: "Talk Talk",
    id: 3257507,
    release_date: "1984-01-15",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d1a46e4d7a6c48f95feb267b7b483ce-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d0f763786b6be3fe0450985f5e0c524/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Running Up That Hill",
    artist: "Kate Bush",
    id: 11616579,
    release_date: "1985-05-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20d97b302bb6209f943e23a2a153300d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f2d90023183010d657b01cba6bd5f19/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'll Be There",
    artist: "Jess Glynne",
    id: 491898322,
    release_date: "2018-05-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0167683656231ba442832c3ef0085fd0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b50f62715805aecb686fa1a90d1705b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just the Two of Us",
    artist: "Grover Washington, Jr.",
    id: 3085246,
    release_date: "1980-04-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e5ac9c12cacc92be13deb6c063d23ea8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6d04772ee490586a8771df7c2705c43/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Drive",
    artist: "The Cars",
    id: 474178182,
    release_date: "1984-03-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6185b2e1e03eb4833d230723eab910a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3c653ace62ec6833849e55970598199/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breathless",
    artist: "The Corrs",
    id: 661620,
    release_date: "2000-01-01",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b8924a147f8a93efe1933b61308aef1-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53399d8b50372230690a46d783a83709/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Soul Man",
    artist: "Sam & Dave",
    id: 685250,
    release_date: "1967-06-20",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4e17e6a0c43e93b0cc3240cb892c9cf-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/127d728275f3bacb36c19705ecf9ceb1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Yours",
    artist: "Jason Mraz",
    id: 845909,
    release_date: "2008-05-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e558b76b232e85cd3b4197b01b64ffc7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14afa8e767482283a4bd57ceae31eb80/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cannonball",
    artist: "Damien Rice",
    id: 904289,
    release_date: "2003-06-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0a584c13095c0db09399b2a07ec19ca-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fb2dfbd954d1bf84405bdafa9a76491/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Brass in Pocket",
    artist: "The Pretenders",
    id: 14636389,
    release_date: "1992-05-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bfce541a2dd34b05775b0f3eeb24ae61-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9eb2fd178dc4c9d53204ee6467cb52cb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When a Man Loves a Woman",
    artist: "Percy Sledge",
    id: 727540,
    release_date: "1966-04-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b38679212ca198a4d4c15bba0a61fe8-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb9cd3ff4da779845c681baf2241570e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Baker Street",
    artist: "Gerry Rafferty",
    id: 3169189,
    release_date: "1978-06-03",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-12166841d4a1fc6b9fb47a9e1dd87be5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4a40656ade105b7010c58687a14bd06/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Cry to Me",
    artist: "Solomon Burke",
    id: 672732,
    release_date: "1962-05-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c159908b21c898e003830d67691c7535-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/942ac93d77390fcded81ff7783c05b49/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Black Velvet",
    artist: "Alannah Myles",
    id: 674958,
    release_date: "1989-03-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c4d9e614034f334dff4857fa5eec107e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/38793735d9b791ceb15e0679161a9248/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Blue Monday '88",
    artist: "New Order",
    id: 1145114442,
    release_date: "1988-12-06",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-991285cc67a3f43dbf7b3b75a541ec2a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/36f64eb1dee209e6510f4805a77f2362/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking in Memphis",
    artist: "Marc Cohn",
    id: 675175,
    release_date: "1991-01-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84c606e8f63a134e36b79adfcfcb1873-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e93c673f9acf0afa27432ae67dfad76b/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Wish You Were Here",
    artist: "Pink Floyd",
    id: 116914042,
    release_date: "1975-09-15",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f7c1f7c23efa8577cf8610ec75194e11-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4720f991d5806bd8dcf46f9460737cad/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Perfect",
    artist: "Ed Sheeran",
    id: 142986206,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4d04fa22ff3cc680cad30eea149cc1d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/000a9228cecfcc7c2093d9cd7bb66447/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Harvest Moon",
    artist: "Neil Young",
    id: 677484,
    release_date: "1992-10-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba95d34546cda7590001327db9692543-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51e508e0f63b2d4f610d84503fc10467/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Constant Craving",
    artist: "K.D. Lang",
    id: 5414332,
    release_date: "1992-02-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-82995b6c93f151ef4dd10c13736e297f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d3f9b6f6f1836c8362d8f42ef5f37d4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You've Got a Friend",
    artist: "James Taylor",
    id: 2794416,
    release_date: "1970-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ab108a0786bba922d6618deda6f3a5d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/feb6b5dcb16e2f5e0441c1d3623f8c20/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Knock on Wood",
    artist: "Eddie Floyd",
    id: 690462,
    release_date: "1979-03-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a2a6de0ab37cbda4debeec654c83483a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0932240b16bf7293d82c58e05f5116ca/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "She Drives Me Crazy",
    artist: "Fine Young Cannibals",
    id: 700715042,
    release_date: "1989-02-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-095d34f3762aef22f9b968defc492ee9-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8f5b3da8a883596aba17a55067e426f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sleeping Satellite",
    artist: "Tasmin Archer",
    id: 3115574,
    release_date: "1992-10-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0845b43c61366668207cfc6c8c424a43-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b85663f5c0008c8fd0b7892506c4f822/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sitting Down Here",
    artist: "Lene Marlin",
    id: 3134928,
    release_date: "1999-05-31",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2e2554b1ecb9b9c82e66b6a6616cc2f2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a039abef730d570941f61c50d8b53e0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rather Be",
    artist: "Clean Bandit",
    id: 73982869,
    release_date: "2014-06-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d2e261169f82dd2789697fd19ed572b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3193132d50c74a62d1cd419fa170139a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Love Changes (Everything)",
    artist: "Climie Fisher",
    id: 3147082,
    release_date: "1988-03-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-507eda177b001070537ca56b0b3ddb8c-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ad9e952f6e2d02d54b4b68a2e5c440d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Got You Babe",
    artist: "Sonny & Cher",
    id: 735788,
    release_date: "1965-04-26",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-750099e4fc8d5ddfbb110441af5eef44-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ebbc4b4ee98befdb3c342aef82af73e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Love",
    artist: "Anita Baker",
    id: 717534,
    release_date: "1986-01-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4df376049864b67660cceb07a2db4122-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4dfc174b1bb5ce17188129fb2994fb78/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Into the Mystic",
    artist: "Van Morrison",
    id: 71433041,
    release_date: "1970-10-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3babab27947533b5017fee77f7957e74-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f648440c11c75ac519bd74b75f486cb8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wasn't Expecting That",
    artist: "Jamie Lawson",
    id: 103907218,
    release_date: "2015-07-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a87f6d40b064edff2fef9cbe023b9210-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0529672ee69ae96207d2967e785e23e3/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Daydream Believer",
    artist: "The Monkees",
    id: 728925,
    release_date: "1968-12-18",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af13deb9955b853252841efe7f53f6f3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ca2f96e8c7bb5c148f18b2d6e065662/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Smile",
    artist: "Lily Allen",
    id: 3153912,
    release_date: "2006-07-17",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b30dcbe1bdcee000103ff764b6caea97-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d6fe228b80ced09fde7666b3d9371567/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Love You Always Forever",
    artist: "Donna Lewis",
    id: 846048,
    release_date: "1996-06-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-150597c754b3113a5e25036f88ac6e14-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27795c295df90807631e2bffa1da0a73/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Owner of a Lonely Heart",
    artist: "Yes",
    id: 3613215,
    release_date: "1983-06-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3b016badfaf4a15d76b7baeff5ddfe60-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1bdcb776e4d04064cba66bdd494a94ef/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Viva La Vida",
    artist: "Coldplay",
    id: 3157972,
    release_date: "2008-05-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ab0b3c336efc5e72a8eb5f783f383a85-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eede3cd0dc3a5a87c7a5b1085b022e2d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Iris",
    artist: "The Goo Goo Dolls",
    id: 4209117,
    release_date: "1998-03-31",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d75996c9a9971ce9d40d77640f73af50-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82b3dfb072236e94f9d6c8204b0fd82c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Universal",
    artist: "Blur",
    id: 38441521,
    release_date: "1995-09-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71ce50ca06a129a75caa7e2d3f0a3c84-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f501ee1cff6f7ecdf0d8f2f67cceb4c4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Under the Bridge",
    artist: "Red Hot Chili Peppers",
    id: 724838,
    release_date: "1991-09-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d178c47b6048f48a58b1b7e033981ce-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d7af03204d679ef877b9033d279d8bd/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "A Thousand Years",
    artist: "Christina Perri",
    id: 14495443,
    release_date: "2011-10-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b944ecc72fe487f50fcd2cf9160ad79f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f361f0228d08731b70cb0c996520606/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rise Up",
    artist: "Andra Day",
    id: 105804300,
    release_date: "2015-08-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14a7261e5cd812bec8dadfaaf32fc913-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/428505622a2bf7bbed8b1d05cdb67b8a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Love Town",
    artist: "Booker Newberry III",
    id: 74785361,
    release_date: "1983-06-06",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cd7508de8b1f6110d7936d6f1c6587f3-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6acb8032280e22494773cc3b08229c29/250x250-000000-80-0-0.jpg",
    genre: "Soul & Funk",
  },
  {
    title: "You're the One for Me",
    artist: "D Train",
    id: 73236419,
    release_date: "1981-11-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c382cb27459adec89d4292d3ad1b773-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/180435dc808834a234c4e8bca7e4a48e/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Forget Me Nots",
    artist: "Patrice Rushen",
    id: 434614222,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-284b8ac6611e87fb57b644a2f313daaa-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c8189301e853ab1debddc16e2bce59ff/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Imagination",
    artist: "Belouis Some",
    id: 3873128,
    release_date: "1985-06-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6d8e339cb937819d987840c31e22c153-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f0e862ec9a60ba799292fb0e0ab5798/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Native Son",
    artist: "Bryan Adams",
    id: 1564430,
    release_date: "1988-11-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28b5b1b1e0857f1da7de40efb0034f5d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/665789e3447d33ef1f3af71ff4d44ad1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Feel for You",
    artist: "Chaka Khan",
    id: 685227,
    release_date: "1984-04-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c0ce584f4cc7368514fd8b782ef8f5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1b35699645d63299c0979e679d7f4ee2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "(Feels Like) Heaven",
    artist: "Fiction Factory",
    id: 5404615,
    release_date: "1984-02-26",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c6a13c163aadda377d5d09db9ebb335-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dancing In the Dark",
    artist: "Bruce Springsteen",
    id: 15586246,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b87c84eba8d82a62d9bf28c5e8422e68-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hysteria",
    artist: "Def Leppard",
    id: 447552872,
    release_date: "1987-01-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c894a5672f87659f5c8e7f45019a4874-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86d5af2077f5b6ef18fd0ca158a91134/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Stop Believin'",
    artist: "Journey",
    id: 534462,
    release_date: "1988-11-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b5c3b31a47fa4f795db48e5b1b2c2d56-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b97441032653a9f03f3d25a5dc2f459f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One More Time",
    artist: "Daft Punk",
    id: 3135553,
    release_date: "2005-01-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e77d23e0c8ed7567a507a6d1b6a9ca1b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e018122cb56986277102d2041a592c8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Get Lucky",
    artist: "Daft Punk",
    id: 67238735,
    release_date: "2013-05-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-853d19a12a694ccc74b2501acd802500-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b298094528702627877720d0be4448b5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Don't Feel Like Dancin'",
    artist: "Scissor Sisters",
    id: 2315311,
    release_date: "2006-07-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8046f3d34f73e4fd5665f89dc7d18ff0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0150dc66d11638cd0771642d01904ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dreamer",
    artist: "Livin' Joy",
    id: 117106930,
    release_date: "1994-01-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-032b20a5886396551ef073486a6058e9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ef022d3d20ed7fa43500a08194768e9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "U Sure Do ",
    artist: "Strike",
    id: 129736034,
    release_date: "1994-08-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d0d11266bef63f6a32339d51a216f11c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0fc707635fcafc750d905d9bd9560ad9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Love Come Down",
    artist: "Evelyn Champagne King",
    id: 5404581,
    release_date: "1982-02-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-904a80885f6082dfec67789af6c54a0c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lets Groove",
    artist: "Earth, Wind & Fire",
    id: 430578532,
    release_date: "1977-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aab6413994118b61630a9b830831e802-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/389e3012c41b48aa493020973fa483fb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Free",
    artist: "Ultra Nate",
    id: 86921769,
    release_date: "1997-04-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c0b93ffc391ec44b1c4630dbfcd1f561-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/12c9401626942f3db294bd916332bf75/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Push The Feeling On",
    artist: "Nightcrawlers",
    id: 105353318,
    release_date: "1992-08-21",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3111f8eec4820558d1d22b05f85a526c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48bed431be72f9e0e68830f13051254b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "And the Beat Goes On",
    artist: "The Whispers",
    id: 131212454,
    release_date: "1980-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74fc7254eda3b1be43785714bed70fa1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1576b2e2f508ed996238bc9913c0fed0/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ain't No Stoppin' Us Now",
    artist: "McFadden & Whitehead",
    id: 4760904,
    release_date: "1979-12-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1efe49a2b45a558eefee1a5eed7ea64-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5317a8ed11b80200085a5300f4bf8ebb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Got to Be Real",
    artist: "Cheryl Lynn",
    id: 68075550,
    release_date: "1978-06-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba6c22196f0de572729a88edabdded35-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6033d76cdc6c1c2ebe48754c6a719b69/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Can't Wait",
    artist: "Nu Shooz",
    id: 71807622,
    release_date: "1986-05-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0f5d0d537eeb89a77de02689e0fa122-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75b5b2eca192562caf0a85f65f12d371/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Never Give up on a Good Thing",
    artist: "George Benson",
    id: 95964494,
    release_date: "1981-02-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20f054ed6667eb1fe1d474a646924448-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7d27b5c59a8da92ccf7d24d82aceb0f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Can Make You Feel Good",
    artist: "Shalamar",
    id: 5448334,
    release_date: "1982-01-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1d945e204872aae8ad562becbf94c26-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3ebc84e231d71d3807a66dc7658a9c4/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Give Me the Reason",
    artist: "Luther Vandross",
    id: 15176244,
    release_date: "1986-09-19",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-501cb4fe04586fe852b72bd2f3dea6ce-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0da8b7dd2dcf02359cb82f30eead27a1/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lost in Music",
    artist: "Sister Sledge",
    id: 846328,
    release_date: "1972-08-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7258174843c3ec7819b9a01df3929c4f-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b384580ff1e772c40db28546a9811d32/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "We Are Family",
    artist: "Sister Sledge",
    id: 846327,
    release_date: "1972-08-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6b05c20abf869b9e739551ad0305bcf-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b384580ff1e772c40db28546a9811d32/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Can You Feel It",
    artist: "The Jacksons",
    id: 5404550,
    release_date: "1980-02-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b87577c5372e2278c26048db92b7fda7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Not That Kind",
    artist: "Anastacia",
    id: 833621,
    release_date: "2000-05-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7ca9a09cbaa6d345db9a0da124b0968-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d468c03b8c03bf5edb8c310a7dd56b8c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Outta Love",
    artist: "Anastacia",
    id: 833622,
    release_date: "2000-05-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eea475a0e8c70d276bfb15623a733b15-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d468c03b8c03bf5edb8c310a7dd56b8c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm So Excited",
    artist: "The Pointer Sisters",
    id: 546757,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0735eb29acc57c39728b521d0daf54ce-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe6400d84e249e3800f3df2da1e91958/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Times",
    artist: "Chic",
    id: 6614292,
    release_date: "1979-10-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c2c096fc9c666d34c2876aa645f0fc8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/868b1a467d78ebe197444b293b52b10d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Gonna Be (500 Miles)",
    artist: "The Proclaimers",
    id: 3109247,
    release_date: "1987-08-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5cf464a29ee9984a7079427ed1c53447-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f22a178ac1d33dc7abe7564f0404a6c8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Reach Out I'll Be There",
    artist: "Four Tops",
    id: 909611,
    release_date: "1966-06-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba131b95d8265885ecdb9c6833e4c746-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18b4eeac942cf8f4e4fee33fd954ff17/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boogie Wonderland",
    artist: "Earth, Wind & Fire",
    id: 1277771,
    release_date: "1978-07-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b20ff68f25b681ba1678f03aa724902-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fe1f8e4c7ea6bf2b1f99543d3a8c35e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Who Let The Dogs Out",
    artist: "Baha Men",
    id: 3389258,
    release_date: "2000-07-25",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9bb5bc163fc483e3496f92ef88b20032-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c811e7975cf933fdb0b17a6426dcc01/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hey, Little Girl",
    artist: "Icehouse",
    id: 13213908,
    release_date: "1982-08-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a075d91d751c69831c0d510ebad6e20-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c0ec5d3e566373109767afce9f9879eb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What Is Love?",
    artist: "Howard Jones",
    id: 367292071,
    release_date: "1983-06-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3deded5081d94f0a096faf67572655a4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4bace238d73b2329d4f6596a71ee6aca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Killer / Papa Was a Rollin' Stone",
    artist: "George Michael",
    id: 16433503,
    release_date: "1998-01-30",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0609e89277d9dcb5c10b9a45136e8a47-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/549a1f50cd5903e2f42e3253814f8b33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here Comes the Rain Again",
    artist: "Eurythmics",
    id: 1022289,
    release_date: "1984-01-06",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5b5969f6e6661e38f2017b11dc1841e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81061a21fd646f5bec5dcfe32684385b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thorn In My Side",
    artist: "Eurythmics",
    id: 1022300,
    release_date: "1991-03-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d214ec732f051fd2e50e5d84c8de83b0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81061a21fd646f5bec5dcfe32684385b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Is A Stranger",
    artist: "Eurythmics",
    id: 576086,
    release_date: "1991-11-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d03738f6a80d353e0c780c2677210bee-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/160c2f2740b5043d609af68003d01eb0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What's Love Got to Do with It",
    artist: "Tina Turner",
    id: 3120266,
    release_date: "1984-02-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-083ffa1c986d0da6a79299a09fd68691-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b5022390f35e035d14edd5e0d6cfb92/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Sometimes",
    artist: "Erasure",
    id: 143041970,
    release_date: "1987-10-20",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87cd1b8510115ce2baece05938a6e5dc-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc1c2bd258e623943d223ab873674d82/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "We Don't Have To Take Our Clothes Off",
    artist: "Jermaine Stewart",
    id: 9968997,
    release_date: "1986-02-25",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e15b496715c320f0500a0d9a3bbf015b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bfc7009e751d43d96f7e8b1aabf7e8f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Nineteen",
    artist: "Paul Hardcastle",
    id: 132042578,
    release_date: "1985-09-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c72471c2d0f401a9208f268f8c796270-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6aeffa2d5c980f71a2e3efc5303ec6df/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bad",
    artist: "Michael Jackson",
    id: 59509531,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6af9d84fbe64703d53cb09674107764c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Can't Get Enough",
    artist: "Depeche Mode",
    id: 68511564,
    release_date: "1981-10-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-845b21514d119725845ce6a5b2d996e2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9ec9c75ccce73408e769d18f382669fd/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pretty Green Eyes",
    artist: "Ultrabeat",
    id: 379139391,
    release_date: "2007-07-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74d81a62e859f5c077aeb803d688c0af-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bbf94dc2b6784a263c3c8ef611f0afcf/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Dakota",
    artist: "Stereophonics",
    id: 2420458,
    release_date: "2005-02-28",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-41617eb5aae34dd3be513cf189033920-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d210da789d5468aa7f4322e4c4941b8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Go",
    artist: "Yazoo",
    id: 143039072,
    release_date: "1982-01-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16a9ba6784173785d576d2da516f10b2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de6b4c328de177e3331e8e2826305c0f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All Around the World",
    artist: "Lisa Stansfield",
    id: 1019524,
    release_date: "1999-12-31",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0f4466060fd296ee615706186528980-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b93da8c24fe0a6942d10caec26725afd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Have Nothing",
    artist: "Whitney Houston",
    id: 5363365,
    release_date: "1992-02-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d4523e57206bbaa14db2c83651f97db2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9f963b239113b1f92c39d10a051d95e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Call Me",
    artist: "Blondie",
    id: 3104486,
    release_date: "1980-03-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4f9787e48485c475ff7dc59c5cbb962e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cdc9d8ba7d6f9d8c40c5389bb4577409/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Sun Always Shines on T.V.",
    artist: "a-ha",
    id: 664115,
    release_date: "1985-06-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-736d5dc2597f1abc7a8228570244fd01-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0ce8977ab98d73bcea00fc838ece034/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Night",
    artist: "Real McCoy",
    id: 1042543,
    release_date: "1995-04-10",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28e1a18fd03df6ce31723fd649a9dcdc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8cdc05b609232d463417238a6dc09e89/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heaven",
    artist: "DJ Sammy",
    id: 69420264,
    release_date: "2002-02-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d370a760b13a4e18ead0b86a4a099789-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62a81585292c53acc048eb02b3fd7b62/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "9 PM - Till I come",
    artist: "ATB",
    id: 61639668,
    release_date: "1999-08-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa5c8d9d0204ff46f69adf82ee7eaa75-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dbb747abd287e0dead5c68ef6c3e2bd8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Silence (feat. Sarah McLachlan)",
    artist: "Delerium",
    id: 85276490,
    release_date: "1997-09-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6822a49b80356b12ebea6204366880be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b37a343d30a8a723b22e0d45896e05f9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Forever",
    artist: "Super3",
    id: 414650452,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d13f959288f617fe4fe4789d2f705d7b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/79a92923ada47f26ac6b8e1c3d4ff0f4/250x250-000000-80-0-0.jpg",
    genre: "Kids",
  },
  {
    title: "L'Amour Toujours",
    artist: "Gigi D'Agostino",
    id: 35782811,
    release_date: "1999-01-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bca5828f633d6633f7a51106db6c1f66-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e19251a60eeae5b1943861dffc23ea5e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Love & Pride",
    artist: "King",
    id: 2311058,
    release_date: "1998-02-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16ab1668c0441aa479ad8bc3d87bfe19-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be74b8af8f2677d8ff8f923a38b0dfb7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Encore Une Fois",
    artist: "Sash!",
    id: 133949042,
    release_date: "1996-03-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7da797c83c315d940e52c4b4b030d0ce-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28b63337235b5d9d1633e260e501c04c/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ecuador",
    artist: "Sash!",
    id: 133134750,
    release_date: "1997-03-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-47ca01bd3d8350f388c9f23642baaadd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dc2524744ef735f7fe0471c0c95f5b2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Be My Lover",
    artist: "La Bouche",
    id: 4096754,
    release_date: "1995-08-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-184efc586336cb6491988a262ff3f0a5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02f880e927fe2d0ea5c5c234ef0bd050/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "For An Angel",
    artist: "Paul van Dyk",
    id: 60827150,
    release_date: "1998-10-28",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b55a0c4986990aaa43c36179ea36a1a0-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f16bad5d5de8b2e243f8e7470b0ef91/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Party Rock Anthem",
    artist: "LMFAO",
    id: 12452085,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3ef1780765879efb593ab8dcd122bf4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sexy And I Know It",
    artist: "LMFAO",
    id: 12452086,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3be73fb4ad7345466f3c44f00849afc0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sorry For Party Rocking",
    artist: "LMFAO",
    id: 12452084,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-644ff92bb8d578e58598ad2353564931-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Halo",
    artist: "Beyoncé",
    id: 2485108,
    release_date: "2008-11-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bba1ec9f872bfa1d167161fb42bdc7d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0450a010b5a825194d7ca00b3067ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flames",
    artist: "David Guetta",
    id: 473784012,
    release_date: "2018-03-22",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a3707ffb8d8fdc30df8fbf43dcbc782-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ba194ad2417b1cc6d79a34113995cb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Alive",
    artist: "Sia",
    id: 107980710,
    release_date: "2015-09-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec9133f8fba10bb30556620aa12ee51c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/36ef26001118282b59659568f267bc36/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Smalltown Boy",
    artist: "Bronski Beat",
    id: 428735732,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b66ff0839b24ee7e8b84f42134ef2575-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ffbbe6dfa1ff0d6ff551379a496b8f85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy 'Bout My Baby",
    artist: "Randy Newman",
    id: 123820402,
    release_date: "1963-10-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3244036b73c1ba8acbd5e4fd0e1c73e0-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d7ac975bb038b063abb2cc61b23791a3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cry for You",
    artist: "September",
    id: 15543742,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ef3280a5b3b775f8da2c3455ab66f07-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bacb7ec2fe6b57f1af8f9a9efc083b68/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Rhythm of the Night",
    artist: "Corona",
    id: 61723285,
    release_date: "1995-01-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f97416643cdf419ed612e60d8574a4ef-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb7dc8ffabfb85536cbae5ca1801f39b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Key, The Secret",
    artist: "Urban Cookie Collective",
    id: 12159134,
    release_date: "1996-07-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-020354f403e87b56684efd7fc556ec8b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57ffff16dc7fff50b677277367f3fb6f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Bring Me To Life",
    artist: "Evanescence",
    id: 80274512,
    release_date: "2003-06-24",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cae4a814e972d68fe00695271871ef40-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56c02d3764f5a078ceecd27b64b7d789/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Jar of Hearts",
    artist: "Christina Perri",
    id: 12343340,
    release_date: "2010-07-27",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1694e8cfa5424dcbeb3f8fbc983030b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2cf468fd0556a7e87d10640ebb270587/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Only Girl (In The World)",
    artist: "Rihanna",
    id: 13529563,
    release_date: "2011-08-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d20ea30267f84d42b66c6aa61c3aa43c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a12c64bb52a167944783878ffe41f02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shut Up And Drive",
    artist: "Rihanna",
    id: 925110,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0e2f4196ee369ad3328a5f6b0b3e8f8b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Airplanes",
    artist: "B.o.B",
    id: 6461684,
    release_date: "2010-04-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0016d9da7194d83c1b4992bafb120ef0-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1922ce037df0dd576c008bb626320303/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Jai Ho! (You Are My Destiny)",
    artist: "A. R. Rahman",
    id: 2947119,
    release_date: "2009-02-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf7449dce83b1ae9c037efd50d22dd85-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6bfa2ee147854dc35fdc5dd4950a084/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stereo Love",
    artist: "Edward Maya",
    id: 6310384,
    release_date: "2010-05-09",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6d7a44a4e0dd6dfe5cc770934abc36e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ad30b3a0b496dd1e7b126b5b213e2cb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Infinity 2008",
    artist: "Guru Josh Project",
    id: 61686264,
    release_date: "2008-06-06",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b8367f9c269cc2c257fa9d27e0570339-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19ebbbef82394515787864d7fc480170/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Stop Me Now",
    artist: "Queen",
    id: 12209331,
    release_date: "1979-06-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5a9b2da5acb610170196eb5872c7d2ab-17.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb22a75aa1aafc992230abd0bd79faef/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Under Pressure",
    artist: "Queen",
    id: 3155488,
    release_date: "1981-12-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fd0525f2f9cbf39468204210e8c1610-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a1daf96211c7d0d662eab97b5e6e337/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Radio Ga Ga",
    artist: "Queen",
    id: 7868649,
    release_date: "1984-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c4208e44f727d6114d5e24ab25f701-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa63381faefb51fba501498bc836765a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Want To Break Free",
    artist: "Queen",
    id: 7868651,
    release_date: "1984-01-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c6099e818548df3284b02303905754d7-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa63381faefb51fba501498bc836765a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Eye of the Tiger",
    artist: "Survivor",
    id: 576431,
    release_date: "1982-03-29",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10457ff7a729df6d66df6f19ab135a4d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e66b5d3a40f69690c1633afb73cc590c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Know Much",
    artist: "Linda Ronstadt",
    id: 7125589,
    release_date: "1986-12-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-022dfcfe3cb58134b286edd10b01ac93-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/095eaaa2dec76bd2b120d10edc708b37/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unchained Melody",
    artist: "The Righteous Brothers",
    id: 885544,
    release_date: "1965-06-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00591b3adbdd0ee9879ebf9252d41316-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4d10d999f93483e2ea1a4e3496d8d72e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Name Is",
    artist: "Eminem",
    id: 1109729,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b410ccaf3c000eeaa74025f558892638-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Summer Of '69",
    artist: "Bryan Adams",
    id: 88902741,
    release_date: "1984-11-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a39fc1a77b3a221c68103011a6787ac5-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7be525c05b1942e1844d0073f7b9ab5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All You Need Is Love",
    artist: "The Beatles",
    id: 116348734,
    release_date: "1967-07-07",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2cbea4cbaf6cc54b8bd66dcaaa2b59c9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c105ffd0f6855c565cd3a0be47a1ee31/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Digital Love",
    artist: "Daft Punk",
    id: 3135555,
    release_date: "2001-06-11",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01ef0c4982c94b86c7c0e6b2a70dde4b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e018122cb56986277102d2041a592c8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Firestarter",
    artist: "The Prodigy",
    id: 62126191,
    release_date: "1997-12-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f147f6b923a1bf1b973ab79e991a023-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/566d28d32080a6d82a2d4d145ea5ea7e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Purple Rain",
    artist: "Prince",
    id: 2794654,
    release_date: "1984-06-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d7c9849786a6614c20d1a1de49dc1bd3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88b5b2593429607587f5ea0f931af960/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "1999",
    artist: "Prince",
    id: 690101,
    release_date: "1982-12-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-35f1254f078a907de89df342c5de137a-22.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e4e1feb81f8ac2be66599b362d5451aa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Most Beautiful Girl In the World",
    artist: "Prince",
    id: 536618412,
    release_date: "1985-09-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffb64ac3de69f3f7a045beea631e3cb1-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b94aca81917de9254d54cb45ba820677/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Gangnam Style",
    artist: "Psy",
    id: 60726278,
    release_date: "2012-09-06",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-57793edd9b2473c82d250249b623793f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45e4493fcc15997085491debddcf049e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waiting for a Star to Fall",
    artist: "Boy Meets Girl",
    id: 5404589,
    release_date: "1988-02-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a39d1c5b9db881e3858809ecf5507e86-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shake It Off",
    artist: "Taylor Swift",
    id: 89077555,
    release_date: "2014-10-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2380c0128a698e232331e8225944315-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68b4e986958b17f05b062ffa8d7ae114/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "There Must Be an Angel (Playing with My Heart)",
    artist: "Eurythmics",
    id: 561785,
    release_date: "1985-11-07",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56462aaac7ee142c495c6888bf6247df-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d68c40aa16097bb08b7537d1774db3fa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'd Do Anything For Love (But I Won't Do That)",
    artist: "Meat Loaf",
    id: 3166759,
    release_date: "1986-11-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4966df866c5257d7eee46580c746a6e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b756a9a509d0c3e15d8919b57e6cb4ff/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What's Up?",
    artist: "4 Non Blondes",
    id: 2184743,
    release_date: "1996-07-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d13de786b154b10be49cb7827af47ac-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22ab04ebf46e75a8c2c83a774c8831a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Will Always Love You",
    artist: "Whitney Houston",
    id: 2244121,
    release_date: "1992-11-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a42593ecbb6884418d6b1b2db3a4c8e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31ba5cdafbf9523b624afc282688429d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Barbie Girl",
    artist: "Aqua",
    id: 1115044,
    release_date: "1997-10-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce59c8a004ae528ba4745f453eb428b6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/89c2dae2b498dc9ca9151324d02eca2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Always",
    artist: "Bon Jovi",
    id: 538586982,
    release_date: "1994-08-10",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92059740f22ea74367426ccadead8c0c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f513327c13908b0def56f1fa0ca79789/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bitter Sweet Symphony",
    artist: "The Verve",
    id: 398570642,
    release_date: "1997-12-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a419590527595bbc5fea9a07f94d91b9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecc7727e897198892b98e7c019fba45f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Losing My Religion",
    artist: "R.E.M.",
    id: 136334560,
    release_date: "1991-02-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c529aa2f5a6fe7bd6579648bf885dfb8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a968ed3814acb2ef8f3929b1bebcb42d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "...Baby One More Time",
    artist: "Britney Spears",
    id: 540175,
    release_date: "1999-01-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cd9497093c088d198b226e058044787d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd904e47db5549124f099c1fef304dc9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "They Don't Care About Us",
    artist: "Michael Jackson",
    id: 8164642,
    release_date: "1997-11-13",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ca07ef6e13a98031151aa6e86af60724-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Desert Rose",
    artist: "Sting",
    id: 2525873,
    release_date: "1992-02-10",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4edca5068bb6820aef6ce2a9918a9575-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/316afdaed93c4a18cf730389648d03d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Believe",
    artist: "Cher",
    id: 786717,
    release_date: "1998-10-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e65c59b2245a6ebd33e25da0313d03cc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af263778ad6ef2f3f5be381292ed02a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "More Than Words",
    artist: "Extreme",
    id: 1564562,
    release_date: "1991-08-20",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7c64505dcfc6444ef7ea744df97bb4e5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ddf2071955dc0bee2ee5cd5ced4d613/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Linger",
    artist: "The Cranberries",
    id: 1137657,
    release_date: "2002-06-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0687e4d1d0990601c81d3b01bb8e5ee3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dadf15bb8ea7e829bd69b2f3966c701/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Maria Maria",
    artist: "Santana",
    id: 82241406,
    release_date: "1999-02-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da7687b28c3f0f885f5a0f042cc1679c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wonderwall",
    artist: "Oasis",
    id: 84572613,
    release_date: "1995-09-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d41f35db23b700b359dc3225ca79d44e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d63b6ce2589b96cee3e6aa85d996023a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "When You Believe",
    artist: "Mariah Carey",
    id: 596057,
    release_date: "1998-11-02",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bcb5f4ae5ce54009c86d6647b5ae95f2-91.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Enter Sandman",
    artist: "Metallica",
    id: 20481851,
    release_date: "1991-07-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed306e418e79b81cf4a4707d3957dc7a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37c5bd270ca19bcf6bd5e9354f9f3df0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Livin' la Vida Loca",
    artist: "Ricky Martin",
    id: 13111370,
    release_date: "1999-06-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-48f9cc3ac0725d5273fafff9f457c0ed-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/554607caa28b1531ea6f447aec02ecdd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Man Who Sold The World",
    artist: "Nirvana",
    id: 1105736,
    release_date: "1994-10-27",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be2aad542197a67130e8ca5743a02005-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe1082c5ef54876802146897e76b592e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wannabe",
    artist: "Spice Girls",
    id: 3164155,
    release_date: "1997-11-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424c75747f71ceb18fa48ceef8c2e197-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba4d6b08d01a59c34e14a8cd5727c6fb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Scrubs",
    artist: "TLC",
    id: 1075781,
    release_date: "1999-02-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-300c6c7963f5373f4628e04bb2fab671-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dd5f40e7688ba155a5ef557977e95d3/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Don't Want To Miss A Thing",
    artist: "Aerosmith",
    id: 624823,
    release_date: "1998-06-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6effc395335f900ecbc7bbf2869a4e8a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82bb160fe7b46cca64dc989a4f5843d0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All Star",
    artist: "Smash Mouth",
    id: 917717,
    release_date: "1999-08-02",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fb0a9f7043f70c7a89aced8c89dc89c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34a4542cb4e8a54639674ed6aab1bd65/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Coco Jamboo",
    artist: "Mr. President",
    id: 2194974,
    release_date: "2008-09-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ee8fa053a712988896018aada47bdcad-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3152ba548375ae7fba3399a0e6b38fe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Scar Tissue",
    artist: "Red Hot Chili Peppers",
    id: 725254,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eb594dafcc4378286fcc5eabcd9ff6c9-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bed Of Roses",
    artist: "Bon Jovi",
    id: 74628110,
    release_date: "1992-04-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e6a132dbd5dd671cc37e86ecdecd93c-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2821c5cfa61955ddd982c15cc21882/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Earth Song",
    artist: "Michael Jackson",
    id: 8164645,
    release_date: "1983-11-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a4beb688d1fc48c1ecb063ca08b03152-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thank You",
    artist: "Dido",
    id: 962173,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a1bc29167e93809ebb3cf9026739ea4a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eaae15d23993db3cd9c69ae113c4333/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Cry",
    artist: "Guns N' Roses",
    id: 1169677,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d494c13c6da1a5f00ba3eb26106a19bf-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smooth",
    artist: "Santana",
    id: 82241402,
    release_date: "1999-06-29",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94aaceeb29fcd6b86c4cdc2135df6d2c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Black or White",
    artist: "Michael Jackson",
    id: 15593566,
    release_date: "1981-10-16",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d99cc18414c8d4dd96eace504aeec035-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Kids Aren't Alright",
    artist: "The Offspring",
    id: 137233986,
    release_date: "1998-06-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c22d48f29c8acdb9dd2a3476237bbb2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I'll Be Missing You",
    artist: "Diddy",
    id: 2794603,
    release_date: "2004-03-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77384daa602dc69238c8120aee4eaa7c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/755dc7da07ba60a8c879963858150f84/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "You Are Not Alone",
    artist: "Michael Jackson",
    id: 8164649,
    release_date: "1997-11-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8beb0e99af20a7afcadfc4b914b5dfa5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Boy Is Mine",
    artist: "Brandy",
    id: 662355,
    release_date: "1997-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd8a0e92c85368491ccb10c463eee254-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4112854d5ad8f7b5dffa421c9df06515/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cotton Eye Joe",
    artist: "Rednex",
    id: 88328649,
    release_date: "1994-10-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-186991c30b6bfa5227144ca75c1ff936-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c9c7419d8c50f843b5e42e8300ebdcb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Basket Case",
    artist: "Green Day",
    id: 678044,
    release_date: "1994-01-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8518dd3ae3f2c754a4e285546e28f697-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e17054b7bfa6576f64ea867b71ef479/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Torn",
    artist: "Natalie Imbruglia",
    id: 554446,
    release_date: "1997-09-05",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c48405f575393141b2c3edeab06b2e3f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce07121876070fc4ac134e9111871e5f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hero",
    artist: "Mariah Carey",
    id: 596015,
    release_date: "1997-11-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a3f83b026c0792c3113836a7e4b899d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Without You",
    artist: "Mariah Carey",
    id: 489646332,
    release_date: "1993-04-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98a6f188d0538d069cfbba3ba31c0fc1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/97b4d44589c542d417003de8c5d68d2f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Return of the Mack",
    artist: "Mark Morrison",
    id: 703748,
    release_date: "1996-04-22",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da7e01ceaada1c767829f08e735b0bb1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/655e659d77faed4a3dca4f9c5a0fcd9e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Heal the World",
    artist: "Michael Jackson",
    id: 15593565,
    release_date: "2001-10-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-33e7b8736ba7e87030703ad2af11430a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All I Wanna Do Is Make Love To You",
    artist: "Heart",
    id: 3482138,
    release_date: "1990-03-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a86f88d42ea837c4a01f5d03d0a2f67-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e45eca89fbb8d759a7e4a5d66349a93/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "One Headlight",
    artist: "The Wallflowers",
    id: 2193499,
    release_date: "1997-02-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b34ff58dc306b4e51606aaf001e07c7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3fcc02ae974d3eb6ff036ba325fb1e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Send Me An Angel",
    artist: "Scorpions",
    id: 927907,
    release_date: "1991-01-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e154046e457298847e4c21d3b948f3d7-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d1724dd3ac61f2e1df593c543ce2b22/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Truly Madly Deeply",
    artist: "Savage Garden",
    id: 117262952,
    release_date: "1996-03-23",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e2152deb69ad631fafccf70ae4a28ac-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d393bffce44a4cf6ac86fab7adc51c62/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Save Tonight",
    artist: "Eagle-Eye Cherry",
    id: 2252935,
    release_date: "1998-05-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fb9c3cb87eb92092b981af7196b59a8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e266242f0f87f9f394f12b1ad3b549f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If You Had My Love",
    artist: "Jennifer Lopez",
    id: 15475926,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67982c16d806e6654446aa62ed9737c7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9e59d8b00661ff2971cf7e62e1d780c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Insane in the Brain",
    artist: "Cypress Hill",
    id: 1015862,
    release_date: "1993-07-20",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-86fefd9738a2a6e337736f0261570acc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff3a4fbb250a131f8cf3f413814eb29a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Black Hole Sun",
    artist: "Soundgarden",
    id: 78631539,
    release_date: "1994-06-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-317614ee96aeb76afc0a2c51a20cf6bc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/94248b664be0890be31cabf62a068293/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Boom, Boom, Boom, Boom!!",
    artist: "Vengaboys",
    id: 397344472,
    release_date: "1999-12-10",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba04da3f9e7b6225c4af615e4aaae592-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b9f7f702c76d22e23cabc3fa706a100/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Men In Black",
    artist: "Will Smith",
    id: 626417,
    release_date: "1997-11-25",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-24676370a0b252c614085ed5b0646411-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e06e37cd6c0b10dde07ff71b4a95fc2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Why Don't You Get A Job",
    artist: "The Offspring",
    id: 137234014,
    release_date: "1996-12-02",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a6f425a1ab9ad11022c6f4444496771-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sweat (A La La La La Long)",
    artist: "Inner Circle",
    id: 7777963,
    release_date: "1992-12-14",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cbed12df9156867b2cbe2ddfbd92629-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1c95e242a8ba84335affb3111ef14410/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "When You Say Nothing At All",
    artist: "Ronan Keating",
    id: 3978398,
    release_date: "1997-11-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5214b5eb6dc7fba7d869794f74c21917-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a7ebcd872ef1170457992f28f878d08/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "More And More",
    artist: "Captain Hollywood Project",
    id: 143154464,
    release_date: "1992-07-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5bedd004066286ab1c24d00a47aade1c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55053ac3f523c231fb7850e67731b9e6/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Everlong",
    artist: "Foo Fighters",
    id: 4762041,
    release_date: "1999-10-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ac995d4ffd81eccda77f35db0fc2fb4-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/266f01f1c7a04843d11cd08f9c07d11f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Candy Rain",
    artist: "Soul For Real",
    id: 1110898,
    release_date: "1997-09-10",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8e91e8770f15c0c702766bfc0c9978b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6583e3244e2bec938a38f0174018740/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Remember the Time",
    artist: "Michael Jackson",
    id: 15593563,
    release_date: "1981-10-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95771b49f6ebe27f6759e39f49083cfb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bailamos",
    artist: "Enrique Iglesias",
    id: 2604907,
    release_date: "1998-11-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67850a0fb50ebea85df3f6b5ca9c9a6b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lithium",
    artist: "Nirvana",
    id: 13791934,
    release_date: "1991-09-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-457c2231637b46c194c8894b4dbae31f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0282817b697279e56df13909962a54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Dragula",
    artist: "Rob Zombie",
    id: 2307303,
    release_date: "1998-08-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e7b7148a74f5bf3ed95d0a10f79231f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9ec5b52540fbb85df121d796616a8bfc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everybody Hurts",
    artist: "R.E.M.",
    id: 121921372,
    release_date: "1992-04-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-304c4eddd6bb91ad7ed117ef74680fdb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f9e1252bb3ee900f342a2c83a378922/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Always Be My Baby",
    artist: "Mariah Carey",
    id: 596030,
    release_date: "1997-11-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cd8776695cf2c5dbef9450d6f1661bd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nobody",
    artist: "Keith Sweat",
    id: 3616541,
    release_date: "1994-10-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0467575217253a0aee7768b0f956da4f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a33be0dde86b9bc7579703c246f9c33/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Down In A Hole",
    artist: "Alice In Chains",
    id: 7675186,
    release_date: "1996-07-29",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5846f4d41897e94b17f6480f122042a4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dd96fe2b1ab3ed2b750fad203de61ee/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Insomnia",
    artist: "Faithless",
    id: 7035328,
    release_date: "1995-09-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8a7302b9a090873bb60955a7ef8e9198-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa2aaac3c9182ce203abc4319107d143/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Song 2",
    artist: "Blur",
    id: 44133771,
    release_date: "1997-02-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c633c54de1797f0289aa98e284eb42cb-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c171487c2b0cd14f12b015aa76304394/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pretty Fly (For A White Guy)",
    artist: "The Offspring",
    id: 137233982,
    release_date: "1996-12-02",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f51479a3de8d32a7c22c82bbc4d56a9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "California Love",
    artist: "2Pac",
    id: 910683,
    release_date: "1999-12-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-03b6afdebdf4f72966aa3a5934d78e9a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49856d92a31e284c7fe34b7971dba0b0/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Knew I Loved You",
    artist: "Savage Garden",
    id: 532605,
    release_date: "1997-02-02",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba4b1767f6961a78d77f31bce9600137-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b96611d8619d9badef37b832da16c7f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lovefool",
    artist: "The Cardigans",
    id: 910474,
    release_date: "1998-01-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-813c750b6f5cc2077fb5846c4c48c21d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c7dcdea2a0d4f52f2a9fae5610194653/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nookie",
    artist: "Limp Bizkit",
    id: 94613610,
    release_date: "1998-07-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-edd162580052a50c9ef667a03e9bebf2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/997093089e97966606459e719848329f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fantasy",
    artist: "Mariah Carey",
    id: 1013792,
    release_date: "1995-10-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f697bffdb916d73e13715e3b2697ddf-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/98ab83037b73f9fab9d97b5644ea2668/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get A Way",
    artist: "Maxx",
    id: 16204632,
    release_date: "1993-03-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a342b780b18f24b9705bcbe3f2c5ec9e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0eba2c7261d4dd4a09d1b9629e339b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "This Is How We Do It",
    artist: "Montell Jordan",
    id: 107351278,
    release_date: "1995-09-25",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7dc5cdd10b4533c6e748a999d938e28e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2490ebf535cce9277eb16332e1eb9a6c/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ordinary World",
    artist: "Duran Duran",
    id: 3130430,
    release_date: "1988-11-09",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13f95a8c35fff35b9b81907d47313ffa-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ed10ab812e802c87e423db20800f678/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Even Flow",
    artist: "Pearl Jam",
    id: 7675129,
    release_date: "1992-01-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0d30c70c2eb5a953e360927dd4ff06f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ee8c40862fb4e258fcbeaa17d7199e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Return To Innocence",
    artist: "Enigma",
    id: 3132752,
    release_date: "1994-01-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a27436e9268d14e949630f7c283c8ab-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00eac2b394a1e76d5b42824ac11a57c0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gonna Make You Sweat",
    artist: "C+C Music Factory",
    id: 829895,
    release_date: "1999-10-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c11c2b282ceeb4264489682d90be69e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0443774b0fee838507fc9221e9c8b6b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Runaway Train",
    artist: "Soul Asylum",
    id: 1057312,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cb734d80dbbee0c473165d313789712-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad93113b7bbb0061171aa3db8a680696/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "We like to Party! (The Vengabus)",
    artist: "Vengaboys",
    id: 397344502,
    release_date: "1999-12-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4de7be9cf17655e7161738e7e4759cc-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce258c669c4eeed127859e9386f7b76a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Stairway to Heaven",
    artist: "Led Zeppelin",
    id: 88003859,
    release_date: "1969-01-12",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00bd440c9ec8b85f26d638febfda5e7c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/460a0edd96f743be03b7405eac38c633/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Stayin' Alive",
    artist: "Bee Gees",
    id: 406815322,
    release_date: "1977-12-13",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c2a7afa0dc7677076cd214adc549edbc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/062e4071ebfc701e5f1ca935e69a0b5b/250x250-000000-80-0-0.jpg",
    genre: "Disco",
  },
  {
    title: "Born to Be Alive",
    artist: "Patrick Hernandez",
    id: 4077573,
    release_date: "1979-03-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-37b418d9f4da9fddc61da0c4d3e6bba7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cd1f9c428f3d6d84c4771f7422186afe/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Stop 'Til You Get Enough",
    artist: "Michael Jackson",
    id: 15586296,
    release_date: "1983-07-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e3f1ec8b9c629b417dd2938fa5fa2df7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/baf09a24b48b82e3af270efba101d07b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance Hall Days",
    artist: "Wang Chung",
    id: 907794,
    release_date: "1983-11-19",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fee8590e5e47fa862e37c34488da626-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f75a7f075154438cb8aa62a4f0c1963b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Urgent",
    artist: "Foreigner",
    id: 2814888,
    release_date: "1987-01-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3694c4fb4f68f6cd6cc7acf4f6c277c-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a7545dbac40fbecabeafc4847bcba549/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Turn Me Loose",
    artist: "Loverboy",
    id: 1025465,
    release_date: "2003-03-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b7b5cb1d97613d45a9b5ff327c58dbbe-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6883eab08b168388d73c2783aae41469/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Logical Song",
    artist: "Supertramp",
    id: 7087537,
    release_date: "1979-10-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07aacbb80b5eac999e3c42a50f7adea4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fee526711039ce10381a3091204faca4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Breakfast In America",
    artist: "Supertramp",
    id: 7087529,
    release_date: "1979-10-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a1e10490260a97a7b160dc636c5a65a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c82f1673a2526a69bda92271d24c087/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rosanna",
    artist: "Toto",
    id: 1079628,
    release_date: "1982-04-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa77c5ba20eeaea5277011bf84e1209c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/153332e88a14255a8c3d5959a5a21882/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Africa",
    artist: "Toto",
    id: 1079668,
    release_date: "1982-04-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b888aec204edec33c4619ad4a5410b9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/153332e88a14255a8c3d5959a5a21882/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tragedy",
    artist: "Bee Gees",
    id: 350027801,
    release_date: "1976-05-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ac78beeda511953fab43c1a32462c87-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff58c365559fb6061db49dbdcfdf2287/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking On Sunshine",
    artist: "Katrina & The Waves",
    id: 3166724,
    release_date: "1983-04-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b8f35812cdd6a881fedd8d3aeb23ff1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a81bcd5543be9705c0c66e57593a258/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Dreams (Are Made of This)",
    artist: "Eurythmics",
    id: 561836,
    release_date: "1983-10-21",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a29ac8f41e96136fe43d146a25cf74d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/364f0d4ea4b5452b59e6664b9e5480f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Final Countdown",
    artist: "Europe",
    id: 619310,
    release_date: "1984-03-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ecbb97adf10b2aae1d16b5cc502c9666-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/da44873bbb18e25f946863209fcbbbe0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "YMCA",
    artist: "Village People",
    id: 78595927,
    release_date: "1978-05-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f07086ceffc98b286ab441997ff0004a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c446db15539f4e82846c606b072fe8b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "It's My Life - Radio Edit",
    artist: "Bon Jovi",
    id: 89870993,
    release_date: "2000-01-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1540a59ab0555d999605a442213fd2c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/08a906bd35e5707ef08ceed3e36db1c7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Jump",
    artist: "Van Halen",
    id: 97098410,
    release_date: "1984-09-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-367531769e408fa1fff3c1eece190e73-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0649448bcdeeeb48c25edc0e83653711/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "You Win Again",
    artist: "Bee Gees",
    id: 348129241,
    release_date: "1987-09-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cfeb951dad11cb4763ddaa6092827cb4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e9ae81018b27c2eb06a0fb9b10427b7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say Say Say",
    artist: "Paul McCartney",
    id: 107768560,
    release_date: "1983-10-02",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-62f222d8e81028167ae0d9f170ecd0b0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28f8b1fe717413c1b709da5f7234b723/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hope Of Deliverance",
    artist: "Paul McCartney",
    id: 10176186,
    release_date: "1993-03-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2b391c166515d926a0f73eb6039be7a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87c3d6b2e71fa02fbd26ffff59f84035/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Night In Bangkok",
    artist: "Murray Head",
    id: 89977351,
    release_date: "1984-11-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-acf24aea0630d453861601ad0f58be01-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84d654a1c8cd9281bfa2f1f118e361c4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say It Right",
    artist: "Nelly Furtado",
    id: 7562986,
    release_date: "2006-12-31",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b34f0a644d84bcee63aaaf1c2a7cdab4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbd6d06c71d72755bba27af0b1e3c88e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Maneater",
    artist: "Nelly Furtado",
    id: 1151141,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0db445dc81c8ae03116fbfc7b01ca1ff-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d3625d9f19527440769e7f8cc09db85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hung Up",
    artist: "Madonna",
    id: 679217,
    release_date: "2005-11-14",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc72b1c3b569b903db2c75969db478d1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8e499d7b0e9be5b77eb6c2697e179fe3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All The Things She Said",
    artist: "t.A.T.u.",
    id: 62070435,
    release_date: "2012-11-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8b748666a964115e726456988f0ae66-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca53a09389eda98a6894cf603a98a9c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Dance",
    artist: "Lady Gaga",
    id: 2603555,
    release_date: "2008-11-24",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da975afec91e06a8dafcc3a95512d1d9-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc24d60a998e1a296f0c22efa8ddffd2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Valerie (feat. Amy Winehouse)",
    artist: "Mark Ronson",
    id: 7923756,
    release_date: "2007-04-16",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74fb75139d912676b474e32994c22d28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa17bc2f15022dd727fd21922e90c9c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Billie Jean",
    artist: "Michael Jackson",
    id: 4603408,
    release_date: "1989-10-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-136c90241aa34cbe924159289df20d65-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0ad67d1beb761f2cb9f8b60e5bcf07a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beat It",
    artist: "Michael Jackson",
    id: 555640,
    release_date: "1983-11-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-edd7c2135ad76391a35c8dc2dcf45483-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92a024220a9532489c75c9d994835697/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thriller",
    artist: "Michael Jackson",
    id: 14874867,
    release_date: "1985-07-18",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49f164459fb60684decc0c06d40c413c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/041e3b8fc5ed298264b6e374b825b24f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bohemian Rhapsody",
    artist: "Queen",
    id: 7857234,
    release_date: "1975-01-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb2d30832c1871ac35261c603e6841fa-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d5f6e56b755d104525cf3a0810356bc/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smoke on the Water",
    artist: "Deep Purple",
    id: 3152610,
    release_date: "1972-07-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25376c5ff0367baa43aec3775818e2b9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c4cbc4f2ad6cbd36d1d7549178e301d7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "My Sharona",
    artist: "The Knack",
    id: 6812046,
    release_date: "1979-08-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424fe4d99e3f61af2893a922c70158d0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87655ffd4c1910baa30c5459cbfaadb9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Das Model",
    artist: "Kraftwerk",
    id: 1006803652,
    release_date: "1987-05-26",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-502ac78ef780eecf899de083a0f7d9a7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4270c9ccd41bd480c76610ead7128d66/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Dancing Queen",
    artist: "ABBA",
    id: 884025,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8708a478849e903d5065f4c9caaf8641-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Highway to Hell",
    artist: "AC/DC",
    id: 92719900,
    release_date: "1979-07-27",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c9dcc5dffa3210c0a7dd4d7c37f84540-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de48d988a82fc401f4b9bafc2639f7d0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Heart Of Glass",
    artist: "Blondie",
    id: 3151379,
    release_date: "1978-05-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08f24ec42eab3b8f97f34648d02b87c0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93eb8b69b5b5d03af224af1c9d28bc53/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Feel Love",
    artist: "Donna Summer",
    id: 487042352,
    release_date: "1978-04-20",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f46fe3dd66dfe25b58bdba8d00a1e97a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31ae32eedb76d458f95b26a9ba35ac49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Shook Me All Night Long",
    artist: "AC/DC",
    id: 92720048,
    release_date: "1980-07-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-884c175339dd0ada8aaf2c6e6e5b9216-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41041b14873956eff0459c8ea2c296a8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Livin' On A Prayer",
    artist: "Bon Jovi",
    id: 538660022,
    release_date: "1986-10-31",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56d2788c18b4000cb3174b3d2134fcd7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0365311a9d03c267f175e0ef79f40c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pour Some Sugar On Me",
    artist: "Def Leppard",
    id: 447552822,
    release_date: "1987-01-19",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-43ce01c5cb659fec6f5b4c0b2c626bdd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86d5af2077f5b6ef18fd0ca158a91134/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Celebration",
    artist: "Kool & The Gang",
    id: 906568,
    release_date: "1977-09-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80d4346440c31f99e0a836790a355bf4-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e993fe7d2c6e0a4fe259485d940aa7a5/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Girls Just Want to Have Fun",
    artist: "Cyndi Lauper",
    id: 76057844,
    release_date: "1984-03-28",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0e3a8101db616bf26e58fae135119eea-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a00e104be064ecd2ba490ded73bd6698/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Way You Make Me Feel",
    artist: "Michael Jackson",
    id: 59509541,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31c72e2585b6238c16c115664707b680-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(I've Had) The Time Of My Life",
    artist: "Bill Medley",
    id: 13128250,
    release_date: "1987-12-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-234cd1a120137e3e8f683c2cf532825d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f24ac40f8c7a0dbe438399788763a042/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy Little Thing Called Love",
    artist: "Queen",
    id: 568121002,
    release_date: "1988-10-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce852e942a9d52502ddc0f2922bdc97c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b8fc5d117f9357b79f0a0a410a170e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take Me Home Tonight",
    artist: "Eddie Money",
    id: 1081064,
    release_date: "1986-10-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54672fa6745545a1a2b120cf72e4f84a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bdfbfeffad8d5c31d1084075b5487cc2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Red Red Wine",
    artist: "UB40",
    id: 3129568,
    release_date: "1983-03-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a6e0685e488b7aa18cb0904416632a3-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba1ee8b4130e3c0e2606dfe365312dab/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "I Love Rock 'N Roll",
    artist: "Joan Jett and the Blackhearts",
    id: 545992732,
    release_date: "1981-11-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1aff44bfddee18618ef3d44d2c19bb3c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c4fb793cfedbb94d6e8926f3647eb8b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Another One Bites The Dust",
    artist: "Queen",
    id: 67503793,
    release_date: "1981-01-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d3709b45bfb7f38189f351c0ad237022-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e1883d47e9e07a3b6dbf5c9249452d8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smooth Criminal",
    artist: "Michael Jackson",
    id: 59509621,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c74526ed21e51f83944b58b0aaf7bcdc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rock with You",
    artist: "Michael Jackson",
    id: 15586286,
    release_date: "1983-07-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b39eebac74144acd8ac092f6a4a7805b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/baf09a24b48b82e3af270efba101d07b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Safety Dance",
    artist: "Men Without Hats",
    id: 63440828,
    release_date: "1982-02-11",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bad51a5c70b9f8cc3b591e1c1a2844ed-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4ff46d3112e44464f09f67d161012f23/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All Night Long",
    artist: "Lionel Richie",
    id: 920062,
    release_date: "1983-06-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85de93bf33918602b5587fbc5adc3e7f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7050e918a1ead7bdbd7cadd9dfa2b858/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Wanna Be Startin' Somethin'",
    artist: "Michael Jackson",
    id: 831216,
    release_date: "1983-07-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b14191de47b59c80f6df4b710c3e1a1b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/544862aa5be45bc82ad4ab1a14daf63a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wake Me Up Before You Go-Go",
    artist: "Wham!",
    id: 15194531,
    release_date: "1985-01-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5a8a41d81b20bbf652bf4251937b622d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c1858a4aa5d732a60c8cfcf424f24ef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's Raining Men",
    artist: "The Weather Girls",
    id: 555755,
    release_date: "1982-11-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b784a20409fe9d1c6bf6e7f312b71788-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9fe1f7455be7fa598bf2777ad15f08d8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Back In Black",
    artist: "AC/DC",
    id: 92720046,
    release_date: "1980-07-25",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cfc0e5baab3f7ce7758e259482bd8681-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41041b14873956eff0459c8ea2c296a8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Man in the Mirror",
    artist: "Michael Jackson",
    id: 59509591,
    release_date: "1988-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1c2d12e99b1113a88d0e20de2d8d12b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pump Up The Jam",
    artist: "Technotronic",
    id: 2961498,
    release_date: "1999-03-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2644d171e6b7832e3555cf8f5d50ff1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ddb94a0ba17254188a54d18b6173f7f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Uptown Girl",
    artist: "Billy Joel",
    id: 1025659,
    release_date: "1983-08-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d8db355da7b4f057c2e3260b4b6eab9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa5a6fc95f4adfb8784dcd719422c26e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Conga!",
    artist: "Miami Sound Machine",
    id: 565055,
    release_date: "1996-10-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b104ddcb9c5d99419756cda891b8c3ff-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1b0411abe7d773fd5e5d56c47da318c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Glory Days",
    artist: "Bruce Springsteen",
    id: 15586215,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9ddb5203765be707ab60c4ad77be75bc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Walk Like an Egyptian",
    artist: "The Bangles",
    id: 610231,
    release_date: "1983-04-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28c8f2fd7ec94b06c2db78ab23ffba6a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/938d17e5a07d6178bcabbe2349a79ba0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're the Inspiration",
    artist: "Chicago",
    id: 3616316,
    release_date: "1984-11-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72fb7217793f1fe5890e7ad66ffde93b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9fb83435f0d9bfee9397100320c59cfc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tainted Love",
    artist: "Soft Cell",
    id: 1173622,
    release_date: "1982-06-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d80f9217e72b4cf8126b5513e6daea2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a809e8767a0cc557be1c894c5ed1c71e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm Is A Dancer",
    artist: "Snap!",
    id: 434215172,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74434845f1300cad0afb9f36e69d5199-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9836fcf60102662b686f93d01bcda69/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ice Ice Baby",
    artist: "Vanilla Ice",
    id: 2397026,
    release_date: "1992-11-04",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6961b10e2b35c40432f0dc85e3e3ce3e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177bec5553a37b3ecf7d548ca8f6d370/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "U Can't Touch This",
    artist: "MC Hammer",
    id: 3090793,
    release_date: "1990-02-12",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-60ed7acfb29dece4c21202221b46995c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3558d89040fc95f8978c67b09aa2d16b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Like To Move It",
    artist: "Reel 2 Real",
    id: 524374752,
    release_date: "1994-10-10",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4824ad8b5e69b61430221fa1a445067b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58ba0c77278cb7f63bb9fbd094e2fabd/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Everybody Dance Now",
    artist: "DJ Twilight",
    id: 8151890,
    release_date: "2011-01-10",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6350f0d56ae43e8baa9bdecb226adb6f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1c2e1e1f519c64b2059d352b9fc8f432/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dirty Diana",
    artist: "Michael Jackson",
    id: 14874854,
    release_date: "1985-07-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65d6ab9c90502b1c8c163794cd2dc81d-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/041e3b8fc5ed298264b6e374b825b24f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Part of Me",
    artist: "Michael Jackson",
    id: 59509471,
    release_date: "1988-09-14",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffb9bf3cb4b740fc1be4e86c04dea015-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/995b33eaad9a62409c822ce9c670e542/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mambo No. 5",
    artist: "Lou Bega",
    id: 994493,
    release_date: "1998-05-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3705a6ed322a15e6b4afd58312d3248b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a827921da4e6afde64294d7483cbf0e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Is All Around",
    artist: "Wet Wet Wet",
    id: 910912,
    release_date: "1994-05-09",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2f8c743311dd0045ae2ef59eed43f5ad-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/447dbf905a9bc8113add6d914babe853/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Lady In Red",
    artist: "Chris De Burgh",
    id: 522699602,
    release_date: "1986-06-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-708eb1b0c787e0f3f4dd439ad3d1f23c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5653d56284b179befadab5070bfc6cf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Heart Will Go On",
    artist: "Céline Dion",
    id: 14552280,
    release_date: "1998-01-20",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8049818b9083d987f933828927580650-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177346106112348e15ec4f9add0c1ef4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everybody (Backstreet's Back)",
    artist: "Backstreet Boys",
    id: 15608663,
    release_date: "1997-10-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9594c8d09b5c734e97a0e7d3bc6d9d7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4f5bf0b5232ddc0f8453c3014c83a592/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Blue (Da Ba Dee)",
    artist: "Eiffel 65",
    id: 88825391,
    release_date: "1998-01-29",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-528a0215cc05c073936d62ced45de231-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/457d79704890c7d827bdf92dbc037087/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Scatman (ski-ba-bop-ba-dop-bop)",
    artist: "Scatman John",
    id: 99360002,
    release_date: "1995-06-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2b889634bb2a3da74d6b07a7b184851-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42f2703765ad6be59c106e0d8db10570/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Sweet Escape",
    artist: "Gwen Stefani",
    id: 886428,
    release_date: "1998-05-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ec43fcc09ac46232844dc3e340d184d-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c10f36c2ce7bf1006ced08147d02de32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Mr. Vain",
    artist: "Culture Beat",
    id: 29794781,
    release_date: "1993-05-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-11e52a1e9077e17c19396bf631e64949-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a652433c79217dc6e885cfa28428f174/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Easy Lover",
    artist: "Philip Bailey",
    id: 82199946,
    release_date: "1987-11-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8464ae439cdd60baade7a6a1935a0bee-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8afa0ca97a60b87740bb36ad17c8591/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Lose My Number",
    artist: "Phil Collins",
    id: 134036238,
    release_date: "1985-10-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-58ee2bd6f17c5eda1cf8ccae549d8373-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Invisible Touch",
    artist: "Genesis",
    id: 3145739,
    release_date: "1987-10-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8be66c79538022dfaf04f6936f463ac0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0fa14b7e1c7c350f389fc7fb1e53cd28/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "You're the Voice",
    artist: "John Farnham",
    id: 2221501,
    release_date: "1988-09-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d714890d76498e83d2346a56583b9f62-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28ebbfbe9136e36c92560ef9243b16c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Juke Box Hero",
    artist: "Foreigner",
    id: 2814884,
    release_date: "1987-02-05",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9711f22462fb784285e6b59c131e2eb9-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a7545dbac40fbecabeafc4847bcba549/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Karma Chameleon",
    artist: "Culture Club",
    id: 3090499,
    release_date: "1983-03-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20cab00420def6df1ab52232281d6c15-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3012d9bd9d57d1764e2549b2301777d7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "When the Rain Begins to Fall",
    artist: "Jermaine Jackson",
    id: 4311056,
    release_date: "1989-10-28",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-478198df9d8cdc6b1e9c46741bdaca98-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9bdf4f76c2b48ed528956a271f44818/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Upside Down",
    artist: "Diana Ross",
    id: 906582,
    release_date: "1987-09-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fda9684d7c47c6e9ca03806123b681de-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae9c863ba27686af441666135693de2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What A Feeling",
    artist: "Irene Cara",
    id: 1434081,
    release_date: "1983-02-21",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0a4cdaad2540a7da575f3a6e812cd12-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/204672f30d473f7969e36f687ff6ee02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Major Tom",
    artist: "Peter Schilling",
    id: 3605281,
    release_date: "1983-06-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-26c6831e728544ffa14ed5406d6265ef-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d9e877767b4c31be9a7ac73979e8e2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Der Kommissar",
    artist: "Falco",
    id: 142334565,
    release_date: "1982-02-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f435c39a820255d32683f19a5a9f708-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8bfb068c2769c3e87bb90c0fa5a37d1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Loco-Motion",
    artist: "Kylie Minogue",
    id: 137232280,
    release_date: "1985-01-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-576b147cc8810622c5ffc4093dd608f3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0b2676ab5b25fab30c4e9a6d4294c839/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Gotta Feeling",
    artist: "The Black Eyed Peas",
    id: 4619466,
    release_date: "2009-06-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b2d6615c939b20388c99f6ebc108f640-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea30377840f4ef9ac62406c5e16e9c4b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Is Love",
    artist: "Haddaway",
    id: 61590162,
    release_date: "1997-08-02",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2302ab34f24fb232d1113e7db7910e8e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f52e7fe0c253a6586800dc85fd93458/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Live Is Life",
    artist: "Opus",
    id: 6973073,
    release_date: "1985-01-21",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98624778e2ca962b64c6dfba792ba1f7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/decd1e28e78e8346bf6340504ad7e7e6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Brother Louie",
    artist: "Modern Talking",
    id: 548510,
    release_date: "1986-12-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d4ce152d923b5c643496713bea7e5e0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f5ab7814eda8bdbab33e9bb6941655f3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're My Heart, You're My Soul",
    artist: "Modern Talking",
    id: 985538,
    release_date: "1984-04-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84ccb4e6641619556ff9bed88ae62e8a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/826af42c59b6a9f6ced4777bbdaf89fe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cheri Cheri Lady",
    artist: "Modern Talking",
    id: 74249000,
    release_date: "1985-09-02",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6faa763ea4a07ff99f9f82e273837ff2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e087110af2549642da54098a5c14b7ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sing Hallelujah!",
    artist: "Dr. Alban",
    id: 1059093,
    release_date: "1997-06-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e62cb289440aadd2997d39c96740d04-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/741671be29de2ec19538224869e51eb5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Ketchup Song",
    artist: "Las Ketchup",
    id: 476739472,
    release_date: "2003-04-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68572e6433783c8c08da48d2afdf22be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be45674dc35c8f974a934dc3779c7b59/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Where Do You Go",
    artist: "No Mercy",
    id: 4096755,
    release_date: "1996-08-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0599596451bbd343918ad19272ef9e39-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02f880e927fe2d0ea5c5c234ef0bd050/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Daddy Cool",
    artist: "Boney M.",
    id: 532070,
    release_date: "1976-09-27",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88a426f8fa49671e66f1251391fa8b63-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/675e0e5a4ae84e6457d513e205fc5a4a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ma Baker",
    artist: "Boney M.",
    id: 1049893,
    release_date: "1977-03-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0add920f236d45b3363f57ac13646d7e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b95e4fc13571a55f02478665f3e2ec9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gimme! Gimme! Gimme! (A Man After Midnight)",
    artist: "ABBA",
    id: 884041,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c30d80f23a9cd9564985ed347e6ab579-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Video Killed The Radio Star",
    artist: "The Buggles",
    id: 4255660,
    release_date: "1989-09-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f4d94ccb1522757cb7b9d605aa07619-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f436d427e93542fc52cd077215ae95a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "La Isla Bonita",
    artist: "Madonna",
    id: 678372,
    release_date: "1986-06-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b1db108fdac3a188fc2e5021713b272-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/43a8e2b1b035391f58b0927cf1040bc4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Voyage voyage",
    artist: "Desireless",
    id: 4298172,
    release_date: "1987-12-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6fe8d45b77c4632fd7015ccbe7d797a5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3fcbfc72be5ad78fa6277eb5dc5df8d3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Big in Japan",
    artist: "Alphaville",
    id: 756850192,
    release_date: "1984-01-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dfcbe0efa2777499c8fa934dfa5e6a99-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4045858b4e63ddeb52d89f3705354673/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hips Don't Lie",
    artist: "Shakira",
    id: 75859436,
    release_date: "2007-11-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-960a51d3bfe9fc778660380879c0b3b6-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/edae3ceaf89afa1159c999fbafc6c836/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bailando",
    artist: "Loona",
    id: 1102129952,
    release_date: "2014-10-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4543313332f32663da3d162cc34579f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a882f510354221bce39fde00397f0c29/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Haus am See",
    artist: "Peter Fox",
    id: 2200206,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f657f7ad5c899817bd9d78200689d46d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Alles neu",
    artist: "Peter Fox",
    id: 2200204,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3c92ea339d2518e0fc898693f16853f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "In the Year 2525",
    artist: "Zager & Evans",
    id: 392461892,
    release_date: "1969-07-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e73fa0ed6aa1c2bd034365b94687b0b2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f094284f1086d3202a49537c3e7caa1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Skandal im Sperrbezirk",
    artist: "Spider Murphy Gang",
    id: 3123329,
    release_date: "1981-12-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9c5048c7db3743830f9a9f3cdcd2b88-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba80f264e3b7c2426e409399d3c22f84/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I've Been Thinking About You",
    artist: "Londonbeat",
    id: 1056486,
    release_date: "1995-11-20",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b177fcda4a25831b719643a9366a42b2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/619122ac7d43050d8e2e65b4d8fd7e03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Go West",
    artist: "Pet Shop Boys",
    id: 3147740,
    release_date: "1996-09-18",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a069690aecb9e1fbd20d8430f17352bd-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dfc1edc4b84659467b3b2bd556de7fc7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Joyride",
    artist: "Roxette",
    id: 15710900,
    release_date: "1991-03-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-27ef7a36a535f908ec0073f72d0caf18-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8c62bc59111088c3464be95fd286399/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sleeping in My Car",
    artist: "Roxette",
    id: 3092174,
    release_date: "1993-03-24",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4cc07c1ad0a1fb665144aa2dafb197eb-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/795b45e8a76e80ef4c342b00f9d37845/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waterloo",
    artist: "ABBA",
    id: 884046,
    release_date: "1974-03-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83e3a77be661e984e65dea8d1104abfa-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kung Fu Fighting",
    artist: "Carl Douglas",
    id: 131285694,
    release_date: "1978-04-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1080d1b669622c788c6117d0d014762-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/419a1541716064e3952cc9b38f47a54b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Back To Black",
    artist: "Amy Winehouse",
    id: 2176856,
    release_date: "2006-10-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a4e5373f108d90b63361839339184bf-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ce985e6653c350c6e6fed077ab7d0d4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Rehab",
    artist: "Amy Winehouse",
    id: 2176852,
    release_date: "2006-10-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1db87b6fd567e5d9e337e942cfc7d936-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ce985e6653c350c6e6fed077ab7d0d4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Family Affair",
    artist: "Mary J. Blige",
    id: 1161679,
    release_date: "2006-12-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ef9456b04eeefbe8d5bfe21cd4df94b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3939a5b62df412e83d898dfd85035c9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Fallin'",
    artist: "Alicia Keys",
    id: 959183,
    release_date: "2001-06-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f977287578b42566feccb2b68f6ba0cb-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d83318951e3899a096ed96c6fd422032/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Umbrella",
    artist: "Rihanna",
    id: 925106,
    release_date: "2007-06-17",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3b1142ec5a98567c5d286a9050a85d1e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boat On The River",
    artist: "Styx",
    id: 61286644,
    release_date: "1979-04-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e0c364518cf931a8d1490ac769dcd44-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87bef520784bf1c12a498ef45570f2b4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Let Me Be Misunderstood",
    artist: "Santa Esmeralda",
    id: 3083376,
    release_date: "2003-09-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6050535d3e7a8c24eb326d7a72b72900-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6165b7abb2f52d4f758dd4542dcc4dcb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking on Broken Glass",
    artist: "Annie Lennox",
    id: 1048702,
    release_date: "1992-04-02",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f179b22ad0d95458070933b6ef59606-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/137bb5655a30cd9cff6e3cb56001ed7a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Have I Told You Lately",
    artist: "Rod Stewart",
    id: 2550674,
    release_date: "1988-11-17",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b440ab344e6b14f0ac88df4efea5dcd0-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc17008ae06cccd392a2312c8f30bc9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm of My Heart",
    artist: "Rod Stewart",
    id: 3605543,
    release_date: "1988-11-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db7377912995a8185f1a0394bed6e4dc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc17008ae06cccd392a2312c8f30bc9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm Divine",
    artist: "Enrique Iglesias",
    id: 2604912,
    release_date: "1999-11-09",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9da64cd913a4780abad50a792d35b27d-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Absolutely Everybody",
    artist: "Vanessa Amorosi",
    id: 111314264,
    release_date: "2000-10-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-433cc83c7394ad772ca74cfdaf2a14d8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca6de5b0bed98fc49eac7b6760bb2fdb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jesus He Knows Me",
    artist: "Genesis",
    id: 3129672,
    release_date: "1984-04-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5836a863d3b16a063b10c92e205472fb-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bfb419853af09096dadd54d7c84d955d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Frozen",
    artist: "Madonna",
    id: 4209669,
    release_date: "2009-09-18",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80c934e33b8fbffb1a60b96386cf5f54-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/195d3d2c1196b20711cfb6ad76e1c2ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "MfG",
    artist: "Die Fantastischen Vier",
    id: 111844700,
    release_date: "1999-11-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a75cb9dca0706fd76ea436b9395c01e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f2be3ef09864973a64c60693c0f9f14/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "November Rain",
    artist: "Guns N' Roses",
    id: 1169683,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5cfa1aa622ab58ca57cbe9f32dad40cf-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All You Zombies",
    artist: "The Hooters",
    id: 5817104,
    release_date: "1985-06-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7691523c56da23cd9afda2d7a6e71c64-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35fe2c643d2f00ecf78cf16def7eae99/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Shadow On The Wall",
    artist: "Mike Oldfield",
    id: 3978858,
    release_date: "1989-06-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a196512ceaef7b5efe44098bc0e4807-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b3e7d77cf4454c3de4aad41fa9abdb4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Egoist",
    artist: "Falco",
    id: 3458985,
    release_date: "2003-03-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b365d4d080b01a65610e0a83e45fd7e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2aa6b348a02b22ba0c981712af14f485/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Another Day",
    artist: "Jon Secada",
    id: 3359659,
    release_date: "1992-03-24",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ddb85833367c4241be271f918a5267b-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6f22e152a4a4f2c659b57b4b3789ce5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Was Born To Love You",
    artist: "Queen",
    id: 13499093,
    release_date: "1995-09-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80868c1055bc334214e3b7eac68a9190-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20adfb09ef5afd27ddcdd830780efcf1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Enjoy the Silence",
    artist: "Depeche Mode",
    id: 68515055,
    release_date: "1990-09-28",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9ca315f3bf66995386dffe05b7b0cb8-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e73716d037ee24f1331a8c0332526590/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Keep The Faith",
    artist: "Bon Jovi",
    id: 74628107,
    release_date: "1997-04-05",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-770478e24233a54b7e636a9827730ae9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2821c5cfa61955ddd982c15cc21882/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Everytime We Touch",
    artist: "Maggie Reilly",
    id: 665239482,
    release_date: "1992-05-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1bd84baf4c244772a1b9c7597e6c1796-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b088365895312b9e384ca187f10ae6f9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Out Of The Dark",
    artist: "Falco",
    id: 13569299,
    release_date: "1998-09-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1dd9d15d2081b2b2bb1431b636628c6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ce857515b6285c7d08d4d264a52fc5e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Maria",
    artist: "Blondie",
    id: 3151637,
    release_date: "2002-09-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8810e9739316335d6f7b4e850c007e7f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dc16bfe0516e13b247098f9fcea35acf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Larger Than Life",
    artist: "Backstreet Boys",
    id: 13141169,
    release_date: "1999-05-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9eb8ace7bf0af462cff5a985589f4b4f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d61eaad8f321ea876a5f5c7219aae892/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You'll Be In My Heart",
    artist: "Phil Collins",
    id: 561875132,
    release_date: "1988-10-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fd2bbbd275f724c93136b5b98272b015-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f48385948df5ed9a8e00c647a87e3a9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breakfast At Tiffany's",
    artist: "Deep Blue Something",
    id: 2315711,
    release_date: "1993-07-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa35a624dce9f767315d0653dc12e48b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f27d35f211f3e2975176f41bd8ac079b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Baby, I Love Your Way",
    artist: "Big Mountain",
    id: 846215,
    release_date: "1994-07-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc0e4cbdf5ab9859bda25bd2a4335952-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd1a6798645fb5cc63fd866668e8faa1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss Me",
    artist: "Sixpence None The Richer",
    id: 134116940,
    release_date: "1998-01-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-038591e63d61995293b17b85ff5ce11d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c37356b5ada5f3a79689653f7a6f5067/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Chiquitita",
    artist: "ABBA",
    id: 884038,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87c8c0beca59195392794e9873a238ba-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Red Blooded Woman",
    artist: "Kylie Minogue",
    id: 6588267,
    release_date: "2003-10-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1b7eca883c229f974ed8022de6164be7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49f20e9e74c0b91060792c889f8d219c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Ka-Ching!",
    artist: "Shania Twain",
    id: 2543341,
    release_date: "2002-11-19",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f8cf2e7e9215c9c2b7e226352dc5e002-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86db043638625fc894a2b0d2e09c21f9/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "La Bamba",
    artist: "Los Lobos",
    id: 3819736,
    release_date: "1986-07-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff6f435824bfaaa9b7311e39419b7cbe-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0e784e5cfd9717676fc9837b88921cc/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Holding Out for a Hero",
    artist: "Bonnie Tyler",
    id: 82299358,
    release_date: "1988-08-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2591683daa167543364c69d8d6863085-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0768a04e291fee32f7b474e18796ac37/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All Good Things (Come To An End)",
    artist: "Nelly Furtado",
    id: 1151152,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72308fddf4c471b3523ec4f727e9fcd9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d3625d9f19527440769e7f8cc09db85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Should Be So Lucky",
    artist: "Kylie Minogue",
    id: 137232330,
    release_date: "1987-08-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-838dbf395ba7d3091fc50075b0835d3a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4329d5d528ceda7b2e9c73080b69557/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Works Hard For The Money",
    artist: "Donna Summer",
    id: 34180931,
    release_date: "1983-05-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec842c804215b28adf6a4f5d1b415152-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/85494b32954ac1611bbdc51e991f6356/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "We're Not Gonna Take It",
    artist: "Twisted Sister",
    id: 3906972,
    release_date: "1984-08-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f3968b16b0980bed352f36e853c3956-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d821765fe1cc4078cddcc23c46c8f72/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Midnight Blue",
    artist: "Lou Gramm",
    id: 5611979,
    release_date: "1987-03-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9b0eaec1f9dc1c4a1f92e17e1807c46-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eaafbf81ad6f979b765ae2721bd48486/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Midnight Lady",
    artist: "Chris Norman",
    id: 61509261,
    release_date: "1986-05-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d389af2a40de05e3e182f34084fadfcc-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ec87c06a02598f11b3884417cfdceb6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Whenever, Wherever",
    artist: "Shakira",
    id: 15211178,
    release_date: "2001-08-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5dc7f5193bce7ca1629c3e2e53c04639-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c50690401eed099df05e72f5080af1b/250x250-000000-80-0-0.jpg",
    genre: "Latin Music",
  },
  {
    title: "Alejandro",
    artist: "Lady Gaga",
    id: 4709938,
    release_date: "2009-11-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-269bb724b66c421cc60de7bd302b1015-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62f368993ddb68c5364cd03221d07ac6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Can't Fight The Moonlight",
    artist: "LeAnn Rimes",
    id: 75779409,
    release_date: "2001-01-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-203b75ffc2f145e13298b84413c243d6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b6725e19d0c57ba87d2e9a00ee7be5b/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Californication",
    artist: "Red Hot Chili Peppers",
    id: 725274,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc72b44cdc562435438c87d94188faa6-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Love Don't Cost a Thing",
    artist: "Jennifer Lopez",
    id: 618317,
    release_date: "2003-09-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc780d6da32c84ca4b6794dd5e5dc023-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6385c391e17a1c16beccb7ef7da976f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Oops!...I Did It Again",
    artist: "Britney Spears",
    id: 582254,
    release_date: "2000-03-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e7c043c0801ad2c4defcdbd97b9da450-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34265ac018fe0d973eac131fe04038a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bye Bye Bye",
    artist: "*NSYNC",
    id: 632447,
    release_date: "2000-03-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b60e6761708b4f5815d105825fba23f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01fdca2ecf54d678ff005162b5b6cc92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Eternal Flame",
    artist: "The Bangles",
    id: 3037340,
    release_date: "1989-04-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eb9fa8068bd8050b1d7d4de10c25ee29-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/160a720272bfeac612989f32ae101c0d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Poison",
    artist: "Alice Cooper",
    id: 626178,
    release_date: "2006-03-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87dbfcaa362a42d9a8c03ec08e178904-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3640fee637a166da4ccc65246467b560/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bed of Nails",
    artist: "Alice Cooper",
    id: 468471872,
    release_date: "1989-03-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0874eb414e7b2244f0f6ac08d822618b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d4638ee8af1ba13ff7ad9c456ca076c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Here I Go Again",
    artist: "Whitesnake",
    id: 576902402,
    release_date: "1982-10-06",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beadb5075d84b9728419b247dfbfcb49-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84a375b90c2751bb7b4e6effb4d7e2d1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hey (Nah Neh Nah)",
    artist: "Vaya Con Dios",
    id: 58667011,
    release_date: "1990-09-27",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5190fd55152c696b269d2a3956d74ff-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea6c274ad45ba5835111eb2e5d8b148e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "You Keep Me Hangin On",
    artist: "Kim Wilde",
    id: 74640929,
    release_date: "1986-09-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-628f70a3efa583cb4d7ed1edc4723227-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34145a9345aa92437fae5db912e7218f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tell It to My Heart",
    artist: "Taylor Dayne",
    id: 444738122,
    release_date: "1988-01-19",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ee6c38109d745d289c3466e9f310ddb-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/130089f12fa9ceaba703a12043b44860/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's a Sin",
    artist: "Pet Shop Boys",
    id: 3122973,
    release_date: "1987-11-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b2620a8c6043e584233fe7dae90617e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5453ca81ae47f016b0aa7b55729da928/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Le Freak",
    artist: "Chic",
    id: 67359668,
    release_date: "1973-05-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec55cb0edfa06119f7283972ab45a228-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a5b56f3b2533c5748cf985447def437/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Superstar",
    artist: "Jamelia",
    id: 463394462,
    release_date: "2003-06-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e7001d7c2fb38e1254fbe79c430109bc-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aaa7a43bfa31f931f46895a6650b0c50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Down Under",
    artist: "Men At Work",
    id: 1030221,
    release_date: "1981-02-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e132010c91e0223e985b5891dedfa4a8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/968a09e7739dfeee3448a1a79555504a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Tearin' up My Heart",
    artist: "*NSYNC",
    id: 15477160,
    release_date: "1997-05-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ca06b08807bbc733f897d04ef6245fb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a4958616c62ba71889a075ce84db82a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "September",
    artist: "Earth, Wind & Fire",
    id: 487484142,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c851383748376218a9e00a3f3a1ba25f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18f41ecd3781fb96bffa2b0b49955db5/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lay All Your Love On Me",
    artist: "ABBA",
    id: 76376863,
    release_date: "1974-04-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16f55e7ae5cf828766ec4d9e938442c7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e28b2ad6ec55276c66dae8297c686c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweat",
    artist: "Snoop Dogg",
    id: 10162588,
    release_date: "2011-03-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e55fe66ca1a72021f87cb75351e59d17-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81151ad27acdf3d3139e9c3dbf875ff/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The River of Dreams",
    artist: "Billy Joel",
    id: 548676,
    release_date: "1993-04-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25e67471c38cf7213752d2d7b65587da-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4586382c3e3c536105b138f5a9ff7443/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "How Will I Know",
    artist: "Whitney Houston",
    id: 1131192,
    release_date: "1985-02-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cda4ae9c587b5fff000e8239d0e38538-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5712b346f0ca0f167da8ffd7588db3f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Alive And Kicking",
    artist: "Simple Minds",
    id: 58831541,
    release_date: "1985-09-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e79992b1416afde60254565a656bcb53-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b2f8d7c1463685f5c3c7c4d78c4c5e2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Voulez-Vous",
    artist: "ABBA",
    id: 884040,
    release_date: "1978-04-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-21b0b52ef0f3641b556fb5203e574c63-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rebel Yell",
    artist: "Billy Idol",
    id: 3140135,
    release_date: "1988-06-23",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14a1efbd189826889cfd4d753172cfde-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cdff56843117adeb102f5c9f490295ec/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rain In May",
    artist: "Max Werner",
    id: 96538266,
    release_date: "1981-03-30",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b37df0dd997895cff73577d71b84e446-0.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61b69b3b041385b3da54881e5a5c4e03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Goldener Reiter",
    artist: "Joachim Witt",
    id: 700835,
    release_date: "1998-10-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c854cf6f271e96691ee3dbd2ebf4c5f0-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5631571f81b8fbd5d318bf22051fdf87/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Such a Shame",
    artist: "Talk Talk",
    id: 3257061,
    release_date: "1982-06-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5e25b2dca98253674770a8666f6d2ef-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18298b61298f0355c69a8038829287a9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Shut Up",
    artist: "The Black Eyed Peas",
    id: 953676,
    release_date: "2003-07-31",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ec8da3e0dd60c1d71f5335be6640a7c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6efe94615f21a80c1ad670741e86e482/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jenny from the Block",
    artist: "Jennifer Lopez",
    id: 628099,
    release_date: "2002-11-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5e250f3ba7b9cee766cbb1cb8b1f370e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f60d5198e5a3deb22b3312bafb6d314c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Riddle",
    artist: "Nik Kershaw",
    id: 1174772,
    release_date: "1984-11-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b71318f4be8ca6a156012881e404973b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce29a69eecc2c08d2b772bf0c5cca02f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Johnny B.",
    artist: "The Hooters",
    id: 1081050,
    release_date: "1987-07-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbfbebf03e58135631258e3a4d1566f2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed86407d9712c21e5177390822daf4b3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The One and Only",
    artist: "Chesney Hawkes",
    id: 130195442,
    release_date: "1991-04-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac6cf839e3ccda11794d071205420359-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/233b1e9651543a6fceaa9fc2f10087a1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Carrie",
    artist: "Europe",
    id: 858447,
    release_date: "1988-09-16",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88e918696d50dbf16e98bf79ab274ffc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2971537322dde7f40381a8586c08cd8e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wind Of Change",
    artist: "Scorpions",
    id: 927900,
    release_date: "1991-01-07",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-38fc0c44aa0880cf58163dce12a2a5b4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d1724dd3ac61f2e1df593c543ce2b22/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Money, Money, Money",
    artist: "ABBA",
    id: 884036,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95c1fe6523b63bb79e9dc438ca5f9176-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Born This Way",
    artist: "Lady Gaga",
    id: 11747931,
    release_date: "2014-12-18",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79ebe6317e5684b616baf7fef3420565-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f256c4bebac58a7c4122e0e639303a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance into the Light",
    artist: "Phil Collins",
    id: 134036268,
    release_date: "1986-10-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e930231969b2d4717e9dcb7571488d5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Son Of Mine",
    artist: "Genesis",
    id: 3145758,
    release_date: "1987-10-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fc8805248bb9158803fb431175054530-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ab6bae79d41a6d8b765fe0f006302bca/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "A Kind Of Magic",
    artist: "Queen",
    id: 13499039,
    release_date: "1986-09-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-61a8c79eb435466b15efe82556eca10a-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/518738a2c485a7ea27ef52ded999902f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Dream It's Over",
    artist: "Crowded House",
    id: 133577580,
    release_date: "1986-10-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f51faf5f52885fa0db849001c5868549-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/797cfed6ecc569b95afd34ba55784fba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fernando",
    artist: "ABBA",
    id: 884039,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bcee896ddc60f1296b8128d58b4515a5-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's the Way It Is",
    artist: "Céline Dion",
    id: 14552286,
    release_date: "1999-11-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-175572757c7123685ef489988edbfc93-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177346106112348e15ec4f9add0c1ef4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's The Way Love Goes",
    artist: "Janet Jackson",
    id: 3501645,
    release_date: "1993-04-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cdafaaee94e59f88fbdd0f3e913b26d1-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4104f417dff07ee2b6c49b798f8b23ad/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "One Night Love Affair",
    artist: "Bryan Adams",
    id: 88902731,
    release_date: "1994-11-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-223550d4e19162a27101d20dfda7f5e4-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7be525c05b1942e1844d0073f7b9ab5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Up Where We Belong",
    artist: "Joe Cocker",
    id: 906585,
    release_date: "1982-02-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9985131650715bf3f6bccee67066dc8b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae9c863ba27686af441666135693de2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "True Colors",
    artist: "Cyndi Lauper",
    id: 608820,
    release_date: "1983-04-23",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7b242487082e692b65d85daec9bbd529-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed1fbfa7f334b65bc021be93cb79157f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Wolf",
    artist: "Shakira",
    id: 75834066,
    release_date: "2009-10-09",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-696234a467163429cadd6bba8e11356a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3686fe46b249a6c0f51809cd5685a024/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Engineer",
    artist: "Animotion",
    id: 17978474,
    release_date: "1996-04-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0befa60752453e93a297610aba5ec595-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb6bb80759cb246cc49e1ea8cb7ebf2c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lemon Tree",
    artist: "Fools Garden",
    id: 72665258,
    release_date: "1995-11-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dd5f56d3a4856599cf4c1c6439a3888a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b0cb03a865c85e596a610166819dc7b0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gold",
    artist: "Spandau Ballet",
    id: 3130318,
    release_date: "1980-05-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5126cb165999b76362167d3d5d7ef65-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b88d3c3ad23435fb5b8d52038551c1f6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Something Got Me Started",
    artist: "Simply Red",
    id: 75814726,
    release_date: "1992-10-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7af526068827296d6540b510bcd9d710-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6082ea7f45ec9a7093225b85bd6ebadd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Femme Like U",
    artist: "K-Maro",
    id: 605348462,
    release_date: "2004-12-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54728df8bf2caead57ea8b6cce3bc58e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b1257498a1c2a50c704f27bb308c6fe/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "If You Don't Know Me by Now",
    artist: "Simply Red",
    id: 75814729,
    release_date: "1992-10-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1dd59d2fe26facc7ec732030cdc84d76-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6082ea7f45ec9a7093225b85bd6ebadd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Self Control",
    artist: "Laura Branigan",
    id: 4288159,
    release_date: "1984-04-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6a5dc085c3710f46710d518a804349b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8d7d2bb9f156110a5be3734c246c99a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "New York Groove",
    artist: "Hello",
    id: 59207391,
    release_date: "1976-02-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-064c205116447588c86cc5b23e538b92-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fcdf815b71950c14d18b54d2ef0eec15/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Axel F",
    artist: "Harold Faltermeyer",
    id: 17933254,
    release_date: "1984-06-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-89fcaa9821fa44ca8de504a4ad272c4b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d1630ee29a1c9ea22822a086c5a4a8e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer In The City",
    artist: "Joe Cocker",
    id: 69795749,
    release_date: "1994-03-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe2cf212f3daa88891ca204dfaed17e9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ccf682bde3b72731fb21347123ed52c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Can Leave Your Hat On",
    artist: "Joe Cocker",
    id: 3104719,
    release_date: "1994-05-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c5bbfff7d5cabe6af7875b8fdc50df4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57e4c3c461961e77fab66662fecee013/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Easy",
    artist: "Faith No More",
    id: 813837,
    release_date: "1990-01-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-90a4340163bc806352238bce407ffba9-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9634f0c731e31dc5f3e3c43f0771376/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Right Here",
    artist: "SWV",
    id: 555805,
    release_date: "1992-08-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2627da4e00955b1a1390a2391751a980-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8852e80ba157a469f6e8702170e585b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Love Your Smile",
    artist: "Shanice",
    id: 910925,
    release_date: "1991-03-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-460e09322c6f078d0e1eb07a1b1fc46a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/447dbf905a9bc8113add6d914babe853/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Moving on Up",
    artist: "M People",
    id: 600153,
    release_date: "1993-02-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4c00990367657165e695b25c81baa3ea-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/03ae0d6ea06504c955ff6661049af62b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Feel Lonely",
    artist: "Sasha",
    id: 686342,
    release_date: "1998-10-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f059995ff5425cf2878f3c6060cc8c1c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/637642a15b8d354e6c3f5f08e74a81af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shiny Happy People",
    artist: "R.E.M.",
    id: 136339500,
    release_date: "1991-11-18",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e400c9430ece7e3f1aa3e11bad1d217a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a968ed3814acb2ef8f3929b1bebcb42d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "The Shoop Shoop Song (It's In His Kiss)",
    artist: "Cher",
    id: 540169472,
    release_date: "1990-08-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff9545413cea4ae026caf77bc7db8899-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/06cd15444c800fc985d53489b8ca4a13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come Along",
    artist: "Titiyo",
    id: 19271551,
    release_date: "2007-10-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96d28df59d4a917665390441034584c9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fcc0f75aaa955309c6377b1b6387ad9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Supreme",
    artist: "Robbie Williams",
    id: 3102420,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dd82e93b2f2a4718c07bdcc474c69c32-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d260fa442f925e1eb1c95bb29941f636/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Miss California",
    artist: "Dante Thomas",
    id: 368705481,
    release_date: "1996-07-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a03174f79f501154a7a27f437d519e89-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7001559164b20d486115986f508ad49/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Don't Pay The Ferryman",
    artist: "Chris De Burgh",
    id: 1158199,
    release_date: "1981-04-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db8496f9cb1df91e59cb8512a40c006a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4507359752a023d0d4e91370c779acd2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Little Lies",
    artist: "Fleetwood Mac",
    id: 664456,
    release_date: "1987-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7339f72f30c8c079c94fa573db2b6cc3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fae8305ef18ac327a4c19436672d5d5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer Wine",
    artist: "Nancy Sinatra",
    id: 4356542,
    release_date: "1968-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e3e8ca343add54784addb8367a967c7b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fcf8669f7aebb982798cf026aa6b872b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "'74-'75",
    artist: "The Connells",
    id: 84382469,
    release_date: "1993-07-29",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0d755c15965c7b4a046486dfc278fc1-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6c7515591bb1d506e22853ee4b6817f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mmm Mmm Mmm Mmm",
    artist: "Crash Test Dummies",
    id: 6075100,
    release_date: "1993-04-05",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4517ab7e527526d73a789e20f2c346f-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b67d4aa649454a55fbd405398cd14975/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cats In The Cradle",
    artist: "Ugly Kid Joe",
    id: 1115742,
    release_date: "1993-08-19",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c25dacbc712b862b8e9defd9dad1e36-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04fd7d9a9a9a00cb7f69d9e3a5509dd1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Moonlight Shadow",
    artist: "Mike Oldfield",
    id: 3978859,
    release_date: "1989-06-08",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d60a78de6faa4a97880f99b078a774d8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b3e7d77cf4454c3de4aad41fa9abdb4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Gimme All Your Lovin'",
    artist: "ZZ Top",
    id: 2133998,
    release_date: "1983-07-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95935b129b3f471ce8f64cf3519b6be7-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/706d994a9b906b857db6869183c30274/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Americanos",
    artist: "Holly Johnson",
    id: 4275259,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c934500c167061bb88c10dddf804ce76-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6827672c0ca3c851f602191c281f1b10/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kopf verloren",
    artist: "Peter Fox",
    id: 2200207,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c86475b4865bfe5b452e36a42094578-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Played-A-Live (The Bongo Song)",
    artist: "Safri Duo",
    id: 2276002,
    release_date: "2007-08-09",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-504a698fa3ce6847d27270f24a578d8e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/411921710089486f9c2f1d00247bd9f2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Heartlight",
    artist: "Kenny Loggins",
    id: 15526148,
    release_date: "1983-09-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6cd0c63cb8667635c1255c6cf0836de7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f45d298a7d06d5b4809d91445976adb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rivers of Babylon",
    artist: "Boney M.",
    id: 968798,
    release_date: "1988-05-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46c13baa1335824ec4392902b69be7e4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24290d29032e06a3669ab17c5e1b7a2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Didn't Start the Fire",
    artist: "Billy Joel",
    id: 626123,
    release_date: "1989-10-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8030aa3bd790b58875dc78a5b2663067-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a44cff0ab7a842716dc62235a211a30/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Centerfold",
    artist: "The J. Geils Band",
    id: 3168236,
    release_date: "1989-09-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c7ec8ba67c79b610f53e989c2f1a449e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc1722705a41d9221bff919895c236bf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Born in the U.S.A.",
    artist: "Bruce Springsteen",
    id: 15586213,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a314576f8bdddadd140e7e390d0bcbd-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hungry Heart",
    artist: "Bruce Springsteen",
    id: 15177141,
    release_date: "1980-10-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f23262581f49e5dd98f80c0ad5a4d8d2-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a971f0ed2fda6d017adeb5e3af3c1619/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Boys Of Summer",
    artist: "Don Henley",
    id: 3977905,
    release_date: "1984-06-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2ad1f48a05d8d7b6fc26ab7f96dc6482-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e374e05d5e2991dda20e1b2636e69cc9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Wild Boys",
    artist: "Duran Duran",
    id: 3130438,
    release_date: "1984-11-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-de36420696344b861fccc9e155e72d04-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ed10ab812e802c87e423db20800f678/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Liberian Girl",
    artist: "Michael Jackson",
    id: 59509561,
    release_date: "1989-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb1ab447320896a59c6b632fcb2435c1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Leave Me Alone",
    artist: "Michael Jackson",
    id: 59509521,
    release_date: "1989-09-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d36dadaa26a4058772825563dd8e06b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/995b33eaad9a62409c822ce9c670e542/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "ABC",
    artist: "Jackson 5",
    id: 920082,
    release_date: "1970-11-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-096452f45cdca8793b4f01d33189c559-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/441713fba5bca16b3731767218c27234/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Suspicious Minds",
    artist: "Elvis Presley",
    id: 1055776,
    release_date: "1969-09-28",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-734d74845023ba9faa12ede425cabd91-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3598c1cdd425022000eba13ade236e8d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jailhouse Rock",
    artist: "Elvis Presley",
    id: 6596867,
    release_date: "1957-08-30",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e0eab947e665fddbb092d5964b28df9-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ddd830fa6768774fdc110e4388b88992/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "MMMBop",
    artist: "Hanson",
    id: 88685889,
    release_date: "1997-05-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-236f6b7aeb474815bbbae717956e5e6e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/394c44c400a9786a9582a7fed308622e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Smells Like Teen Spirit",
    artist: "Nirvana",
    id: 13791930,
    release_date: "1991-09-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-358eb79e55e30b4719d976e15d41e230-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0282817b697279e56df13909962a54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mamma Mia",
    artist: "ABBA",
    id: 884030,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b71351d35c024079047ab3bc66b0fbc-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Isn't She Lovely",
    artist: "Stevie Wonder",
    id: 2802054,
    release_date: "1976-02-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-028af9e88956de62defabf7dc8293723-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cca2a5854f722f615dddf22ca9d93804/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy In Love (feat. Jay-Z)",
    artist: "Beyoncé",
    id: 609244,
    release_date: "2003-06-24",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5ecd6528459f97a68077d10a7d626e9-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ea1a631aa5235bbd0063643beb96fa8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Single Ladies (Put a Ring on It)",
    artist: "Beyoncé",
    id: 2485118,
    release_date: "2008-11-14",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b844f52cb55a5d993a3daf4be4b0d069-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0450a010b5a825194d7ca00b3067ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Roar",
    artist: "Katy Perry",
    id: 71645431,
    release_date: "2013-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-805b723187e1492231c36b8847e129aa-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe781ecd9879a82beed80f6d3e80745b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Firework",
    artist: "Katy Perry",
    id: 17135111,
    release_date: "2012-03-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e320159d1758c4f4728a81c3f4fe6098-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99578b0bb8c838383c414a5b62b5e15d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jolene",
    artist: "Dolly Parton",
    id: 114422238,
    release_date: "1974-02-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d28a32ff05149c935553cb0a10d4d21-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d47fc7a27a0d40cc2a8c119ed2bc53b7/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "9 to 5",
    artist: "Dolly Parton",
    id: 992937,
    release_date: "1980-11-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1aa2ac195fd4025a0db436a4c6ef5d2c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62438de920e4943c653d545999cd120e/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Shape of You",
    artist: "Ed Sheeran",
    id: 142986204,
    release_date: "2017-01-06",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8f5b81a6243ddfa4c97b9a4c86a82fa-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/000a9228cecfcc7c2093d9cd7bb66447/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In the Air Tonight",
    artist: "Phil Collins",
    id: 134036212,
    release_date: "1981-10-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eee21ed4de5195b63ffa0c25021e87c9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're The First, The Last, My Everything",
    artist: "Barry White",
    id: 537988582,
    release_date: "1974-08-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-070df5a471f6a397993f6d613e1f0b03-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d4d9be63470e9c842f8d504aabc8bd6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Maggie May",
    artist: "Rod Stewart",
    id: 538641872,
    release_date: "1971-08-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d1fc907d52fadfcde07ff18dbe10611-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/964629a321bad47e2ad1d5f4c6073e89/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "CAN'T STOP THE FEELING!",
    artist: "Justin Timberlake",
    id: 124237488,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e2950c9eaf0c5c9bd8621dc855099f4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/209aa560e3b5fbe679e91887fc0c7332/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cry Me a River",
    artist: "Justin Timberlake",
    id: 969494,
    release_date: "2002-11-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-065c43d27032a85c93bf25f48f08e706-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cba368fa8466d72d149264577cb19d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feeling Good",
    artist: "Nina Simone",
    id: 1707933617,
    release_date: "1965-06-21",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c96464869490f4464b6545c5096c1c75-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b1664b2dc4a4bd7a95aca09d67bc47f/250x250-000000-80-0-0.jpg",
    genre: "Jazz",
  },
  {
    title: "(Something Inside) So Strong",
    artist: "Labi Siffre",
    id: 6469108,
    release_date: "1987-07-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-02c32139016aeb0107013511147eb48b-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2be6d43bc57c07ccf80d7d499eaa5ac2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Still Standing",
    artist: "Elton John",
    id: 1171163,
    release_date: "1983-04-30",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4c0855ea6f93be88d6c0843689d63494-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f099560bed3f4813a8bf71e203cd3f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tiny Dancer",
    artist: "Elton John",
    id: 424567592,
    release_date: "1971-11-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb374939bd35ab1affe51816776899c0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bef764300d825a16e2eab8040cafe6c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Your Song",
    artist: "Elton John",
    id: 880181,
    release_date: "1970-10-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ae8f9b10e8924ad25b2ceed76c3e48e8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dba55570a13a8be5d20e457a75091dd5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Girl",
    artist: "The Temptations",
    id: 1156952,
    release_date: "1965-03-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e210a366364e907920d40326238d9dbb-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4b491c556300c84af39e5fe07dd1e34c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Vibrations",
    artist: "The Beach Boys",
    id: 3091992,
    release_date: "1991-07-16",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbefe86c9e461c8c0a63c269a9aada52-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a9121464332223e7cb452040f5ddb70/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Barbara Ann",
    artist: "The Beach Boys",
    id: 3091983,
    release_date: "1963-05-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07b47598f3ad1125392d5e5335b8f610-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a9121464332223e7cb452040f5ddb70/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Uptown Funk (feat. Bruno Mars)",
    artist: "Mark Ronson",
    id: 92734438,
    release_date: "2015-01-12",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-629282ccbd8902762c922ccebd4c3d03-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3734366a73152d0367a83a4b09fd163f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Creep - Radio Edit",
    artist: "Radiohead",
    id: 138547415,
    release_date: "1993-02-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4850a49917137825d128ae02a60f71fe-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f08424290260e58c6d76275253b316fd/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Fireflies",
    artist: "Owl City",
    id: 4188437,
    release_date: "2009-07-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-899e8078b5ddc258fe539c6b9baefa2f-25.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c923db2cb6698897426be066e01086c3/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Where Is The Love?",
    artist: "The Black Eyed Peas",
    id: 1697351967,
    release_date: "2002-04-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-ddd90e1fae94e3aeb003eb69c7e99766-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e4b70f9985801a0acbcef1782bd18eb/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Cupid",
    artist: "Sam Cooke",
    id: 1653404212,
    release_date: "0000-00-00",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cd927b6ed2b16803a7e1a0b4b03c85f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b125979fdadeeaf39435061ae3d53f6b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Wanna Dance with Somebody (Who Loves Me)",
    artist: "Whitney Houston",
    id: 75981528,
    release_date: "1987-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-653b4a72ba9d8f4e8cfaca53428ca84d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/26b5030b971a5fd363f82cad8cff7453/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Vincent",
    artist: "Don McLean",
    id: 3166507,
    release_date: "1987-04-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beb57aac1bc6ca269a7a45c1a6f45721-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8514b388d88fabe7e094b4d167c583d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What A Wonderful World",
    artist: "Louis Armstrong",
    id: 2538209,
    release_date: "1967-05-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-90eeb04333f1493438ea659e07f9c465-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7dcaac79890bdda6a2e618c323351c38/250x250-000000-80-0-0.jpg",
    genre: "Jazz",
  },
  {
    title: "Titanium",
    artist: "David Guetta",
    id: 62624905,
    release_date: "2011-08-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79335512785dc23ab9515031e03adb08-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All About That Bass",
    artist: "Meghan Trainor",
    id: 113420702,
    release_date: "2014-06-30",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c0df24413d8585eae3a220afe38e544c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14f6b5d6ab06d3bc46b15c67c15305f1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Call Me Maybe",
    artist: "Carly Rae Jepsen",
    id: 17826508,
    release_date: "2012-05-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af92f4b745c8c46b73317d4240e78982-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d59ba2295d91292a8bcb372bad9d88be/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Empire State of Mind",
    artist: "Alicia Keys",
    id: 4761593,
    release_date: "2009-12-15",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f4fc0d4a1a138682469073181552417-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/74406abeb7ba1e28ebf4a7e7a01c4e1f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Wherever You Will Go",
    artist: "The Calling",
    id: 994228,
    release_date: "2001-02-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d387386f3889f384aeb90d4b798c84a4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/40d325ff5bb9aa8dc5af27a3d5cf5e50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If You're Not The One",
    artist: "Daniel Bedingfield",
    id: 2319140,
    release_date: "2002-02-07",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d831f8d2e3aa7f48c1f51d10c3743627-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8dc5eb14bdfb161c81b3f9966ef4ae9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gotta Get Thru This",
    artist: "Daniel Bedingfield",
    id: 2298587,
    release_date: "2001-04-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aadc588d975c053c5fa58ce6fe68dd0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/10e111eb47dd8b80c8b2166fa4ac0872/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Son Of A Preacher Man",
    artist: "Dusty Springfield",
    id: 1104547,
    release_date: "1969-09-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2054654f099b27d98a6733ec5827ea39-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc8846fc9a82c9223f5b1c39dddc5270/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Spooky",
    artist: "Dusty Springfield",
    id: 2267150,
    release_date: "1969-10-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a612322681e7da8a49c41d352ef308e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5ec8da1d38abddd0406e74039bd15f90/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "American Pie",
    artist: "Don McLean",
    id: 3156285,
    release_date: "1971-06-12",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-06c4fb5b801c6ee59a284506cdfd0fa9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a0cfdacfaaa0ba9b514f86f9d69f988/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss The Rain",
    artist: "Billie Myers",
    id: 2298539,
    release_date: "1997-04-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00194d3a9f0382db37f87127b3a70497-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/521c96c424f76ba822f09273a7918896/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Somebody That I Used To Know",
    artist: "Gotye",
    id: 14993990,
    release_date: "2011-12-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9744174e88211551e94357d7ee1f6ee7-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/875e28fbbb3a7b381ffd5839b5905445/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Waterfalls",
    artist: "TLC",
    id: 1043894,
    release_date: "1994-11-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e421e4e28cc565b257fde1e10ca5098e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3134fd3fba883b4e6c8df8dc1487dbef/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Unpretty",
    artist: "TLC",
    id: 1076856,
    release_date: "1999-02-22",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a235f55452f3b4d0e67530cfc7046e0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dd5f40e7688ba155a5ef557977e95d3/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Creep",
    artist: "TLC",
    id: 1043879,
    release_date: "1993-02-22",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8f66cdb27dad259dbf10dc9c2b13c5b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3134fd3fba883b4e6c8df8dc1487dbef/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Running on Empty",
    artist: "Jackson Browne",
    id: 3613971,
    release_date: "1977-12-06",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46b07239a514eea3b6ebe197ecc679f8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a5e537dadbff5e3528af1c7ce9495325/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Try",
    artist: "Macy Gray",
    id: 15175385,
    release_date: "1999-07-03",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e709c64a545ee1623dd180be062775a0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c68c524bca29e48dff44d2eacb719e43/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Hey Ya!",
    artist: "OutKast",
    id: 628266,
    release_date: "2003-07-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-adc4965ae3a8b122a10814ee15828ab3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81783b6cc6030733cd475f820855562/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Writing's On The Wall",
    artist: "Sam Smith",
    id: 107900036,
    release_date: "2015-09-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01816014ed67406c99bf5d497270b1bb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75e56110a5cef30a4518a075d10ad05e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Day Like This",
    artist: "Elbow",
    id: 1088130,
    release_date: "2008-03-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-153040625aeee992a083fae9ced42c3e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b4d06789fb518d736d62244a9363301/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Marry You",
    artist: "Bruno Mars",
    id: 8011854,
    release_date: "2010-10-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bfa38e5adb50b74448ca0b89ea6e656f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b59dc18e109515420f8237719bd2186/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Worry Be Happy",
    artist: "Bobby McFerrin",
    id: 3127387,
    release_date: "1988-09-03",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-737eefbc710fc89681dec000170e0d25-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/121ed33882c127248831b9aacfe5220b/250x250-000000-80-0-0.jpg",
    genre: "",
  },
  {
    title: "Do Your Thing",
    artist: "Basement Jaxx",
    id: 2661885,
    release_date: "2001-06-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3dd26406f7ff24dcc5deb9b3e28194a0-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5c187cbbd0265d34859f9116a4cc46d/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Wind Beneath My Wings",
    artist: "Bette Midler",
    id: 3616256,
    release_date: "1988-03-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c426d924b9c35dc6cbca63b757a133-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/30fa0ef71608a98273700307fc40ad59/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feel Good Inc.",
    artist: "Gorillaz",
    id: 3129407,
    release_date: "2005-05-23",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88e048997f12d2e0ee67589307270507-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dd837db7a1c6acf7acb20e26c0452c3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "DARE",
    artist: "Gorillaz",
    id: 3129413,
    release_date: "2005-05-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6314296e111419d818779e578b0f41e3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dd837db7a1c6acf7acb20e26c0452c3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "We All Stand Together",
    artist: "Paul McCartney",
    id: 1125787962,
    release_date: "1984-11-06",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8b6681dc581cb626d07b5647167d978-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b834628a1a37bf798dc6326f68edf50a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Moondance",
    artist: "Michael Bublé",
    id: 782888,
    release_date: "2003-02-11",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6eed2160916290cfa7718ce5e5e8c8f9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a5dd92ae1b53ad19f39e3036dd510541/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beyond the Sea",
    artist: "Bobby Darin",
    id: 3613860,
    release_date: "1958-10-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a7dc6e9cd63d9486dce17cdc242704e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9193897d1d59fff550bc9cc971a7eb5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Power Of Love",
    artist: "Huey Lewis & The News",
    id: 3116046,
    release_date: "1985-05-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2b047e7c2823a55f5f498613c342409b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/947835de11de3edaf23440d0078b0a92/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Raspberry Beret",
    artist: "Prince",
    id: 775712,
    release_date: "1985-04-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b92cf7a930a8a17d93adcf9d93c9eed9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/52efb13d0fe33002a4079d321f7f9d69/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Price Tag",
    artist: "Jessie J",
    id: 14405185,
    release_date: "2011-11-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51f9d6b549a89f2e89ab6d0a054b95a2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e830d5ee10ad8654e0d8ab70a18e54a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If I Were a Boy",
    artist: "Beyoncé",
    id: 2455091,
    release_date: "2008-10-17",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e199b72cf085dacb833e0c3931dc21ab-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a5cc391d209d80a3e3146772c41665c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shackles (Praise You)",
    artist: "Mary Mary",
    id: 626167,
    release_date: "2000-04-25",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3965f2250cb1ab24cc01bda19da6b3d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ce09d9b4267630242eb0063a10f3ee/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Sweet Child O' Mine",
    artist: "Guns N' Roses",
    id: 2171464,
    release_date: "1987-03-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd2e782bbd2e6ca929301cc66b5cbfd6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88286a305ec9940bb7099fb5dac0211d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Higher And Higher",
    artist: "Jackie Wilson",
    id: 558161542,
    release_date: "1967-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e712e3f0894fc42e62baad9ff736c37a-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/artist/af0d35bde1677b99e056ec6dfa50b413/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Better in Time",
    artist: "Leona Lewis",
    id: 365070,
    release_date: "2008-03-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1930b96ebb6b2867252a58b143c924e7-89.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/884deb9902213e00195d3d696965b080/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No One",
    artist: "Alicia Keys",
    id: 365418,
    release_date: "2008-12-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4f4f8fa69eaab133a8ec179502da1db4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Leave Right Now",
    artist: "Will Young",
    id: 533992,
    release_date: "2003-12-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-432e9593a80aa28af422b6615c01abff-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7eca7c22988937feba5c5ca021f3bb8b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Since U Been Gone",
    artist: "Kelly Clarkson",
    id: 534076,
    release_date: "2004-11-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b89bb2323ec824d71ed61dcf0a982289-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce2a4077de90c1b5c9ce0f46321611a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breakaway",
    artist: "Kelly Clarkson",
    id: 534077,
    release_date: "2009-03-02",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-707a6ffcf60827fe067e9523bd33e099-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce2a4077de90c1b5c9ce0f46321611a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Behind These Hazel Eyes",
    artist: "Kelly Clarkson",
    id: 534078,
    release_date: "2009-03-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d318ba6c67cd8e121cc4cb65c0d5e52f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce2a4077de90c1b5c9ce0f46321611a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "SexyBack",
    artist: "Justin Timberlake",
    id: 534726,
    release_date: "2006-09-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d83c16b5f7dce9e5db13a350a1a76dd4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "LoveStoned / I Think She Knows (Interlude)",
    artist: "Justin Timberlake",
    id: 534729,
    release_date: "2006-09-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d83c16b5f7dce9e5db13a350a1a76dd4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Freestyler",
    artist: "Bomfunk MC's",
    id: 534964,
    release_date: "2000-05-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1ca83715b2bca799096345c6333a83ff-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cafda36f44d15143abdb48cd18f604af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweetest Girl (Dollar Bill) (feat. Akon, Lil' Wayne & Niia)",
    artist: "Wyclef Jean",
    id: 536408,
    release_date: "2007-08-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cbbab757dc25d401e80802fc0cde00d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a0fe3540c7fbb9f779c9e6cd12742c3/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Where I Wanna Be",
    artist: "Donell Jones",
    id: 536926,
    release_date: "2007-09-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e5fd2b1408dd4194648bae378c6fd12-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b5096ef469133260e0f3d3671961ac0/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "(You Drive Me) Crazy",
    artist: "Britney Spears",
    id: 540176,
    release_date: "1999-01-12",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16eb34c9daa2cb026f033d07cb6b77d8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd904e47db5549124f099c1fef304dc9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm a Slave 4 U",
    artist: "Britney Spears",
    id: 540191,
    release_date: "2001-10-31",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3f28e0395022cdfdf5bd4843621e75c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d38e9d1e1a07e69e0bad3302eeb8722/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If There's Any Justice",
    artist: "Lemar",
    id: 545245,
    release_date: "2004-11-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c29ab2e328a23504349fba2ca8a87e39-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/750053aaba00e32ee83a54cdae111c91/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Because of You",
    artist: "Kelly Clarkson",
    id: 546130,
    release_date: "2009-03-02",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e754b2d3fd95f4597fd91d4e93e2e30-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce2a4077de90c1b5c9ce0f46321611a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stupid Girls",
    artist: "P!nk",
    id: 547530,
    release_date: "2006-04-04",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6afa85fbf0e167f10dc36cfa6f170f50-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf22d6537087e34651291f6710879b5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "U + Ur Hand",
    artist: "P!nk",
    id: 547538,
    release_date: "2006-04-04",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-70aa5e63df07b621e64622adcfbf174c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf22d6537087e34651291f6710879b5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's My Goal",
    artist: "Shayne Ward",
    id: 548274,
    release_date: "2006-04-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28d13129d2b07cce7c1d5cb065924890/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Promises",
    artist: "Shayne Ward",
    id: 548275,
    release_date: "2006-04-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28d13129d2b07cce7c1d5cb065924890/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Sprung",
    artist: "T-Pain",
    id: 548342,
    release_date: "2006-05-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b95b7733027084f14de832478580fca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Your Love Alone Is Not Enough (feat. Nina Persson)",
    artist: "Manic Street Preachers",
    id: 550585,
    release_date: "2007-04-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e8f1f115f57bb9a1c2ecb54d011858e6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6435d857d8e6e6c00038f6697e362d5/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Here (In Your Arms)",
    artist: "hellogoodbye",
    id: 550951,
    release_date: "2007-05-18",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00ac641cd441327a0978bb7d0a008cd5-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7748a1b0e617469b151d0b11ae92a41/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Beautiful Liar",
    artist: "Beyoncé",
    id: 551207,
    release_date: "2007-04-03",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-73f8d17ca4e2b0d76446cb202280bb77-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24aa4e9bed041437c703bd8c9c0b0381/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Deja Vu (feat. Jay-Z)",
    artist: "Beyoncé",
    id: 551226,
    release_date: "2007-04-03",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-03a836e0e95f1526cc272b999205f5eb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24aa4e9bed041437c703bd8c9c0b0381/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Shiver",
    artist: "Natalie Imbruglia",
    id: 554449,
    release_date: "2007-09-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b70ed4d6a5d48b36a601b846a014a6ca-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce07121876070fc4ac134e9111871e5f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Daughters",
    artist: "John Mayer",
    id: 556646,
    release_date: "2005-06-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0245b6180252866c227030d47eefc4ff-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/11414fe73b4ae0a0161254a7f0768e45/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Caught Up",
    artist: "Usher",
    id: 559923,
    release_date: "2005-04-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e117bee15be4274bf0947339aee7baaf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Almost Here",
    artist: "Brian McFadden",
    id: 559928,
    release_date: "2005-04-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e117bee15be4274bf0947339aee7baaf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Come 1",
    artist: "Faithless",
    id: 560272,
    release_date: "2005-08-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-103c17b839bcf8e0c57b985b1e030841-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/09150c41390667202b0e0d9e7ca10105/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unwritten",
    artist: "Natasha Bedingfield",
    id: 560504,
    release_date: "2005-05-24",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ae25a6742fe0f4b56664c159f8cdbf6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/caa7a44a1dd426f4ceb66f34e82c5b23/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In the Morning",
    artist: "The Coral",
    id: 560769,
    release_date: "2005-05-23",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a629c218f370f520a00e3324fcfb6fd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f37b2618e2e5e3e2e8ad26d0b7065cc/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Hips Don't Lie (feat. Wyclef Jean)",
    artist: "Shakira",
    id: 562487,
    release_date: "2005-11-28",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c813f35d1cfbd4c0e35719352dcd78c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01c2d6152eb73558d91dd955eeba9b5a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get Up (feat. Chamillionaire)",
    artist: "Ciara",
    id: 563922,
    release_date: "2006-08-08",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c082e54500e4f757a7c9a399aa1dfc05-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ac8bc4c8bfa91949e49c672a012716fa/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "The Rose",
    artist: "Westlife",
    id: 566286,
    release_date: "2006-11-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fdeff774266214c2b01c16457a5d5e84/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "He Wasn't Man Enough",
    artist: "Toni Braxton",
    id: 567649,
    release_date: "2000-03-07",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2b19f701bbba7a6de9c89f737b7cead/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Un-Break My Heart",
    artist: "Toni Braxton",
    id: 567656,
    release_date: "1993-10-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2b19f701bbba7a6de9c89f737b7cead/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Differences",
    artist: "Ginuwine",
    id: 567968,
    release_date: "2001-02-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c426f195673c36254ed6365eb739071e-87.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ee5ef668765cdc1f7900498fb7308c9f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Oh (feat. Ludacris)",
    artist: "Ciara",
    id: 569201,
    release_date: "2005-01-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-137b1dba631b84f628abfd7cd21eb221-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6617b0742476083a5d507097b1f15534/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "1, 2 Step (feat. Missy Elliott)",
    artist: "Ciara",
    id: 569213,
    release_date: "2005-01-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10d8ae1a3caecd05dcc271bdc8ee8b9f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6617b0742476083a5d507097b1f15534/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let Me Love You",
    artist: "Mario",
    id: 569899,
    release_date: "2016-08-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87abc814a2d13524984a05eda6a1475d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de4e9b0f4d2ecf01f035369cca257f2c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Incomplete",
    artist: "Backstreet Boys",
    id: 569913,
    release_date: "2005-08-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-485a8c02d342d101595cabec2cebc66f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba145cded08eb7a11b0238e67bce1b0f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let Me Hold You (feat. Omarion)",
    artist: "Bow Wow",
    id: 571165,
    release_date: "2005-07-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f197cbaf92c885c4184176b9a291a791-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc60ec982e6ed18e19a3ac1e373ee591/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Like You (feat. Ciara)",
    artist: "Bow Wow",
    id: 571541,
    release_date: "2005-07-11",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-246205023b5122695de264691d56132b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc60ec982e6ed18e19a3ac1e373ee591/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Raise Me Up",
    artist: "Westlife",
    id: 574866,
    release_date: "2005-10-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ba1d4127c23a779d95fa2986eb9d1ef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Left Outside Alone",
    artist: "Anastacia",
    id: 575228,
    release_date: "2005-10-25",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f8eea68541d7e8c7c5e546dbaf57916-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/adcc3162f6abf31d2ddcffe5f0887694/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ordinary People",
    artist: "John Legend",
    id: 576284,
    release_date: "2005-11-07",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-298a56f9bc391044196f97b3f1ce04e7-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed44612066b98bfed7ff97669e6f6103/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Girlfriend",
    artist: "Avril Lavigne",
    id: 580029,
    release_date: "2007-02-23",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00a46b9642435935f18df29c937eb0e2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bills, Bills, Bills",
    artist: "Destiny's Child",
    id: 580889,
    release_date: "1999-07-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a29777b8f5ca1a22af465ec9809318c-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73da200f9335f752d2f7cb5ed8933cef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jumpin', Jumpin'",
    artist: "Destiny's Child",
    id: 580931,
    release_date: "1999-07-27",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aaec2f2645f5fa663f66428e9f6664a8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73da200f9335f752d2f7cb5ed8933cef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say My Name",
    artist: "Destiny's Child",
    id: 580936,
    release_date: "1999-07-27",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7d50221755ae6e7c0af47b41741afe6-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73da200f9335f752d2f7cb5ed8933cef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Soldier (feat. T.I. & Lil' Wayne)",
    artist: "Destiny's Child",
    id: 581082,
    release_date: "2004-11-16",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d062d719d6f9e9b7712c621b809d9a6d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a834c91eee2bb719f89287aa6d93ff51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cater 2 U",
    artist: "Destiny's Child",
    id: 581085,
    release_date: "2004-11-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99d0601bac96ff2100394cd6066d109b-89.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a834c91eee2bb719f89287aa6d93ff51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Girl",
    artist: "Destiny's Child",
    id: 581095,
    release_date: "2004-11-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b70e70a085bfe70771663d87baf3b897-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a834c91eee2bb719f89287aa6d93ff51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Prerogative",
    artist: "Britney Spears",
    id: 582250,
    release_date: "2004-11-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9584d2aed496dfc0e50c66b2cad6f94b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34265ac018fe0d973eac131fe04038a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lucky",
    artist: "Britney Spears",
    id: 582261,
    release_date: "2004-11-08",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e1ad2ac9190ad8c73ccd4e8d49d5a6ed-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34265ac018fe0d973eac131fe04038a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Run It! (Featuring Juelz Santana)",
    artist: "Chris Brown",
    id: 583392,
    release_date: "2006-01-30",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c26e6550c79e0d2cb45e11734c6fd408-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/08715753a44fedb76cb597ed4b1b9c2f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "With You",
    artist: "Jessica Simpson",
    id: 587102,
    release_date: "2004-04-16",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c8d6a85f9920c31fbbce0dbf9f6cbe8d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed8ed0f4e9dcc54379b5cf18dec1f2bf/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Thank God I Found You",
    artist: "Mariah Carey",
    id: 596072,
    release_date: "2007-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a1292c4a78e731fa81c57f1282b496fc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Naughty Girl",
    artist: "Beyoncé",
    id: 597856,
    release_date: "2004-05-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-43c6b3b5edb105aadb6cf9c77935535f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0d05e7449b21e78bc278e69d82bb15e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Yeah!",
    artist: "Usher",
    id: 598098,
    release_date: "1998-12-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e7239aacd1d85071238906aef97e5d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Burn (Confession Special Edition Version)",
    artist: "Usher",
    id: 598697,
    release_date: "1998-12-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e7239aacd1d85071238906aef97e5d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Boo",
    artist: "Usher",
    id: 601626,
    release_date: "2004-08-31",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c291c14c754829bdc290ddb7b37e55be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c81883cd6884abb66cee03c49d8bbba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Don't Want to Be",
    artist: "Gavin DeGraw",
    id: 604745,
    release_date: "2010-02-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8a028eb19a85487b3aebeaaeea6d73af-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2e9db3150f9445ca440f9fb6236a9e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Perfect Gentleman (feat. Hope)",
    artist: "Wyclef Jean",
    id: 605281,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e8ef71aeea075ad1e4fdfca5716e9a56-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecc59f47a8994ef8064e8cc5f3143f34/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "As I Lay Me Down",
    artist: "Sophie B. Hawkins",
    id: 609209,
    release_date: "1995-04-10",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f680b787494797bc3e85524aa8b41a7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ce55751a923cce517d8909616b19ea0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hemorrhage (In My Hands)",
    artist: "Fuel",
    id: 610171,
    release_date: "2005-12-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-850e1b2e0c17910ad52cfa26999cba70-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/413c48599e2bea7e876e0398a10e25d2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "100 Years",
    artist: "Five For Fighting",
    id: 610989,
    release_date: "2004-02-03",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-674c7144cb22094910d84a1abff54596-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d2527c0b451d0ef5753a9fa136631a0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Amazing",
    artist: "George Michael",
    id: 615851,
    release_date: "1987-03-29",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4881216bc203e25b1822a9c43a4409cb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/29a3a921a0ae284b7530cdb563e182ae/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Play",
    artist: "Jennifer Lopez",
    id: 618321,
    release_date: "2003-09-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7b64e1257ef37a14d1049c49c3886f41-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6385c391e17a1c16beccb7ef7da976f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Real (feat. Ja Rule) (Murder Remix)",
    artist: "Jennifer Lopez",
    id: 618363,
    release_date: "2003-09-15",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cbe581bcb57c42fbb1a8750f66ec911a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6385c391e17a1c16beccb7ef7da976f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Rock My World (with intro)",
    artist: "Michael Jackson",
    id: 619622,
    release_date: "2001-08-22",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-caa0e90ad0483ef5d1d7eb5f2bfbc2ee-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0381446a7083728f88374afece0fec3b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Think I'm Not",
    artist: "Kandi",
    id: 623397,
    release_date: "2000-09-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72c941c2d27a54f7a75aabd52306024c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dd57119003dca89a0af02200e22579d/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "She's so High",
    artist: "Tal Bachman",
    id: 623469,
    release_date: "1999-02-23",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-363f413a748515794e27487616387e7c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8750a18d11333dd0aa37c7876b17edb3/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Promise",
    artist: "Jagged Edge",
    id: 623717,
    release_date: "2012-10-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8934c06cac190c6fa3a3ccac5ddc96c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a7bedce12f57e199780c05afd7a6e0b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Absolutely (Story of a Girl)",
    artist: "Nine Days",
    id: 624080,
    release_date: "2000-05-16",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-df041d7e7299770eb05898b4a90b8be1-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37dd9d0fff31cfa168d855e1d21ebda5/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "He Can't Love U",
    artist: "Jagged Edge",
    id: 624575,
    release_date: "1999-11-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-df4a9266167222a2a0ebb7998798632b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a7bedce12f57e199780c05afd7a6e0b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Irresistible (Album Version)",
    artist: "Jessica Simpson",
    id: 624711,
    release_date: "2001-06-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bf99b387a0d5cb78235ae55f8e7a6532-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cea5fda2b6250f0db5e17a3950873101/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let's Get Married",
    artist: "Jagged Edge",
    id: 625038,
    release_date: "1999-11-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c922ec7cfae220eb236f879fffc4e531-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a7bedce12f57e199780c05afd7a6e0b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "So Anxious",
    artist: "Ginuwine",
    id: 625436,
    release_date: "1999-03-15",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f41a7cd4cbbd50ffd68cad4533a96db9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d50f36228bc318f8d7b3f2c42b250df7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No More",
    artist: "Ruff Endz",
    id: 625939,
    release_date: "2000-08-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5486a108417155d07129622d688ee2a9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/96a8d09d250f0d8833b0eebe122bcef7/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Miss You Love",
    artist: "Silverchair",
    id: 627797,
    release_date: "1999-03-22",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f256422f6b2284b03ed694995ea50d94-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e337fd1e6628e800e9302e8f14364291/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "The Way You Move (feat. Sleepy Brown)",
    artist: "OutKast",
    id: 628247,
    release_date: "2003-09-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e882d47b640254f471cf84d9409beb01-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81783b6cc6030733cd475f820855562/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Roses",
    artist: "OutKast",
    id: 628267,
    release_date: "2003-09-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3b13fe3f8283dcd6b464fb903ac2046-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81783b6cc6030733cd475f820855562/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Fiesta (feat. Jay-Z, Boo & Gotti) (Remix)",
    artist: "R. Kelly",
    id: 629328,
    release_date: "2003-09-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-44280bf4abdf10d407babce687e03b42-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dc7637895e982429854e76d654fbf0fc/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Karma",
    artist: "Alicia Keys",
    id: 629460,
    release_date: "2003-12-02",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-783ab5c375aeb0c0eb452d79e7e2fe1e-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cef30c1c2a5a63c773d26d56049c81e5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If I Ain't Got You",
    artist: "Alicia Keys",
    id: 629466,
    release_date: "2003-12-02",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56077bcb6fbc521958dc165a6846e252-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cef30c1c2a5a63c773d26d56049c81e5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's Gonna Be Me",
    artist: "*NSYNC",
    id: 632488,
    release_date: "2000-03-21",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65a1f08f94cccab9f702f354fc594599-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01fdca2ecf54d678ff005162b5b6cc92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're Making Me High",
    artist: "Toni Braxton",
    id: 632934,
    release_date: "1996-05-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e59e9944a2489ef2943bf708c12cae78-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/879e6b0a21327acd4160cf89322aec52/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gone",
    artist: "*NSYNC",
    id: 633123,
    release_date: "2001-07-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-39d395e281d157c05611ef472ba70ff4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c86390863bc26a71e43318402a8d293/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sick and Tired",
    artist: "Anastacia",
    id: 638409,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68026b9415c9ebaf996a51fadd34147a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e550f19538f883c6663e1f1da24b7359/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rockstar",
    artist: "Nickelback",
    id: 660849,
    release_date: "2005-09-26",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c6a184eebb7dc82c6e3914786a70723-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0174acbb7bc72ba56b3ff7212829f18/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Slow Jamz (feat. Kanye West & Jamie Foxx)",
    artist: "Twista",
    id: 661912,
    release_date: "2004-01-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eeeea7cb5b2a94f2d61e0baee287ba41-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9636c9851c45fbd87352a4563b35d19e/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Lonely No More",
    artist: "Rob Thomas",
    id: 662287,
    release_date: "2005-04-05",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-327d4aca7403717f55325dc5907be6f3-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4131b7189ac8cd75bd2568f7f4230107/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lose Control (feat. Ciara & Fat Man Scoop)",
    artist: "Missy Elliott",
    id: 662589,
    release_date: "2005-07-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b2dcf18c88232dbc673d20c8c850f3f3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1674494e6fb1c319cc333de15a57f82c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Grind With Me",
    artist: "Pretty Ricky",
    id: 662685,
    release_date: "2005-05-17",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3aea2eebd528cda0de9057fbfbb39590-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c137a3eed7a76f3fedb08bc7f07450eb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "1973",
    artist: "James Blunt",
    id: 663476,
    release_date: "2007-07-24",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67b90360cf41001caea0c4b2dd5a9928-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d338414b056a7afd5ba7313c40df77da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Diamonds and Pearls",
    artist: "Prince",
    id: 664140,
    release_date: "2009-01-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83a0f04c0da71c81a7a343b86a22f85b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/69e3f69ae975e0e25b241d5a066c0ba2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cream",
    artist: "Prince",
    id: 664142,
    release_date: "2009-01-24",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a91d688ee6193c3c99b39186518ebc4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/69e3f69ae975e0e25b241d5a066c0ba2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Minute Man (feat. Ludacris)",
    artist: "Missy Elliott",
    id: 671138,
    release_date: "2001-05-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-64e9946019e593e00bd285016d8d4554-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d506a6d8b28483240daee6f379fd866c/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Get Ur Freak On",
    artist: "Missy Elliott",
    id: 671140,
    release_date: "2001-05-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3256f311ca0a348ee0b7431a5047957d-81.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d506a6d8b28483240daee6f379fd866c/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Saltwater",
    artist: "Julian Lennon",
    id: 675227,
    release_date: "1991-08-06",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-386456e1e0a1825ce2b8684458239ea2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fdc7c762fe31b72609e8b3ce8936fb1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In the End",
    artist: "Linkin Park",
    id: 676183,
    release_date: "2000-10-24",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-179888dacdd6a28871ead1caebf86c79-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/033a271b5ec10842c287827c39244fb5/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Welcome to the Black Parade",
    artist: "My Chemical Romance",
    id: 676573,
    release_date: "2006-10-23",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51fb5b80cce4a2eec1c41468eacd0f31-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae78cd7f32230593392c8b76519e9044/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Teenagers",
    artist: "My Chemical Romance",
    id: 676590,
    release_date: "2006-10-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a2a0270eb927e59c77131acde153a74-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae78cd7f32230593392c8b76519e9044/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Shadow of the Day",
    artist: "Linkin Park",
    id: 676844,
    release_date: "2007-05-14",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d95427fd5e3e51e586f98a07be864e8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2dd699adcd68089f49f3ab558a2bd296/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "What I've Done",
    artist: "Linkin Park",
    id: 676847,
    release_date: "2007-05-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-519437e79b95138d9907888afaa77bc1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2dd699adcd68089f49f3ab558a2bd296/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Numb / Encore",
    artist: "JAY-Z",
    id: 676954,
    release_date: "2004-11-30",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b74c8feb9012e0eb79593f221d9057f7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7245b8fe756d39f20a53020163168dbe/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Numb",
    artist: "Linkin Park",
    id: 677232,
    release_date: "2003-03-24",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1cdfe90120447061efa70135a552152c-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/44df4f6fb2534768f4924365c103d0f7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Rain",
    artist: "Madonna",
    id: 677400,
    release_date: "1992-10-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b209de5937db8461d3cc779260a3d146-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00eba6517f89cd953072782206b3bad9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Snow (Hey Oh)",
    artist: "Red Hot Chili Peppers",
    id: 680518,
    release_date: "2006-05-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-565492c9ee832d0d7ca1b4c32ccdcb2d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3a4028b1192c50d82a579439cbfc4af/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Everything",
    artist: "Michael Bublé",
    id: 680660,
    release_date: "2007-04-30",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7165f31e1fe781199e5bcebcde7b1c22-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1bb7630219206f20050fb7e7f46b23c1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pure Shores",
    artist: "All Saints",
    id: 690526,
    release_date: "2001-03-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4df4dfea82c6aa1a4ced151befeb2df7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f73db4644fd8f418c194dbbfad75a2c2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Turn Me On",
    artist: "Kevin Lyttle",
    id: 705531,
    release_date: "2004-03-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-15518c4d7f47d643e33ac67dae20fc69-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0261ed55e0f9a9029cbd397b786c4847/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Cupid's Chokehold / Breakfast in America",
    artist: "Gym Class Heroes",
    id: 708037,
    release_date: "2006-07-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-664ec55c7b8afb1ccc9a0ccfdbc3496b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c1336089785f66ce4a98ae1e7a67173/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Runaway",
    artist: "The Corrs",
    id: 714692,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a312d12444ae32da9b273b7cec7421f7-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec392dc2f4032357cffbab34bdfc93e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Day in Paradise (R&B-Version)",
    artist: "Brandy",
    id: 714954,
    release_date: "2002-02-25",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc3fbeab77289bb44889d22e300ba764-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f8fc36d4630624087603352ee8528e01/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Remember the Name (feat. Styles of Beyond)",
    artist: "Fort Minor",
    id: 715929,
    release_date: "2005-10-04",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-359a13841fb4f3e997f636181b862025-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d4059c5525f643e2843b2f1e18e2d39f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Vogue",
    artist: "Madonna",
    id: 723903,
    release_date: "1990-11-09",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-608eb9a140911aae07462f0571de4b70-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/329c772be6103678d4c53c43d293cd51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Justify My Love",
    artist: "Madonna",
    id: 723909,
    release_date: "1990-11-09",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b4408b39708ab554c30ee735c02fd320-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/329c772be6103678d4c53c43d293cd51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Around the World",
    artist: "Red Hot Chili Peppers",
    id: 725241,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d97452f49d99ecd30f4036ad223c3567-17.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Otherside",
    artist: "Red Hot Chili Peppers",
    id: 725261,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b9917cd6868ee7aa90bc2dfc289655b-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Road Trippin'",
    artist: "Red Hot Chili Peppers",
    id: 725320,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a098affb90170f3ada75c065d2e898c-17.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "When It's Over (David Kahne Main)",
    artist: "Sugar Ray",
    id: 756272,
    release_date: "2005-12-20",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cfefc2be340cf32f6454a923ab5222ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "American Idiot",
    artist: "Green Day",
    id: 773367,
    release_date: "2004-09-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0e5e89c15cb1f76ee668a2bee6752a5e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e17c202b5ab081171f31c81eb32dc5d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Wake Me up When September Ends",
    artist: "Green Day",
    id: 773430,
    release_date: "2004-09-21",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-deff58c18a5d1519041457b0030b73bb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e17c202b5ab081171f31c81eb32dc5d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Ironic",
    artist: "Alanis Morissette",
    id: 776541,
    release_date: "1997-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e14b6ae18e14369a7ac6da11fbe6969-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba42860e4b1209e7daa11cf7f36d05b1/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "You're Beautiful",
    artist: "James Blunt",
    id: 784321,
    release_date: "2005-08-08",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1595ef41a27a8136efcce75b09f5d55-20.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b701ce9a0c1cdddf43a42638d0610b3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Strong Enough",
    artist: "Cher",
    id: 786748,
    release_date: "1998-10-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6dc0f07c41edc07a96d3fca64736e08a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af263778ad6ef2f3f5be381292ed02a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Low (feat. T-Pain)",
    artist: "Flo Rida",
    id: 786826,
    release_date: "2008-03-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-589fde3e5f1211b02ecd47a656ccdaa2-48.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f57db1d78cde50906285c31160dc20cd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Damaged",
    artist: "Danity Kane",
    id: 790402,
    release_date: "2008-03-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-df5af091eee288fef67c6607f557397a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24539ec683c6b2506ad40ba97e4be176/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "4 Minutes (feat. Justin Timberlake and Timbaland)",
    artist: "Madonna",
    id: 794020,
    release_date: "2008-04-28",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f3010611887c404230e1f2f53b5e646-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6f20f5a022403e93a94e5b9e7c1b81d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Are You Gonna Be My Girl",
    artist: "Jet",
    id: 811880,
    release_date: "2003-09-15",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87e4c945fb096ffe3b85d0a0e5de1748-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be2bce57ff0de814db635834d55b2e40/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Come to Me (feat. Nicole Scherzinger)",
    artist: "Diddy",
    id: 815263,
    release_date: "2006-10-16",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-48f6912e4185b7b0ed9c77868b63c712-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a09b17ed270f28cf3935699e45e112ed/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Fit but You Know It",
    artist: "The Streets",
    id: 823132,
    release_date: "2002-03-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-29b8aa8b5d02301b43c2e1f73c013f72-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ca1b9bd6cf02ff2b32b90a7e8cbef07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dry Your Eyes",
    artist: "The Streets",
    id: 823135,
    release_date: "2002-03-18",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a4bfab3834fffef676e7514318637fd6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ca1b9bd6cf02ff2b32b90a7e8cbef07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Bangs (English Version)",
    artist: "Ricky Martin",
    id: 830084,
    release_date: "2014-06-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ae776622e565529242771771931cacd3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ce3c2e14c8872e7a74367a0a12f90b6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "No More (Baby I'ma Do Right)",
    artist: "3LW",
    id: 831253,
    release_date: "2000-11-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ef36823f0593702a161cc79093e36f4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/39c93a940020b7910673dc3da7a52f90/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Tha Crossroads",
    artist: "Bone Thugs-N-Harmony",
    id: 831546,
    release_date: "1999-10-08",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b8efc9b7efc9afe5391cadd48054893a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0443774b0fee838507fc9221e9c8b6b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Need To Know",
    artist: "Marc Anthony",
    id: 833823,
    release_date: "1999-11-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b62ca81f0f81392dcf8098d8f94c6e24-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/12bb759dc412400a3e7e304933cb5f02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Photograph (Radio Edit)",
    artist: "Nickelback",
    id: 847072,
    release_date: "2005-06-14",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d4956ed80abed21eb0768bd281cb5bd5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35e6f1860ed9f8a7e486c56deb8c3832/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Teenage Dirtbag",
    artist: "Wheatus",
    id: 849698,
    release_date: "2000-09-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c6e3904ae1ef5d5c604ddca39e363fc3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b3f9660d7b98cc4fc6b4c9207bb1164b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bounce With Me (feat. Xscape) (Edited Album Version)",
    artist: "Bow Wow",
    id: 863423,
    release_date: "2000-11-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4220d0b48cf09fe4a4ce42bad946ba59-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c096d9d7d254f700dd179d0bee4cbe04/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Song",
    artist: "Sara Bareilles",
    id: 872915,
    release_date: "2008-06-23",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d14a4ac167e715763627a2a57b83b2e4-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b598688c3783e05053da9744f71b788/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "As Good As I Once Was",
    artist: "Toby Keith",
    id: 881404,
    release_date: "2005-11-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85be3a93e6776a4bce92569f9d4edd10-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a1b96a8f0a255fd2be123e800c1a2de/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Fergalicious",
    artist: "Fergie",
    id: 882287,
    release_date: "2006-09-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6c77def47b57e639eefab206fb628fab-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0285dad64dcaaf6ff3652fbc8e3f58d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Clumsy",
    artist: "Fergie",
    id: 882288,
    release_date: "2006-09-25",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a2be30b1ed062f9cd74ec2dbc0dbfdc3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0285dad64dcaaf6ff3652fbc8e3f58d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "London Bridge",
    artist: "Fergie",
    id: 882290,
    release_date: "2006-07-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b5b08f43cf8275cd16a74c2eb7d93617-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0285dad64dcaaf6ff3652fbc8e3f58d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Big Girls Don't Cry (Personal)",
    artist: "Fergie",
    id: 882296,
    release_date: "2015-01-06",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46a975c7ae17676eadf734dc8d5030b7-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0285dad64dcaaf6ff3652fbc8e3f58d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let It Go",
    artist: "Keyshia Cole",
    id: 885965,
    release_date: "2007-04-28",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2b5e7f00baea379e9498d99bca7bde7d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/33e298f0e1832595a5ff712c74da6c6f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Last Night",
    artist: "Keyshia Cole",
    id: 885967,
    release_date: "2008-04-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-639279583f33c1034b71cbce1524c801-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/33e298f0e1832595a5ff712c74da6c6f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ghetto Gospel",
    artist: "2Pac",
    id: 886446,
    release_date: "2004-01-21",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac873560bcb14db5c47ac6378543ac6e-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c10f36c2ce7bf1006ced08147d02de32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Don't Phunk With My Heart",
    artist: "The Black Eyed Peas",
    id: 886447,
    release_date: "2008-05-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83e03acd04a52d3c2f9f71160dd92814-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c10f36c2ce7bf1006ced08147d02de32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Let Me Blow Ya Mind",
    artist: "Eve",
    id: 886460,
    release_date: "2008-05-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6825a2b5ecfebd658af1b0113109d37-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c10f36c2ce7bf1006ced08147d02de32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All About You",
    artist: "McFly",
    id: 886873,
    release_date: "2008-02-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3068a16facada1d3786fb26bae9a59c4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d308b5fd1806d2d4effddecfbfaf4c68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mustang Sally",
    artist: "The Commitments",
    id: 890307,
    release_date: "1991-08-20",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98ac6859986de09e99295c2137bb6441-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/acfdfdd50925b732738d40e8c4c23b84/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "DONTTRUSTME",
    artist: "3OH!3",
    id: 903258,
    release_date: "2008-07-07",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6f514ad1b52dc5e838fa5479a6d0042-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/38456a9f23783447b51903475d3da8cc/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Starz In Their Eyes",
    artist: "Just Jack",
    id: 907576,
    release_date: "2007-10-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-498d8e00359e375151aba79d45fbbe0d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e4b7cc3e633cc96e19bb25131ccc034/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Stop The Music",
    artist: "Rihanna",
    id: 909875,
    release_date: "2008-06-17",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eca92f9cc2197fdba8ce04ae578b8e76/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "About You Now",
    artist: "Sugababes",
    id: 911356,
    release_date: "2007-10-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f99ecdb37861befd2c2326080c9a5e8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc5a8eef0d1ce9ee09dacb0b9ef4193c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thnks fr th Mmrs",
    artist: "Fall Out Boy",
    id: 911496,
    release_date: "2007-11-20",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-93ad2ceac69834ae67e95ff04ddb75d4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6d53977c34d4c366502f6ed7703dd32e/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Too Lost In You",
    artist: "Sugababes",
    id: 913832,
    release_date: "2008-02-25",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c7eeba8ba54a236e670472546d457c1a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/688dbebac904ce0f2c9eec2875978346/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Makes Me Wonder",
    artist: "Maroon 5",
    id: 914780,
    release_date: "2008-06-30",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f672fd5f04d5654fdb763b09642118f0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ee73da8014978c301b75f9c6904cb54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Livin' On The Edge",
    artist: "Aerosmith",
    id: 916325,
    release_date: "1993-03-30",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-614820d25797e92b47736fc604eb7379-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d57056281f6ba958d1056b103e7357aa/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Cryin'",
    artist: "Aerosmith",
    id: 916334,
    release_date: "1993-06-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4cba3914249e5b9feb4fd81700c4faf3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d57056281f6ba958d1056b103e7357aa/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "No Diggity",
    artist: "Blackstreet",
    id: 916496,
    release_date: "2003-06-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a15b8571aa6200c044bf59c76ed9127c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae7a265b79fb71278cde2504849d6f7/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Stop And Stare",
    artist: "OneRepublic",
    id: 916827,
    release_date: "2008-02-07",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a79ba8c4c5bbb69293b76d22b9cc6e73-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/565b424faee4bdc6e6db39db8d6fc333/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Apologize (feat. One Republic)",
    artist: "Timbaland",
    id: 916841,
    release_date: "2008-02-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-473b2d618cb6d4ae6515b45972626269-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/565b424faee4bdc6e6db39db8d6fc333/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance, Dance",
    artist: "Fall Out Boy",
    id: 917257,
    release_date: "2006-03-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-118dfd6b1fc71c60cc8040c1251626a5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c9f58887d836400029a7c099bd7a4044/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sugar, We're Goin Down",
    artist: "Fall Out Boy",
    id: 917265,
    release_date: "2006-03-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-796fac63e4802af0348be47c39ca20f8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c9f58887d836400029a7c099bd7a4044/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It's Like That",
    artist: "Mariah Carey",
    id: 917359,
    release_date: "2005-03-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-879fc3c18694ccf92e9145a5321d8c34-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b26d1086b7e4af5eed8c8c1d0cc2eeb8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fields Of Gold",
    artist: "Sting",
    id: 922125,
    release_date: "1998-09-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27958a837e1c12a9a1e49c423292a7a5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shape Of My Heart",
    artist: "Sting",
    id: 922138,
    release_date: "1998-09-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27958a837e1c12a9a1e49c423292a7a5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "So Sick",
    artist: "Ne-Yo",
    id: 924800,
    release_date: "2007-07-03",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7b52ebb4549befa1f07097d97b9be897-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4d2b4d8c1b7575d92558f36aebe43449/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Hate That I Love You",
    artist: "Rihanna",
    id: 925111,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-41b1323dec7c57682d7ca4b30e2e8157-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Disturbia",
    artist: "Rihanna",
    id: 925118,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b6194aa3d97c9a111e358d56041ec44-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take A Bow",
    artist: "Rihanna",
    id: 925119,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9571d1a0be0db0b03e001cbdaffdc458-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Round Here",
    artist: "Counting Crows",
    id: 925651,
    release_date: "2007-09-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-36c2f8fce174302ab16abc5fe39971cf-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dd42edba056119169528c4e0a6a784c5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mr. Jones",
    artist: "Counting Crows",
    id: 925653,
    release_date: "2003-11-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-756f6266b9b4f2316e40b1aaf0dd33e9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dd42edba056119169528c4e0a6a784c5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Give It To Me",
    artist: "Timbaland",
    id: 925748,
    release_date: "2007-08-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/abeea246b841555a83b79064f6bcee29/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Patience",
    artist: "Take That",
    id: 929558,
    release_date: "2006-11-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2369381a6ca8064ebbc09da75cd806f3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/688dbebac904ce0f2c9eec2875978346/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take Your Mama",
    artist: "Scissor Sisters",
    id: 930051,
    release_date: "2004-05-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b79afdb6391230c1dd795f04e0a0da52-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/715911bf1f58a8a43e309c851655a6d2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Luck (feat. Lisa Kekaula)",
    artist: "Basement Jaxx",
    id: 935732,
    release_date: "2005-03-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a17c28cb5b79252e71f371df0e9ab867/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Only One I Know",
    artist: "The Charlatans",
    id: 938769,
    release_date: "1990-09-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b1491f96e4fc3802bd55f2a43eb4adf-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc68158202b483cef1ea55244d09d7c5/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Paper Planes",
    artist: "M.I.A.",
    id: 943376,
    release_date: "2007-08-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-05c656bea76864854d0986e0d8278782-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dfb8aa426b4d5e2f8c292a6e2df93d6/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "It Wasn't Me",
    artist: "Shaggy",
    id: 952870,
    release_date: "2000-08-08",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7d3e965867c2dbbcd64097681aed576f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eb3f1be6046ad03fe70abb09a2f289ec/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Thong Song",
    artist: "Sisqo",
    id: 952875,
    release_date: "2007-09-17",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2f53e38c39c89526683f57328988c0d5-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eb3f1be6046ad03fe70abb09a2f289ec/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Mr. Brightside",
    artist: "The Killers",
    id: 953097,
    release_date: "2015-02-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3485e45690a964a3e53644ee66d63f5a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecff532dd84c6538099b58baefccb8fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rollin' (Air Raid Vehicle)",
    artist: "Limp Bizkit",
    id: 953098,
    release_date: "2007-09-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7e5aeca72efad9b8fcc94ea9185fd7d-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecff532dd84c6538099b58baefccb8fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Kryptonite",
    artist: "3 Doors Down",
    id: 953104,
    release_date: "2007-09-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ca84ed8b7564603d4ba9add90770ecb-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecff532dd84c6538099b58baefccb8fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Bad Touch",
    artist: "Bloodhound Gang",
    id: 953105,
    release_date: "1999-09-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d75c544c4741a34f90dfa42b87554dba-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecff532dd84c6538099b58baefccb8fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Love Today",
    artist: "MIKA",
    id: 953605,
    release_date: "2007-02-27",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d805b3527773621230a15136cda199af-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d9a13bb07b812fa04f34258f7068c038/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Happy Ending",
    artist: "MIKA",
    id: 953611,
    release_date: "2007-02-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed78a95f4bbde8965ea8fe0be6347444-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d9a13bb07b812fa04f34258f7068c038/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Foundations",
    artist: "Kate Nash",
    id: 954806,
    release_date: "2007-09-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8ee12703b77abd8438262ca251cacb95-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/13eb5e5580a1a1f06d5aa41872ce7f52/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hit 'Em Up Style (Oops!)",
    artist: "Blu Cantrell",
    id: 958747,
    release_date: "2001-01-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbc95b9982bc56ada67e33eab29f51b1-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24c3043f8d72ea148c2ad1819df212ce/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Angel of Mine",
    artist: "Monica",
    id: 958976,
    release_date: "1998-07-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-033d0dbeb02defb3d8dc3b807ce64b9c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6024cb25122f304a2bec9969b00e2ecc/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Music (feat. Marvin Gaye)",
    artist: "Erick Sermon",
    id: 959253,
    release_date: "2001-08-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-624482466697ff67048ff14be27b5358-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/814396f98dd72c6aeaf6098125841574/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "If I Let You Go",
    artist: "Westlife",
    id: 959916,
    release_date: "1999-04-25",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0be55022a97b3cc41491e1e4c678648c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here's to the Night",
    artist: "Eve 6",
    id: 960166,
    release_date: "1999-11-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1f244df106b818ac7f5466cd745433d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53c389e343bce6c799d412e5580362af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flying Without Wings",
    artist: "Westlife",
    id: 960836,
    release_date: "1999-04-25",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0be55022a97b3cc41491e1e4c678648c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's Not Right But It's Okay",
    artist: "Whitney Houston",
    id: 962142,
    release_date: "1998-11-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1cadda88dbc2c0970f08720e5bf3d9cb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5116544ed84bc468877d02fcfe4bf8f6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here With Me",
    artist: "Dido",
    id: 962165,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cb00862e1cfaf6c3f909235a755c4fc-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eaae15d23993db3cd9c69ae113c4333/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'U Know What\'s Up (feat. Lisa "Left Eye" Lopes)',
    artist: "Donell Jones",
    id: 962216,
    release_date: "1999-10-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a9cdb42b28bac9d20aafdaa529b40e2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/959aab86461bbe0c92ee8ea5393bff4b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "My Love Is Your Love",
    artist: "Whitney Houston",
    id: 962260,
    release_date: "1998-11-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1ee5a3bd52a088e6dc6c1021126fd457-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5116544ed84bc468877d02fcfe4bf8f6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ms. Jackson",
    artist: "OutKast",
    id: 963042,
    release_date: "2000-10-31",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-02974f7bff502cd2f6661589cf71739a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/646d6414a24faaccf67c1d7e01f7d095/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Genie in a Bottle",
    artist: "Christina Aguilera",
    id: 964342,
    release_date: "1999-07-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4cebd81feca591df27e705f23c80f985/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Own Worst Enemy",
    artist: "Lit",
    id: 967040,
    release_date: "1999-02-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-39eea26dd33da4bc5c40786939290b62-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a91f52c785f596ecc7f73e086dd8afbe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer Girls",
    artist: "LFO",
    id: 969161,
    release_date: "1999-08-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1442b44648d3b2c6ae352bb12a23c30e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24f96efcfedd8331e359c7730b26400c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "U Remind Me",
    artist: "Usher",
    id: 969347,
    release_date: "2001-08-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3658cc6c8445c7510eda8ef231dc3c9-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1243a7f89bec5d034b82fec489f7221/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Beep",
    artist: "The Pussycat Dolls",
    id: 978913,
    release_date: "2005-08-25",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-543e29c2305640c06dbd54080541331e-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02822548b58fa72419b3044871d08a92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sexy Love",
    artist: "Ne-Yo",
    id: 979203,
    release_date: "2007-04-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/38536292d737ed7e5ca7f76b80b0137b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Touch My Body",
    artist: "Mariah Carey",
    id: 979860,
    release_date: "2008-04-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b28e03e27f980d14e03ded5c5fb1c9cf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What You Waiting For?",
    artist: "Gwen Stefani",
    id: 983448,
    release_date: "2004-11-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1d920c257be04bdc4abd0b3b4b93b90b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0dbe967d19dcf74cf7e2ad7e5c350bc4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rich Girl",
    artist: "Gwen Stefani",
    id: 983449,
    release_date: "2004-11-22",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-747ea1edc62fe01d220e017292804861-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0dbe967d19dcf74cf7e2ad7e5c350bc4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cool",
    artist: "Gwen Stefani",
    id: 983455,
    release_date: "2004-11-22",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-011e8a102fe6c647ab40b1d21481e5f1-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0dbe967d19dcf74cf7e2ad7e5c350bc4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waiting On the World to Change",
    artist: "John Mayer",
    id: 988558,
    release_date: "2008-05-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/939d0cda3950cfa85554937b4da75188/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Love",
    artist: "Westlife",
    id: 993615,
    release_date: "2000-11-06",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc34dbf4472a140993f06b0cbc06ab57-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/031a6c4a0067a19d68a215387f1fe2e2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let's Dance",
    artist: "Five",
    id: 994520,
    release_date: "2001-01-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fbf7665487d2c67c1a9620ad5dd7929-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22f3192b17972a268f8cbe46fa6bb105/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Survivor",
    artist: "Destiny's Child",
    id: 1008337,
    release_date: "1982-03-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f19c2672e5ba688113571ac13361e865-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86f72dd2051d0a225f5799451d20fc5e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dancing in the Moonlight",
    artist: "Toploader",
    id: 1011028,
    release_date: "2000-12-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-75976df5397b849563879b832453b6d9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/26e81c50320c98da5ae6f346ff5a092e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Anything",
    artist: "3T",
    id: 1012851,
    release_date: "1995-10-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9986697887257cb5ddef20435ae986b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/df847b35407475fc497d3046eae11186/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Killing Me Softly With His Song",
    artist: "Fugees",
    id: 1024973,
    release_date: "2010-02-10",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04a5526b7b94c6e2d617ade762ddf5dc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Sad Love Song",
    artist: "Toni Braxton",
    id: 1043825,
    release_date: "1993-07-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9c21fdae79c1679ac17c0962d47a7b2d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b906011f5618872a8cfca1695fdd1059/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Diggin' On You",
    artist: "TLC",
    id: 1043882,
    release_date: "1994-11-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1ec9b1ed90233f546025c7439f9bd44a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3134fd3fba883b4e6c8df8dc1487dbef/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Breathe Again",
    artist: "Toni Braxton",
    id: 1047761,
    release_date: "1993-07-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99e513c9fc23317ab413e2cff2158fd3-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b906011f5618872a8cfca1695fdd1059/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Said I Loved You...But I Lied",
    artist: "Michael Bolton",
    id: 1054944,
    release_date: "1993-11-15",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3c8a5cc24dd1599b085c83dc15044d7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f38b48f4124e9b349d512e9d03ec64b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Vision of Love",
    artist: "Mariah Carey",
    id: 1055713,
    release_date: "1990-06-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9690f993822034f613c10ce3a746053e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6842e0e44e02fae9cc5bfa666ffe435/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Someday",
    artist: "Mariah Carey",
    id: 1056637,
    release_date: "1990-06-12",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-22483b9a75df3341fb04aa236ef4df5f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6842e0e44e02fae9cc5bfa666ffe435/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "How Can We Be Lovers",
    artist: "Michael Bolton",
    id: 1056774,
    release_date: "1989-06-27",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db643b49baec4273e055d575b71a011f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e241034e68be88ff024bd050df76f043/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Step by Step",
    artist: "New Kids On The Block",
    id: 1065849,
    release_date: "1990-06-04",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72128b6a621b369c7d4f01a93054aef1-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5dfb57052ac6302651554dae116230e5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tonight",
    artist: "New Kids On The Block",
    id: 1065851,
    release_date: "1990-06-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d984853f2f1f1fd34c20c6aa9e3f024-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5dfb57052ac6302651554dae116230e5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Promise Me",
    artist: "Beverley Craven",
    id: 1073621,
    release_date: "1990-07-09",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-464c26457a0b54125aec40725107e3d6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a3c59a06e27516153f63ad76f9eb0a9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bubbly",
    artist: "Colbie Caillat",
    id: 1087017,
    release_date: "2007-09-17",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-61ada02b1559a07bc876837a1f9936c1-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/363668345f2ad1bd0d72bd53ff339277/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Drop It Like It's Hot",
    artist: "Snoop Dogg",
    id: 1098069,
    release_date: "2004-11-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d6b2f5daaa104448b5c219732a66673/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Tried",
    artist: "Bone Thugs-N-Harmony",
    id: 1099490,
    release_date: "2007-05-08",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c99d6abf66e6c2477b0e02cad6d91359-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e31edd175fbf3de647d5bcf6b6a7cc3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "My Band",
    artist: "D12",
    id: 1102401,
    release_date: "2004-04-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-df0170dbdff5b3a4522c4ac6d83dd24a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bdc8d2198ca13f1acc6a44bdaa16a31f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Pieces Of Me",
    artist: "Ashlee Simpson",
    id: 1102486,
    release_date: "2004-07-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-179d891d7aff6ac4c7a6ab5b10b9e4d7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19dc49068be0ea8527824943e0f3b102/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Only U (No Intro)",
    artist: "Ashanti",
    id: 1102518,
    release_date: "2004-12-03",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71c13155fe181323bb71d0ea54e8333f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f8d7a15ab143414d10f6369b5ec5936/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Because Of You",
    artist: "Ne-Yo",
    id: 1102966,
    release_date: "2007-05-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74c791668132fcf4241884c7c08aa1b2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a43959834621942aa43a1aded17d3fbe/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Blaze Of Glory (From 'Young Guns II' Soundtrack)",
    artist: "Jon Bon Jovi",
    id: 1104054,
    release_date: "1990-09-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f43220454b8fd60f9eb62dae751d7d3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f4a2d8eb0ed56a5c8512cbbde378cdf9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Just My Imagination",
    artist: "The Cranberries",
    id: 1104510,
    release_date: "2002-06-13",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e5b12be914dd2a3a01fea0ac4e1791b8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e12974f5b2c6e5e9fb1e8c32463e62de/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Through The Wire",
    artist: "Kanye West",
    id: 1104683,
    release_date: "2004-02-10",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d079eec6f8995ef64ace8d5fd39dd6e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stan",
    artist: "Eminem",
    id: 1109730,
    release_date: "2000-12-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbbd5055e0b4b64397e939f75ca0662b-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Shake That",
    artist: "Eminem",
    id: 1109733,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f08b2561c8d6d7c0739ac3d83e6903b0-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Like Toy Soldiers",
    artist: "Eminem",
    id: 1109736,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95323b6fa73fee831fef54ede332e4ab-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Real Slim Shady",
    artist: "Eminem",
    id: 1109737,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d28ee67c24d60e740866c7709d772f55-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Mockingbird",
    artist: "Eminem",
    id: 1109739,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-655dfb802c35579d26a32136e3d0e7b3-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "When I'm Gone",
    artist: "Eminem",
    id: 1109744,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8aaafada99fb0a10485ea594f664d917-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "When You Were Young",
    artist: "The Killers",
    id: 1109953,
    release_date: "2006-10-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ca6ba727fefad4f03d2ab55ed6fc471c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/daaf443d20af3ec952c2504a02032e49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rule The World",
    artist: "Take That",
    id: 1112125,
    release_date: "2007-10-15",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a104d9e85c1a28068de57aa2fc6f47f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4f967bef41ae37144b38d5a54ee79be8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wanna Get To Know You",
    artist: "G-Unit",
    id: 1121645,
    release_date: "2014-12-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/efdd8f314f78b5dd7db4fbafdbdc8b94/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "America",
    artist: "Razorlight",
    id: 1121936,
    release_date: "1976-11-20",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dd0e1a856c6cd5c0f72a192f1c184fa8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1fc2315d13dd6362ee6762936d1d5feb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Can't Tell Me Nothing",
    artist: "Kanye West",
    id: 1124863,
    release_date: "2007-06-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/96bc031a10b72be6beb897795169b738/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fat Lip",
    artist: "Sum 41",
    id: 1125079,
    release_date: "2001-08-09",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be56f0279ee32f97c341e8216eb67a9c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4980c272257aa0c38dc2d22972b72dfe/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Chelsea Dagger",
    artist: "The Fratellis",
    id: 1125334,
    release_date: "2007-10-02",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fa02d5f63ae06b10f0a5f811aacce64e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56a5af71d7e2c4fbab839135d09f041e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Until The End Of Time",
    artist: "2Pac",
    id: 1126556,
    release_date: "2001-04-19",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-403b3fb28da19ecb74d17d6df1b6fb86-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/992a1dbca08c51e41d1cffeb0b0ff6d7/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Purple Pills",
    artist: "D12",
    id: 1126602,
    release_date: "2001-06-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-73a846d897bc2e8630daa91c76f86b5e-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/df8e1ba1054c1eb75e3f22b6fe51291f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Locked Up",
    artist: "Akon",
    id: 1137869,
    release_date: "2007-06-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-40c47f185617f96219ba2bd9304bd4b6-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1500be1f54f01e99d52a5014860bf3b6/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Better Than Me",
    artist: "Hinder",
    id: 1138326,
    release_date: "2007-01-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbcf90261dddb7f0d4e983aef4cae101-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1af921cd540fb3523ab883472a3c2ba0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Lips Of An Angel",
    artist: "Hinder",
    id: 1138329,
    release_date: "2007-01-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f11e21f34b3c9fae8aab9d03d6d8cd0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1af921cd540fb3523ab883472a3c2ba0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Make Me Better",
    artist: "Fabolous",
    id: 1138690,
    release_date: "2007-06-12",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef0f9fa8203752883da48bddc7ebf2d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everytime We Touch (Remix)",
    artist: "Cascada",
    id: 1139054,
    release_date: "2007-01-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f1cf9edd40e52a426f85c912af34f76d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Case Of The Ex (Whatcha Gonna Do)",
    artist: "Mya",
    id: 1140336,
    release_date: "2001-05-28",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7c0992e71bc25551999c5b81f4e46f69-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5981b4586472571c8a5acbea587531a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get It On Tonite (Album Version)",
    artist: "Montell Jordan",
    id: 1141514,
    release_date: "2011-12-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0de552a0ec753426c5f3da00a6f9fd48-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91c638c497c3602dc0128dffa3fa6f12/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Grace Kelly",
    artist: "MIKA",
    id: 1142725,
    release_date: "2007-02-26",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91f5f0d415958e009641ed060fe09e64/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sit Down",
    artist: "James",
    id: 1142813,
    release_date: "2007-05-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1254de2a0840c0d7af706294ca9480bc-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/196adb20fa0b1535a177b1244fdd9f28/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take A Look Around",
    artist: "Limp Bizkit",
    id: 1143493,
    release_date: "2007-11-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8279e87f84934b7dba38be2fc9ef2470-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f17a6d764d4eea95dab085c917e1ce7f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Laid",
    artist: "James",
    id: 1150506,
    release_date: "2009-01-30",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-082fe5fe6560a0278ad88c2707229185-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/489b166c2de5db7223037ec3d6e0882f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Insensitive",
    artist: "Jann Arden",
    id: 1153062,
    release_date: "1995-02-28",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7da3bfc0252f4aca06ae5e287078b14a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Push The Button",
    artist: "Sugababes",
    id: 1159190,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6d1e69b954094b358d191fa678701498-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c65ac45fff047f4827f6bca0ef00066d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Cha",
    artist: "The Pussycat Dolls",
    id: 1161005,
    release_date: "2005-10-13",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a0a90c95cf7c5c1065f3444b04e7361-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8d29ee7cf1683a32358f9bab783df15/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stickwitu",
    artist: "The Pussycat Dolls",
    id: 1161008,
    release_date: "2005-10-13",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5e736fb4101708dc1cf6110cad71c1a8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8d29ee7cf1683a32358f9bab783df15/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Buttons",
    artist: "The Pussycat Dolls",
    id: 1161009,
    release_date: "2005-10-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-97ee8821821f3685621b67089edf3f04-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8d29ee7cf1683a32358f9bab783df15/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Be Without You (Kendu Mix)",
    artist: "Mary J. Blige",
    id: 1161682,
    release_date: "2006-12-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-17adbf23555df328915e52fa5ec62e70-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3939a5b62df412e83d898dfd85035c9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "She Will Be Loved",
    artist: "Maroon 5",
    id: 1165303,
    release_date: "2007-06-21",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-efea4d487562ac1318bec7dcb7fc5316-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/39fe38574c7af3181d1e56ad7c03fce3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Put It On Me",
    artist: "Ja Rule",
    id: 1165992,
    release_date: "2001-04-03",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a668499d42e097e77b4cc2dbd2fb5df-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93cd81ab9e24303e07abbc2bc76a5191/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Sacrifice",
    artist: "Elton John",
    id: 1166025,
    release_date: "1989-10-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d7d4656f1144d5a768e6a134ed1a0d2-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1cb1ba8c9d2357c6cda590b48ac2d642/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Live And Let Die",
    artist: "Guns N' Roses",
    id: 1169676,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4ab4275114b5e24c876892ada9af1b9-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Cry (Original)",
    artist: "Guns N' Roses",
    id: 1169677,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d494c13c6da1a5f00ba3eb26106a19bf-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "This Ain't A Scene, It's An Arms Race",
    artist: "Fall Out Boy",
    id: 1169830,
    release_date: "2007-01-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf36522124e0f5cfc86cd5bc2b7f3959/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Belong Together",
    artist: "Mariah Carey",
    id: 1170586,
    release_date: "2007-09-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46c3bee176312eb96c4c53cf2cbc9914-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64512aad7ba4d5278b84c647b131124e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Too Little, Too Late (Album Version)",
    artist: "JoJo",
    id: 1170615,
    release_date: "2007-09-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-445138c5aebb19dd558eb26aedf5b7a2-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64512aad7ba4d5278b84c647b131124e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ridin'",
    artist: "Chamillionaire",
    id: 1172093,
    release_date: "2008-06-19",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7fc4ebf437cef42eefe4999075ff55b2-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61731168bac0366142b80b66a4e31d74/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "When You're Gone",
    artist: "Bryan Adams",
    id: 1172332,
    release_date: "1999-11-10",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0b9ed40e62936f3ab8ea80122115730/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Reason",
    artist: "Hoobastank",
    id: 1172920,
    release_date: "2003-12-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8be54e2c3140b823274a7e99652d89b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lost Without U",
    artist: "Robin Thicke",
    id: 1173720,
    release_date: "2006-10-03",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a89db39bc758dbb28c903a5f142748d1-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/79f3c86a009fdeb34ef9053a4cead92d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get It Shawty",
    artist: "Lloyd",
    id: 1176072,
    release_date: "2007-03-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a515e39e2241f37aa30e748d5a1678db-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04e14d3286307ce645d0ded5d68d02f9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Just A Girl",
    artist: "No Doubt",
    id: 1176775,
    release_date: "2003-11-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b30056da894f7f8acb84899210f38089/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tease Me",
    artist: "Chaka Demus & Pliers",
    id: 1178331,
    release_date: "1994-02-07",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6e11edf46c1a543389573e65e278a00-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a9cfcaaaa113ee5d5a39bf138ef721da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Don't Let Nobody",
    artist: "Chaka Demus & Pliers",
    id: 1178332,
    release_date: "1994-02-07",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9bd969d32d6d084b7b69608553bb58b9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a9cfcaaaa113ee5d5a39bf138ef721da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Twist And Shout",
    artist: "Chaka Demus & Pliers",
    id: 1178343,
    release_date: "1994-02-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13ff857bc986a9e6552e5fc2765677d1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a9cfcaaaa113ee5d5a39bf138ef721da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm The Only One",
    artist: "Melissa Etheridge",
    id: 1181048,
    release_date: "1993-11-17",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e48af44aec0a30728abc4f0e64adb6a4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b73e0d4e32b14a307e53ee6c3aae45dd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come To My Window",
    artist: "Melissa Etheridge",
    id: 1181050,
    release_date: "1993-11-17",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b58cbdf37fc3a54edc3e05885c394de7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b73e0d4e32b14a307e53ee6c3aae45dd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(Everything I Do) I Do It For You",
    artist: "Bryan Adams",
    id: 1182159,
    release_date: "1996-03-15",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4647e4ca8bb0ff10a9a29ac32766aa5c-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d7d28861a72ecb30a7676ec38db05907/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beverly Hills",
    artist: "Weezer",
    id: 1183538,
    release_date: "2005-05-02",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-098b19c73290971f444bd0e34c860497-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/239515c0d77e9fc21c2c37b28a1fcac7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Gold Digger",
    artist: "Kanye West",
    id: 1184309,
    release_date: "2005-08-12",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5de817ccdf15a4a2d912ee7595c3f94a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cbfc94084895e59b5a313a98ab1bd9a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Diamonds From Sierra Leone (Remix)",
    artist: "Kanye West",
    id: 1184318,
    release_date: "2005-08-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d72498f04d4ed61879a9205416719ac3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cbfc94084895e59b5a313a98ab1bd9a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Chasing Cars",
    artist: "Snow Patrol",
    id: 1184425,
    release_date: "2006-08-04",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-abd45759f4e986b4724fa1d804aa752f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fce2bbf1625b343693fd80627c4cfbb4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Shake It",
    artist: "Metro Station",
    id: 1277448,
    release_date: "2007-09-18",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-502d2013e11163ecd4f93d5291b99b39-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8da54959f5118fcd83541790b9a2368a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It Never Rains (In Southern California)",
    artist: "Tony! Toni! Toné!",
    id: 1561286,
    release_date: "2001-08-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5f8f9d5b64ca0656dba28f8d18a5342-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2a9db8bff21181276833b06b7fa8041/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Be With You",
    artist: "Enrique Iglesias",
    id: 1567451,
    release_date: "2003-11-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2ca67be29390d2471c0be0b0dd1ca30-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f7545f0e94660f0a9dd9d4b91731d34/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Life Is A Rollercoaster",
    artist: "Ronan Keating",
    id: 1570220,
    release_date: "2004-10-11",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eeab5a410906d11480c6c16b64c98917-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f708bbfeffebecc35b92c8913d08a92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lovin' Each Day",
    artist: "Ronan Keating",
    id: 1570226,
    release_date: "2004-10-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fb614f73259bb0aa337195a1008cf153-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f708bbfeffebecc35b92c8913d08a92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In The Morning",
    artist: "Razorlight",
    id: 1571865,
    release_date: "2006-10-02",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a52ded50461cc5ae8ac4505c31418f8-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf79510d0659a7afbc20d65c6772ad5e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Tomorrow",
    artist: "Orson",
    id: 1574192,
    release_date: "2006-05-29",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-64252685e36ac4b83cfe9e97d343a30b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/06527d8c384b94b7b5abada839770f6e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "SOS (Album Version)",
    artist: "Rihanna",
    id: 1574660,
    release_date: "2006-05-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c9d1cf15cdb62115087a6392fd6fee46-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb37c39961b77543b0bbc96770f8e444/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unfaithful (Album Version)",
    artist: "Rihanna",
    id: 1574666,
    release_date: "2006-06-29",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9042c73b05f53f67e7b7b2daeb0d72a4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb37c39961b77543b0bbc96770f8e444/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The First Cut Is The Deepest",
    artist: "Sheryl Crow",
    id: 1574833,
    release_date: "2003-10-05",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3114172fdccc1616c6104cd62dd35cc6-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0aba1ee8762f87f8aaa2e9942360a82/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shine",
    artist: "Take That",
    id: 1574969,
    release_date: "2006-11-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/377750fc0d5d8f1fd11a9fa64423cf13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Money Maker",
    artist: "Ludacris",
    id: 1575902,
    release_date: "2006-10-12",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b492238d4b3be57ed3acd7a0015789b2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ffc46591f4f8f3ca409cff3a374ba32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Runaway Love",
    artist: "Ludacris",
    id: 1575918,
    release_date: "2006-10-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98f40090101477fe34816b7e95e35ecf-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ffc46591f4f8f3ca409cff3a374ba32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Wonderful World",
    artist: "James Morrison",
    id: 1575967,
    release_date: "2006-09-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d7e155f383f4657a0accc4d72ccec056-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec24740ad8f628885b9cc712596bfd53/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "You Give Me Something",
    artist: "James Morrison",
    id: 1576692,
    release_date: "2006-09-04",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/746c02d4e65e13dcf5baa7e85fbec786/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Glamorous",
    artist: "Fergie",
    id: 1577408,
    release_date: "2006-09-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bb5bab853b68ef884f9777a5f1fdb9fd-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d37b7b7b9102e13fac5c0ecf0c72d557/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What's The Difference",
    artist: "Dr. Dre",
    id: 1579477,
    release_date: "1999-12-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d9a24d054cbc5ab11843beed9f1422b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Forgot About Dre",
    artist: "Dr. Dre",
    id: 1579480,
    release_date: "1999-12-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d9a24d054cbc5ab11843beed9f1422b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Next Episode",
    artist: "Dr. Dre",
    id: 1579481,
    release_date: "1999-12-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d9a24d054cbc5ab11843beed9f1422b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Only Thing That Looks Good On Me Is You",
    artist: "Bryan Adams",
    id: 1582142,
    release_date: "1996-05-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-73ec01beaa635fa2f37d88c18cf1fffc-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6863d958964589b41d07ff135bab7b41/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let's Make A Night To Remember",
    artist: "Bryan Adams",
    id: 1582144,
    release_date: "1996-05-07",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d4d3ff8a90f799de27c46b5e6ce4fc8f-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6863d958964589b41d07ff135bab7b41/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Where My Girls At",
    artist: "702",
    id: 1583246,
    release_date: "2008-05-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98024672361670ae872c6a0c3719b8c6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42a52b79682eaac37bef1192dc2f62cc/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "No More Drama",
    artist: "Mary J. Blige",
    id: 2112335,
    release_date: "2002-02-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-89534b53198b17a9eb4fb34c78b20d97-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5c9b71b326343fdb107ddbad05a5194/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ms. Fat Booty",
    artist: "Mos Def",
    id: 2112351,
    release_date: "2002-07-30",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8bbe6bd70313a9f82ab6543f55dd9c37-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/89e28a0a93eff9dc574476710b5c09e2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing Else Matters",
    artist: "Metallica",
    id: 2114406,
    release_date: "1991-10-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd4c3e7cd1a7ed330ffe5e212af5815e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f1c31620f0e108b707ce1a1af0954158/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boombastic",
    artist: "Shaggy",
    id: 2122526,
    release_date: "1995-06-05",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ea952116e588f067f0ebd0b73450a3f6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2db7115ada8037b73dfa057f3c045085/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Angel",
    artist: "Shaggy",
    id: 2122528,
    release_date: "2008-08-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6aa0f8e1058496d0386fef356dcd1dfa-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2db7115ada8037b73dfa057f3c045085/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bag Lady",
    artist: "Erykah Badu",
    id: 2122750,
    release_date: "2000-11-17",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6c9323fdb8d3f890ba43f525e85e41ab-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/362b626099fff89887b04c8ab35feba7/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lollipop",
    artist: "Lil Wayne",
    id: 2124764,
    release_date: "2008-08-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4ebf63bdbca6ed04e1a3c64af9757132-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58cbc0f25d672d458b671859d14be5f8/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Heard It All Before",
    artist: "Sunshine Anderson",
    id: 2129585,
    release_date: "2008-08-29",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-29e273f6637c3e588308ac327bc6ae8c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c64b910d7335efb6f8c6e932c9840354/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Missing (Todd Terry Remix)",
    artist: "Everything But The Girl",
    id: 2138669,
    release_date: "1994-06-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106ae38357f72d17430b7954ff2a3fab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Far Away",
    artist: "Nickelback",
    id: 2139735,
    release_date: "2008-09-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-90e1fd386df3968e41b4eef47f33333e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3b50e2c23bbffb1428aceee83f2effc/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Air Hostess",
    artist: "Busted",
    id: 2157931,
    release_date: "2004-06-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54f3e1470245d21658510406087de54b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/449c0810e5e9760f82f07a2a068b38d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When I Grow Up",
    artist: "The Pussycat Dolls",
    id: 2161358,
    release_date: "2008-09-22",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4bd82eb2311085d6a5378695ef0548b8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love",
    artist: "Musiq Soulchild",
    id: 2162815,
    release_date: "2008-04-28",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff2cd8cd67338c5a7cef0a273dac303c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8e6ea79e4554a3bffdd537401f3f1b6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Live Your Life (feat. Rihanna)",
    artist: "T.I.",
    id: 2164882,
    release_date: "2008-09-08",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/16f821683f216c4bf834c9348b9a8f62/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everytime",
    artist: "Britney Spears",
    id: 2168030,
    release_date: "2004-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a2df02edee7a53a641d92aff0eff2ad0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34265ac018fe0d973eac131fe04038a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here Without You",
    artist: "3 Doors Down",
    id: 2172900,
    release_date: "2002-11-13",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed8ebdc05d8787c83ade5d5c4bba22a7-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0fe967e7af33d23452daea54da22903b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Back At One",
    artist: "Brian McKnight",
    id: 2173612,
    release_date: "2000-02-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7e9f2e42b63f41d301f9c3a3f836b119-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c79a0a494e9bddb36ada87ef97e9d451/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Baby, I'm Back",
    artist: "Baby Bash",
    id: 2176492,
    release_date: "2005-03-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a26f741a4d121197fd5c5af3f4e49163-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2656e2f43589ca1a1b11cd6c1dd9a77c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Tears Dry On Their Own",
    artist: "Amy Winehouse",
    id: 2176858,
    release_date: "2006-10-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-515144f250d85a17b79ff1414fee6537-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ce985e6653c350c6e6fed077ab7d0d4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Southern Hospitality (Featuring Pharrell)",
    artist: "Ludacris",
    id: 2179333,
    release_date: "2008-06-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c82d7d22a4943a3f9a43b58b5622167d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/17f70741d309cdb239804bbbd25f6e54/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Spotlight",
    artist: "Jennifer Hudson",
    id: 2181154,
    release_date: "2008-09-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce404490831008fb78fd882f496929d9-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4377b5a4e248d579db47eab05956fd9e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Outta Control",
    artist: "50 Cent",
    id: 2183835,
    release_date: "2005-06-21",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-daf9a574c489937f0fbf0b938cc01592-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc8804f86ebc1496f28d51a38cf138ec/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Like The Way",
    artist: "Bodyrockers",
    id: 2184465,
    release_date: "2005-05-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f23f62c3de4906791aeff01c0f1db204-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5e0b056b5968d94647d5235850c9cd0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "God Gave Rock 'N' Roll To You II (Live/1992)",
    artist: "Kiss",
    id: 2184735,
    release_date: "1991-07-05",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-75c957c8964e407ab9efdd96232faf25-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a2a6ae101ad36ca524cef3a5f606a52f/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "You Needed Me",
    artist: "Boyzone",
    id: 2203336,
    release_date: "2008-10-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d29e172a755e8693da84f4f05543cf7-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b9108c553da504d4b2ff3fe1f82c892/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When The Going Gets Tough",
    artist: "Boyzone",
    id: 2203342,
    release_date: "2008-10-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-82465625256847e7b42554b61bb1efc7-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b9108c553da504d4b2ff3fe1f82c892/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dangerous",
    artist: "Kardinal Offishall",
    id: 2203354,
    release_date: "2008-10-13",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72b86b1fdb3485e9b11dd72f400456e7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c37e16d3e0ba7d8274ec6ad90ae642d4/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Wanna Know",
    artist: "Joe",
    id: 2221574,
    release_date: "2008-10-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6230e7480f89363c8943cf203c66c238-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d316a5dceea79d5e0c7475976661a8ae/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Stutter (feat. Mystikal) (Double Take Remix)",
    artist: "Joe",
    id: 2221577,
    release_date: "2008-10-13",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a7a6e07ed1865405b9df85c6839123f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d316a5dceea79d5e0c7475976661a8ae/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "A Little Time",
    artist: "The Beautiful South",
    id: 2230580,
    release_date: "1990-10-29",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-abee37cb5793ccf6f156c34739bfef71-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73a960872a2c457707d2ab6edbd8749b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "AM To PM",
    artist: "Christina Milian",
    id: 2232897,
    release_date: "2002-01-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8bec6dfb5198e3493453e953ac7920f4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e66e6d29a957371dd58a971892f3b7e1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm Of Life",
    artist: "Oleta Adams",
    id: 2233716,
    release_date: "1991-07-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-717e6ab044e794d34ce6272f4a734ac3-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/05704f56ff522fb4ffa81693078ef29e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get Here",
    artist: "Oleta Adams",
    id: 2233717,
    release_date: "1991-07-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7e9b1235f19b006476ccf7a5c1eb3cb3-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/05704f56ff522fb4ffa81693078ef29e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Would You Do?",
    artist: "City High",
    id: 2233967,
    release_date: "2001-10-23",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da11fc01e600142d331f239fa4642dde-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/95b0b861afc06a87e7d0c2f42ffb3f35/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Disco Inferno",
    artist: "50 Cent",
    id: 2256565,
    release_date: "2004-12-21",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fd5146134743ec2a302c27fa5ac49387-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/333b3620de536ae80acdf70331c9503b/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Don't Forget About Us",
    artist: "Mariah Carey",
    id: 2261617,
    release_date: "2006-02-06",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07694b7e50a6db82f6361962c55f0284-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eee47533e27fc9aed74f3212ebe5fb8f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'Slow Down (12" Version)',
    artist: "Bobby V.",
    id: 2261899,
    release_date: "2005-05-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96da0fed0feac47335280e3aad1fc6ad-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d3cb2ca458fc783c73768f8d0d5f9f2/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Dirty Little Secret",
    artist: "The All-American Rejects",
    id: 2263378,
    release_date: "2005-10-02",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01f706ab51fd10a153367064839e3dc5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hey Mama",
    artist: "The Black Eyed Peas",
    id: 2264729,
    release_date: "2004-01-20",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6efe94615f21a80c1ad670741e86e482/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Break It Down Again",
    artist: "Tears For Fears",
    id: 2266864,
    release_date: "1993-06-07",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-384094a0a779c4c5050ea9d9cc23b575-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/798541a8024eed902da5cea050ef94ed/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Contagious",
    artist: "The Isley Brothers",
    id: 2276508,
    release_date: "2001-08-14",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b14257458e68f1c2569bdd20bc994324-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6192e617fe3a6e8163cbb89dff0208c4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "You're All I Have",
    artist: "Snow Patrol",
    id: 2279320,
    release_date: "2006-10-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/83d4c843fc3e6deee6ad13790eb2e530/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "So What",
    artist: "P!nk",
    id: 2281133,
    release_date: "2008-10-27",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a81faa70dc57fc8b860aa8f0fa92f1da-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d89c0c403b9dd94ce1ceb021d13cf662/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everyday I Love You Less And Less",
    artist: "Kaiser Chiefs",
    id: 2281570,
    release_date: "2005-03-07",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bc37432f73270052bbd6b2d3e1110a8e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4e858cde7d2f5ed7381186addd816a19/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "I Predict A Riot",
    artist: "Kaiser Chiefs",
    id: 2281571,
    release_date: "2005-03-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fb4f5cd769dfc14746982f21c2e7d4c1-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4e858cde7d2f5ed7381186addd816a19/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Teardrops On My Guitar",
    artist: "Taylor Swift",
    id: 2282486,
    release_date: "2008-10-04",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1dd7a2b5a06b42b6c56abfabf6e3ed5-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c0e80bb3c16e1c987cf235929eb7757/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Our Song",
    artist: "Taylor Swift",
    id: 2282494,
    release_date: "2008-10-04",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7dc76314afe33aba6ed9d6a2b9b9399-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c0e80bb3c16e1c987cf235929eb7757/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Kool Thing",
    artist: "Sonic Youth",
    id: 2288587,
    release_date: "2007-06-21",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9cb58869d709dc48d06db9a306ee41a3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e9e4f3f059bab95e602b805cfcb43df/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Learning To Fly",
    artist: "Tom Petty And The Heartbreakers",
    id: 2293281,
    release_date: "2007-07-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3b6b3e02ce240ab3bbae4938b5c0351-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e60cb17ff666bd8a299fb71043c1cf2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Into The Great Wide Open",
    artist: "Tom Petty And The Heartbreakers",
    id: 2293285,
    release_date: "1991-07-02",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f4ecdeaf8465557497b0fa4b9dd57f0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e60cb17ff666bd8a299fb71043c1cf2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Gotta Get Thru This (D'N'D Radio Edit)",
    artist: "Daniel Bedingfield",
    id: 2298587,
    release_date: "2003-04-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aadc588d975c053c5fa58ce6fe68dd0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/10e111eb47dd8b80c8b2166fa4ac0872/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gotta Tell You",
    artist: "Samantha Mumba",
    id: 2303104,
    release_date: "2001-09-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6fcc48e3991d6ee33cd4e6257621527e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f20fcec7a01e5f3e13f200d6b19cffa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Vertigo",
    artist: "U2",
    id: 2315240,
    release_date: "2004-11-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-809945f47800af215cb6cb2573d02202-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/74985254012fb1794c487fe3f5aa3840/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Better Together",
    artist: "Jack Johnson",
    id: 2320086,
    release_date: "2005-03-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8279dc1a88b43db8969fc31f9f233774-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fc75cf6170ae0ed9c6a40810c67ba87/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Banana Pancakes",
    artist: "Jack Johnson",
    id: 2320088,
    release_date: "2005-03-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49b57b4e5c73b2ebaaa2c64c7d28b8a7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fc75cf6170ae0ed9c6a40810c67ba87/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Dizzy",
    artist: "Vic Reeves",
    id: 2320340,
    release_date: "1994-09-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1925fcf102457b80d9e0156f4a1bd18a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a9493dd55b9e6cce90cac8bb4388e2d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Just Lose It",
    artist: "Eminem",
    id: 2330805,
    release_date: "2004-11-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c96eee250b4e3240296e790db07ac72-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef99b64b2d0f806e667c132893274bc5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Salt Shaker",
    artist: "Ying Yang Twins",
    id: 2374799,
    release_date: "2003-09-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3909cbb4ace7bf11582691525ba8dd95-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a5ece56c54045323442ef553dee930f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Wait (The Whisper Song)",
    artist: "Ying Yang Twins",
    id: 2375108,
    release_date: "2005-06-28",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d9a7d2f497c0e60e9481f07a2d8a15e1-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e30fadde8272534627e2ee6b3e60f89/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Touch The Sky",
    artist: "Kanye West",
    id: 2423416,
    release_date: "2005-08-29",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8673b9ae49957e6969b06c99dfe5a681-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a7aaa21c3b7802745448c33c4affbd1/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Switch",
    artist: "Will Smith",
    id: 2426647,
    release_date: "2005-03-28",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ea0323e6a61c2bce1f9fea7e1dff6313-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c8079db8d19ac62becbdea6a19e913c2/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lonely",
    artist: "Akon",
    id: 2426732,
    release_date: "2005-04-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4617e767b76fa0d3326c56d9d7850d48-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0c3940d55267dd837cfae7d95e4b3b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Hollaback Girl",
    artist: "Gwen Stefani",
    id: 2426753,
    release_date: "2005-10-24",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-63623342a6013c9a90bde5ff31a04d68-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c133e6ca154037e1de95fb82475d206b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Generation",
    artist: "Bob Sinclar",
    id: 2430021,
    release_date: "2005-09-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a86fe3205a3c926491d202ac8a02bc6c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Promiscuous",
    artist: "Nelly Furtado",
    id: 2430338,
    release_date: "2006-06-12",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-275797f42234a7602c95706a1c5e4d53-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55a124e01e46d0aa494fc59afe53c639/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let's Get It Started (Spike Mix)",
    artist: "The Black Eyed Peas",
    id: 2431781,
    release_date: "2010-04-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8d7a615bf38ac9a97d55717bd0218bdc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Move Along",
    artist: "The All-American Rejects",
    id: 2438123,
    release_date: "2005-09-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a756121ac80b4f458a5bf05e72056693-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/378b6a65c7b2bd76d826aa2d2fc6e751/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It Ends Tonight",
    artist: "The All-American Rejects",
    id: 2438124,
    release_date: "2005-09-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83e5536e0f6d07ed2a449f50b464001c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/378b6a65c7b2bd76d826aa2d2fc6e751/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Lie",
    artist: "The Black Eyed Peas",
    id: 2440661,
    release_date: "2006-06-29",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49b53de25cc7e66bbeaecea48e804e4c-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35c6f881d5660898e5a3d135cf42e7af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Humps",
    artist: "The Black Eyed Peas",
    id: 2440662,
    release_date: "2006-06-29",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-12f8795cd43966d1cc1e788189c5ecbe-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35c6f881d5660898e5a3d135cf42e7af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Not In Love",
    artist: "Enrique Iglesias",
    id: 2447998,
    release_date: "2004-03-08",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa8db819d78059a1bad23258a7dd6e2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Found Out About You",
    artist: "Gin Blossoms",
    id: 2448350,
    release_date: "1992-08-31",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-846a502890cacede3c60ce58b8a5fff1-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/21cbfcda72e8202b0e9ddb0e5fed8c3a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sexbomb",
    artist: "Tom Jones",
    id: 2459527,
    release_date: "2003-02-09",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-456ed3b8a2a8a6c28b7ab6642ef3bdc4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be7a8eaf564cca20a4e76a72cb39aa74/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Of Us",
    artist: "Joan Osborne",
    id: 2463249,
    release_date: "1974-04-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65d3b50620a37aff30d09d33754d0580-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fcf1bc7b0971b8718c51a73b33ca5e1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tell Me",
    artist: "Groove Theory",
    id: 2464635,
    release_date: "1995-07-25",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3ddd576cc9339e5ff681f82975b0a6f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2dc25a4d8c44e724a5226c5e6e37bdd6/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Give Me One Reason",
    artist: "Tracy Chapman",
    id: 2477838,
    release_date: "1995-10-31",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79b1992a18d9f08d6c077e9693c27c26-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f34b4d8e8b5795adde5ce91e78a830e7/250x250-000000-80-0-0.jpg",
    genre: "Folk",
  },
  {
    title: "Bleeding Love",
    artist: "Leona Lewis",
    id: 2484995,
    release_date: "2008-11-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8df8f5da6c0006ad5a575126df2246de-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02d0ed56b2485b5f58fa63e6a3bbb73c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "A Moment Like This",
    artist: "Leona Lewis",
    id: 2485008,
    release_date: "2008-11-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7429a77d43fb522a7c6e142e5cfa4b1d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02d0ed56b2485b5f58fa63e6a3bbb73c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If I Had No Loot",
    artist: "Tony! Toni! Toné!",
    id: 2487402,
    release_date: "1993-06-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8db914c0baedbb23f8e2ab2fc731f6c6-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7091130dc59c2cd4fd1736115b54e21/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Southside",
    artist: "Lloyd",
    id: 2496270,
    release_date: "2007-12-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-095838122f8c8daf0252ce8fc3ed22f4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/11b7981cec8d0689d19888bf88813586/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Signs",
    artist: "Snoop Dogg",
    id: 2498752,
    release_date: "2005-04-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5a74874ef42a0382753cd3d4e400240-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67a836ad43bad1593baab1300e3f0cf4/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Suga Suga",
    artist: "Baby Bash",
    id: 2501296,
    release_date: "2004-03-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/15bb85fb5e546a2190dd41eb7eca3a00/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What's Love Got To Do With It",
    artist: "Warren G",
    id: 2509581,
    release_date: "1997-02-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f3af0f32a48fba1b3993c9d1c43e0b6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18d2887e3abf29f8d288efa4866a6259/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Be Like That",
    artist: "3 Doors Down",
    id: 2511228,
    release_date: "2000-05-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-21ff68e8b8f9dcbf6cfaec7839fb3e28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e075197e5985cd4269f829f1ea217cad/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One (Album Version)",
    artist: "Mary J. Blige",
    id: 2514842,
    release_date: "2006-10-31",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f4c0b1832e0bf35c3fee6c9b0bd01c4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/54f4de6cadc6e5687cdcfbeefa918a98/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Just Wanna Love U (Give It 2 Me)",
    artist: "JAY-Z",
    id: 2515569,
    release_date: "2000-10-30",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-735395cc9beb5006a40330dee602e7e4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4f410c346c29edb4e625a8a067fa6520/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: 'Touch Me (All Night Long) (7" Mix)',
    artist: "Cathy Dennis",
    id: 2520739,
    release_date: "2000-07-09",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed2de12a6ead7c34c5098dc85eba7ac3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f49a05e9c75f071e65a9704d8c077931/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thunder In My Heart Again",
    artist: "Meck",
    id: 2522622,
    release_date: "2006-02-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/98d83fc99ab4539ed2cc80934545387b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Control Myself",
    artist: "LL COOL J",
    id: 2527718,
    release_date: "2006-05-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7f64f493fe5b921f9d80b77cd12837f4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fill My Little World",
    artist: "The Feeling",
    id: 2530287,
    release_date: "2006-06-05",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ac5203eb8ee0befdc1da0ed61f81786/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lean Back",
    artist: "Terror Squad",
    id: 2530363,
    release_date: "2007-12-13",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c5da2711481d0c8b7d99137917709fa-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ecc5d16327a2d0ca13312e3d0da043d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Rock This Party (Everybody Dance Now)",
    artist: "Bob Sinclar",
    id: 2544065,
    release_date: "2006-05-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0604fced8451b2cbc155e30d84f69cce/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Island In The Sun",
    artist: "Weezer",
    id: 2544628,
    release_date: "2001-04-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bbb2dfeb928201b2e084c8c1796aa844-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a67a3dc5916907b9d5679c068d874451/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Love Machine",
    artist: "Girls Aloud",
    id: 2547682,
    release_date: "2004-11-29",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f51264c456946209bb7f209a80ffc77-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c0dc95d36b9064cb45007528f830596f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stay (Faraway, So Close!)",
    artist: "U2",
    id: 2547848,
    release_date: "1993-07-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6039d9288dc4b20ad989cd5ce9802652-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/69c4ded5f55e250d4ff22ca4e7d3ed2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Big Pimpin'",
    artist: "JAY-Z",
    id: 2548838,
    release_date: "2007-11-22",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7f64040bb7e51f6b00cc9d77e14d832e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a349e3a00eb843fa5bda7970bb40a61/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "1 Thing",
    artist: "Amerie",
    id: 2553109,
    release_date: "2008-11-10",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ebb165a2bf657a954321fafb86907380-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ce44c4450d9e2153706f0840d843e1c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Starlight",
    artist: "The Supermen Lovers",
    id: 2575685,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0048372d96d2453369729fb3d154a7a8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/924c87a478de374538b1b5b554b20949/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Snap Yo Fingers",
    artist: "Lil Jon",
    id: 2594366,
    release_date: "2006-05-02",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-09100564973501abd2d1cd1c765dda76-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4bac5fc5fa841c2bb014223f8f8be4b/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Move Ya Body",
    artist: "Nina Sky",
    id: 2598368,
    release_date: "2004-06-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e3a3580bdab4eea03051bf894b0e5a5-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ec0475e6606608914f924204859a942/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Missing You",
    artist: "Case",
    id: 2600215,
    release_date: "2008-05-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13c5eee15fc71162fa362139ab59cacb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb9b5428a36553e00a959e1c1a557b3a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "When You Tell Me That You Love Me",
    artist: "Diana Ross",
    id: 2602276,
    release_date: "2005-04-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-896ad7c4978b687e86afd0c0b1a83855-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7db5a08be2abcf4c5a5d34960dd18a72/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Shining Moment",
    artist: "Diana Ross",
    id: 2602283,
    release_date: "2005-04-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-463dc4945fa544c8c28cc1c0874b9ef8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7db5a08be2abcf4c5a5d34960dd18a72/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'Bailamos (From "Wild Wild West")',
    artist: "Enrique Iglesias",
    id: 2604907,
    release_date: "2008-11-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67850a0fb50ebea85df3f6b5ca9c9a6b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Do You Know? (The Ping Pong Song)",
    artist: "Enrique Iglesias",
    id: 2604913,
    release_date: "2008-11-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-288a38b7f90821ee28787a579f6a79d7-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hanging By A Moment",
    artist: "Lifehouse",
    id: 2628562,
    release_date: "2001-07-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3191cf4b352dde531a684c94c2bd9905-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c36d47d43d558336e176e4f3f0cdb2f2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let It Rock",
    artist: "Kevin Rudolf",
    id: 2630775,
    release_date: "2008-11-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1f4da20f1eee15467dcb8a63095ed2e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ceb2d932ee7f01e4d4cf7ce35889c09/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heartless (Album Version)",
    artist: "Kanye West",
    id: 2651093,
    release_date: "2008-11-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7db344db3c0d329b0967adb86b24662b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "White Flag",
    artist: "Dido",
    id: 2654326,
    release_date: "2008-12-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-760cc53023e0a1b133acecf6b4280036-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3ffee70a615564ac1238c493774bd9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Romeo",
    artist: "Basement Jaxx",
    id: 2661874,
    release_date: "2001-06-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-86a05b5cdd450d138a267e49dde3c4a7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5c187cbbd0265d34859f9116a4cc46d/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Drops of Jupiter",
    artist: "Train",
    id: 2672855,
    release_date: "2008-11-28",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0013a02fe68f5026f63ee16b8996ba6-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a2400f7114621869e993e8adbc24cbe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gives You Hell",
    artist: "The All-American Rejects",
    id: 2676238,
    release_date: "2008-11-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d952648bdde60f12eedbdbf8ad28a6d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Closer",
    artist: "Ne-Yo",
    id: 2718242,
    release_date: "2008-12-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f41914cb2aedb8c3c5c40966e8b44196/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Miss Independent",
    artist: "Ne-Yo",
    id: 2718246,
    release_date: "2008-12-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f41914cb2aedb8c3c5c40966e8b44196/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "JCB Song",
    artist: "Nizlopi",
    id: 2734152,
    release_date: "2006-05-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37716b88883e667aef51e8cd3edcaba4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Call On Me",
    artist: "Eric Prydz",
    id: 2739742,
    release_date: "2004-09-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d14d82d9402c738c88660f893ee22af9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Me & U",
    artist: "Cassie",
    id: 2793680,
    release_date: "2006-08-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-696f1222bd794515efa612cc3279b34b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/191d3e7ca739887e4befabde40cf10cd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Wish",
    artist: "Carl Thomas",
    id: 2794602,
    release_date: "2004-03-09",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-39d56992a17ee75b34a042cf85c6d04e-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/755dc7da07ba60a8c879963858150f84/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Hot Boyz",
    artist: "Missy Elliott",
    id: 2803679,
    release_date: "1998-03-31",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbf7a3155b7b53caaa0122978d6194e7-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0624cda602ebdb6f705b4364014ee223/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "You Found Me",
    artist: "The Fray",
    id: 2889763,
    release_date: "2009-02-02",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4257d070cfa3f72103f6e94007ccfd1b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/357c6766a490652760028cd66c53442b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Takin' Back My Love",
    artist: "Enrique Iglesias",
    id: 2917317,
    release_date: "2009-03-09",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b405914ad08167ae263182e867edf72-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/802864e885dbac600025b87e23398c83/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Sex Magic (feat. Justin Timberlake)",
    artist: "Ciara",
    id: 2924891,
    release_date: "2009-03-12",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b2eb2e3f8122ef7ae037d8a1f184512-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14920dc26ced9a181a51b05b7ab26958/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Faded Pictures",
    artist: "Case",
    id: 2925070,
    release_date: "2009-03-10",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-69c2c02ad65856270659fa08fa88b813-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a4d90c6fb4cee22357ea9a4cb5939f2/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Sugar (feat. Wynter)",
    artist: "Flo Rida",
    id: 2943201,
    release_date: "2009-03-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d3e3ac6a0355336720bcd3abf97fa936-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/71ba8675d023e7f3d1c2d042c81ec133/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Kiss Me Thru The Phone",
    artist: "Soulja Boy Tell'em",
    id: 2949679,
    release_date: "2009-03-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ad0ff036688602bd4d32e5a798139ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pon de Replay",
    artist: "Rihanna",
    id: 2953166,
    release_date: "2005-08-12",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5680a73944eeb51b3186858eac1adc57-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7324007a556009d008075eeb32157c16/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Party Up",
    artist: "DMX",
    id: 2954802,
    release_date: "2009-03-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-733f02f797ff79ef977cc5e48a2f17a9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fc26eac306c4f5d499c33a28a4bbbae/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Get Up (Before The Night Is Over)",
    artist: "Technotronic",
    id: 2961499,
    release_date: "1989-11-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4338834e678710db68bee3e13bc73247-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ddb94a0ba17254188a54d18b6173f7f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Livin' It Up",
    artist: "Ja Rule",
    id: 2962733,
    release_date: "2001-10-03",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08632233f091b05ceee082de010f4167-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf24cf88a9e67e7e48b9da49965a5134/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "21 Guns",
    artist: "Green Day",
    id: 3087241,
    release_date: "2009-05-15",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-617fb64711638bac744e5a86e12850ae-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ebd6fc49c3c87cbd5441424f151c04f2/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Keep Coming Back",
    artist: "Richard Marx",
    id: 3087496,
    release_date: "1997-11-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d898d8c7134804b8fec1c94017b90562-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b2f1669d644dcab1cf79800aaea72c65/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Took You So Long?",
    artist: "Emma Bunton",
    id: 3088626,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8568d7f61b4f1fba3bfe9d04c11c8b17-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e441a14683530ea4af81339d9062edb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "She Wants To Move",
    artist: "N.E.R.D.",
    id: 3090719,
    release_date: "2004-03-23",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f971cbcc7e573bf54e0480b2529479ae-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c759f1f1a6aa8dbccdcb7ce05bb7d1a7/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Have You Seen Her",
    artist: "MC Hammer",
    id: 3090794,
    release_date: "1990-02-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ca3fe4939635fb5198561d4715839cc1-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3558d89040fc95f8978c67b09aa2d16b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hold On",
    artist: "Wilson Phillips",
    id: 3090950,
    release_date: "1992-03-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-82a002095d3baa91cfc5f729a4038335-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ec253e4915193b4b11185cea4afd4ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Release Me",
    artist: "Wilson Phillips",
    id: 3090951,
    release_date: "1990-03-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d142b9c2c6f1fe08e8cf7cf3b9444c1-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ec253e4915193b4b11185cea4afd4ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Impulsive",
    artist: "Wilson Phillips",
    id: 3090952,
    release_date: "1990-03-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d0728bc05570a762255cf9bb51f9a7c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ec253e4915193b4b11185cea4afd4ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're In Love",
    artist: "Wilson Phillips",
    id: 3090954,
    release_date: "1990-03-24",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bae9eb0107a64c04172fc210d40b8b0-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ec253e4915193b4b11185cea4afd4ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Can't Get You out of My Head",
    artist: "Kylie Minogue",
    id: 3098100,
    release_date: "2001-10-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a085a375a7bbd76f95758339dc3210cb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/834a7fd1964efa083b6e5bf82b49f406/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "In Your Eyes",
    artist: "Kylie Minogue",
    id: 3098105,
    release_date: "2001-02-19",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-34c03270df53eed7e8130b3015218328-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/834a7fd1964efa083b6e5bf82b49f406/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "She's The One",
    artist: "Robbie Williams",
    id: 3099009,
    release_date: "2004-04-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fd0826766ad278e89f65d976e3b08457-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d97332e17a89414a877cce274d86d95/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Clint Eastwood",
    artist: "Gorillaz",
    id: 3100444,
    release_date: "2001-03-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-27e3878b664a5aa43c95553cec73821e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f4d581f4b86c869547704d7db9aa2c43/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Rock DJ",
    artist: "Robbie Williams",
    id: 3102419,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-825d8ff4d84c6a5fbaee36f68a9e5f74-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d260fa442f925e1eb1c95bb29941f636/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fix You",
    artist: "Coldplay",
    id: 3106505,
    release_date: "2005-06-07",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2866fc806038b68237a4e404a0742a1e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a1a3e7c5e46b5f763328d95431ac19a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Speed of Sound",
    artist: "Coldplay",
    id: 3106508,
    release_date: "2005-06-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-81c30379d796c8b77c28fbd055cd5e64-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a1a3e7c5e46b5f763328d95431ac19a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Are You Gonna Go My Way",
    artist: "Lenny Kravitz",
    id: 3107395,
    release_date: "1993-03-09",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf6210ca916e4c0568d5f64fda927bfa-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92fc3b56541e6af90105452f8e3cf510/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Right Here Right Now",
    artist: "Jesus Jones",
    id: 3109370,
    release_date: "1991-01-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf40b9adf7d6b3f2188ab8baa4f79f07-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e14053e309d4fae675df0daca915998/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "One Call Away",
    artist: "Chingy",
    id: 3112419,
    release_date: "2006-09-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b8d3d2fdcf1aea95531c64dfe3d8c1e0-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/535071fc15641e578a69dce6fc3d1670/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Bossy",
    artist: "Kelis",
    id: 3115987,
    release_date: "2006-09-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5eda7ab019e89e8851d9afc9365de5b4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d2b2b606cace23ec0aef9c66a8bcc2c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "She Moves In Her Own Way",
    artist: "The Kooks",
    id: 3119029,
    release_date: "2006-01-23",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7e7212c053320c0e6981ef4fdf7783bb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff7f8634c319827bd8350f6d4305a80d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Face Down",
    artist: "The Red Jumpsuit Apparatus",
    id: 3119156,
    release_date: "2006-07-18",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b564e8cf8a060bd192906fe5bb9d311-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d468592f2a5f4f920c337fc41bf1b04e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Beautiful Soul",
    artist: "Jesse McCartney",
    id: 3119252,
    release_date: "2006-02-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0b91e17deb08c4f54098ec8f791b98c8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Put Your Records On",
    artist: "Corinne Bailey Rae",
    id: 3119484,
    release_date: "2006-02-27",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e7e1e102580a395619de31bcdaf27dc-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/395904b0070e13c1edd27a4bd45b7f4f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tripping",
    artist: "Robbie Williams",
    id: 3119900,
    release_date: "2002-01-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1786a7d289663c8e0ca3adfb30a7b41a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6849e357cb889d6ce800040570ebafc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Black Horse And The Cherry Tree (Radio Version)",
    artist: "KT Tunstall",
    id: 3120612,
    release_date: "2006-02-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff75b07ee4ed87439d3f65af0901ca46-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b00b9ee9d0543257b70abf6b28aee606/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Suddenly I See",
    artist: "KT Tunstall",
    id: 3120617,
    release_date: "2006-02-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-411d2449e78543ad3f37ed0d76268dd5-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b00b9ee9d0543257b70abf6b28aee606/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fall At Your Feet",
    artist: "Crowded House",
    id: 3123900,
    release_date: "1991-06-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a250c367ed8a01b879e28215b42f8d5d-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02ff8eb7243d0f51dc087135012bdb02/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Close To You",
    artist: "Maxi Priest",
    id: 3125893,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c58c0f6433b7ae8c4c490eae8018bfb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7416486e591fa1c95a7f70780b8abbc5/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Candy",
    artist: "Iggy Pop",
    id: 3125907,
    release_date: "1992-06-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-37e34140993f3c0bf6d753341cbeacf7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c6466020ab675f2a36586a20b500f0b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Still Got The Blues",
    artist: "Gary Moore",
    id: 3125932,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-76f17d12d8017df7f052c7bc5424bd46-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5ca9ba4e56c7e8a69791f6b194399d3/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Galvanize",
    artist: "The Chemical Brothers",
    id: 3130293,
    release_date: "2005-01-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ee2f0a1fe6f3f2d512b7d0667f00c9f5-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51d7e6bb289a89b531aaa7d047baa6ea/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Santa Monica",
    artist: "Everclear",
    id: 3131304,
    release_date: "1995-05-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-35ef1f2a7ae3e3a71ceff78ff6a0025a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/527fbe3057927e6ce78e288b6f593124/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Naked and Sacred",
    artist: "Chynna Phillips",
    id: 3132131,
    release_date: "1995-10-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c79fad74643aa74dda3c0c95c9ee62cc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/589402eca75e424501f9c5b0e54fc0b8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Live For You",
    artist: "Chynna Phillips",
    id: 3132134,
    release_date: "1995-10-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28cca77676277876008f55e3c3248a7b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/589402eca75e424501f9c5b0e54fc0b8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hey Boy Hey Girl",
    artist: "The Chemical Brothers",
    id: 3135034,
    release_date: "1999-06-22",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9df76369c7deb78928b552814cf87199-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/269d141e05d38d3bad414f16b8f047fe/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Goodbye",
    artist: "Spice Girls",
    id: 3135706,
    release_date: "2000-11-06",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98d8978464feee30e728e66f42c9ac11-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b0cbf02d23485527c29fc1039e4da138/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "In Too Deep",
    artist: "Belinda Carlisle",
    id: 3136272,
    release_date: "1996-08-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-75d30df4366553a6ce9914054113ed1d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4ff616135daf7d10cfc4a587b99eff83/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Lola's Theme",
    artist: "The Shapeshifters",
    id: 3137332,
    release_date: "2004-07-12",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b81e29fe36e39771ab7a82d4736861e7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ac68184e5cbae22810453b615232867/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Let There Be Love",
    artist: "Simple Minds",
    id: 3139497,
    release_date: "1991-03-03",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-733d63c233a3d8557a84ffb370a47416-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/30093e3d2496f605a0dca32e80070f81/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bed",
    artist: "J Holiday",
    id: 3146991,
    release_date: "2008-02-05",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cabb4ba436065653eb2917d6cc28e6cb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3d13ff20642f1a7ea592beab94e546e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Always On The Run",
    artist: "Lenny Kravitz",
    id: 3151230,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dfcb99f380261f8620df89d3917d7727-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a40fdebd05516980c02b6980ef12c2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Stand By My Woman",
    artist: "Lenny Kravitz",
    id: 3151231,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fcaf96f241b81d1640f1e3d8fb9fb040-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a40fdebd05516980c02b6980ef12c2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It Ain't Over 'Til It's Over",
    artist: "Lenny Kravitz",
    id: 3151232,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac3e153d5681c87bbefcae6f2297acde-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a40fdebd05516980c02b6980ef12c2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Spinning Around",
    artist: "Kylie Minogue",
    id: 3151534,
    release_date: "2000-07-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-81e73a4d783a5f4d0262d09e6e9efad2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9739769057203c37f03d6fc5a92a9909/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Power of a Woman",
    artist: "Eternal",
    id: 3151572,
    release_date: "2002-12-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c81cd9d0ad1928ced6822314873f9822-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4705992d9f297417ee6ce7ae156b9769/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All Rise",
    artist: "Blue",
    id: 3152219,
    release_date: "2004-11-15",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb48ce5fdec8ead28c5bf10efd749f07-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2763fc2300ebde89852a09b880a4d61b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Touch Myself",
    artist: "Divinyls",
    id: 3152715,
    release_date: "1992-06-29",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc5b03cd11ba83b77ea97eb4bf443ef8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87b4cb604b0ce721b6633e5bac4d0ed2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Here I Am / Small Axe (Come And Take Me)",
    artist: "UB40",
    id: 3152725,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94caf1671bdeed99a88b97a840f52b97-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/899f9753e753e81a1feac26909080e8f/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Higher Ground",
    artist: "UB40",
    id: 3152880,
    release_date: "1993-07-16",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-afbfdaf8085050fa16b49d93fe0f5515-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4174ce37d7906bb85bdcc71bcc675ff7/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "(I Can't Help) Falling In Love With You",
    artist: "UB40",
    id: 3152882,
    release_date: "1993-05-10",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be93ad8a893e78807e369247702df926-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4174ce37d7906bb85bdcc71bcc675ff7/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "This Is Why I'm Hot",
    artist: "Mims",
    id: 3154364,
    release_date: "2007-03-27",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbf6a4840cd7b0aa878a2b207460b199-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e85648856217de74b25119b08c028f6d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Trick Me",
    artist: "Kelis",
    id: 3155815,
    release_date: "2004-05-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a86e259892b91bfb881cc3f5fd7d9996-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/140a7ec5e359c594e9d1205eead306b6/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Milkshake",
    artist: "Kelis",
    id: 3156448,
    release_date: "2003-12-08",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d69576f4d1923252b6632d430a291fcb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4bc1ea4ab4967fc2a5facaf9a986c545/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Millionaire",
    artist: "Kelis",
    id: 3156453,
    release_date: "2003-12-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9edcd83806cac8303c2f303c23d92c4f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4bc1ea4ab4967fc2a5facaf9a986c545/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Fly Away",
    artist: "Lenny Kravitz",
    id: 3156968,
    release_date: "1998-12-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-86e5377b1d8c5bbb408603f13dbd6b22-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fed66f13aac9058bffaf25e677eb858d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Naive",
    artist: "The Kooks",
    id: 3158004,
    release_date: "2008-03-31",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65c42ec2404a8543f3813aba37e8dc96-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc60dc84f76f498600c0c871eb9a0abf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Worry",
    artist: "Kim Appleby",
    id: 3163934,
    release_date: "1986-09-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42d8cf5a5572215d16ca0e8bcd7b3a6f-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8faa7517aabe346b7a749772c6918834/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come Undone",
    artist: "Duran Duran",
    id: 3166606,
    release_date: "1993-02-15",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c53a02cd0ce064c388bf51655479760b-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/26e42a1a8eee2426482b026471cbc317/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Don't Wanna Fight",
    artist: "Tina Turner",
    id: 3167895,
    release_date: "1993-06-15",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6bbdcab1b1210467b84bad72f0984f2-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9ae768c59326588a4f336646a734e0b1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All For You",
    artist: "Janet Jackson",
    id: 3168996,
    release_date: "2001-11-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08e37dd8f4c8649ffbde9b2f7946ad21-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff125d71cdbd7314f68e44d331356e09/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Doesn't Really Matter",
    artist: "Janet Jackson",
    id: 3169010,
    release_date: "2001-06-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cce2f230dd692e33891135c35bdcbb7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff125d71cdbd7314f68e44d331356e09/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Kissed A Girl",
    artist: "Katy Perry",
    id: 3169161,
    release_date: "2008-09-15",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-26d073cd8e482b92c18caa4918461de1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef17ef6218f9a5d42b2cbd5d4eeed25f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waking Up In Vegas",
    artist: "Katy Perry",
    id: 3169162,
    release_date: "2008-09-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d41aa6f749416109c5c1667ba02f94ec-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef17ef6218f9a5d42b2cbd5d4eeed25f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thinking Of You",
    artist: "Katy Perry",
    id: 3169163,
    release_date: "2008-09-15",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a6ad2cc372169cfd16b589ae3d2d371-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef17ef6218f9a5d42b2cbd5d4eeed25f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hot N Cold",
    artist: "Katy Perry",
    id: 3169166,
    release_date: "2008-09-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a932aa1d0bef2c10d8659a5461fbc0bf-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef17ef6218f9a5d42b2cbd5d4eeed25f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This House",
    artist: "Tracie Spencer",
    id: 3184294,
    release_date: "1999-01-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2e83dd98ac72994efd12a1ced8f4556-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1de254c52d1aaf0f6247e411c4b29ab6/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Whole Again",
    artist: "Atomic Kitten",
    id: 3233916,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8258fd536d607ade570fdb6231164339-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e8ffee7b0c04f9d02d7cf6223dff7b7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unskinny Bop",
    artist: "Poison",
    id: 3235346,
    release_date: "2006-03-07",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99cd3b98ca7ac1ef12ef828b387dad2a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48ebebdfce609b5330d2d046afe1b319/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Something To Believe In",
    artist: "Poison",
    id: 3235352,
    release_date: "1990-03-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-771747f64eb0c0f9b452db5659eaa85c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48ebebdfce609b5330d2d046afe1b319/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wonderful",
    artist: "Everclear",
    id: 3242060,
    release_date: "2000-06-29",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2119313fb6e6100220ed9dfc2e6869b5-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c0e3934f386d8a07cdff7d022d7f516/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I'll Be Your Baby Tonight (feat. UB40)",
    artist: "Robert Palmer",
    id: 3242351,
    release_date: "1990-11-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0573f2d3797ed6dfb41725614445f1ef-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/900069fd8ffdfdb17c0574ff9e6d1b1f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get A Life",
    artist: "Soul II Soul",
    id: 3265072,
    release_date: "1992-07-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f11b2f9d91ddc8dc083dfda6c9a39e68-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65e9013d6164f4b0dede4da41e7cb1ce/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Oh Carolina",
    artist: "Shaggy",
    id: 3278462,
    release_date: "1993-07-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2b817db1d27b538eeb5d1c83f5f41e9c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45ac8ab25c4b0d1c677c30077db65c80/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "I Like It",
    artist: "Sammie",
    id: 3283305,
    release_date: "2000-04-06",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a6b279b67947370d5353b7862a1f88fa-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d83e315dbdd7ae71b63bec9c245dbb8/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Opposites Attract",
    artist: "Paula Abdul",
    id: 3311524,
    release_date: "1988-06-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e1c4886091cc1ab75c3848423a4911f3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b4d71e1c818c86bda5edf4b820e9496/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rush Rush",
    artist: "Paula Abdul",
    id: 3311534,
    release_date: "1992-06-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cadf58fdb2deddf4a7e0a81e148e3c74-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d662dd4877f19f64c8b1fb5ff9bbd6da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sailing On The Seven Seas",
    artist: "Orchestral Manoeuvres In The Dark",
    id: 3319276,
    release_date: "1989-02-13",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-caf76c787675dbce44c1b12f4dcd69d3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ddb1589cf546195f1d5b72c685d782d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pandora's Box",
    artist: "Orchestral Manoeuvres In The Dark",
    id: 3319277,
    release_date: "2007-01-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424d8d211b1b2770744fc9dcff51e7a1-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ddb1589cf546195f1d5b72c685d782d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "That's Why You Go Away",
    artist: "Michael Learns To Rock",
    id: 3337829,
    release_date: "1999-08-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1267bb39ed8b23340863608a452d3b57-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0b58e4ac2fc60e588d7a6be9cbf1d7ac/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fade Into You",
    artist: "Mazzy Star",
    id: 3360821,
    release_date: "1993-09-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5b3e646e5ce949fda8d7be186fbbd41c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d3703e81301662626cc06a8b2843c78/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "That Girl",
    artist: "Maxi Priest",
    id: 3362643,
    release_date: "2008-07-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3715cb93d1f38e7bef51eea22a707da8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea51e7f043e12d31468872cb01a3fb5c/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Fascinating Rhythm",
    artist: "Bass-O-Matic",
    id: 3363711,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8147b737f931fc1817c154739132acb7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9934f68f983c77cdc5211b5061f9bd10/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "I Turn To You",
    artist: "Melanie C",
    id: 3363796,
    release_date: "2000-09-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8fb8abe55ca150d2b83fe5db34d369a9-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f20a0a19158fea0ac436d9ac240c97fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Never Be The Same Again",
    artist: "Melanie C",
    id: 3363798,
    release_date: "2000-09-05",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d38d052d0dfae8a806cb6932bd0404f-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f20a0a19158fea0ac436d9ac240c97fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Play That Funky Music",
    artist: "Vanilla Ice",
    id: 3366338,
    release_date: "2009-04-21",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fcbb9db77a876690cbc7ec5004f84d5-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55522600c5cc17b1c56df91ddee0630d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Baby, Baby",
    artist: "Amy Grant",
    id: 3377259,
    release_date: "1991-06-18",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-824a67e393642df2d5a04625e43fa669-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34d721988bc16085f6a76844b893ba91/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Every Heartbeat",
    artist: "Amy Grant",
    id: 3377260,
    release_date: "1989-02-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbce8e7d5aea402bc3a07acd69811dc6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34d721988bc16085f6a76844b893ba91/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's What Love Is For",
    artist: "Amy Grant",
    id: 3377261,
    release_date: "2007-08-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a74f5dbd7736f5206a96aa6f5bc0ed1-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34d721988bc16085f6a76844b893ba91/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Spaceman",
    artist: "Babylon Zoo",
    id: 3389663,
    release_date: "1996-02-29",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1b32e147f810d244e8a7f14fa6ebf3ed-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a9c077fe8f6342724a5aa61deae88d51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Something To Talk About",
    artist: "Bonnie Raitt",
    id: 3402676,
    release_date: "1991-07-29",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5fffc85c11ebcaf58d08ace16cac6017-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3a4a48d754748571273a43c71583bf9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Can't Make You Love Me",
    artist: "Bonnie Raitt",
    id: 3402678,
    release_date: "1991-02-20",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-add5a9a0562c2e879c784d88bdc15901-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3a4a48d754748571273a43c71583bf9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ms. New Booty",
    artist: "Bubba Sparxxx",
    id: 3405985,
    release_date: "2005-11-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3240891b048eddbc2da811a209550d6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19a37a491b24a0ba5d06183f01f26c1a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Do It To It",
    artist: "Cherish",
    id: 3414898,
    release_date: "2006-08-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a113adc1609114128aa813c26462c38-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b95adc61ae5d092fda8fcc1a522df47/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When Love Takes Over (feat. Kelly Rowland)",
    artist: "David Guetta",
    id: 3445820,
    release_date: "2009-04-17",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-381e88fc079c3014625069ce556338c9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ee1ea209237b95cf27a1427ac5ecdec0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Again",
    artist: "Janet Jackson",
    id: 3501662,
    release_date: "2000-07-06",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b01008d4d24a378650329f66c328c9ea-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4104f417dff07ee2b6c49b798f8b23ad/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Happening All Over Again",
    artist: "Lonnie Gordon",
    id: 3535615,
    release_date: "1993-08-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ca4e87e62d611850d2ad8eb3b23eb04-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/59ac02b382c6dc4bfe673e8552cb11d8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Epic",
    artist: "Faith No More",
    id: 3590156,
    release_date: "1989-01-29",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-44682608a4d15fefc971a89bbf984a41-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88fcaad892e7686ec8b9a901e5a29c41/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Supermassive Black Hole",
    artist: "Muse",
    id: 3590186,
    release_date: "2006-06-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8ea24f298f5235edf80df865dc177632-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc457d27a8c0b7fc6f9b56fb94e22a0d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "There You'll Be",
    artist: "Faith Hill",
    id: 3598718,
    release_date: "2001-05-21",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f593408e332050426c68c246420d384-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe9f2f1290275e57df54a32e18d31471/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Closer to Free",
    artist: "BoDeans",
    id: 3599660,
    release_date: "1996-11-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83156609e740fb3849b7b16b5a80798b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c0752505252130719511a891214b6f79/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It's Been Awhile",
    artist: "Staind",
    id: 3611374,
    release_date: "2001-05-22",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9ceb5ef9c5e0cc00bd5188ccc0227d60-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3bc28968fcb37db269ea1a90fc7bfc5c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Twisted",
    artist: "Keith Sweat",
    id: 3616531,
    release_date: "2004-10-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a654d422004ae1ff32b31af5b39d2e8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a33be0dde86b9bc7579703c246f9c33/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Nobody (feat. Athena Cage)",
    artist: "Keith Sweat",
    id: 3616541,
    release_date: "2004-10-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0467575217253a0aee7768b0f956da4f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a33be0dde86b9bc7579703c246f9c33/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "New Shoes",
    artist: "Paolo Nutini",
    id: 3657964,
    release_date: "2007-01-30",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cacfde19b743f5c73b83e78d1583b3f0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f43d26dc4fa2d28279dd3665af1dad83/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shawty (feat. T Pain)",
    artist: "Plies",
    id: 3661514,
    release_date: "2007-08-07",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c99e5c0fd3f029e946c650384d9e60e6-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c764e7491bdf0810ef9a1e7dd98743f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Hotel Room Service",
    artist: "Pitbull",
    id: 3731410,
    release_date: "2009-06-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c1e2174461d1a10afb5e833cca3c154-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b0fe2aab6f61ba46edd70b74867c2fa/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "She Talks To Angels",
    artist: "The Black Crowes",
    id: 3731807,
    release_date: "2017-11-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-894684c7dca7e1acca89456671506ae8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a693d580357750108ad14ccde560aa3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flat Beat",
    artist: "Mr. Oizo",
    id: 3748159,
    release_date: "1999-03-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d5e9415f713e3286caa1661f98196d5e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/331d18ce92c355bc0dd5b699e063249c/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Follow Me",
    artist: "Uncle Kracker",
    id: 3819653,
    release_date: "2000-06-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9dac177a57b67455c05cfe6e122cd4dc-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c5cae288ea63e37ea904ac492dea926/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "What's It Gonna Be?! (feat. Janet Jackson)",
    artist: "Busta Rhymes",
    id: 3822107,
    release_date: "2009-07-14",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2e60942932f0dcfb1b5497e1179fa1b7-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a9663fa127e4dae473e1754ad921b671/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Misery Business",
    artist: "Paramore",
    id: 3829140,
    release_date: "2007-06-11",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b5a730c3a1d7060f6c1155b9fd02ac77-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a48b36fe9dd29b2bef2f5058cbe0c25/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "From a Distance",
    artist: "Bette Midler",
    id: 3860243,
    release_date: "2005-07-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c976040240ca5268171a266380d2cda6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe274e0131fc9daf8b31cf41a097e3ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance with Me",
    artist: "Debelah Morgan",
    id: 3875181,
    release_date: "2007-11-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-195415d48196ce5ee5d33c5a5a45465c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3be47a01e4301644217cfdca10c89228/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Just the Girl",
    artist: "The Click Five",
    id: 3877492,
    release_date: "2005-08-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3b62e5a9b99c4a4b0958cf9e2a1b3820-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec927c501a57078ce66aa1137c360534/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Informer",
    artist: "Snow",
    id: 3884974,
    release_date: "1992-12-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2d1826b149210fa3522427a73a6e4d6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/899fb38ed8057501e6275319f9c78599/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All I Want (Sunship Radio Edit)",
    artist: "Mis-Teeq",
    id: 3892663,
    release_date: "2004-07-13",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c733f5bab8cfe3e580e1d07e2487ea10-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a93cad437b3825fa5b6d558178bd795f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Laffy Taffy",
    artist: "D4L",
    id: 3896443,
    release_date: "2005-11-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cdd258d55d092470214bdb0be9f5251-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/13ea59bc8163ced278f9f7b8e019a750/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Sunshine (feat. Anthony Hamilton)",
    artist: "Twista",
    id: 3899461,
    release_date: "2004-01-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01ef75e46da0500ee0761fc1925fb284-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e43cce86c61e9da6e767a9553681a19/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I'm a Thug",
    artist: "Trick Daddy",
    id: 3907007,
    release_date: "2001-03-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0edff05c66f1f5a255aedeba5d2b8c1d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bbc6487b67e43c628d3406e71009bb55/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Desperately Wanting",
    artist: "Better Than Ezra",
    id: 3928390,
    release_date: "2005-03-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa75037037e3347d9ea720102747e63e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/46e6ffccac3acea3ac17c3c9c736a12a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(When You Gonna) Give It Up to Me (feat. Keyshia Cole)",
    artist: "Sean Paul",
    id: 3957322,
    release_date: "2006-09-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b882af06ebbe109ab5820aa6a72a646-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dd7650a5eae9cdde6aa8b4f977fb3a6/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Pony (Single Version)",
    artist: "Ginuwine",
    id: 3958370,
    release_date: "2008-05-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1953ee17d6457350ced604e78de74998-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cae317b2c560c0b95a9c106d3ceb108e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Maria Maria (feat. The Product G&B)",
    artist: "Santana",
    id: 3958522,
    release_date: "2009-07-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6db0d3839217f44b65c4d727e44e439a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e3efd1474f3c04b4cd6d5e35f48a0c92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Battlefield",
    artist: "Jordin Sparks",
    id: 3959408,
    release_date: "2009-07-20",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bf7b330a46eb83af3a99421785b13a90-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b177fda7c042ec001b8a107bec060688/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Air (feat. Chris Brown)",
    artist: "Jordin Sparks",
    id: 3960474,
    release_date: "2008-04-11",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9f52941b2956283c74b1e76d8607095-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c43d5f019822b288e1c31fbb4243eadc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bulletproof",
    artist: "La Roux",
    id: 3976602,
    release_date: "2009-06-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bea6df263b0b61a43a1b228b627879a2-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91f8ab3d5dace0f93a4de460a9c5c10b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Take Me To The Clouds Above (LMC Vs. U2 / Radio Edit)",
    artist: "LMC",
    id: 3979726,
    release_date: "2006-11-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7291ddcdfb47e42e6f63cd5d2352d998/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Any Dream Will Do",
    artist: "Andrew Lloyd Webber",
    id: 3981462,
    release_date: "2005-11-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0fc806f8babd86f7a570197c2307808c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8fff4bb0a157b2f5b6cbed404fd6015b/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Bootylicious",
    artist: "Destiny's Child",
    id: 4006939,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-18140c5967f467a9fbcd42daf283ecb3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6569bbc2ccd786c051555084ebc9b355/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Like a Boy",
    artist: "Ciara",
    id: 4007318,
    release_date: "2009-07-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-222c7d6335ba3cfa69585e781bb51946-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9b24f9dddee58fd516f48f593d38b82d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heaven Is a Halfpipe (If I Die)",
    artist: "OPM",
    id: 4086982,
    release_date: "2000-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0febb7290dd4dc928c1554a15b9a5b25-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8600ad1ca9a86b139519354d2d061ab7/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Youth of the Nation",
    artist: "P.O.D",
    id: 4087048,
    release_date: "2001-09-11",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a869b136fe70f7ec5e2c6f479f9f6a01-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/655f3e742d4b5ef53d0b95554d34616e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All Summer Long",
    artist: "Kid Rock",
    id: 4087608,
    release_date: "2007-10-09",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4cfc4b781a41bbd627ee8b7c26d4f6d6-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b0ce5aa10cc52b8dc6661c7ce9b6c876/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Wanna Sex You Up (Single Mix)",
    artist: "Color Me Badd",
    id: 4089646,
    release_date: "1991-01-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42946d67f270d7035eb3f6088e09fcf2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6a712e65a0ad34577553759dd7b4141/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All 4 Love",
    artist: "Color Me Badd",
    id: 4089647,
    release_date: "1991-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bc80998aa1c24302240cbd9b0656287-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6a712e65a0ad34577553759dd7b4141/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take a Picture",
    artist: "Filter",
    id: 4094391,
    release_date: "1999-08-23",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8936f824746325c3ef81edb1816e847/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "So Fine",
    artist: "Sean Paul",
    id: 4096449,
    release_date: "2009-08-14",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-497553bb30f416afc2fc541a88b94f19-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27b211e7caf59b0e434a836dfc078165/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Knock You Down",
    artist: "Keri Hilson",
    id: 4123045,
    release_date: "2009-05-04",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d3c673b53543c5496b5c4794bfd1540-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dba35079692ff47f7bce2a00d3bc514/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Down",
    artist: "Jay Sean",
    id: 4160021,
    release_date: "2009-07-10",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a7f4fbf785e1745c8f628fe85b3d594/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Right Round",
    artist: "Flo Rida",
    id: 4162078,
    release_date: "2009-03-30",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b19b5989a16be91a3c7deeec432e5d7-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53654f189e39eb74aea041f8140649f5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "We Be Burnin'",
    artist: "Sean Paul",
    id: 4162685,
    release_date: "2005-09-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f789de6a585e413450d55782b0756a4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/acea01270d22ab9c87661dff9d98bfec/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Temperature",
    artist: "Sean Paul",
    id: 4162693,
    release_date: "2005-09-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0872e670f67fcdf1a95afb61a935c494-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/acea01270d22ab9c87661dff9d98bfec/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "One Time",
    artist: "Justin Bieber",
    id: 4187426,
    release_date: "2009-06-26",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/43a761bf503ec58425f97e2371e4a6ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Happily Ever After",
    artist: "Case",
    id: 4190566,
    release_date: "2009-06-16",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9f87ef0503dc5a0e2d53d417f45dd49-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a8b49bc036a6f40865c8efcf007e4d6/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Beautiful Stranger",
    artist: "Madonna",
    id: 4209674,
    release_date: "1999-05-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f11b66cde7536b6ffb27e8c1184e5616-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/195d3d2c1196b20711cfb6ad76e1c2ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Know You Want Me (Calle Ocho)",
    artist: "Pitbull",
    id: 4216277,
    release_date: "2009-08-31",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f11224510479252a1092daaacfb702e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dafa15ce0606a487a15f00dea03654e8/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Day 'N' Nite (Crookers Remix)",
    artist: "Kid Cudi",
    id: 4232307,
    release_date: "2009-09-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-639cf0cd2961d0db251bc3abb03ed775-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/852d93133d8a0b298b87f4fd69b3f1fb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Party In The U.S.A.",
    artist: "Miley Cyrus",
    id: 4233792,
    release_date: "2009-08-11",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b7837979aa22bc9fe6430dd101af7e37-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5eb7bf3d5a9e623f2f74536dbb197dfa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Obsessed",
    artist: "Mariah Carey",
    id: 4247257,
    release_date: "2009-10-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-082d51d2b657950734641497232773d8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67e6af08b67272ec67eacccbb44a1368/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Every Morning",
    artist: "Sugar Ray",
    id: 4288270,
    release_date: "1998-12-23",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6963181b24fbbefcd1aa5bf74c6388a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b31183d3344b839c9dc801fd804899b1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'll Be There for You (Theme from 'Friends')",
    artist: "The Rembrandts",
    id: 4289170,
    release_date: "1995-08-15",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4057c4511d25678516d6d8967067c5b5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ac4bfa0c432871ba5e843c1ca3058825/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Forever",
    artist: "Drake",
    id: 4302261,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b73ee8c5c3fb7b5b86c76df6cbce9bd5-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/785f058792860715d97984a969a8407f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Climb",
    artist: "Miley Cyrus",
    id: 4307896,
    release_date: "2009-10-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc7ac52dfb9e1bd401634875ee65adc6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/515658453fdf5d6b55550edec648487c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ego",
    artist: "Beyoncé",
    id: 4311359,
    release_date: "2009-09-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d2841ea3e467036608744a56c37aa183-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e6686fb79ab27afcc7ceeaf98689277/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Pretender",
    artist: "Foo Fighters",
    id: 4311597,
    release_date: "2007-09-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d4394abe6694b953f4a021a581c4e90-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/266f01f1c7a04843d11cd08f9c07d11f/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Learn to Fly",
    artist: "Foo Fighters",
    id: 4311599,
    release_date: "1999-10-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-921fd44bf4a984c333e7caa7b8f7ee7d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/266f01f1c7a04843d11cd08f9c07d11f/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Do You Want To",
    artist: "Franz Ferdinand",
    id: 4315297,
    release_date: "2005-10-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-44350a023cf37c7618c93f7a7c361797-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bdc6f51d05880072275b2e1eefc907ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Bet You Look Good On The Dancefloor",
    artist: "Arctic Monkeys",
    id: 4315310,
    release_date: "2006-01-29",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13db1edf4b13d35534a80c6140da7200-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7a0a1ca91431861989efe5a22aad557/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "When The Sun Goes Down",
    artist: "Arctic Monkeys",
    id: 4315319,
    release_date: "2006-01-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f013b0775dd305db5d50db9d275de4e9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7a0a1ca91431861989efe5a22aad557/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Brianstorm",
    artist: "Arctic Monkeys",
    id: 4315378,
    release_date: "2007-04-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f2b4e3f9a7946f44e5f47261d242ee2-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d7a4f9f1af8736457de34f28d50ef496/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Fluorescent Adolescent",
    artist: "Arctic Monkeys",
    id: 4315382,
    release_date: "2007-04-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80138b5b93f7980e5f2ecf8bead39602-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d7a4f9f1af8736457de34f28d50ef496/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Crying Lightning",
    artist: "Arctic Monkeys",
    id: 4315578,
    release_date: "2009-08-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-05812c43966c45c4781b189bdb99328c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/13cdeb23547351f3ea543a2f5b4b9a4b/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Take Me Out",
    artist: "Franz Ferdinand",
    id: 4315684,
    release_date: "2004-02-16",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-17189dc85050c415c1a7f52908039d67-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a45459c60f8e8b809c701a22b445b6c3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Home",
    artist: "Michael Bublé",
    id: 4368120,
    release_date: "2005-02-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49b20136638703183cac60c731d165ba-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82adbd44bbe338520d8dfd815524dfaa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "22",
    artist: "Lily Allen",
    id: 4530801,
    release_date: "2008-12-02",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fc19ec744baac2007b72cb245a2789f9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6d9b52cac00fd43603bec19b40a0ffc3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hate It Or Love It",
    artist: "The Game",
    id: 4586924,
    release_date: "2005-01-13",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b5f6469d0e7b67675587b8a21b9e2f00-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77812f497bc35690f4dab2269cd76424/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "How We Do",
    artist: "The Game",
    id: 4586926,
    release_date: "2005-01-13",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd1a6c2babbc7741653ad51dcf6a7770-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77812f497bc35690f4dab2269cd76424/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Boom Boom Pow",
    artist: "The Black Eyed Peas",
    id: 4619462,
    release_date: "2009-10-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c26523f7662d6d679e02be01e2a5d934-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea30377840f4ef9ac62406c5e16e9c4b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Meet Me Halfway",
    artist: "The Black Eyed Peas",
    id: 4619464,
    release_date: "2009-06-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5b0ce5e82f368fd73efb4fffe4ec52b-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea30377840f4ef9ac62406c5e16e9c4b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Somewhere Only We Know",
    artist: "Keane",
    id: 4624027,
    release_date: "2009-11-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c8b907f111dc41062bc03ff4c82a8144/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Story",
    artist: "Taylor Swift",
    id: 4634579,
    release_date: "2009-11-16",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8a2bb5b70b8b999bbe8f4b1b1d72fc6b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58d23fd7cd3456d59e946f3e8bacb1bd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Belong With Me",
    artist: "Taylor Swift",
    id: 4634582,
    release_date: "2009-11-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e272f695fcab4bb28c692453cd3768b5-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58d23fd7cd3456d59e946f3e8bacb1bd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dip It Low",
    artist: "Christina Milian",
    id: 4639613,
    release_date: "2004-02-02",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-55d796febdb139f4068267c1541dc501-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67013a63761b59dbc7dd7d08577f022c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Te Amo (Album Version)",
    artist: "Rihanna",
    id: 4670276,
    release_date: "2009-11-23",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-70b9e315c520bc2bad9e0387950b69b0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af82bfab0277506de1d91c3f9d2e28c3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bad Romance",
    artist: "Lady Gaga",
    id: 4709937,
    release_date: "2009-11-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f5cd03cb7d3f329ec0372ccfb055635-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62f368993ddb68c5364cd03221d07ac6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Paparazzi",
    artist: "Lady Gaga",
    id: 4709950,
    release_date: "2009-11-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b0182bba441946d1902ee90466330646-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62f368993ddb68c5364cd03221d07ac6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wrap Me Up (Original Mix)",
    artist: "Alex Party",
    id: 4955228,
    release_date: "2009-12-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d1a61963523540a785a17423238a9a7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19d56728f53c6791c62d09f44a87029d/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All The Small Things",
    artist: "blink-182",
    id: 5059907,
    release_date: "1996-06-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fd1761b9023e6708b2e82c1ce4529f2-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3df0e187221bbb3717fde50164d3a58/250x250-000000-80-0-0.jpg",
    genre: "Kids",
  },
  {
    title: "BedRock",
    artist: "Young Money",
    id: 5060034,
    release_date: "2009-12-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-890662c8b378fffd94b2b34a635de24e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20ba560a4e6fd95147a782984320894f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Write Sins Not Tragedies",
    artist: "Panic! At The Disco",
    id: 5094040,
    release_date: "2005-09-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0861a4c9785a089ed84126e94dc6c3b0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/12f418509c1f4fecca36b80c1432019a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Whatcha Say",
    artist: "Jason Derulo",
    id: 5420471,
    release_date: "2010-02-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3afb1fc3ec1450b791ab33f275d257e9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27386b7a34a432c964078d3cff59cf6f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Walk Away",
    artist: "Jade",
    id: 5617992,
    release_date: "1992-11-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e7658aab31bdb05c6f74b87f4eddbf7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/059707c9b249ce3e89b99c2fde9c6878/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Round and Round (Soul Mix Edit)",
    artist: "Tevin Campbell",
    id: 5619144,
    release_date: "1991-11-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ee5dc93d7511e970beb09de7351537f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/764a227f147a9e2078de69992fa445e5/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I'm Too Sexy",
    artist: "Right Said Fred",
    id: 5662806,
    release_date: "1992-01-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e5f611f7bbc8a33676f0f0a4ae8c2ea7-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/577b03c9a648d9fba539f42442fb51f0/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Independent Women, Pt. 1",
    artist: "Destiny's Child",
    id: 5817088,
    release_date: "2001-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-73229b64a1fedd2a1196dbbd0c9d1ad4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6569bbc2ccd786c051555084ebc9b355/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pictures Of You",
    artist: "The Cure",
    id: 6215171,
    release_date: "1989-05-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-78ff66c28a954701f6757f86a2365725-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/413833519b93fda0a2e4b148d2f04efa/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Sometimes When We Touch",
    artist: "Newton",
    id: 6247405,
    release_date: "2009-10-05",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c807a83fb3591763c20131548522ae1f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c4fe0e7a9c4c84e10310a4a5d30edfef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dear Maria, Count Me In",
    artist: "All Time Low",
    id: 6249577,
    release_date: "2007-09-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ea74f7e48bcdd7874f33c03f21652e6-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/acab0eec4dca657129907a8cac083645/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Replay",
    artist: "Iyaz",
    id: 6276158,
    release_date: "2009-08-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a17695e29260b8b9ba37286c113ce28-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e10ea1c02a800e48c6bb3572bd661102/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Got To Have Your Love",
    artist: "Mantronix",
    id: 6283556,
    release_date: "1989-06-19",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-26f6f6a69c179db1da4e8fdc9739b7fa-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a137ade09e14a5bccf032627b616e0f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Crying in the Rain",
    artist: "a-ha",
    id: 6463613,
    release_date: "1986-03-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f8ba82a24c3daced728aec5b0e88f46a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c79804ef54033e72fb62167f4598ef25/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Give Up",
    artist: "Chicane",
    id: 6467783,
    release_date: "2008-10-06",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ae4e8ff4ec02f2f42513393697a8b76c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/54f610fa434765c8b433e12130f260f9/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Escapade",
    artist: "Janet Jackson",
    id: 6575395,
    release_date: "1989-01-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6d3d5952acdb32d9606708ec1ce353d-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb1ea876e6936b5a8b36364464aa29f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Black Cat",
    artist: "Janet Jackson",
    id: 6575397,
    release_date: "1989-08-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-02683f97f02182f7fe5b87dcaa2330dd-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb1ea876e6936b5a8b36364464aa29f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come Back To Me",
    artist: "Janet Jackson",
    id: 6575399,
    release_date: "1989-01-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a98f9ea1430fb82a3df13908b888f11-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb1ea876e6936b5a8b36364464aa29f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Never Let You Go",
    artist: "Third Eye Blind",
    id: 6586756,
    release_date: "1999-11-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bf38330951573ae801b97a3c08729ee5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7c462129dd1da031b6db143f54a43cdf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bleed It Out",
    artist: "Linkin Park",
    id: 6611048,
    release_date: "2007-05-14",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b1fe8eb355db58cd024f55d28af37c1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2dd699adcd68089f49f3ab558a2bd296/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Boulevard of Broken Dreams",
    artist: "Green Day",
    id: 6611231,
    release_date: "2009-06-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6d927bc8521d89904dbc2b932783ee2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2497e819405074b107b3bce1d95cf9/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Show Me Love",
    artist: "Robin S",
    id: 6628158,
    release_date: "1993-06-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-adbe77575fd770ff758b0d5dff6e065e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d09c6609076ecd405215bdff6e4783f5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bust It Baby, Pt. 2 (feat. Ne-Yo)",
    artist: "Plies",
    id: 6639947,
    release_date: "2008-06-10",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6b6f06913d5bfe3b88da08fee85b5da-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e46608f08bce5b059b0a91aeb4c93e55/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Better Days (And the Bottom Drops Out)",
    artist: "Citizen King",
    id: 6640921,
    release_date: "1999-02-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-122269c1cb7441377cf91146d0b8fb8c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7a520aed11a75b84caf989cd2bf4658a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Breathe",
    artist: "Faith Hill",
    id: 6641040,
    release_date: "1999-11-09",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-93f56186dbc19e8be9d843c680df52e3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/580a1286a0994b5052cb75c1d455b504/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Don't Stop Movin'",
    artist: "S Club 7",
    id: 6789702,
    release_date: "2010-07-12",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-617cfa09a1de7b6f75581e746ae4a8e5-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a2fa199d821f46369b9fdee63575b66/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pure & Simple",
    artist: "Hear'Say",
    id: 6789707,
    release_date: "2001-03-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dac610e9b684ccdf6f110945626e44c8-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a2fa199d821f46369b9fdee63575b66/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "E.T.",
    artist: "Katy Perry",
    id: 6812365,
    release_date: "2010-08-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92519d967a3de6cdbffdf6faa12f7659-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c7283218e496f35f1d804d3617eecb73/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rise",
    artist: "Gabrielle",
    id: 6824033,
    release_date: "1999-05-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e21f07c10ba66e855e5a39ee08f8e9e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c1f56822d8eacb62577066c98c348aa8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flowers (Sunship Edit)",
    artist: "Sweet Female Attitude",
    id: 7238967,
    release_date: "2000-03-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a9423feae5686f4572993c10beb61f6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ccb26ce88266799a835b90c4bc64d2b1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Right Now (Na Na Na)",
    artist: "Akon",
    id: 7255500,
    release_date: "2008-12-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79cb484828ccefe8f3120f9f01647695-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/96eea2e34f08d4043539fc3b1fb8e0eb/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "My Place",
    artist: "Nelly",
    id: 7278730,
    release_date: "2005-11-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84be61f6648896d819040c70bffe8e75-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58c63d95ea1e1bcdf363d2e14a87b3cc/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Ride Wit Me",
    artist: "Nelly",
    id: 7292333,
    release_date: "2000-03-10",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d30ce1dc6334ec0b4f48d198dd4b619a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f3649b61f5418db4c51c726beac9964e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Hate This Part",
    artist: "The Pussycat Dolls",
    id: 7292420,
    release_date: "2009-04-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecc254d2a8f9ff5b76620b0dbec53c9e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Loca",
    artist: "Shakira",
    id: 7365619,
    release_date: "2010-10-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9b7ae6b468322bb9a1acc6a02eedadd8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Show Me The Way",
    artist: "Styx",
    id: 7374163,
    release_date: "1975-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7fdba3f4e6087f2deb52d79c6204256e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aca8711c74dfbcdf9a3c5ada79b0ba82/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pump It",
    artist: "The Black Eyed Peas",
    id: 7375556,
    release_date: "2005-02-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1e6282bf00a31ddff8205a986ac5fe65-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/595ae492a34647054ea30d805096d5b5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Is It Any Wonder?",
    artist: "Keane",
    id: 7378318,
    release_date: "2006-09-07",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51da163a845471d1f42f7c4e908556cc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everything You Want",
    artist: "Vertical Horizon",
    id: 7410392,
    release_date: "1999-06-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1651ee54b1d650ebc7c140c5e41de076-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04e36aff87717fdfb1c2ae1f437dc0ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That Don't Impress Me Much (Album Version)",
    artist: "Shania Twain",
    id: 7442366,
    release_date: "2009-09-22",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5943f0ad9dabe26cb3ff9f0ca45c611a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Silent Lucidity",
    artist: "Queensrÿche",
    id: 7459741,
    release_date: "1990-11-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-50219fd8c73621dd0e321c7d031c5b46-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b5685b3ebddf6ed7133cbac215c279b0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "There You Go",
    artist: "P!nk",
    id: 7512050,
    release_date: "2000-02-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95fa4d7987b397ea52965591d69ecd2a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1cbbb4cd69bc27b7eb263e817d03dcd0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Who Knew",
    artist: "P!nk",
    id: 7512057,
    release_date: "2006-05-18",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e47c09bf6511f14fe4d91aec8b90f7d3-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1cbbb4cd69bc27b7eb263e817d03dcd0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Raise Your Glass",
    artist: "P!nk",
    id: 7512065,
    release_date: "2010-11-12",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5bb91800af2afd9f7f8c94d348344024-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1cbbb4cd69bc27b7eb263e817d03dcd0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Move Any Mountian",
    artist: "Shamen",
    id: 7517553,
    release_date: "1990-03-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-262971afc3ff0d8b079ec760dbed3340-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e35600f4030ddef182cfb51aff3c2935/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Izzo (H.O.V.A.)",
    artist: "JAY-Z",
    id: 7532692,
    release_date: "2001-09-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-36578ee5b2f17f72738aef5f2585e523-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/480b13a10b700c021cc840f7fa4cda8b/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I'm Like A Bird",
    artist: "Nelly Furtado",
    id: 7562977,
    release_date: "2000-11-16",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc255cf2ec68d88c13e5c691fc0657b9-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbd6d06c71d72755bba27af0b1e3c88e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Over My Head (Cable Car)",
    artist: "The Fray",
    id: 7674864,
    release_date: "2005-10-22",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fec936e322b528e2dacd53675d573b2e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a61a4b61f98acf37faaf35554d706b1b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "How to Save a Life",
    artist: "The Fray",
    id: 7674865,
    release_date: "2005-09-13",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-78d5786fad33809f0837b74b09b8efa9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a61a4b61f98acf37faaf35554d706b1b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Last Kiss",
    artist: "Pearl Jam",
    id: 7675332,
    release_date: "2003-11-11",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d2d046898e66054ef2a2f63d067c2b6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/96faa60d22790a60b0bf1d6243ca4f1a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I'm Every Woman",
    artist: "Whitney Houston",
    id: 7675405,
    release_date: "1992-11-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84573bcd22329a077248159d042c8cd8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d071ab8d81111be6434c2f2cf372ab13/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "The Time (Dirty Bit)",
    artist: "The Black Eyed Peas",
    id: 7705885,
    release_date: "2010-11-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9c326124978989513e0877490a32de03-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d6ac0785938a611ced3e620227bdcfc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Who's That Chick? (feat. Rihanna)",
    artist: "David Guetta",
    id: 7754536,
    release_date: "2010-12-06",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1c9e6d4f0b1370477201badea7739b3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/509ab77d946e226a8ff8834dcd25e2f1/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Steal My Sunshine",
    artist: "LEN",
    id: 7779493,
    release_date: "1999-05-31",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6274393aaec16a620c1899f7703ecddc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a1c881af8afad453feb862e037555147/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Coming Home",
    artist: "Diddy - Dirty Money",
    id: 7857290,
    release_date: "2010-12-10",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a194b74602666dfe41269619ea6713e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stop Me (feat. Daniel Merriweather)",
    artist: "Mark Ronson",
    id: 7923754,
    release_date: "2007-04-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-263e31740d26e1e0528373d7de4775f1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa17bc2f15022dd727fd21922e90c9c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Valerie (feat. Amy Winehouse) (Version Revisited)",
    artist: "Mark Ronson",
    id: 7923756,
    release_date: "2007-04-16",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74fb75139d912676b474e32994c22d28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa17bc2f15022dd727fd21922e90c9c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Lazy Song",
    artist: "Bruno Mars",
    id: 8011853,
    release_date: "2010-10-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6462ec4cf38bc3a4416ecbaa25a67ab-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b59dc18e109515420f8237719bd2186/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(Mucho Mambo) Sway",
    artist: "Shaft",
    id: 8083107,
    release_date: "2011-01-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f192a3b6d39d198cc660d17407d24e81/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rolling in the Deep",
    artist: "Adele",
    id: 8086126,
    release_date: "2011-01-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e78000f07a5cafb99b58e0c24cebbc80-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bdaf37e2e7f883e84bbc3462c938293/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rumour Has It",
    artist: "Adele",
    id: 8086127,
    release_date: "2011-01-24",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8a787cc57ef15e61922acd1335d7692-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bdaf37e2e7f883e84bbc3462c938293/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Set Fire to the Rain",
    artist: "Adele",
    id: 8086130,
    release_date: "2011-01-24",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-011a9b862c6d294da97973fddb72b847-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bdaf37e2e7f883e84bbc3462c938293/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Someone Like You",
    artist: "Adele",
    id: 8086136,
    release_date: "2011-01-24",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d518138b96f250bee6c06c282182513-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bdaf37e2e7f883e84bbc3462c938293/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "With Every Heartbeat (Album Version)",
    artist: "Robyn",
    id: 8104352,
    release_date: "2007-09-17",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3e008fd70c27ad84c004d687f36a5ac/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fastlove, Pt. 1",
    artist: "George Michael",
    id: 8112357,
    release_date: "1996-01-05",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-911237912e9005c75bd086530fae535b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eed917419ae0b958730b075c165dd607/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Your Baby Tonight",
    artist: "Whitney Houston",
    id: 8164769,
    release_date: "1990-11-06",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6c57f3fa3eacc77686103d3d21d0350-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae3c96eda6ff123bf537b78387d4a11d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Higher (feat. Kylie Minogue)",
    artist: "Taio Cruz",
    id: 8167112,
    release_date: "2011-02-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ac7926e62ad2e20b656a9e8bee93b02-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f7fa7c56ff43a5aa7704b4e38312e26/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "On The Floor",
    artist: "Jennifer Lopez",
    id: 8930372,
    release_date: "2011-02-22",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fa723eea4ab555bf88e2d3d3e59ace50-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3df69d1356e25bf018df6cb66f8376b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Need A Doctor",
    artist: "Dr. Dre",
    id: 9806802,
    release_date: "2011-02-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e8da94c98652c7121fdbb475075aa6d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a71f29ab2cfa97c88f69afa9041e5f35/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Look At Me Now",
    artist: "Chris Brown",
    id: 9815458,
    release_date: "2011-02-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a335c1df1805ac07bf5a96cd578954fc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweat (Remix)",
    artist: "Snoop Dogg",
    id: 10162588,
    release_date: "2011-03-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e55fe66ca1a72021f87cb75351e59d17-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81151ad27acdf3d3139e9c3dbf875ff/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Just Can’t Get Enough",
    artist: "The Black Eyed Peas",
    id: 10214182,
    release_date: "2011-04-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-df861ff51cbdcd4d4124a046ba868783-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9b1143cf8255a6ac45c81ff1cf32529d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Can't Stop",
    artist: "After 7",
    id: 10245255,
    release_date: "1989-08-22",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bb3a629bd077d372f5fd4d194a1e71b2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35934a99503482f2206937bb56b4dcc8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Golden Skans",
    artist: "Klaxons",
    id: 10284894,
    release_date: "2007-04-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2b333806e9d743e029c165e7e5697be/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "These Words",
    artist: "Natasha Bedingfield",
    id: 10307864,
    release_date: "2010-01-20",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/037175c9788fc00c4fc5125e75c043e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Give Me Everything (feat. Ne-Yo, Afrojack & Nayer)",
    artist: "Pitbull",
    id: 10308117,
    release_date: "2011-03-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80ac209393f0dc03e31466ee7f936092-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93806e3105596219e5c241af4b7e1955/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Let the Sun Go Down on Me",
    artist: "George Michael",
    id: 10392073,
    release_date: "2011-04-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb45b6067a76659d7f8322e9b7e8c54f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c9fd7e1257024bff5be2a795fc7e5ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Judas",
    artist: "Lady Gaga",
    id: 10496009,
    release_date: "2011-04-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91c4f68c2e8d805ce1e1d53d2f73bb44/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Run the World (Girls)",
    artist: "Beyoncé",
    id: 10686934,
    release_date: "2011-04-21",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6c260260ff8b6622f06e4501efb3a057-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9ffe6c9d8a81bfa273378f45b06deee/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Black and Yellow",
    artist: "Wiz Khalifa",
    id: 11272651,
    release_date: "2011-03-28",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a50011aa2e3c78737acbc9a0ff6605a7-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9cdc2fb0118ecd5b02e8cef180ad2c50/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Edge Of Glory",
    artist: "Lady Gaga",
    id: 11282846,
    release_date: "2011-05-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c7650967fb544f7edae9b8b1c214a0d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rapture (feat.Nadia Ali)",
    artist: "iiO",
    id: 11495726,
    release_date: "2006-06-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-58144c901f083b56c1b37f7a0cfd9529-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5dbef12cc8e979e99f0970ede1947da/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Touch Me [feat. Cassandra]",
    artist: "Rui Da Silva",
    id: 11663132,
    release_date: "2006-06-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5d22b6254d088c3914086de29b90d281-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1fcf07163732e1a64a61be0acd2dd865/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Feels Like Heaven",
    artist: "Urban Cookie Collective",
    id: 12159135,
    release_date: "1984-02-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d01405c2e314fe6789bcff2a8301c326-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57ffff16dc7fff50b677277367f3fb6f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Pocketful of Sunshine",
    artist: "Natasha Bedingfield",
    id: 12270014,
    release_date: "2011-05-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e71f9954dbdb35d27b4c2ae35d307cba-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/94457d1b153666f38985fe6837ac61db/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "International Love (feat. Chris Brown)",
    artist: "Pitbull",
    id: 12270398,
    release_date: "2011-06-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cc4e7c4bf747a2f6a0c7b59d7608bff-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4863897b132d5c656b9190fc87e9c5bb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Too Close",
    artist: "Alex Clare",
    id: 12324769,
    release_date: "2011-07-11",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-277e9e3dc29c63e7bdd10626db53ee96-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b0359aae28bdd739f51623284c858f6f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'Moves Like Jagger (Studio Recording From "The Voice" Performance)',
    artist: "Maroon 5",
    id: 12724819,
    release_date: "2011-07-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9fa4e23fef999ba8a477efe1ba03a874-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86fb42ca017f0266d0885c1bede988bf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Horses",
    artist: "Daryl Braithwaite",
    id: 12791711,
    release_date: "1991-07-29",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6cb0e40da7d817ed9586de3f3aeb1454-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fc5f98e7f888e01ee2a401ae8136815/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Man Down",
    artist: "Rihanna",
    id: 12817764,
    release_date: "2011-07-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dcd6f6b3cdc437db8bd8cce66ace72a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Skyscraper",
    artist: "Demi Lovato",
    id: 12976495,
    release_date: "2011-07-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5078f55143908d8790edf84e482b1c7c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Candyman",
    artist: "Christina Aguilera",
    id: 13129923,
    release_date: "2008-11-07",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5e073307f63ce67bc134d1f993fbcd0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Irreplaceable",
    artist: "Beyoncé",
    id: 13131048,
    release_date: "2007-05-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a17b2812aa5e4313c5b226c18558158-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3afedf4800754b8c87eda2bf5cbd06b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Goodbye Mr A",
    artist: "The Hoosiers",
    id: 13131186,
    release_date: "2007-10-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5dc4d1931b968d5d624f81fb911d5864-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/217d7fd25d5521edceb63ae1bbc38cd0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What a Girl Wants",
    artist: "Christina Aguilera",
    id: 13131682,
    release_date: "1999-11-16",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c35c427f09f7c049747229d4dabde930-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92905e4be8c6f1c49a4796c96f3b1c26/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come on over Baby (All I Want Is You) (Radio Version)",
    artist: "Christina Aguilera",
    id: 13131684,
    release_date: "2008-11-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8908202bb281b42a25df911c77f36f0b-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92905e4be8c6f1c49a4796c96f3b1c26/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Not Alone",
    artist: "Calvin Harris",
    id: 13131862,
    release_date: "2009-08-17",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c30e923e62ac4f1cd3be07b0fba80624-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe730c8560784944b7d349b1605a0427/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Children",
    artist: "Robert Miles",
    id: 13131916,
    release_date: "1995-12-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d55f759db5f41dc23c835da50bc25442-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0dcb6d056d2e619cc479be7c24bd8e39/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Check On It (feat. Slim Thug)",
    artist: "Beyoncé",
    id: 13132317,
    release_date: "2003-05-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ee5e5c4d8140b82776f0ae492a3e3a74-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/47a1d2dec4ca5c7e734a19dd8bba8b97/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer Love",
    artist: "Justin Timberlake",
    id: 13132402,
    release_date: "2006-09-12",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b707034b3a1cd1bb8323d051b2afe86/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Acceptable in the 80's",
    artist: "Calvin Harris",
    id: 13132580,
    release_date: "2007-06-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-debdde3f2a323ba136850d9f8e2b4cf9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45444d3745d99607af530ef1c008f573/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Me, Myself and I",
    artist: "Beyoncé",
    id: 13138703,
    release_date: "2003-03-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-555237c068abd690bd6a382e20834224-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b3415e1ea8c2e98a8bff153c6cfb4f7b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say",
    artist: "John Mayer",
    id: 13139469,
    release_date: "2008-07-07",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/939d0cda3950cfa85554937b4da75188/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gimme That",
    artist: "Chris Brown",
    id: 13140206,
    release_date: "2006-02-04",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7cd50704e52ae73937c7f3c8959c089d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ab54c7e1839b47f3d2361776a20b2f0/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "(God Must Have Spent) A Little More Time On You (Remix)",
    artist: "*NSYNC",
    id: 13140661,
    release_date: "1998-09-08",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-545255fbfe54ef08204b547780145f08-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbdb20df66f20256eac80af608980c1c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Want It That Way",
    artist: "Backstreet Boys",
    id: 13141170,
    release_date: "1999-05-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce3d80ecfb8a0ec8afe4bff400d5e79e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d61eaad8f321ea876a5f5c7219aae892/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hey Ya! (Radio Mix / Club Mix)",
    artist: "OutKast",
    id: 13141229,
    release_date: "2003-09-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b3e4aada3a8c394dcce645b9024c378c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summertime",
    artist: "DJ Jazzy Jeff & The Fresh Prince",
    id: 13142599,
    release_date: "1991-07-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db1e4b18a57499f1311bcb665604d87c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/11c9ba03d98347205620fdc31449749d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Before He Cheats",
    artist: "Carrie Underwood",
    id: 13144323,
    release_date: "2005-11-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a09742a48711a0ebd5f63e6bb499846e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e6671d78586231350f8eda9097d0036/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Ice Box",
    artist: "Omarion",
    id: 13144433,
    release_date: "2010-02-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-858c3ee2d270cb4941f0d719e0a64678-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3d7bcbd88d9242021361165ea5be959/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Butterfly",
    artist: "Crazy Town",
    id: 13144759,
    release_date: "1999-10-22",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b84d9dd102798235c12ebf6196205ce1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4360765d7a2aceae49aaaa8cfcff6ddf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Goodies (feat. Petey Pablo)",
    artist: "Ciara",
    id: 13145340,
    release_date: "2004-09-27",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d74ab3820eae78d0270e143cd4bfe567-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d38ab86f75dd3d1d87581face5ec3116/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Your Body Is a Wonderland",
    artist: "John Mayer",
    id: 13146259,
    release_date: "2001-08-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-057e57d2dc51d44554140260fad8578c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/70933e9355b563d73f3eb7272f1f8960/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All or Nothing",
    artist: "O-Town",
    id: 13149243,
    release_date: "2003-02-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-321fd0b1b8bdf4a9a8350298e2a4c62e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/649bc2446c8ebfc64c1d49102e47215c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cold as Ice",
    artist: "M.O.P.",
    id: 13162037,
    release_date: "2000-10-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7250e4554a21c5a3b05a0f1269e42a70-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b6cf6cd00671f0df1b2c750aca2d88fd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Wifey",
    artist: "Next",
    id: 13165897,
    release_date: "2004-04-30",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8083de2e65b4c427710e475f2197d1e7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fea2bf81593b4fef77fe519250b0741b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Sweet Lady",
    artist: "Tyrese",
    id: 13181571,
    release_date: "1998-08-31",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-727941f53a6a109bc7d035b770d4687e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dd1b551e7d99dee0521c9d431fcaa09f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Pop, Lock & Drop It (Video Edit)",
    artist: "Huey",
    id: 13181757,
    release_date: "2010-02-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f73de7e4f662dbc03f6309eb30b8eadf-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1fc1e57226d985b09461e03858e893ab/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Headlines",
    artist: "Drake",
    id: 13191028,
    release_date: "2011-08-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-afba37843bf94ed89957db6dc27ad267-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e9c05f4b95a31af4dd284f763ea2e27/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Two Princes",
    artist: "Spin Doctors",
    id: 13438339,
    release_date: "1991-08-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c70d6c5e8e0011bb51b1c7f0a973efa4-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/46abfe87f61beb365349cd25ce46f087/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Last Friday Night (T.G.I.F.)",
    artist: "Katy Perry",
    id: 13460184,
    release_date: "2010-03-23",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0255bba1e7b574c4d485f2475c36c11-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c7283218e496f35f1d804d3617eecb73/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "S&M",
    artist: "Rihanna",
    id: 13529559,
    release_date: "2011-08-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b49dd405a5c07c9265e268dbbcdf308e-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a12c64bb52a167944783878ffe41f02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Wanna Go Home",
    artist: "Jason Derulo",
    id: 13548266,
    release_date: "2011-06-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7607a2404a68e9877e3d5579ab72bc72-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f8c5376d6ad5edd6334f4146cf373a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The A Team",
    artist: "Ed Sheeran",
    id: 13651702,
    release_date: "2011-09-09",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5cb3b1c9868750405b9f9cbdb65c7256-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9dc81301e73f9fe31362858febcef03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Drunk",
    artist: "Ed Sheeran",
    id: 13651703,
    release_date: "2011-09-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbe7aeea400d8d9347076a232e01a79a-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9dc81301e73f9fe31362858febcef03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lego House",
    artist: "Ed Sheeran",
    id: 13651710,
    release_date: "2011-09-09",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c873783ffd9cbe3ad27958a387ef4cb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9dc81301e73f9fe31362858febcef03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Keep Holding On",
    artist: "Avril Lavigne",
    id: 13705107,
    release_date: "2007-04-17",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94c2035e1718bc7b997fc5f3b24e688e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3e150c4bd64c9a0f2e2ccfa97dbe0936/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Yo (Excuse Me Miss)",
    artist: "Chris Brown",
    id: 13783330,
    release_date: "2005-11-14",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f45b6c32e1cc1e63ae3454b9af9566e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Confessions Part II",
    artist: "Usher",
    id: 13783452,
    release_date: "2004-03-23",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88a4322f8b83e0798409146566e708d8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b89c20012cccb051c8a4e04d98386f95/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Come As You Are",
    artist: "Nirvana",
    id: 13791932,
    release_date: "1996-03-25",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d92af1ae1e9c12708b22863d9d4475de-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0282817b697279e56df13909962a54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "She's So Lovely",
    artist: "Scouting For Girls",
    id: 13816166,
    release_date: "2011-10-07",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3290c93d3aacd5b5bd8aa8ced1c15cdb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0740f28f1be5b17922bb47c0cd792ca5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Found Love",
    artist: "Rihanna",
    id: 13839981,
    release_date: "2011-09-22",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/618976e0787bafb970ce45f2e78fd4d5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Night People",
    artist: "Professional Losers",
    id: 14144466,
    release_date: "2006-07-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-de70a36e1cd937f96c1a8a89b3c6867c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99117b19d198d2a99b1b81a31c9fba08/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Stronger (What Doesn't Kill You)",
    artist: "Kelly Clarkson",
    id: 14241633,
    release_date: "2011-10-24",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c95d54947f248425a63055dc36645cb9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/790f5653c73d01fe3bd55d2990ee3db6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Paradise",
    artist: "Coldplay",
    id: 14299589,
    release_date: "2011-10-19",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-40392174522b5dc24ef3d67d225b1e59-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0dd8263dfed37c50a868abbf65fd7da/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Every Teardrop Is a Waterfall",
    artist: "Coldplay",
    id: 14299593,
    release_date: "2011-10-19",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25ab536ef3a724866b60cb0e3518a7ca-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0dd8263dfed37c50a868abbf65fd7da/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Princess of China",
    artist: "Coldplay",
    id: 14299596,
    release_date: "2011-10-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8ccf7ae25fbef0748435d1548a069228-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0dd8263dfed37c50a868abbf65fd7da/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Fly",
    artist: "U2",
    id: 14343286,
    release_date: "1991-10-21",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dfbcd926785a55d78f0cc7954556df4a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64b797ea83d52f3869593a448c790a4d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mysterious Ways",
    artist: "U2",
    id: 14343287,
    release_date: "1991-11-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ff7f6083d76b17a222ecf36a2e9e599-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64b797ea83d52f3869593a448c790a4d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mr. Saxobeat (Extended Version)",
    artist: "Alexandra Stan",
    id: 14393619,
    release_date: "2011-10-26",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd3b4665e9398285bcc5ab330ac73612/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Got 2 Luv U (feat. Alexis Jordan)",
    artist: "Sean Paul",
    id: 14500223,
    release_date: "2011-11-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6555856fa23bc5eab47d9d79fca28b9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d61e3d80e4dd3fb986cb442023d530b/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Where Have You Been (Album Version)",
    artist: "Rihanna",
    id: 14525573,
    release_date: "2011-11-21",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-70eb82ab241f9ed6f5a22ed1fa958ad3-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5199f89d5113a83b5086463d5d0c9415/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Grenade",
    artist: "Bruno Mars",
    id: 14616013,
    release_date: "2011-02-08",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84c30f9fb8faa8ac5e80320c66494914-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/12ac2e58b173d8704718b48a0e91b349/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Is",
    artist: "Vanessa Williams And Brian McKnight",
    id: 14630802,
    release_date: "1992-03-31",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-457fa8fcce556ab79e3fb90ecefd1d9a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3999ed89f8f52144208e91c83a4ffc93/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Until It Sleeps (LP Version)",
    artist: "Metallica",
    id: 14632295,
    release_date: "1996-04-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aed353510c02609d2d8ca20d4887aed5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Home",
    artist: "Westlife",
    id: 14834540,
    release_date: "2005-02-08",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ebbdea4a120e2b6d2e7b3538f113279a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Now That We Found Love",
    artist: "Heavy D & The Boyz",
    id: 14935057,
    release_date: "1989-07-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46c64597a1be01db22a091a5ad9bf96e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77481e7bbcd9e4f7631696fa825a3d15/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Danza Kuduro",
    artist: "Don Omar",
    id: 14948701,
    release_date: "2010-11-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ef575c9be21540fd5a1c699d98c475d1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c22882ab30334c535a3e638f9cbf24d6/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Creeps",
    artist: "Camille Jones",
    id: 15002075,
    release_date: "2015-02-10",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7755ac1666782839dabaaa3eb07f5f93-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1abb8469423bdf2a29364747756b60eb/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "What's Left Of Me (Main Version)",
    artist: "Nick Lachey",
    id: 15050556,
    release_date: "2006-06-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-283fd46b9d960d5a0ece32d54ddc41f0-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/edb123e396df7dfe68f31ee534041e87/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Animal Song",
    artist: "Savage Garden",
    id: 15175374,
    release_date: "2007-02-02",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77f4314c306ca3024442a076893a790a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d14d6858c6692515091a67db20b63d44/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dreamlover",
    artist: "Mariah Carey",
    id: 15176152,
    release_date: "1993-08-31",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f19f1f70292db8aef48e3a9e3f2102e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/074f9fdaefead72442368c647b46f500/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Guerrilla Radio",
    artist: "Rage Against the Machine",
    id: 15178643,
    release_date: "1999-11-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d2d66193cef26f0cfbe6c11ffcde88ad-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2cec0fa04bf4fe49a6ad539ddfbae77d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Little L",
    artist: "Jamiroquai",
    id: 15215897,
    release_date: "2001-08-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7357de6d2bb00b0d0afcc2b0b715e8ed-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fc6c36d3b78be9862f91112afe6ad91/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wild Ones (feat. Sia)",
    artist: "Flo Rida",
    id: 15269141,
    release_date: "2011-12-19",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7d05c999c04b5a9255be77eef6b22459-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d2540f454fad844251105b78ec4ea7b/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Hey Baby",
    artist: "DJ Ötzi",
    id: 15319250,
    release_date: "2010-04-25",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1d084335e38ad28c41be68b308235ed-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ca3d33867c9eb2529fc8e01fd68da9c/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Sunshine (feat. Lea)",
    artist: "Lil' Flip",
    id: 15346718,
    release_date: "2004-03-30",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-332844509b1b139ddba84885c0169fcc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/07e303524a9cdf9c81b11942f4ee08bf/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Lose My Breath",
    artist: "Destiny's Child",
    id: 15376488,
    release_date: "2004-11-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bc9432d917ea8dd26826fbda0b3037d2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a834c91eee2bb719f89287aa6d93ff51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Freek-A-Leek",
    artist: "Petey Pablo",
    id: 15379253,
    release_date: "2002-11-26",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed00585e8abc986c2840db3d73c07185-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cf2c878507b89b1e691636d7b7a4a2fc/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Toxic",
    artist: "Britney Spears",
    id: 15391618,
    release_date: "2003-11-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6ca5af9dcdbe1a480b3aedbd4622210-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a2b95cda407d004d829831d20e2e20b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shape of My Heart",
    artist: "Backstreet Boys",
    id: 15396992,
    release_date: "2001-10-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f47076fe9eff7fc5104f790c946c337-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/52f58f75fc623f6202d1825cc0081dcd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "La Tortura (feat. Alejandro Sanz) (Album)",
    artist: "Shakira",
    id: 15404852,
    release_date: "2005-06-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6ebd48ac0f1c6f2cacaef9626d58ae3-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f2065ca5ce5f5ddefbd8b89e13386c17/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Video Games",
    artist: "Lana Del Rey",
    id: 15425694,
    release_date: "2012-01-06",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c810999483a2bd14bc7b37417fa643ef-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b00b6bf2a353dc0bfd6ff6846708d0e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What is Love",
    artist: "Haddaway",
    id: 15457312,
    release_date: "1993-11-29",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d51262eae83e25c34b62abb37f4d209d-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2c6db52efd00bd77cb57de0cbcd0ef85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Life",
    artist: "Haddaway",
    id: 15457313,
    release_date: "1993-11-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-331c96a02800a9f51bff520b2e0f5a72-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2c6db52efd00bd77cb57de0cbcd0ef85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Love (feat. T.I.)",
    artist: "Justin Timberlake",
    id: 15489385,
    release_date: "2006-09-08",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d9d20f41009a10737febc060a814b14b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/615bb58abf2e5fd86741ed5311d364b1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Goes Around.../...Comes Around (Interlude)",
    artist: "Justin Timberlake",
    id: 15489392,
    release_date: "2006-09-08",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b26204c922c056bae45bdf7b0670d297-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/615bb58abf2e5fd86741ed5311d364b1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love in This Club (feat. Young Jeezy)",
    artist: "Usher",
    id: 15527509,
    release_date: "2008-05-27",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c8bae6730e2a5d12d2c507e05d63e28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4e12ebffaef7555770c0fc35f92be3b4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Use Somebody",
    artist: "Kings Of Leon",
    id: 15531171,
    release_date: "2008-09-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a530de2d218dd205db64aaa6f53795a4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4d41f829fac22196b44e97824ee9180/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Ex-Factor",
    artist: "Lauryn Hill",
    id: 15586234,
    release_date: "1998-02-25",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a654af02d21e66d50fb307ad6ef743f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1322b9d5248a55034f098802072cfac4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gimme More",
    artist: "Britney Spears",
    id: 15589893,
    release_date: "2007-10-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f61130a188573334849c74455d85d27-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/76763b33422eb22b46ffa7c1d8bfe910/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Piece of Me",
    artist: "Britney Spears",
    id: 15589894,
    release_date: "2007-10-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f79ff640c68f689406dccb7cc7c9487-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/76763b33422eb22b46ffa7c1d8bfe910/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Give In to Me",
    artist: "Michael Jackson",
    id: 15593568,
    release_date: "1991-10-16",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-564fbe346c551cf1e8f845f348addbfc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Will You Be There",
    artist: "Michael Jackson",
    id: 15593569,
    release_date: "1993-06-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1b3928f72a2c24326d111214ce4a8007-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heartbreaker",
    artist: "Mariah Carey",
    id: 15599093,
    release_date: "1999-08-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/784da1add09faff9726abdbf500cbf35/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She's All I Ever Had",
    artist: "Ricky Martin",
    id: 15613949,
    release_date: "1999-10-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6e3866e0bc15c0ddcb05c9a39d83a8a-0.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8f7d172b047120fccdaaa86a71e8d47/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Tell Me",
    artist: "Avril Lavigne",
    id: 15626757,
    release_date: "2004-05-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9c63447beeb3e502ec8eb765449b251-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/438e2e737a239379ee4a52e4fb0cf675/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Night in Heaven",
    artist: "M People",
    id: 15642705,
    release_date: "1998-10-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-064e893a93c2e05cdf20e2d42977b020-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f8c459f449832bc6005f26e340df3c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boom! Shake the Room",
    artist: "DJ Jazzy Jeff & The Fresh Prince",
    id: 15698925,
    release_date: "1993-10-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71edbccb3b950d03ebac84e55c16b2ec-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/beffbbf0ca43d886bc2f7686f17dbe75/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Where the Streets Have No Name (I Can't Take My Eyes off You)",
    artist: "Pet Shop Boys",
    id: 15707229,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b583bffc022aac143b30b48f42011fef-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b032e0e6281ee7535e0756a213aede73/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Disarm",
    artist: "The Smashing Pumpkins",
    id: 15715637,
    release_date: "1993-11-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77bca39fcf21f1407b3a01b3537f45f2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9821c23566db6e54775087a85d57efce/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What Makes You Beautiful",
    artist: "One Direction",
    id: 16014608,
    release_date: "2012-02-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8e5e0d51244ccd4d3452136c10043b14/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summertime Sadness",
    artist: "Lana Del Rey",
    id: 16149860,
    release_date: "2012-01-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2b8dc737bc83c94d673885bc0536ec2b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c2c6143c3e83a01ea73517c57d1d138/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Want U Back",
    artist: "Cher Lloyd",
    id: 16197305,
    release_date: "2012-02-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fff6c0270c34f6e2e5ccd37226219e98-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2d26b3822c3016b3ad5c4900053eff0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Praying for Time",
    artist: "George Michael",
    id: 16433494,
    release_date: "1998-01-30",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c62179d8a72f5cf096b9d257f8bce1a2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/549a1f50cd5903e2f42e3253814f8b33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "As",
    artist: "George Michael",
    id: 16433498,
    release_date: "1998-01-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2f50e701afbdffb147cfd02b99a708ca-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/549a1f50cd5903e2f42e3253814f8b33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Freedom! '90",
    artist: "George Michael",
    id: 16433501,
    release_date: "1990-10-20",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79850298cbba261d8b19cd7b227f91b3-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/549a1f50cd5903e2f42e3253814f8b33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Some Nights",
    artist: "fun.",
    id: 16501727,
    release_date: "2012-02-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-645528be10dde5a9669c823c6a3a0092-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f7bd91e2d91ce2d308ee754d6821ff7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "We Are Young (feat. Janelle Monáe)",
    artist: "fun.",
    id: 16501728,
    release_date: "2012-02-14",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2533143dd0ac8f82d905404d613df5ff-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f7bd91e2d91ce2d308ee754d6821ff7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Starships",
    artist: "Nicki Minaj",
    id: 16607977,
    release_date: "2012-02-14",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/491d15440764a745c62e6d36f12a554f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Doesn't Mind",
    artist: "Sean Paul",
    id: 16944546,
    release_date: "2012-03-16",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1282cb65c89c35be7cfc983166e4995a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/526d42d913cd89140759c39a642e9bbd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wide Awake",
    artist: "Katy Perry",
    id: 17135122,
    release_date: "2012-03-23",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f28311d908125c75db69bed4d7ab462-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99578b0bb8c838383c414a5b62b5e15d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take Care",
    artist: "Drake",
    id: 17347967,
    release_date: "2012-03-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0796263bcbc277103007a9c114881c01-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e7a6c8f36669dcd11abe7e7c3222e91/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Motto",
    artist: "Drake",
    id: 17347988,
    release_date: "2012-03-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec05daac58b7ad784674624d88e4c587-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e7a6c8f36669dcd11abe7e7c3222e91/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Beneath Your Beautiful (feat. Emeli Sandé)",
    artist: "Labrinth",
    id: 17480431,
    release_date: "2012-03-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a45b36292eed0ec47a10cebc32b348d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Little Talks",
    artist: "Of Monsters and Men",
    id: 17615568,
    release_date: "2012-04-03",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-efc65ba8b5282c54b5c9698aba11786e-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd269461cb1125e4d74abfc21ba39e6e/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Everybody Talks",
    artist: "Neon Trees",
    id: 17806810,
    release_date: "2012-04-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51993eba64cf0fdb1afd688aabf798e1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa71c0d064e5698ac486c5bb8005318a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "I Won't Give Up",
    artist: "Jason Mraz",
    id: 17827563,
    release_date: "2012-04-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-810e5dc2522616763dd335369748d342-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bfa86b30b8d0278a6253c9631b3202d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Drive By",
    artist: "Train",
    id: 17880924,
    release_date: "2012-04-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-43e26bf7d137b8afff5edd58c578c6ba-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cbfad98434163acc07e30fb557404b18/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Do (Cherish You)",
    artist: "98º",
    id: 18059138,
    release_date: "1998-07-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d7f5f224adeae412f35dfbfc51bba6bc-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f030ef60160074e69f2451a4b4ac2aad/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Move Your Body (D.J.Gabry Ponte Original Radio Edit)",
    artist: "Eiffel 65",
    id: 18061916,
    release_date: "1999-01-18",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-55cf85ff22f5ef20c128fc954912501e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed32356ccdb1a74ad08c01a0f588c37f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All This Time",
    artist: "Sting",
    id: 18221389,
    release_date: "2011-11-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2ee01f9a120baebe3d108c9d8e077dd3-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/373b3c7bed40c3e7637432bb3e76d1b8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "So Good",
    artist: "B.o.B",
    id: 18227779,
    release_date: "2012-04-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f9c1175479eac461899e4c0cf921e77-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/32e50032afeddaedb645363434f238bc/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Whistle",
    artist: "Flo Rida",
    id: 18232117,
    release_date: "2012-04-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-26b22583189b9ec7982a7b2df1d246ec-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e92a46b7e2132f83a0416b0e4606c131/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Put Your Hands Up for Detroit",
    artist: "Fedde Le Grand",
    id: 18639001,
    release_date: "2011-05-20",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e82d1c5e060cd1da1a287c7b634c3377/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beautiful Life",
    artist: "Ace of Base",
    id: 19271701,
    release_date: "2008-11-14",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/703a4eab8c4641b16f01f1d452db1957/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You",
    artist: "Ten Sharp",
    id: 22961611,
    release_date: "2015-03-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d7cadf7b0a0fba88244ef8b09b144c8a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51a063a30141836e34e32b32c8aa9b94/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Silhouettes",
    artist: "Avicii",
    id: 24276241,
    release_date: "2012-04-27",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-38ee4b9938bd9b672ee61b95680161e2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cc97f6748a49d0950c388a5ae695fe1/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ruby",
    artist: "Kaiser Chiefs",
    id: 26608051,
    release_date: "2007-02-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bb130efac1e9f31dbfd05cf007f4c1ff-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20d08d2d341364bebb9296f5d12a6004/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Pound The Alarm",
    artist: "Nicki Minaj",
    id: 28852761,
    release_date: "2012-05-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-786e1a14376f91daeaa3369fa6068020-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3de7484240aaf23e129662c5914707df/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Got to Get It",
    artist: "Culture Beat",
    id: 29794791,
    release_date: "1993-05-11",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0c8ad2b6d6a83c8904d1620fe2e867d8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a652433c79217dc6e885cfa28428f174/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I Believe In You",
    artist: "Kylie Minogue",
    id: 31058061,
    release_date: "2004-11-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6537854d6c7f5f95e85ef161f74c6ec6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Levels",
    artist: "Avicii",
    id: 32109141,
    release_date: "2012-06-04",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8683a6d123ea9e86c3640c88b9abaf82/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Love",
    artist: "Maroon 5",
    id: 33382301,
    release_date: "2004-01-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-24cf38760e3ae6ea1fdec3a1f4455216-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a93caa280e6dd750508aa54c932b6bbe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Oh Girl",
    artist: "Paul Young",
    id: 33542981,
    release_date: "2012-06-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-783cbdc09470b562447eab2e644cab04-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4cbb211fb5094813bb24349ef206ee75/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boyfriend",
    artist: "Justin Bieber",
    id: 37027901,
    release_date: "2012-06-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f61c13dea9856c047879fc4307a5c50-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/312ece7b31fb86c7a13afd757e99437c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beauty And A Beat",
    artist: "Justin Bieber",
    id: 37027991,
    release_date: "2012-06-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f32f06cd9ed4f674e47a8ebf680aff7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/312ece7b31fb86c7a13afd757e99437c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tender",
    artist: "Blur",
    id: 38442111,
    release_date: "1998-03-15",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-630cdea700e78c32b53a9c6e6e92710a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8ca3605465df71d54cd5204b7d2a38f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Coffee & TV",
    artist: "Blur",
    id: 38442131,
    release_date: "1998-03-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f178920975d4e332e09993fba0a8716-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8ca3605465df71d54cd5204b7d2a38f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Payphone",
    artist: "Maroon 5",
    id: 38934641,
    release_date: "2012-06-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a633b1c69a2b999562e97972c9be5af-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81d7aae96ab55297abdfd5370f6b38a0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Turn Up the Music",
    artist: "Chris Brown",
    id: 39544341,
    release_date: "2014-12-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc215cdae45f88d6f41bbeec29a6e796/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Wake Me Up",
    artist: "Chris Brown",
    id: 39544461,
    release_date: "2012-06-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc215cdae45f88d6f41bbeec29a6e796/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "True Love",
    artist: "Elton John",
    id: 40987781,
    release_date: "1993-11-22",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e15f210a10c4812662eba67a2ff9a956-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd76e50c54c1298735a0ebe320aafd94/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kingston Town",
    artist: "UB40",
    id: 41299901,
    release_date: "1989-09-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eb1dd73115350a5123c7f826aea99431-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22dd28eab628af31319dbcc1873e8085/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "One More Night",
    artist: "Maroon 5",
    id: 44230981,
    release_date: "2012-07-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46a717c140589c27a1fca8c53433fdfc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81d7aae96ab55297abdfd5370f6b38a0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Try Again",
    artist: "Aaliyah",
    id: 44354921,
    release_date: "2008-10-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f2b8db275644efe450e5989bd950eb20/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Spectrum",
    artist: "Florence + The Machine",
    id: 45594311,
    release_date: "2012-07-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed9d47b8b386d0a89766ef8baffa0ccd-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62d6168fd810482d09252025d9f69b0a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Good Time",
    artist: "Owl City",
    id: 53825251,
    release_date: "2012-08-21",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74c9b9f3474d7d1a4e80087ce02736fb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e6ddcd830f7a041f606d7b29864be2e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Love It (feat. Charli XCX)",
    artist: "Icona Pop",
    id: 54420921,
    release_date: "2012-06-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a85e65c79376218e9d62ab46ae7fca0d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ce4269eefe4ffc9aee9c2f47c2be3eb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "How We Do (Party)",
    artist: "Rita Ora",
    id: 55012841,
    release_date: "2012-08-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9603207e962290ea151d7a13e877c887-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d772dcdf53328ad64c7d21113b1e319a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hall of Fame (feat. will.i.am)",
    artist: "The Script",
    id: 55848051,
    release_date: "2012-08-31",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-959796e628b6a493ccddd34aefbd98f3-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/049dca48d45587b1bc5d2c47d7b875e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Blow Me (One Last Kiss)",
    artist: "P!nk",
    id: 60200371,
    release_date: "2012-09-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5326beb7b3126a2bd17f5329f30b6df-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a43e104f2ff2872df2e1bf4207b6b5e6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Candy Shop",
    artist: "50 Cent",
    id: 60722933,
    release_date: "2005-03-03",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af14b7d83985d109600aed54d16e3322-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8550ab66b25e9ccfbe3cad3cdb7d8284/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Just A Lil Bit",
    artist: "50 Cent",
    id: 60722940,
    release_date: "2005-03-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f354d6a3261905aade483525bcc8d2e8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8550ab66b25e9ccfbe3cad3cdb7d8284/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Gangnam Style (강남스타일)",
    artist: "Psy",
    id: 60726278,
    release_date: "2012-09-06",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-57793edd9b2473c82d250249b623793f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45e4493fcc15997085491debddcf049e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Will Wait",
    artist: "Mumford & Sons",
    id: 60742288,
    release_date: "2012-09-24",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9407103d95d6e21af678af5a62d2cb64-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e41dc3e801f3972cc417ed83654a1b11/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Something",
    artist: "Lasgo",
    id: 60831419,
    release_date: "2011-09-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-36ebbd92ef1d2202a1cd6075952d893a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/39a9a6a52c3bce30aa4f3a76a35ef53e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't You Worry Child",
    artist: "Swedish House Mafia",
    id: 60842359,
    release_date: "2015-06-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6af76bb98f7f7dad2b0722d0f69be091-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6e59fada64940a751de6eaa01229e8b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Die Young",
    artist: "Ke$ha",
    id: 60847163,
    release_date: "2014-12-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff22ec58ad90bb8192c694acd3bd9c6f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ca332797349e6a5921abbc7f48152a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Clarity",
    artist: "Zedd",
    id: 60904700,
    release_date: "2013-09-10",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e09502d1e074842cf9a279c5cfdb485d-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b8a51cd4d5e2a277c8a1c4f88d59489/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Diamonds",
    artist: "Rihanna",
    id: 60978718,
    release_date: "2012-09-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00ef53b742b76cb70c83ff62e0f4aed7-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a1b2457fcd12bb9ed5488e4bbd01ec25/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feel So Close",
    artist: "Calvin Harris",
    id: 61142285,
    release_date: "2012-10-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f5933979913bdf6869cb2c682d71313-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3cd154b7ee332e7fb0dc3e8bd4085f6b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I Need Your Love",
    artist: "Calvin Harris",
    id: 61142290,
    release_date: "2013-08-12",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aff54d37f1ce4fe6db06ed8efbff12bb-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3cd154b7ee332e7fb0dc3e8bd4085f6b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Sweet Nothing",
    artist: "Calvin Harris",
    id: 61142292,
    release_date: "2012-10-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-23d25374d6c19f1394746935cf9fe84a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3cd154b7ee332e7fb0dc3e8bd4085f6b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Thinking About You",
    artist: "Calvin Harris",
    id: 61142297,
    release_date: "2012-10-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a5867b02118a8e498b3e6c01a09c66b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3cd154b7ee332e7fb0dc3e8bd4085f6b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Unfinished Sympathy",
    artist: "Massive Attack",
    id: 61381707,
    release_date: "1991-02-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc77ee5767ec8674e9b09caaf76f47f3-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81ba5b6bf32dcbf2e2dae244a7cfab51/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "U Got 2 Let The Music",
    artist: "Cappella",
    id: 61405980,
    release_date: "1993-11-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e250a731120ac110fbe2020ff3d20d27-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dca0975da499b5d0a201bbadc034bd2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Can't Hold Us (feat. Ray Dalton)",
    artist: "Macklemore & Ryan Lewis",
    id: 61424044,
    release_date: "2012-10-09",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6f43d68a3494083edfd0706b30c5d1a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/238f1c36e8445fd162d1d53b8181ecb5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Thrift Shop (feat. Wanz)",
    artist: "Macklemore & Ryan Lewis",
    id: 61424045,
    release_date: "2012-10-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbefa0e25844fe4bfed436cdd14547d3-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/238f1c36e8445fd162d1d53b8181ecb5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Same Love (feat. Mary Lambert)",
    artist: "Macklemore & Ryan Lewis",
    id: 61424047,
    release_date: "2012-10-09",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88f6412c07409c67ac04e8d5968e42a0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/238f1c36e8445fd162d1d53b8181ecb5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Girl on Fire",
    artist: "Alicia Keys",
    id: 61542462,
    release_date: "2012-11-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c8b06c89386cef8de715bcb17d3410c3-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/36c185941d3fd043ff9861d89040ba46/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Live While We're Young",
    artist: "One Direction",
    id: 61644178,
    release_date: "2012-11-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a2df1d712c921527c36fb7c1fc66c7d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come With Me : Come With Me",
    artist: "Special D.",
    id: 61675981,
    release_date: "2004-08-02",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-159027f0eaef45a528e3bc107d19d01a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42b418c533629608f88853d1105164cf/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Let Me Love You (Until You Learn To Love Yourself)",
    artist: "Ne-Yo",
    id: 61793545,
    release_date: "2012-11-06",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ee94b4cd64d1192633f5913eb22f576-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/13a5fae9ef2b362718fd963d02af49d5/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Let Her Go",
    artist: "Passenger",
    id: 61860867,
    release_date: "2012-11-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66107fa004b1e7aaeb40d73f03f0c9a8-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cd9cdfd35a7596625b0d6bf273c1f646/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Leave (Get Out)",
    artist: "JoJo",
    id: 62080234,
    release_date: "2005-09-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41539bc6d603aa89959f2ef31cb25118/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ooh Aah (Just a Little Bit)",
    artist: "Gina G",
    id: 62166934,
    release_date: "1997-11-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f376afe41ca5983862adfb1042bd2e7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everytime You Need Me (Radio Version)",
    artist: "Fragma",
    id: 62172333,
    release_date: "2001-01-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-016b1028afe7bd4f9e457a70e98b5f67-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7ce9233e857aa2dca89b169b79c5eda/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Stay (Album Version)",
    artist: "Rihanna",
    id: 62376291,
    release_date: "2012-11-19",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a11d7ebc8a3f7f4245c6915bafbd241-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/246fb21e05da3d1460eb147123bb906b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ain't No Other Man",
    artist: "Christina Aguilera",
    id: 62417658,
    release_date: "2006-08-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f981b3c1c16073eed24b3cda25fd5a8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3aa572afd198583c88297022bf2d9ddb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Something Kinda Ooooh",
    artist: "Girls Aloud",
    id: 62418649,
    release_date: "2012-11-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c2cd1df1907590734c48d283d3531ee6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ccac386ee2a76f429fab05ba71acd99f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Scream & Shout",
    artist: "will.i.am",
    id: 62439051,
    release_date: "2012-11-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bbc64550c95ad5f83991dd2f0b9a0c7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6da4573b0e3ef016f7618214a466eed2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Star Girl",
    artist: "McFly",
    id: 62471655,
    release_date: "2012-11-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba3ad2d94e95113104212afa6f3c6835-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4762dda7d47537a9759fa0b680a0c9a0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Five Colours In Her Hair (U.S Version)",
    artist: "McFly",
    id: 62471686,
    release_date: "2012-11-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4814982eb13fd2def976a1fa309f17f0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4762dda7d47537a9759fa0b680a0c9a0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Work Hard, Play Hard",
    artist: "Wiz Khalifa",
    id: 62557258,
    release_date: "2012-12-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b294f1309c20a590e59e9427055fdcd4-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d6583447f0da9284bec73517752253d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I'm Free",
    artist: "The Soup Dragons",
    id: 62637202,
    release_date: "2012-12-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-12c9d9424456d3982b36ea7f979d42c0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef0b7e6071137d72900f460670b88c42/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Locked out of Heaven",
    artist: "Bruno Mars",
    id: 62723995,
    release_date: "2012-12-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd276ff814d66d2707d5f8bbbef2d911-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ae8e9f1714cc6cc9393f7873008f45c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Treasure",
    artist: "Bruno Mars",
    id: 62724017,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ee8eb9d17ee715e12b278d21fc4b45a9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ae8e9f1714cc6cc9393f7873008f45c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When I Was Your Man",
    artist: "Bruno Mars",
    id: 62724019,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b7aaf57e2aa777e6c64da14ad741754-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ae8e9f1714cc6cc9393f7873008f45c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Titanium (feat. Sia)",
    artist: "David Guetta",
    id: 62847142,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79335512785dc23ab9515031e03adb08-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "She Wolf (Falling to Pieces) [feat. Sia]",
    artist: "David Guetta",
    id: 62847144,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac02b5452efad6edcc78fa504c15c607-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Without You (feat. Usher)",
    artist: "David Guetta",
    id: 62847145,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bce6d59b44c8263ebc43e2377bbdf737-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Where Them Girls At (feat. Nicki Minaj & Flo Rida)",
    artist: "David Guetta",
    id: 62847150,
    release_date: "2012-12-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f17b96a445ebc8c1a28882cbaa9dc83a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Pompeii",
    artist: "Bastille",
    id: 63034985,
    release_date: "2013-02-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1233704c8226fa765c6a3e6d3f906cde-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d5984bac3f7865ea5a92a30221d5386/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Radioactive",
    artist: "Imagine Dragons",
    id: 63510358,
    release_date: "2013-02-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b53be55456ff326e9c2a7bf1d0abe601-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e8314f4280cffde363547a495a260bc/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "It's Over Now",
    artist: "112",
    id: 64471365,
    release_date: "2013-02-12",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-520a7fcc2f4517dc0ffee1b490cd2118-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fab66532af65edbee7b56c6547ff315/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Peaches & Cream",
    artist: "112",
    id: 64471366,
    release_date: "2013-02-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4bcde9ae4b4c58ad95ccedea59c4bb61-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fab66532af65edbee7b56c6547ff315/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Harlem Shake",
    artist: "Baauer",
    id: 64734097,
    release_date: "2013-05-22",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74241497c9eb6f55c848901f7915f9b6-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd39494987420479d7952267463aec97/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Heart Attack",
    artist: "Demi Lovato",
    id: 64971011,
    release_date: "2013-02-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-417e36026ee86689c37d21e3119de069-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/da96fc0fcad01a573790d1eca9945d39/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Most Girls",
    artist: "P!nk",
    id: 65186996,
    release_date: "2013-03-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ca7e12ec13ccf1fa48a14b24ee1cc69-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9cec6bbe4133d681c0866b3102b11ec7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Happy Ending",
    artist: "Avril Lavigne",
    id: 65191248,
    release_date: "2004-02-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/72b94c4a72d2161e12ceba6ca66a1815/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Feeling",
    artist: "Flo Rida",
    id: 65232772,
    release_date: "2011-08-29",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-105d7f89aba9d3e8fdd8053361bb72fc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4d99ed71d4020effadd12d9ae1e61933/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Suit & Tie (feat. Jay-Z)",
    artist: "Justin Timberlake",
    id: 65356217,
    release_date: "2013-03-15",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4843c434ca56a8e9a8630a4d0c645f60-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f409c33ab781a565ab46f06ba3415a31/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mirrors",
    artist: "Justin Timberlake",
    id: 65372358,
    release_date: "2013-03-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f295d2883bbadbc6065e56cc9b21720-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/30e4c3888332f3c24fd4a9168a705272/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hard To Handle",
    artist: "The Black Crowes",
    id: 65707258,
    release_date: "2013-04-16",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-449c983faa49bdbe2e6dad1eea6761af-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/706af33605b63bd4dc5ee8ad7747ebac/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Another Love",
    artist: "Tom Odell",
    id: 65723649,
    release_date: "2013-06-24",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-59a65b4e38b7911386a2f4adbe24893b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/23d34526dc6ef2073bb854fc91a25112/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Counting Stars",
    artist: "OneRepublic",
    id: 65759979,
    release_date: "2013-09-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-89e3858361362cf5ce1f8d223ef21197-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75cbabada297707bac115ea3a1d50bb5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Who Am I (What's My Name)?",
    artist: "Snoop Dogg",
    id: 65938186,
    release_date: "1993-10-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88c9788f57310f977edd649a17114cf8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Fly High",
    artist: "Jim Jones",
    id: 66023308,
    release_date: "2015-02-04",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac71afbc8fb3eb77305d92f16a838bca-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e2789c2731db83764d3f60f1ee63e19/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Cha Cha Slide",
    artist: "Mr. C",
    id: 66028747,
    release_date: "2014-12-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f6513d2b19fa666333b6eded3dd5fea-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a04f2e6fa10ef70fc88201bcc0361b0f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love On Top",
    artist: "Beyoncé",
    id: 66190255,
    release_date: "2013-03-29",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e36067b451efc0153f588540407d7a4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc927a6b67e8a16351a3de87ba94328c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Best Thing I Never Had",
    artist: "Beyoncé",
    id: 66190266,
    release_date: "2013-03-29",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d9bed8c64b24941377516d9978fcd0b0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc927a6b67e8a16351a3de87ba94328c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Porcelain",
    artist: "Moby",
    id: 66266869,
    release_date: "2008-06-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5e06e2bf8f6f7752a40d6ec2d5de254-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77154158a26e46531b1998ed2c3ea140/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Natural Blues",
    artist: "Moby",
    id: 66266874,
    release_date: "2008-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d386e4b294fd5a4829d23e3871c18b8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77154158a26e46531b1998ed2c3ea140/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Safe And Sound",
    artist: "Capital Cities",
    id: 66314771,
    release_date: "2013-04-05",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fba9d3c7ea8d606edfe6966ba5240d7e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come & Get It",
    artist: "Selena Gomez",
    id: 66404487,
    release_date: "2013-04-09",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-69222b5cee078a7343ca0112cd80eaf5-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a5859340c44432bb59958b147d2aad1d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Songs Know What You Did In The Dark (Light Em Up)",
    artist: "Fall Out Boy",
    id: 66404564,
    release_date: "2013-04-16",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c412c40cd49f4880ca8659b1b1772b40-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/265ba23705271a65f0633cd7a7444bed/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Waiting All Night (feat. Ella Eyre)",
    artist: "Rudimental",
    id: 66554899,
    release_date: "2013-04-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b8eefb185497474be21a617f2648faa6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5dbea837a03edb5dc3dabc47c03dbf1/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Stop Movin' (Radio Edit)",
    artist: "Livin' Joy",
    id: 66601211,
    release_date: "1996-07-12",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b9216114e6fe18cdf04375c041bdc3c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get Lucky (feat. Pharrell Williams & Nile Rodgers)",
    artist: "Daft Punk",
    id: 66609426,
    release_date: "2013-04-19",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bdab5f5d846a91f14a01b75731dbc22a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc49adb87758e0c8c4e508a9c5cce85d/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Young And Beautiful",
    artist: "Lana Del Rey",
    id: 66660698,
    release_date: "2013-04-22",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af92b8964712d00c05a944a0fdaeb39c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e45246b90badf4bd3a9a994729433bf8/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Don't Call Me Baby",
    artist: "Madison Avenue",
    id: 66881687,
    release_date: "2011-12-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e274b0e1f645e819446333710b6273be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0e3ee8873febbd48c52e0629705523e/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Sexual Healing",
    artist: "Max-A-Million",
    id: 66975897,
    release_date: "1997-06-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f4d64858d558bef6d93c898d814dba0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "La La La",
    artist: "Naughty Boy",
    id: 67041323,
    release_date: "2013-05-20",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3a11ab04630e8f6251b3a898477fed1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0059a4597222a502c8c298819f2d3f8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lisztomania",
    artist: "Phoenix",
    id: 67239341,
    release_date: "2013-05-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9aa7c2d5711a7b042a24efbd96606d53-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5bfd635c3bf252c6c0d3e3e4b8f53794/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Really Don't Care",
    artist: "Demi Lovato",
    id: 67320697,
    release_date: "2013-05-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-69a31117124b7fc8b7502eb71cf271ff-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6e49efbd01fe2cd9cfc481a5edd4da7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Riptide",
    artist: "Vance Joy",
    id: 67354825,
    release_date: "2013-09-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2150a01983b8283c4a43c614ed2b6de2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93b43ae51ab68cbe154220f1d3b8a942/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Love Me Again",
    artist: "John Newman",
    id: 67446406,
    release_date: "2013-07-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-620605a4390dcb636ba9682799324669-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9cfd261e3d2aac39bffdf87d0eb61400/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Home",
    artist: "Phillip Phillips",
    id: 67446814,
    release_date: "2005-02-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fa29dc2c5ba489bdfa78a7fa6e75a617-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/909347a5bd004b27e669069589ed5e13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "White Noise",
    artist: "Disclosure",
    id: 67563938,
    release_date: "2013-01-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/852c5b27f0d710e29c7be01a33608747/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You & Me",
    artist: "Disclosure",
    id: 67563944,
    release_date: "2013-06-03",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/852c5b27f0d710e29c7be01a33608747/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Counting Blue Cars (Studio Version)",
    artist: "J.R. Richards",
    id: 67794719,
    release_date: "2014-02-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fb99876e620c85ec358b1a71a5dfd5c3-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fed4bda69aaaf9ac5c0427e267d6c0a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "We Can't Stop",
    artist: "Miley Cyrus",
    id: 67928750,
    release_date: "2013-06-03",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/927419c821ded69c31c945446714ace0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Backseat Freestyle",
    artist: "Kendrick Lamar",
    id: 67929155,
    release_date: "2013-06-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d3c453fe8facc3f8dcc47bcfdd6a0afd-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b5be27644d505bad7bdb516fe4165475/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "808",
    artist: "Blaque",
    id: 67943130,
    release_date: "2007-05-21",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ddcda8dcc15bdad046594a277404ed3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31341ee01666b9d7240ee3ca6ce5600b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Mouth",
    artist: "Merril Bainbridge",
    id: 68024830,
    release_date: "1994-09-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/313111071a77a9aaea3c406066021d11/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gonna Make You Sweat (Everybody Dance Now)",
    artist: "C+C Music Factory",
    id: 68075245,
    release_date: "1990-12-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6369a683762ea46ae176744a80892a1b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/480c4177106ac283accd9cb35198b43d/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Black Skinhead",
    artist: "Kanye West",
    id: 68350105,
    release_date: "2013-06-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-944c0016e6ee77a238e6aa824f99bdcc-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a56530f7906bd9786fa47bd0be421b3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Burnin' Up (Album Version)",
    artist: "Jonas Brothers",
    id: 68414905,
    release_date: "2013-07-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/95c392f46192413ca204f232b16f7779/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "1979",
    artist: "The Smashing Pumpkins",
    id: 68976286,
    release_date: "1995-10-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-afac376efc72740e2b3c463612baef79-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c64acd4abaf6e8f2d7c895b4b73f3764/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Beautiful People (feat. Benny Benassi)",
    artist: "Chris Brown",
    id: 69295402,
    release_date: "2013-07-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-607a6d42051f1f182ea88add5d6d9893-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/041de2e983e6fd6539f042a66e2962e8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Summertime Sadness (Lana Del Rey Vs. Cedric Gervais)",
    artist: "Lana Del Rey",
    id: 69419038,
    release_date: "2013-07-31",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-efb17fde66866a8996879a9c1e663aba-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d0643dae51cf195717246ad9341c5b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Not an Addict",
    artist: "K's Choice",
    id: 69830287,
    release_date: "1995-02-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-32b41ec4ad1d4ba43372afb0dca5fd98-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/96766dc00360ac19e4150a842ebd65be/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Everybody Everybody",
    artist: "Black Box",
    id: 70051866,
    release_date: "1990-04-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a071011cf02e46f50b89cc6e7908e5e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad31d939346f5770e86af0622c595d32/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All of Me",
    artist: "John Legend",
    id: 70079770,
    release_date: "2013-08-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6e20c7dcb6388b1d013c19e9a3983d9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9a170ca0ae06918f9baa6cb109fb321/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Wrecking Ball",
    artist: "Miley Cyrus",
    id: 70100098,
    release_date: "2013-08-25",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/811459eab745b63e17252a5dd41baa06/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lady (Hear Me Tonight)",
    artist: "Modjo",
    id: 70179720,
    release_date: "2013-08-19",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ae408da9e670b3c0bfa4eb026b2d5e4-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0cc5f4e1e3aee3eb30546097bbfadb60/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Things That Make You Go Hmmmm.... (feat. Freedom Williams)",
    artist: "C+C Music Factory",
    id: 70196653,
    release_date: "2000-02-28",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac42a06f2c5cd1db3fb02907647a1ebb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/480c4177106ac283accd9cb35198b43d/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Wasting My Young Years",
    artist: "London Grammar",
    id: 70206826,
    release_date: "2013-09-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/615db2012ca3877aee3da15e5e7f7017/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wake Me Up",
    artist: "Avicii",
    id: 70266756,
    release_date: "2013-09-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9ab2ab24219594a5c8009df906fba81e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec97306735b46ec334e0ce562290775b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Do I Wanna Know?",
    artist: "Arctic Monkeys",
    id: 70322130,
    release_date: "2013-09-09",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f1dc690a43d4c7914384b4d61636d2b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64e54e307bd5e2bdb27ffeb662fd910d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Why'd You Only Call Me When You're High?",
    artist: "Arctic Monkeys",
    id: 70322139,
    release_date: "2013-09-09",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c12d329942225261a987fc3f1edef4cd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64e54e307bd5e2bdb27ffeb662fd910d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Blurred Lines",
    artist: "Robin Thicke",
    id: 70335406,
    release_date: "2013-07-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a27d6a8268414cb60805e9ca1f0a699-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3479d5d0a070eae22f7b67ddb715f49e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Royals",
    artist: "Lorde",
    id: 70403437,
    release_date: "2013-03-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f7a463d7c928f017d69aaa7f2b810e8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bb0b356418fbb275c0c3db7259128d7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Team",
    artist: "Lorde",
    id: 70403440,
    release_date: "2013-09-30",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-356139bca2d934df4a284651ffd4189c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bb0b356418fbb275c0c3db7259128d7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Hey Porsche",
    artist: "Nelly",
    id: 70625532,
    release_date: "2013-09-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-15c45f81619af3c6f641da1b69b67ed4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2edfed19af239636bd719b8fc2202fa8/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The Power of Love",
    artist: "Céline Dion",
    id: 70627953,
    release_date: "1993-11-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-acedf78ad489e0663a2eb4913edee940-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4e0abf3f7a4f4ce8cba4af2cbd6416d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ho Hey",
    artist: "The Lumineers",
    id: 70834770,
    release_date: "2013-10-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c3de4697083ee5929d290d7cd7eac5a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e1b328ab275f0ff34e0d478c739a89aa/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Automatic Lover (Call For Love)",
    artist: "Real McCoy",
    id: 71180726,
    release_date: "2003-06-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-19c775c49e544aa7035bb1b5be6d4b5f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55765184bd90adab249b765dd91cd77a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's the Way You Make Me Feel",
    artist: "Steps",
    id: 71400923,
    release_date: "2000-10-30",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ecafa2946ee6d24a9f570b9e0acb3ae-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a148dfe850b0dbb574b10629fee98a38/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Wish (LP Version)",
    artist: "R. Kelly",
    id: 71481397,
    release_date: "2000-11-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74d34d18783e6b96afa5d3a7d8c3ee7a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/953d0d09a35af90735caab67b83c9a6d/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Dark Horse",
    artist: "Katy Perry",
    id: 71645436,
    release_date: "2013-10-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c6cbd2f57e6d14efb0403b8fc44fef6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe781ecd9879a82beed80f6d3e80745b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Take You There",
    artist: "Sean Kingston",
    id: 71751482,
    release_date: "2008-08-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a874cbe6a4210b6df2b25a7f376aa88f-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c4637431d399e0546767cae6d8d7a85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sober",
    artist: "P!nk",
    id: 71849861,
    release_date: "2008-10-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-841fcc9158e78b1c5250718a4f1ce110-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57010d59449040db067e5e62e49b7d42/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Life Would Suck Without You",
    artist: "Kelly Clarkson",
    id: 71901172,
    release_date: "2009-03-09",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ef722f2c34a07ae56268097b2e1b131e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b53f0f2d5a73769e29c6b01629edf58/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Monster",
    artist: "Eminem",
    id: 72059773,
    release_date: "2013-10-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57ba8f36a09d07be40cf778dfa923e49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Next To Me",
    artist: "Emeli Sandé",
    id: 72283603,
    release_date: "2013-11-18",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79c06b5b2965de854fdfcc168478abfc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b52901777db8faba9536ae0795088f20/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say Something",
    artist: "A Great Big World",
    id: 72299954,
    release_date: "2013-11-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0925babc3db685266a6bc5fbe9d99a61-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eb5b766197ec98b8df0fafec11b50266/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lily Was Here (feat. Candy Dulfer)",
    artist: "David A. Stewart",
    id: 72308297,
    release_date: "1990-06-08",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bb13e7548bc47b2e7e5dacbdb834aed3-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20b07f7266dd4fb1bcfbe6424c70ff51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Timber (feat. Ke$ha)",
    artist: "Pitbull",
    id: 72314234,
    release_date: "2013-11-25",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4236638d0c0803d119d91e1124cbf15-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b304ae156680756fa7290aeef0f32704/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Is What It Feels Like",
    artist: "Armin van Buuren",
    id: 72327967,
    release_date: "2013-05-03",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a1b9e5d073b9a2c68d88588acdc5fe09-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c11a0aa8f4bf63c10a86ff32942e9e2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Best Song Ever",
    artist: "One Direction",
    id: 72544948,
    release_date: "2013-11-25",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a89cfcc9a98fd72de3afd2b183650918-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/620bcd9a3310b1bfaba639b060b54abf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Story of My Life",
    artist: "One Direction",
    id: 72544949,
    release_date: "2013-11-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4dc28238148e53db19c99a0b81c28c1f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/620bcd9a3310b1bfaba639b060b54abf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Right in the Night (feat. Plavka) (Fall in Love with Music)",
    artist: "Jam & Spoon",
    id: 73133012,
    release_date: "1993-12-13",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2162aa56b25bf4fd822b73cd4643d2ed-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/74e4aa76edd35a7725e324b34132aa41/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Can We Dance",
    artist: "The Vamps",
    id: 73145938,
    release_date: "2013-09-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a0d024d64270e2a0fd94d3abb926f5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'Happy (From "Despicable Me 2")',
    artist: "Pharrell Williams",
    id: 73616508,
    release_date: "2014-06-23",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b4ff500ac3523e2ce881818f9ffb1879/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Turn Down for What",
    artist: "DJ Snake",
    id: 73707710,
    release_date: "2013-12-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fdf94fcc0f53773e580c8ab9a7d7cf5c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82c139e154a40073542914dfed468474/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Drunk in Love (feat. Jay-Z)",
    artist: "Beyoncé",
    id: 73724283,
    release_date: "2013-12-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2db8cd5736f646fded2ff2d19042a9d0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd920280eb3de243b3d0d7b313428c13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rather Be (feat. Jess Glynne)",
    artist: "Clean Bandit",
    id: 73982869,
    release_date: "2014-06-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d2e261169f82dd2789697fd19ed572b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3193132d50c74a62d1cd419fa170139a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "In These Arms",
    artist: "Bon Jovi",
    id: 74628109,
    release_date: "2007-04-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fa5433b825e01220cb877e26b9da6ef8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2821c5cfa61955ddd982c15cc21882/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Stay High (Habits Remix)",
    artist: "Tove Lo",
    id: 75072795,
    release_date: "2014-03-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c2821d15387f0b91713bdb323e658486-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/234f7e4100c8764ac365f61715882b0b/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Latch",
    artist: "Disclosure",
    id: 75526535,
    release_date: "2015-05-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e46c5abf2d234dd93db4797f137c554f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e44468007c45f2523d056a0b19eed80a/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Best Day Of My Life",
    artist: "American Authors",
    id: 75711295,
    release_date: "2014-03-03",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dce57d102576f1d8526977fd9f05021e-25.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b965a1aa1b44e52fa89ab7b393a80eeb/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Magic",
    artist: "Coldplay",
    id: 75716617,
    release_date: "2014-03-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-426e3b8cffd45d934921468c9c26053d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3539e28c608c6376d63511a4426e4e54/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Live Like You Were Dying",
    artist: "Tim McGraw",
    id: 75766544,
    release_date: "2010-11-30",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-34da5c2174537d5b3598cc239cc9fb08-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be12b009324217afcd78a385c071c61e/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Who Do U Love",
    artist: "Deborah Cox",
    id: 75817990,
    release_date: "2014-03-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-825fadc969a509df3daffb348a6e76d7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/963c4ea359622492ebaf01a83da663fe/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "She Wolf (Album)",
    artist: "Shakira",
    id: 75834066,
    release_date: "2014-03-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-696234a467163429cadd6bba8e11356a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3686fe46b249a6c0f51809cd5685a024/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dare (La La La)",
    artist: "Shakira",
    id: 76066730,
    release_date: "2014-03-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad17bc162663a06dd9316c9ee6bdc4e5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Dreams",
    artist: "La Bouche",
    id: 76461356,
    release_date: "1994-11-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f226133b176adbfbd02afd23527231a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75afd62e80bf375f4ee5f6945b2e1367/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Will Never Let You Down",
    artist: "Rita Ora",
    id: 76520904,
    release_date: "2014-03-31",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-debd4c13070f784ba36c0a8c8ea5a8fa-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f2c11f9d5209af2dc7e6f14c9a2c4a0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Other Side",
    artist: "Jason Derulo",
    id: 76734208,
    release_date: "2013-09-10",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1b65a9c1053c6907c30423e37091cf2-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f292a9003f7ca21ae6e5eaf37dae0083/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy for You",
    artist: "Let Loose",
    id: 77325534,
    release_date: "2014-08-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db9aa10954d192eddc3848ec6fbaa5f6-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/857dac1ff9578423e2c6f7460eec58ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fancy",
    artist: "Iggy Azalea",
    id: 77349246,
    release_date: "2014-04-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6ac47cbfab378ebc12ea515ab47bd5c-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2d85012dcf46070934d394bb35be64d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Black Widow",
    artist: "Iggy Azalea",
    id: 77349251,
    release_date: "2014-04-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07edd0e5e29acf1a6b3c9c65598ae395-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2d85012dcf46070934d394bb35be64d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Problem",
    artist: "Ariana Grande",
    id: 77725101,
    release_date: "2014-12-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7103da477873028a72b0ad5e5ba467e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shower",
    artist: "Becky G",
    id: 77861338,
    release_date: "2014-04-23",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbf8b3974c4fce2c5ea8a61bb712890a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c646208f455371fc46c45078a414fc7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cheerleader (Felix Jaehn Remix)",
    artist: "OMI",
    id: 78098991,
    release_date: "2014-05-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-64275e4ce05208d18c9e33fc40ba0967-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7f8e1e80d7b9d66ffddf7f457375ccf7/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Love Never Felt So Good",
    artist: "Michael Jackson",
    id: 78213551,
    release_date: "2014-05-09",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2e8d4c54f9b0fc08cdc6622b09f6191c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fad0dd777fb660bb1c3388334afd8419/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stay With Me",
    artist: "Sam Smith",
    id: 78282330,
    release_date: "2015-02-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d229b5c0c19b5cb6654c8680236319d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/44e0fbe81ca549290348d3544375e29a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Not The Only One",
    artist: "Sam Smith",
    id: 78282333,
    release_date: "2014-05-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66b3b13a1cb27f7dbb5b3fcb139fb294-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/44e0fbe81ca549290348d3544375e29a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Colorblind",
    artist: "Counting Crows",
    id: 78934726,
    release_date: "2008-03-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b70f3eb108a47fb89a620a2927228ce3-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d856fe41f25e299f2caddc777bbfef4c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ugly Heart",
    artist: "G.R.L.",
    id: 79036884,
    release_date: "2014-06-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-253f5ddc20f096ae5f1cf579a5c8dc3c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f1f6b9faa56c06617523afbcac4c3a6/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Maps",
    artist: "Maroon 5",
    id: 79542042,
    release_date: "2014-06-16",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45efcf300bd082c33954a5d08e729fa4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Chandelier",
    artist: "Sia",
    id: 79587580,
    release_date: "2014-07-04",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6950b39ce61fbcc6bff89d32f6d1ff7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4275db12f5e284b3c2f3e20726187b64/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Runaway (Radio Edit)",
    artist: "Janet Jackson",
    id: 79588928,
    release_date: "2001-01-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecc4dd3edd69606fde71fc67132ae014/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boom Clap",
    artist: "Charli XCX",
    id: 79590714,
    release_date: "2014-06-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-38ad12853ad1110d1aee7f1aa6bfc0af-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e87d1f644da8067dda2284e582602900/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rude",
    artist: "MAGIC!",
    id: 79845486,
    release_date: "2014-07-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff8560a93a6bf660c99b94316d53470e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a393ea2d5dafca17cb80c3258a47a9ee/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Don't",
    artist: "Ed Sheeran",
    id: 79875050,
    release_date: "2014-06-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c9f2d4d9410bc20344a80cb2e5310ef0-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1d763da698c38bde6a526c8220ca0ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Photograph",
    artist: "Ed Sheeran",
    id: 79875054,
    release_date: "2008-06-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ebc5cfa3c7e0fbd4efd668f3a07f17c1-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1d763da698c38bde6a526c8220ca0ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bloodstream",
    artist: "Ed Sheeran",
    id: 79875056,
    release_date: "2014-06-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-080fb0afa7db99d4a18aeec307502815-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1d763da698c38bde6a526c8220ca0ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stay The Night (Featuring Hayley Williams Of Paramore)",
    artist: "Zedd",
    id: 79942796,
    release_date: "2014-07-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bd28bc80e52cc8af656a7fe62b854ff-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d1941bbbb615993e9ca18d7710dca024/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "She Looks So Perfect",
    artist: "5 Seconds of Summer",
    id: 80077244,
    release_date: "2014-06-30",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d948d840676c73f30e6bd54c361321dd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/607edd6e04268585206f8f42ca760de0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hideaway",
    artist: "Kiesza",
    id: 80194320,
    release_date: "2014-07-07",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b187589f9fb8d6fb9dca348369fd7b28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e82b7ee9a70e690a0d927a6df8411176/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Call Me When You're Sober",
    artist: "Evanescence",
    id: 80273734,
    release_date: "2006-09-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bc4eca578fd899fa134f80c570d66152-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ff74ac5b35955c8d4fe115ac1d1f41f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Higher",
    artist: "Creed",
    id: 80274464,
    release_date: "1999-02-14",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d96d5d5d30474394d3200ae42760eb2c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7c5e2402a3bcb04b871d503087ef56da/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Paralyzer",
    artist: "Finger Eleven",
    id: 80275000,
    release_date: "2007-03-06",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2aec631b23c72b70b9cdb98cad43676-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/302f0325f79a75a76f778ee0a80dee48/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fake It",
    artist: "Seether",
    id: 80276490,
    release_date: "2007-08-28",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d3cbf33c4c4b3d4aec3ff19130d1cbd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b828bdaa512c9061bb6df1a9255142b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Break Free",
    artist: "Ariana Grande",
    id: 80648074,
    release_date: "2015-06-07",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7469b198c271e3a00f5994de0e8e4fbe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bang Bang",
    artist: "Jessie J",
    id: 81609384,
    release_date: "2014-07-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-098293742a7e0cd59f3136419e9af04a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e436fcd3350dc5fe2adbf59516f6cbe7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Womanizer",
    artist: "Britney Spears",
    id: 82199332,
    release_date: "2008-12-02",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a274f062bccc252f52d574f1b75d623c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/16d60157d51a9e21ffac61b50a7d02d0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Circus",
    artist: "Britney Spears",
    id: 82199334,
    release_date: "2008-12-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88ca289010680d206f31a55cacb284f0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/16d60157d51a9e21ffac61b50a7d02d0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Smooth (feat. Rob Thomas)",
    artist: "Santana",
    id: 82241402,
    release_date: "2011-02-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94aaceeb29fcd6b86c4cdc2135df6d2c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cool Kids",
    artist: "Echosmith",
    id: 83756190,
    release_date: "2014-08-15",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-18479f0212c52a72819d39a0273dd8c3-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7f50aafeda73533575225e471bd295ca/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Amnesia",
    artist: "5 Seconds of Summer",
    id: 83939103,
    release_date: "2014-09-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3adecb7d4a531c0280b7e57433e6f41f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02de0988cdbc1d4a221a8b5011a03a93/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Talking Body",
    artist: "Tove Lo",
    id: 84669123,
    release_date: "2014-09-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f46749d8a9a93ab0077b4280b4998a98-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bca07a0bfaaf112657e078b3cbc48d82/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Take Me To Church",
    artist: "Hozier",
    id: 84791735,
    release_date: "2014-10-06",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3900c5e9ba62090e8ae9a04cb693e64-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8442e9ac0227a07b00c9dfd0ec00032d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Calling Elvis",
    artist: "Dire Straits",
    id: 84843605,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-48786fe652bf87138adeb62f8036e251-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c99199e34fd23973d9e023820e2a1b7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heavy Fuel",
    artist: "Dire Straits",
    id: 84843617,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-329401975a98b7f0ee443229ccad31f3-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c99199e34fd23973d9e023820e2a1b7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Budapest",
    artist: "George Ezra",
    id: 85918455,
    release_date: "2015-05-04",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e9bcc54894581394efceba747feb05c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "A Sky Full of Stars",
    artist: "Coldplay",
    id: 86044539,
    release_date: "2014-05-19",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1f0b2a043558e50b0522adb2ba13bb0-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58669ace38d333770941332f8d860dc1/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Am I Wrong",
    artist: "Nico & Vinz",
    id: 86646433,
    release_date: "2014-10-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f48306ec049862c6bb753596a33b325f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e351fc0509a96a598ae8c89fb0d0d530/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dangerous (feat. Sam Martin)",
    artist: "David Guetta",
    id: 87371673,
    release_date: "2014-10-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6103e327c4429fc330d448a29e05e2da-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1be5c19b38770609a0884b988d7094c4/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I Promised Myself",
    artist: "Nick Kamen",
    id: 88310763,
    release_date: "1992-05-25",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9098a24729e4b9a456d56a660e9d66d8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e82b704a2551a383ae70736b151a8da6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Like Chocolate",
    artist: "Shanks & Bigfoot",
    id: 88806539,
    release_date: "1999-08-02",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fe1a66f0c14d7b8e979d1e97aa785de/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tipsy (Club Mix)",
    artist: "J-Kwon",
    id: 88897871,
    release_date: "2004-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-600331e81a9e029627cdadf4b7179387-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e4b8c4332328521ec4ed16b4b9e26b1/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Summer",
    artist: "Calvin Harris",
    id: 88936747,
    release_date: "2014-03-14",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4bbcbad7c63500139522852bbb0cd9e7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42e13c6a1f25fd821407b4d4762eb3ce/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Sandstorm",
    artist: "Darude",
    id: 88954303,
    release_date: "2007-04-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca9cc547764659df60e604984017a088/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cry For You",
    artist: "September",
    id: 88995553,
    release_date: "2008-02-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f409d1d4f83bc0276b320b2bb3041ba4-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/586a3d7a95e84e0dbe707639455e7d56/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Blank Space",
    artist: "Taylor Swift",
    id: 89077547,
    release_date: "2014-10-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6e5160a0eb0a1e062f294a21148fd2fc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68b4e986958b17f05b062ffa8d7ae114/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Style",
    artist: "Taylor Swift",
    id: 89077549,
    release_date: "2014-10-27",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46db1198976b4002b5825d5012796686-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68b4e986958b17f05b062ffa8d7ae114/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jealous",
    artist: "Nick Jonas",
    id: 89128471,
    release_date: "2014-11-10",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bae2b329478805338f94d23bab52a785-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/375942fe5d0cf64de22ef7e8f9924978/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Are You With Me",
    artist: "Lost Frequencies",
    id: 89458301,
    release_date: "2014-11-17",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dee82fee12e2295a0e64846208cbb48f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let It Go (From 'Frozen' / Single Version)",
    artist: "Demi Lovato",
    id: 89824505,
    release_date: "2013-11-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66bcac9897d8f3513ab01aae7d746386-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e4264ac960d380a45992d93d9ee9930f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mama Do (Uh Oh, Uh Oh)",
    artist: "Pixie Lott",
    id: 89825089,
    release_date: "2014-11-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3145854921eebaa3a7d41a6f0ffb3c31-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f3e878d31dc253cc380e7cc1344c5275/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Steal My Girl",
    artist: "One Direction",
    id: 90248129,
    release_date: "2014-11-17",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-64545c52043277754f422cd595573bbf-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/44d6c40a5d127528c991b1923948d4bd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shut Up and Dance",
    artist: "WALK THE MOON",
    id: 90326361,
    release_date: "2014-11-28",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-798d4dc4ad26e725ca6b44e973837873-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fd07406ef0cfa6dab31fd68d71eb2b7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Tragedy (Radio Edit)",
    artist: "Steps",
    id: 90630567,
    release_date: "1976-05-16",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6abb1de3c3a72c1b44aa32f34bb69720-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/23200f2a6cecb3df661cd187a6889dba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Infinity",
    artist: "Guru Josh",
    id: 90630583,
    release_date: "2014-11-28",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ead0161bd29c89d8b335a896eb902bc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/23200f2a6cecb3df661cd187a6889dba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Still Haven't Found What I'm Looking For",
    artist: "The Chimes",
    id: 90630633,
    release_date: "1987-05-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42125795b165df9efd2cc0ccec82bd22-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/23200f2a6cecb3df661cd187a6889dba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nobody Knows",
    artist: "The Tony Rich Project",
    id: 90630707,
    release_date: "2014-11-28",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f322a1324dce999fc84eccba4bd2939b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/23200f2a6cecb3df661cd187a6889dba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Firestone",
    artist: "Kygo",
    id: 90726629,
    release_date: "2015-08-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1262f338e190e41d5e623f3d7200d05-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28a8beab24b92bcbbd1e80df83c4bd24/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Around The Way Girl",
    artist: "LL COOL J",
    id: 90806063,
    release_date: "1990-05-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-75c9c1da2d9fb1d0d51ad27e3096db04-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a50b1e25b7d176948fb3be9274c95dc/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dani California",
    artist: "Red Hot Chili Peppers",
    id: 91442720,
    release_date: "2006-04-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fdbf699c18913829bcb51fa7d9fbbf27-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca9441e904f0f09044fb514bbfc49089/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Anaconda",
    artist: "Nicki Minaj",
    id: 91543346,
    release_date: "2014-12-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-973bec88ef1004398d4f380791a3824a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ebbcc26be4391b139475aed5ce6a6c3e/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Trap Queen",
    artist: "Fetty Wap",
    id: 91934728,
    release_date: "2014-12-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0b9a1925e05cc4beef37c0e6d12b6ec-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57e2e32eb38000eb7454fff32fc56f02/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "There's Nothing I Won't Do",
    artist: "JX",
    id: 92141202,
    release_date: "2014-12-14",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5400ad9eea5245a3b906b0866b6c563c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wait for You",
    artist: "Mikkel S. Eriksen",
    id: 92154620,
    release_date: "2007-03-20",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-11c1039fc86dc26813bf3eef7692fc70-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5ffa1c57b16d0abd4f7baaeb424c48bb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thunderstruck",
    artist: "AC/DC",
    id: 92720102,
    release_date: "1990-09-21",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9cfe20c66de66d027c282b9981164a36-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e715766b21a8db6076f6a9a89e25cf82/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Moneytalks",
    artist: "AC/DC",
    id: 92720106,
    release_date: "1990-09-21",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-228522341b90797320da2c5e1ffac11c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e715766b21a8db6076f6a9a89e25cf82/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Uma Thurman",
    artist: "Fall Out Boy",
    id: 92872160,
    release_date: "2015-01-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31b43bab1867a0d699d1c1a2ac306c97-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c0a1d1281570ad3becbb6146c6d54c0c/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Heartbeat Song",
    artist: "Kelly Clarkson",
    id: 93029240,
    release_date: "2015-01-12",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-15ed8b876642007c075c181352ac2bae-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/518d7d043e005c17c96db2afb94f790f/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Uncover",
    artist: "Zara Larsson",
    id: 93194632,
    release_date: "2015-01-19",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51a0bf2cfaae23422b91040672f006b1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19c9a6e7fc71b50afe379d22ff564d9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Headsprung",
    artist: "LL COOL J",
    id: 93195088,
    release_date: "2004-03-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d49afecb1f50fab49a1fc5a455d94ecb-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f906fa962984644b266f6da8c657c048/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All That She Wants",
    artist: "Ace of Base",
    id: 93851020,
    release_date: "1992-11-10",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65d57c929fec42f60cdd2f6c6ebfc691-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cbd01c1f0b97725e2e069f579a0020f8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Worth It (feat. Kid Ink)",
    artist: "Fifth Harmony",
    id: 94195484,
    release_date: "2015-06-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4694341d7c0647c1ce5acadc3cc53515-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7f85b17171a8b1d062dca5484c022de/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Truffle Butter",
    artist: "Nicki Minaj",
    id: 94195822,
    release_date: "2015-02-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffb3be62652569b6ac69b87375db2d06-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c7a83a79ec78cfa0e4d93839d065557/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Marvin Gaye (feat. Meghan Trainor)",
    artist: "Charlie Puth",
    id: 94424876,
    release_date: "2015-02-10",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a1e0a43ab0f635176511d733202b0e5f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/64807472696691250b2d9842de935fd9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Break Stuff",
    artist: "Limp Bizkit",
    id: 94613616,
    release_date: "1999-02-17",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5e7ee1f5ee1214ecefa143d3e09dd3a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/997093089e97966606459e719848329f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Love Me Like You Do",
    artist: "Ellie Goulding",
    id: 94664184,
    release_date: "2015-02-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-52a0cbcca1c78dd825802aca70d4a4b9-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68753cbcc25b895096edf665bab00f65/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Ex's & Oh's",
    artist: "Elle King",
    id: 94753848,
    release_date: "2015-05-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c642cf08e42c2955a390f7ad7078202e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7663599d3a24513178261fcbe91f94c0/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "See You Again (feat. Charlie Puth)",
    artist: "Wiz Khalifa",
    id: 95813354,
    release_date: "2015-03-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf237c1625aaf84089b3ea6514b5ac52-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9411ab46dfc749311f7bbfd7398b7251/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Where Are Ü Now (with Justin Bieber)",
    artist: "Jack Ü",
    id: 95963378,
    release_date: "2015-02-24",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51e57b4c37cc891bdfe52a9433c7f65a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f26a87471422a3800e576cf18bde4184/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "FourFiveSeconds",
    artist: "Rihanna",
    id: 95965912,
    release_date: "2015-01-24",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-451ff2fb61b89b09b167580e899b69cb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ebe39c7db49fedcc2c0e8b28daf6bf31/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ain't Nobody (Loves Me Better)",
    artist: "Felix Jaehn",
    id: 95978792,
    release_date: "2015-04-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-40b16bf296acafbb404e55320a8678ad-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd5af0c9128cada6270eed382f2adc87/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: 'It Must Have Been Love (From the Film "Pretty Woman")',
    artist: "Roxette",
    id: 97085034,
    release_date: "2011-07-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-087ef667466625cc3010ff8036798c77-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea2967f883f39855e430d24feddf4a19/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Worry (with Ray Dalton)",
    artist: "Madcon",
    id: 97094250,
    release_date: "2015-04-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1a55000dd31f0af843a8238790951598-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d778373518b1c54b3182041a0dfe4b07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stitches",
    artist: "Shawn Mendes",
    id: 98028102,
    release_date: "2015-04-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1cd3eeb2dd5f69724ac741a81e185b7b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8514d2027310b7a3e0db1e0db54b17cd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Reach",
    artist: "S Club 7",
    id: 98981556,
    release_date: "2015-05-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e9654a051c71463ba0bec1ccddfad23-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5bea3cbaea9de3fdc785c64412b157ad/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Have You Ever",
    artist: "S Club 7",
    id: 98981564,
    release_date: "2015-05-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dca860c941dae2fa5cdf8b6127155b36-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5bea3cbaea9de3fdc785c64412b157ad/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Sweet Day",
    artist: "Mariah Carey",
    id: 99408480,
    release_date: "1995-11-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66d6c68ade18fc5aff2c71999770002e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/608dcac5f547cd39e1caa441e64f33e8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thinking About Your Love",
    artist: "Kenny Thomas",
    id: 99558148,
    release_date: "2015-06-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d19b085ec73690866faf4dcfd288dba5-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cd455219b3b685a3dd93176f96be680/250x250-000000-80-0-0.jpg",
    genre: "Soul & Funk",
  },
  {
    title: "I Still Believe",
    artist: "Mariah Carey",
    id: 99594114,
    release_date: "2015-05-05",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6df7fbbeaec413c785b5507220030220/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Roses (feat. ROZES)",
    artist: "The Chainsmokers",
    id: 99756342,
    release_date: "2015-06-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5847fb40dcbe689acd561d543b5d6768-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dca2f32070d5bebf548ce450f969bcc8/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Stressed Out",
    artist: "Twenty One Pilots",
    id: 99976952,
    release_date: "2015-05-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be897cd6f8f1c730f02fa9b5a11f6bb6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48e4d114036a2b4ac24f7fadb44a3c4d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Sugar",
    artist: "Maroon 5",
    id: 100004590,
    release_date: "2015-05-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b3342aa577e65264b812eb61c62ee808-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0a1d4442389bcd7919a7054f8c0b785/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Want to Want Me",
    artist: "Jason Derülo",
    id: 100598482,
    release_date: "2015-06-19",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f45037f1e63b5889ed9b2c03ffada5a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4e438c2f2ad8cde2ff67009ce311df06/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Watch Me (Whip / Nae Nae)",
    artist: "Silentó",
    id: 100614636,
    release_date: "2015-05-06",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-210f5cc16857005a3e201a86794cd958-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/294810837e79077c74039a2bde044dfe/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Jubel",
    artist: "Klingande",
    id: 102875744,
    release_date: "2015-06-30",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7d3fc92c5dacd8d30397d113dfd07920-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a1b6fef621f8eb2081e6cb4850ac27b/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "I Took A Pill In Ibiza",
    artist: "Mike Posner",
    id: 103806954,
    release_date: "2015-07-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d89133b14545f4d9716c39c216552112/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "How Deep is Your Love (feat. Disciples)",
    artist: "Calvin Harris",
    id: 103996098,
    release_date: "2015-07-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1d1006537dc5829f51946894067a9e78-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f79dbfc625337959420585635b03e91/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Hold My Hand",
    artist: "Jess Glynne",
    id: 105498804,
    release_date: "2015-03-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ef43e89fce4adf51f9ee021d0fdcbc5-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fab6839029d8fd4736549144f350070c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Back Here",
    artist: "Bb Mak",
    id: 106397338,
    release_date: "2000-04-11",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b57884dc76937bbc8d81499968e41aa-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4549cf4c92daf334a4ba5ff1ed40f289/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Hills",
    artist: "The Weeknd",
    id: 106506512,
    release_date: "2015-08-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6096b1784a5b1bce1593f014c8829e94-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eea9f7fc913300e40307a0ff70dc73cf/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Can't Feel My Face",
    artist: "The Weeknd",
    id: 106506516,
    release_date: "2015-06-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a2cc3ee9a7ee51307ceeacf596db247d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eea9f7fc913300e40307a0ff70dc73cf/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Waiting For Love",
    artist: "Avicii",
    id: 107346368,
    release_date: "2015-08-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7796a5e06f6daa8e71ad621d4baa171b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fe420c62b5696019b9359b689785086/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Runnin' (Lose It All)",
    artist: "Naughty Boy",
    id: 107406104,
    release_date: "2015-09-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fd04f6799ba18cf1e364a36419793ee-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a826f8f8efb62f35b3f78f83b18a233b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sugar (feat. Francesco Yates)",
    artist: "Robin Schulz",
    id: 107471416,
    release_date: "2015-07-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f00d193c4e02052691f978ea334462e9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/43815c2b219ed818623d61c6ab71a3b5/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Regret",
    artist: "New Order",
    id: 107749890,
    release_date: "1993-09-25",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3f664f32ea0f7ccf07fe46cce4d2d4f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0172cf1cd85983f65ea24df3b831524/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: 'Writing\'s On The Wall (From "Spectre" Soundtrack)',
    artist: "Sam Smith",
    id: 107900036,
    release_date: "2015-09-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01816014ed67406c99bf5d497270b1bb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75e56110a5cef30a4518a075d10ad05e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Skyfall",
    artist: "Adele",
    id: 107955594,
    release_date: "2012-10-05",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9b4c6419ca18982a82f9b9bf15471acc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hands To Myself",
    artist: "Selena Gomez",
    id: 109169056,
    release_date: "2015-10-09",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54256c14f3e1c185dcd81f6e1068ff04-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51f0d069107589ed8d7f4dd848e3a33b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good For You",
    artist: "Selena Gomez",
    id: 109169062,
    release_date: "2015-10-09",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-683312ecdd21497e7da21e694af9e37b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51f0d069107589ed8d7f4dd848e3a33b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cool for the Summer",
    artist: "Demi Lovato",
    id: 109703218,
    release_date: "2015-07-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a214f269860351a1923014f908c761c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e559086c775eece3bd9db9175db553d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bang My Head (feat. Sia & Fetty Wap)",
    artist: "David Guetta",
    id: 110663020,
    release_date: "2015-11-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ceb6da4d55158a856db93b27015f8e8b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86444e54d6d1b3b3c1078540386cecef/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Black Magic",
    artist: "Little Mix",
    id: 111777900,
    release_date: "2015-05-21",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a33f379abd6bb5204c3386b425eb813b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Do You Mean?",
    artist: "Justin Bieber",
    id: 112662364,
    release_date: "2015-11-13",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-edd4a56243d6b3ca2c5043bbe0c3226c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/340283aafac320864b207c420124ee46/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sorry",
    artist: "Justin Bieber",
    id: 112662366,
    release_date: "2015-11-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4aec008ea277bade72bb31f331301b0c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/340283aafac320864b207c420124ee46/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Yourself",
    artist: "Justin Bieber",
    id: 112662368,
    release_date: "2015-11-09",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-82688112a6cce207f260a5b91241c25f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/340283aafac320864b207c420124ee46/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Drag Me Down",
    artist: "One Direction",
    id: 112667890,
    release_date: "2015-11-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6156f5a8992e70c2a524c95e51124cc2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/433ce4072595090a6b2add706416d311/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lean On (feat. MØ & DJ Snake)",
    artist: "Major Lazer",
    id: 113366532,
    release_date: "2015-11-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3014373057a1486c786709b8dd888368/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lips Are Movin",
    artist: "Meghan Trainor",
    id: 113420742,
    release_date: "2014-10-21",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-294da6cf71843bdcc676f465dd5015a6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14f6b5d6ab06d3bc46b15c67c15305f1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hymn for the Weekend",
    artist: "Coldplay",
    id: 114811546,
    release_date: "2015-12-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f919512e51faf22f342d3727ff751dc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5df065fdcbaffd0f83d09789bad9d2db/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Adventure of a Lifetime",
    artist: "Coldplay",
    id: 114811560,
    release_date: "2015-12-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-225141012b34b52c9009a72135d2c635-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5df065fdcbaffd0f83d09789bad9d2db/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "I can't stand it",
    artist: "Twenty 4 Seven Ft. Capt. Hollywood",
    id: 116924416,
    release_date: "2016-01-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-62feb859cea069681f1477154b4bf8c0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d6287f851eca2e533b0cb3f201623048/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Never Be Like You",
    artist: "Flume",
    id: 117176358,
    release_date: "2016-01-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-efec1836a71c5091557a3fc0ed2f1db3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b2956ccdac8af8f657e909188aa0231/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "We Don't Talk Anymore (feat. Selena Gomez)",
    artist: "Charlie Puth",
    id: 117797212,
    release_date: "2016-01-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31ed99bd4e3b2519fee9aa8a12f6826d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/948200588c813c1afd10f29b56e0ce50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Work",
    artist: "Rihanna",
    id: 118190298,
    release_date: "2016-01-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e8850cd33ce8d17c90d5c71ed028e243-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fc5f387624c25de0ec96b4719e3a36d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Needed Me",
    artist: "Rihanna",
    id: 118190304,
    release_date: "2016-01-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-09f081e92a26c5722ac530c064981588-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fc5f387624c25de0ec96b4719e3a36d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love On The Brain",
    artist: "Rihanna",
    id: 118190312,
    release_date: "2016-01-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3135df9d27931726c4a003a27e8773e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4fc5f387624c25de0ec96b4719e3a36d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Chocolate",
    artist: "The 1975",
    id: 118760310,
    release_date: "2013-03-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-baaa582a2dd98eb5c787afa657284182-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f71d66a99bcb46e0768bced1769d0f9/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "It's Not Over",
    artist: "Daughtry",
    id: 118996424,
    release_date: "2006-11-21",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2e2de0763101358974767f96a597f1af-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81916bcc55a66a059964ebea556d794d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Home",
    artist: "Daughtry",
    id: 118996426,
    release_date: "2005-02-08",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b73b39c3a82abc6fd28ec34aa9d02e81-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81916bcc55a66a059964ebea556d794d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feels Like Tonight",
    artist: "Daughtry",
    id: 118996432,
    release_date: "2006-02-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b6e6787f391b3a57e1b8129fef0e538-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81916bcc55a66a059964ebea556d794d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Since I Don't Have You",
    artist: "Guns N' Roses",
    id: 119519234,
    release_date: "1958-06-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1d8c0fd0eb5c215e6fbe1fd2919c6410-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d2814d6c110b68afb3efd5573624666/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Panda",
    artist: "Desiigner",
    id: 120026876,
    release_date: "2016-02-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-17cce4aa2f211c97af7cbe2d2f800f83-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c8de97b0f63c2fc67106ca69198aee18/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "King",
    artist: "Years & Years",
    id: 120307594,
    release_date: "2015-02-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6d150cc2ba9b711d292546b722ac4456-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c40aaac2fb76f4b95a8623aa460f59c5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here",
    artist: "Alessia Cara",
    id: 120739000,
    release_date: "2015-05-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bebaab983a7a421903d3be07618bbf0e-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c2c09bb30073e88323ddcdb6e3311e49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Scars To Your Beautiful",
    artist: "Alessia Cara",
    id: 120739016,
    release_date: "2015-11-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94b35ed5ee3f8526bf1af1d13e259c60-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c2c09bb30073e88323ddcdb6e3311e49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pop Ya Collar",
    artist: "Usher",
    id: 120742236,
    release_date: "2000-10-23",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-93add2ce9cb857ff9b3315ee49baa3e1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2c30c54358b56d1c0556a5aab00f48bd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Girl (Kungs Vs Cookin' On 3 Burners)",
    artist: "Kungs",
    id: 121593956,
    release_date: "2016-03-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-316beaa388876f1efc5a15d93f211beb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6945e1d1812cfd26b0ff668886ebfe46/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "PILLOWTALK",
    artist: "ZAYN",
    id: 121595532,
    release_date: "2016-01-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e04c71fefc102d0202a656e7b6d17509-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1224e92320d2d00568fb0327b248acf4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "7 Years",
    artist: "Lukas Graham",
    id: 122016704,
    release_date: "2015-06-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d1d92a00f214c4d3f0e723b620d5647-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/30db449ef0f3214b2eb5b95104d03de7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Aloha",
    artist: "Møme",
    id: 122440564,
    release_date: "2015-10-30",
    preview:
      "https://cdn`s-preview-5.dzcdn.net/stream/c-582b578866983d6bbea1b13691e41100-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27202f0b3fb35fbda502d9c873a0bf2f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Ain't Your Mama",
    artist: "Jennifer Lopez",
    id: 122450802,
    release_date: "2016-04-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e8c1b7210a4708631515b8c778fcab5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad148abf988309facb40399ba4a54cec/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "I Wish I Was a Punk Rocker (with Flowers in My Hair)",
    artist: "Sandi Thom",
    id: 122539400,
    release_date: "2006-05-22",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0aa7d6b2e3f2d67335833e74085ecdc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ddc4060f2db51579a6f807bbf954640d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Low Life (feat. The Weeknd)",
    artist: "Future",
    id: 122703266,
    release_date: "2016-04-13",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e02b410da0784c8b1dd1b05b3c197095-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d67a9d21359a1704127c45dde2c8c57c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All My Friends (feat. Tinashe & Chance the Rapper)",
    artist: "Snakehips",
    id: 122886910,
    release_date: "2016-04-15",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-63ffad34e43ce98caf07d22dbd762964-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9dee7bff71e7663fc2c94e8918266ada/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: 'Just Like Fire (From "Alice Through The Looking Glass")',
    artist: "P!nk",
    id: 122946174,
    release_date: "2016-04-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3cf8f214ffd58f452a94086180d0b5e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/03afe5c85becc933ff77998808d08872/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hotter Than Hell",
    artist: "Dua Lipa",
    id: 123357486,
    release_date: "2016-04-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1d90c832981ee3f29c3409bd5bd9067-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e92b4f69e3db10f830c66f089950dd1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Is What You Came For",
    artist: "Calvin Harris",
    id: 123883254,
    release_date: "2016-04-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-35993c83c7915821a27c9aadeaf446c5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8af7ff13a47e2775307299843da9b36/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Alarm",
    artist: "Anne-Marie",
    id: 123903244,
    release_date: "2016-05-20",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bc35bfb97daab1b75d2d3f9d6dac4fae-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f152e1da4b3bb1ebbf33381b2a88f75/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Dreams",
    artist: "Gabrielle",
    id: 124220438,
    release_date: "1977-02-04",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d20b8c89b80ae04971c1e3cf910c6d0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2cc14e736859706babb2246aee61e5e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "CAN'T STOP THE FEELING! (from DreamWorks Animation's \"TROLLS\")",
    artist: "Justin Timberlake",
    id: 124237488,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e2950c9eaf0c5c9bd8621dc855099f4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/209aa560e3b5fbe679e91887fc0c7332/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This One's for You (feat. Zara Larsson)",
    artist: "David Guetta",
    id: 124433692,
    release_date: "2016-07-09",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-32503723ad5d306dc5742359d7ee2816-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e72ba6dace3fa59dab4bb403fc9ba4f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "One Dance",
    artist: "Drake",
    id: 124603270,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d63a57a6516bbeb921f70acd48c20fe1-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56bdb7a86a27fadb96332c0c8f1b8e81/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Too Good",
    artist: "Drake",
    id: 124603278,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-864cf69fa5d894c64374132c9cd14d2a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56bdb7a86a27fadb96332c0c8f1b8e81/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Hotline Bling",
    artist: "Drake",
    id: 124603286,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e42062559e1d54700c02ac372d7b9738-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56bdb7a86a27fadb96332c0c8f1b8e81/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Final Song",
    artist: "MØ",
    id: 124604316,
    release_date: "2016-05-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9230c65e68a520402e772c1bfa18a8a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd198aa0a511e33d42c787a364434962/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Side To Side",
    artist: "Ariana Grande",
    id: 124995668,
    release_date: "2016-05-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2ea533b11d8fd908d263aab96118fe0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5bf0368c12f211bdafe91deba06cdda/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dangerous Woman",
    artist: "Ariana Grande",
    id: 124996686,
    release_date: "2016-03-11",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a8f399e9ddbb8ec2530232c0dfd953f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Do You Remember?",
    artist: "Phil Collins",
    id: 125493146,
    release_date: "1989-11-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6520d2e5d8a8917766ef612a3d22ebc6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00e0c82ab19515ecb615775c4db797c7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Something Happened on the Way to Heaven",
    artist: "Phil Collins",
    id: 125493148,
    release_date: "1989-11-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4e857cf66410eedc34a2b27522ddf62-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00e0c82ab19515ecb615775c4db797c7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Wish It Would Rain Down",
    artist: "Phil Collins",
    id: 125493152,
    release_date: "1989-11-20",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fc4eb1b862f5f577274b07d75f90947c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00e0c82ab19515ecb615775c4db797c7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Work from Home (feat. Ty Dolla $ign)",
    artist: "Fifth Harmony",
    id: 125513414,
    release_date: "2016-02-26",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7f492422f7d5d5ada9d8c95eccd0c084-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4529a3c8ec16c3e21de17a6294a6b3e1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heathens",
    artist: "Twenty One Pilots",
    id: 126884459,
    release_date: "2016-06-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eae119c6444ec44a9fd315938b52ea8e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3dfc8c9e406cf1bba8ce0695a44a9b7e/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "What's My Age Again?",
    artist: "blink-182",
    id: 127354205,
    release_date: "1999-09-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9dca3eee5df7ae101eec360aedcb411b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/50627ad1a5ce0215359f0e8b2e37a01f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Adam's Song",
    artist: "blink-182",
    id: 127354209,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e0b1eac8341917e1c9a3d424e2c24b8f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/50627ad1a5ce0215359f0e8b2e37a01f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Drinking in L.A.",
    artist: "Bran Van 3000",
    id: 128237751,
    release_date: "1997-03-31",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d5ba3110391d2509f687cfe4e7c6cee6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f8cbd0fa24ca51b23b89fea5e3f51ff6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Starving",
    artist: "Hailee Steinfeld",
    id: 128948012,
    release_date: "2016-07-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49b6eb5ce4026853a02df44d85a8259d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/46b47680d6c0f754365a9744737ef426/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Life is a Highway",
    artist: "Rascal Flatts",
    id: 128948754,
    release_date: "2016-07-22",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aaa5371eb02be6493e51919e2ee63603-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/619de6393fcae78ae199d38324ed611c/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Cold Water (feat. Justin Bieber & MØ)",
    artist: "Major Lazer",
    id: 129011934,
    release_date: "2016-07-23",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a90fa0db4b7142b0fcd65f9c3c9a3a82/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In the Name of Love",
    artist: "Martin Garrix",
    id: 129313094,
    release_date: "2016-07-29",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-ddf76b88ae3d8c30d531802554064d18-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eed47f7aa8765df6c4e8891a4a70d298/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Middle",
    artist: "DJ Snake",
    id: 129632290,
    release_date: "2015-10-16",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1e0ff22298f34d0e296a9f34f4a24415-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a52e1bbddc750c996a66b0ccfa4370c/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I Don't Wanna Know (feat. Enya & P. Diddy)",
    artist: "Mario Winans",
    id: 130012244,
    release_date: "2004-08-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f738ad32f015fb0826f757f99bf4c59b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0c57677dbbbf536f80109d11664f00ad/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Black Beatles",
    artist: "Rae Sremmurd",
    id: 130105294,
    release_date: "2016-08-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed9a64fa21d9d4e8d799232462a7ce22-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d9c957b88855e088940f96e955cf26be/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Love Lockdown (Album Version)",
    artist: "Kanye West",
    id: 130412304,
    release_date: "2008-09-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13fc9756f0922ad18ba9c851e650fa9e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2877c6bf4fad750c54dd212fb50a366b/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "LUV",
    artist: "Tory Lanez",
    id: 130414944,
    release_date: "2016-08-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e220070713936c551e8d54de16193131-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87fc9529b168e4085459c5ccc0eb5ade/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: 'Still Falling For You (From "Bridget Jones\'s Baby")',
    artist: "Ellie Goulding",
    id: 130495956,
    release_date: "2016-08-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5bca693a14e7a1a09e2239f55c1d4629-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ddf28511ba3c8c1a8ad81ee43d60495/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nine Million Bicycles",
    artist: "Katie Melua",
    id: 131200662,
    release_date: "2005-07-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6763ba0b214f5078f5fadad2f4c770d0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6abeb6b8111a3b9a2e878b27d896dfb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Closest Thing to Crazy",
    artist: "Katie Melua",
    id: 131207980,
    release_date: "2008-10-27",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/458f370e11945b9b4c5b806747679977/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Time Is Now",
    artist: "Moloko",
    id: 131225962,
    release_date: "2000-10-31",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-17f925bbb4f2c573968614cf16e728a2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ac44604eb968f8a05b5be5d01227cde5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Broccoli (feat. Lil Yachty)",
    artist: "Shelley FKA DRAM",
    id: 131611536,
    release_date: "2016-09-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f46208abc53f900e3743eff335a92e6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3725bffa56ab8ce31e5194ef65259707/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Get Ready",
    artist: "2 Unlimited",
    id: 131708868,
    release_date: "1992-02-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b380fbe90441ef8afc8aca0cdca0cc60-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fcf639eb7914835c67586b0b3da55fd3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Way",
    artist: "Calvin Harris",
    id: 132265704,
    release_date: "2016-12-23",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5df2c65329a6576458d528c3fdf28a92-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cd6eb258ea0449cc0137e6afd35eee34/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Hey There Delilah",
    artist: "Plain White T's",
    id: 133105770,
    release_date: "2006-05-09",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3fe9c0d137871820ee4cc9c18f3e9e19-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1bce8d12398935d6e559ee007500da88/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Good as Hell",
    artist: "Lizzo",
    id: 133167964,
    release_date: "2016-10-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-619fb6c1edb35c8390815fa751e5a1df-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/71693ef84b80cb7c6b479c59186200b2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Perfect Illusion",
    artist: "Lady Gaga",
    id: 134525902,
    release_date: "2016-09-09",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-05583ca6ced0829c397d871a3f68aa68-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/614af5cb69dd52e4de82dd2cd9e217c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rockabye (feat. Sean Paul & Anne-Marie)",
    artist: "Clean Bandit",
    id: 134527024,
    release_date: "2016-10-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c0aa63f7aae85c540cff265d612f490-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58b6ae3ff5e9dfc6f9e4d59862094528/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Cheap Thrills (feat. Sean Paul)",
    artist: "Sia",
    id: 134533238,
    release_date: "2016-02-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ffca961cc45707118731eaa45ca2a31-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/305b06b02f21849dd2bf45614e0fafef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Greatest (feat. Kendrick Lamar)",
    artist: "Sia",
    id: 134533240,
    release_date: "2016-09-06",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-24e48f0f0cbc867716d5c0364e791c36-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/305b06b02f21849dd2bf45614e0fafef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Limit",
    artist: "2 Unlimited",
    id: 134706074,
    release_date: "1993-05-10",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e53d1cede41aa09ffe487c53f0739da-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37e6834a65f160a58c3b58dc8a76d240/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say You Won't Let Go",
    artist: "James Arthur",
    id: 134945400,
    release_date: "2016-11-04",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c31cef998d5a510b124b176e46a43192-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ea183258031dd17afb3706a903d5900/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cool Girl",
    artist: "Tove Lo",
    id: 134948420,
    release_date: "2016-10-28",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e356054aeb1a6a78ec18b9d0cc01a2a3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b74f13b67c28682ebaea1c2dd35820a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Shout Out to My Ex",
    artist: "Little Mix",
    id: 136135070,
    release_date: "2016-11-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61df554793c5d1b0d31d4987c03ffe44/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Policy of Truth",
    artist: "Depeche Mode",
    id: 136331384,
    release_date: "1990-03-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d2d606830554fa4231e6526352e1e223-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2632e326fac7c48c2fbef198f295995a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "24K Magic",
    artist: "Bruno Mars",
    id: 136336110,
    release_date: "2016-11-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-633e75a95d0917b64e51355c4b84937a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/012b27906b430a37ec1d8f793d5c4fa6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cake By The Ocean",
    artist: "DNCE",
    id: 136341758,
    release_date: "2016-11-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-91efe4af4545a8d4b5a59f657b2eb9e2-17.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3380b311743baf8435f616371b95194/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Starboy",
    artist: "The Weeknd",
    id: 136889400,
    release_date: "2016-09-22",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66c54596b9fec3fc878301b49debf569-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/134778e4c4f19ea71c82408300925a9a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Feel It Coming",
    artist: "The Weeknd",
    id: 136889434,
    release_date: "2016-11-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2ba5ff904acf081109f000c56b37562f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/134778e4c4f19ea71c82408300925a9a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ready 2 Go",
    artist: "Martin Solveig",
    id: 137336032,
    release_date: "2015-02-18",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-741583d20eb4291396c5e9cc450da1b0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c416e36c700eabc739ca3402df11d11/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I Don’t Wanna Live Forever (Fifty Shades Darker)",
    artist: "ZAYN",
    id: 137828447,
    release_date: "2016-12-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbbc4eaec3e7a083940c84769ea2b96c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Hold On",
    artist: "Steve Aoki",
    id: 137911999,
    release_date: "2016-12-12",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2ab5b5bca42adcee94a39974f65275b1-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a539d60efd4b12f346ce71272dbb893/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Hello",
    artist: "Adele",
    id: 138545995,
    release_date: "2015-10-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf968741c42b47400aca81b6da437a03-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eaeadce7932a97533fe495881d2fcd7a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Send My Love (To Your New Lover)",
    artist: "Adele",
    id: 138545997,
    release_date: "2015-11-20",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d94ba50b929313d13223fc56ad48c657-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eaeadce7932a97533fe495881d2fcd7a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "From Paris To Berlin",
    artist: "Infernal",
    id: 138728387,
    release_date: "0000-00-00",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4e72c06619ab08fd04e4e36cecd5baf2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Because I Got High",
    artist: "Afroman",
    id: 139102115,
    release_date: "2001-08-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6c028d858d28020363bfa5316f5d24be-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2dff8a4efefaea6e300a4732ffffa3bf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "For Whom The Bell Tolls",
    artist: "Bee Gees",
    id: 139138469,
    release_date: "1993-09-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8aa67deccda1dc63f76c8c1a65c3902d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cbf620ce76f4d23c12043f19b32de852/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Issues",
    artist: "Julia Michaels",
    id: 139874527,
    release_date: "2017-01-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4eab1657f7315053e63e38c8903149eb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f5109f8902b35c2d88a1ac6c14b3fe1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Faded",
    artist: "Alan Walker",
    id: 140295501,
    release_date: "2016-06-03",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1ad117a5accc2ee7138046ba4473e1b5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e50540d7f752c93e0695ebae272be0c3/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Scared to Be Lonely",
    artist: "Martin Garrix",
    id: 140856305,
    release_date: "2017-01-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a617220ac4fec839455b061b5f2fbe1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8e6e0c8973442986572a2e8a5492fdd9/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Human",
    artist: "Rag'n'Bone Man",
    id: 140869461,
    release_date: "2016-10-07",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2339a7e8fb0ff09fb0899ee867c36fd1-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb1e80abccac5db8d2a0265f990dd5af/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Skin",
    artist: "Rag'n'Bone Man",
    id: 140889759,
    release_date: "2017-01-26",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e77eb99733c2b46032e06b1f962de3d2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75141a524cc39b49a969211c132df2ef/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Groovy Train",
    artist: "The Farm",
    id: 141567165,
    release_date: "1991-03-04",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4ce7b605fe6eb1ed4f11346c34f8840d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/40097e1c15ac78eec2720d635edbb9e6/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Ciao Adios",
    artist: "Anne-Marie",
    id: 141826655,
    release_date: "2017-02-10",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b9fb3d39d2a7f12dd4d48b1928a3acb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2afe820c32c6be78f12f49252fcf7bb5/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Life Is A Highway",
    artist: "Tom Cochrane",
    id: 141826703,
    release_date: "1991-02-01",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-917a900987c29286ff030efddf83ef14-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/923563603da263fb7879aae3b9935690/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It Ain't Me",
    artist: "Kygo",
    id: 142328215,
    release_date: "2017-02-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e30195c96f161b253dc9b650ba7d44d8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9ec7d8f75708f9a9de5de403cf205c0/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Mask Off",
    artist: "Future",
    id: 142337683,
    release_date: "2017-02-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-746d2c42cd289de6e8d4531cfa4a5983-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5186078c5bd5623ebec9b2753d8aaebe/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Something Just Like This",
    artist: "Coldplay",
    id: 142706538,
    release_date: "2017-02-22",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c7f32280916bc10e989ca5f4ed3b8afb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ae6c01a51296a7ec6d89c96a4fac32c/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Swalla (feat. Nicki Minaj & Ty Dolla $ign)",
    artist: "Jason Derulo",
    id: 142750222,
    release_date: "2017-02-24",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f289e89023c2117bc903b00162e0c620-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eaa869a2b298f79a739abc44eb19e04/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stay",
    artist: "Zedd",
    id: 142808100,
    release_date: "2017-02-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a31a6941d546855e4b863af9ec0a3d7d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/16e8d7fc0a40f75bffac15d7e9e2a9c5/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Fill Me In",
    artist: "Craig David",
    id: 143169092,
    release_date: "2000-08-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f517cc318b89a0078dcca76ed4005d0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ea24270d49242d3a3f7edaf30d9989e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "7 Days",
    artist: "Craig David",
    id: 143169102,
    release_date: "2000-08-21",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d3f017a794d2a0b2cf749245c0a9b859-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ea24270d49242d3a3f7edaf30d9989e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Love You No More (I'm Sorry)",
    artist: "Craig David",
    id: 143170362,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed11e6aaee9ef692a7c2605c6c90dd27-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ccc40587d0c9bc4ef4cab1a82adc558/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Young Dumb & Broke",
    artist: "Khalid",
    id: 143172406,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ad8f8f4b4e41c0f2fcfc48781450415d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fa1597e86f5b4283ea316f2ddb54008/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Pretty Girl (Cheat Codes X CADE Remix)",
    artist: "Maggie Lindemann",
    id: 143172578,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-35b2284cadfd2a07d01e7e84c26b970c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bca69583b74747f5bad7c0d16b16b915/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Never Forget You",
    artist: "Zara Larsson",
    id: 144389058,
    release_date: "2015-07-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3fd52fe2ace03c8a9b6dbdf9860f6caf-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/32181f6a74b8ae31013f6a1aa00ca680/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Symphony (feat. Zara Larsson)",
    artist: "Clean Bandit",
    id: 144393668,
    release_date: "2017-03-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a8ec244392715dc548e4c3a1c5aeb55-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86c4dc247f751008f2bbd726fdd04465/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Do You Really Like It?",
    artist: "DJ Pied Piper & The Masters Of Ceremonies",
    id: 144535600,
    release_date: "2001-05-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54266cfa0a06358c86343e07c5c0968b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7417fcba18dbac94a142d04d07968218/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Passionfruit",
    artist: "Drake",
    id: 144572210,
    release_date: "2017-03-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8caa8c875f2ea73fc454ddf8b92a250-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f0187ad83cdd1f47f1c55420f9df227/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Ayo Technology",
    artist: "50 Cent",
    id: 145429556,
    release_date: "2008-10-05",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2582dbdb199adf790e943b54ad5e8057-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58ec83e8f74b55096f2115523eb9e7df/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "No Promises (feat. Demi Lovato)",
    artist: "Cheat Codes",
    id: 145432614,
    release_date: "2017-03-31",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1eb90bf5036527257e67549e4dc1568b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c542b7050bd4435bcc60c4bda1efee3b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Paris",
    artist: "The Chainsmokers",
    id: 346434701,
    release_date: "2017-01-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b4a3298977aca8b0e9104f09873379b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d4451405fea03536f6788fa806045141/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Secret Love",
    artist: "Bee Gees",
    id: 348129131,
    release_date: "2008-03-03",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb5068c5c6e4616c5862fdcf470fbe66-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f865f8e722986099cd3ac954ddd5e6f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(Can't Live Without Your) Love And Affection",
    artist: "Nelson",
    id: 350059451,
    release_date: "1990-08-07",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-59c7c85b268ca295638b791d2525a27b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/007cf45d319186497b198fcbb187c01e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "HUMBLE.",
    artist: "Kendrick Lamar",
    id: 350171311,
    release_date: "2017-04-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e99276844e12072cd449b6728f74c65f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ce6b8452fae425557067db6e6a1cad5/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Despacito (Remix)",
    artist: "Luis Fonsi",
    id: 350428781,
    release_date: "2017-04-17",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ab51cbf8ebae60634ba587782f5acda2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Cure",
    artist: "Lady Gaga",
    id: 350720621,
    release_date: "2017-04-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-39aeee8760e90d9b86783926b32b51ee-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f1000f6aab94a6a9991af2c3bbfb1685/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "There's Nothing Holdin' Me Back",
    artist: "Shawn Mendes",
    id: 351595631,
    release_date: "2017-04-20",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1fbb3ea2fe8332e76fbdd9881625fc8-24.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35d5f7dd0b398bb37287b3454f0b05b9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cradle Of Love",
    artist: "Billy Idol",
    id: 354284151,
    release_date: "1990-05-15",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f13a638382f67124fdd35eec4207d122-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/38a065da1b4efcb6e9ef41794ee220c2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mama",
    artist: "Jonas Blue",
    id: 355724441,
    release_date: "2017-05-05",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a511aa1e9bb9c2a4577c6def607f6f84-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b7e72d659e204adc7a84f1a5ab8af51d/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "1-800-273-8255",
    artist: "Logic",
    id: 357859971,
    release_date: "2017-05-05",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-38ea65b1197a2981a139ffc23ea1285a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae3a9ec5d5f2b7885753b1835852ae1c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "NO",
    artist: "Meghan Trainor",
    id: 358920101,
    release_date: "2016-03-05",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c082a4c67ba51aed7a27d1321d8ccf0e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b6f3f7b4a3d8d765498f7939c7458cb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Like Me Better",
    artist: "Lauv",
    id: 358972401,
    release_date: "2017-05-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3de2f157c9e7f5fad64b4420312d0f3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3905f16f540254e3c9841cc781c91271/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sign of the Times",
    artist: "Harry Styles",
    id: 360301951,
    release_date: "2017-05-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e3f5f1b8fc50bdb831961bcea4e7720d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a41167cfcc7e840821fad5f5f5f91da2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Glycerine",
    artist: "Bush",
    id: 361116571,
    release_date: "1994-12-18",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-052d1bc40dfc6c0bee5b8abd46e61c88-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01bb7dd3e6fa3f6e47f554f518954d58/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bad Liar",
    artist: "Selena Gomez",
    id: 363418191,
    release_date: "2017-05-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c65adfe12a5a3497a34a2835e949772e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a2ecffe63ad4434d6158ab1cee72ccf3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Strip That Down",
    artist: "Liam Payne",
    id: 363834871,
    release_date: "2017-05-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cdee6d0cd7e369d6d2cf6f04d4df99df-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b6a75ea44e69d582722e9dd0505228b6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Chantaje (feat. Maluma)",
    artist: "Shakira",
    id: 364957401,
    release_date: "2016-10-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c4e5870cd420281f34d4ded7b11f46ad-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/975abab73605c4526d1a050940ecf7cf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "IDGAF",
    artist: "Dua Lipa",
    id: 366297871,
    release_date: "2017-06-02",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f1ed1aaab7d4dc765781a9bf16c95ac-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75476548cb1a37e557b38bd8e8f8823f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "New Rules",
    artist: "Dua Lipa",
    id: 366297921,
    release_date: "2017-06-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cab3cdc9503765922c3aeb05e2b0d0f6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75476548cb1a37e557b38bd8e8f8823f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unbelievable",
    artist: "EMF",
    id: 367782641,
    release_date: "1991-05-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71fb54a8a26715910d17c0f2531cb176-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f666a086f47062b759f20913a6d3d37/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "2U (feat. Justin Bieber)",
    artist: "David Guetta",
    id: 369397961,
    release_date: "2017-06-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6428ad2544c232e4e26889b4f0dd76f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a392a6c833438989f1c2803da7f50f1b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Bon appétit",
    artist: "Katy Perry",
    id: 371182241,
    release_date: "2017-06-09",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7974148c2797fef5b7634b1338ec31f5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Green Light",
    artist: "Lorde",
    id: 371625771,
    release_date: "2017-03-02",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10f057c6d651c5f3a70141b5de6ebb97-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0c424dbe627530cd06a6fd408baba3f3/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Bad Blood",
    artist: "Taylor Swift",
    id: 372049961,
    release_date: "2015-05-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-57704d1d3a3a450057f2a7d32d326678-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de7addba2b3b6effb8ea517fe5edff02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wild Thoughts (feat. Rihanna & Bryson Tiller)",
    artist: "DJ Khaled",
    id: 375689861,
    release_date: "2017-06-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e921e090eeb9e255d3991aa78d55a696-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31c5c7f955540f34d0f109be6e86bc19/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I'm the One",
    artist: "DJ Khaled",
    id: 375689871,
    release_date: "2017-06-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4ff9d36d0c08d8f3224ce36d2af46dee-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31c5c7f955540f34d0f109be6e86bc19/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Slide (feat. Frank Ocean & Migos)",
    artist: "Calvin Harris",
    id: 376480891,
    release_date: "2017-06-30",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf65b72f6125687df1f21bbe3e44794a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/953237229a2d2a8d36c99faf24c337d7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Feels (feat. Pharrell Williams, Katy Perry & Big Sean)",
    artist: "Calvin Harris",
    id: 376480961,
    release_date: "2017-06-30",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-62ebd6be0e0c60fca44f6dbdf11985f9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/953237229a2d2a8d36c99faf24c337d7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Mi Gente",
    artist: "J Balvin",
    id: 376989861,
    release_date: "2017-06-30",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1b0a205da89ae65325520ad0bb10965-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aad5dfbfd03587d830cb7dec810d92e0/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Unforgettable (feat. Swae Lee)",
    artist: "French Montana",
    id: 382428781,
    release_date: "2017-07-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-390c00770021a4bbd8fdbde55f972a24-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9b0f5d741397596eefe0c1db4c5aea1/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dragostea Din Tei",
    artist: "O-Zone",
    id: 383495601,
    release_date: "2004-06-06",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bdabeb6095ca91be01ae377c91da6458/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bring Em Out",
    artist: "T.I.",
    id: 383740881,
    release_date: "2004-11-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-11ca0e1cc8163d14c4f4a165f9dc528d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0658619a6f777f7311feb4972698c184/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Live Your Life",
    artist: "T.I.",
    id: 383741911,
    release_date: "2008-09-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08fc82935fc4aa0793fabdfaec2793cc-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/797f88ddbe4364d743ea4b6e7598bb97/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Whatever You Like",
    artist: "T.I.",
    id: 383742081,
    release_date: "2008-09-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-47624242862d6a82db61b7efa2f31588-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f4c83cd8eebca6f61ee616f78160c99d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dead And Gone",
    artist: "T.I.",
    id: 383742181,
    release_date: "2008-09-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6357fadf335c8c24677e7c65cd5b5184-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f4c83cd8eebca6f61ee616f78160c99d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Gasolina",
    artist: "Daddy Yankee",
    id: 385097421,
    release_date: "2004-07-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/77f9bfa97ef4ee089f7596759c5adf87/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Proper Education",
    artist: "Eric Prydz",
    id: 388965251,
    release_date: "2006-12-29",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8559217f18e6c194ff0ec86e871f8d0c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Middle",
    artist: "Jimmy Eat World",
    id: 389059151,
    release_date: "2001-11-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9ffa2c9aa5fb3ddbbe59000afbb47d1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7494f2967fd2426dad3caab076e66bd5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Praying",
    artist: "Ke$ha",
    id: 391689572,
    release_date: "2017-07-06",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-43f197ffaae18e3b1d91f067fbd30bf7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92db233804151e1bef19e26a3d5e78f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Silence (feat. Khalid)",
    artist: "Marshmello",
    id: 391727202,
    release_date: "2017-08-11",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83440039f998732a74af06ca3c2a9759-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eda5721c07c0c9d6ff937132f33d808a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Glorious (feat. Skylar Grey)",
    artist: "Macklemore",
    id: 397729422,
    release_date: "2017-09-22",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-044d607588b23b9fc32d8d1667952126-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01cd93c5a9e67df6f5156b01267c29eb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dusk Till Dawn (feat. Sia)",
    artist: "ZAYN",
    id: 403074632,
    release_date: "2017-09-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8dfecb0d061fb0ca1c4497def94a25f7-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a16b3974bb938cf6fdd290ea8c60baf0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "rockstar",
    artist: "Post Malone",
    id: 404667822,
    release_date: "2017-09-15",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2bf933d5cee270018a277b99c6798b7f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Wish",
    artist: "Ray J",
    id: 409607052,
    release_date: "2005-09-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-66fd604bf6aedfb7ba5d662c6d907f73-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ef81adac7e58c61b83cbabd7e7ff4a88/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "1, 2, 3, 4 (Sumpin' New)",
    artist: "Coolio",
    id: 409963662,
    release_date: "1995-10-02",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f3ad5b7eafec51e9f67638b7084d2a19/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Is Your Night",
    artist: "Amber",
    id: 409976592,
    release_date: "1996-11-26",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c8fd1b8319a46814e7b0ac9fcb39b69/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What About Us",
    artist: "P!nk",
    id: 415238442,
    release_date: "2017-10-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f38b16e42fbdfdbd8cab85d9b67ef6e1-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0ca592cf963bdab9f8da72ff7bbec38/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mine",
    artist: "Bazzi",
    id: 415370102,
    release_date: "2017-10-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc0517095cd6ed95f56ccf6b22a864a7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae5084aaf778ffcd83df999c84f8bd16/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Slow Hands",
    artist: "Niall Horan",
    id: 417673222,
    release_date: "2017-10-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b29a54bf15f7a1af72b595e4127969d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/280f054262ce5c2b534150d79f694350/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wolves",
    artist: "Selena Gomez",
    id: 420355352,
    release_date: "2017-10-25",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-de32f0e4aabf6ab7d43aeda0332fe6cb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/018f19bb66c83e0c843dddaa6d97e544/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Too Good At Goodbyes",
    artist: "Sam Smith",
    id: 422201642,
    release_date: "2017-11-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b70e81f72475ff650226b63186f67f6a-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c05a89574878fc88a9254c3e7f6b4b26/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Lovers Do",
    artist: "Maroon 5",
    id: 422214512,
    release_date: "2017-09-01",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/891a16bc70a0927c3a01a96b26eb4a4f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No More Sad Songs",
    artist: "Little Mix",
    id: 425662542,
    release_date: "2017-11-24",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/52eff0349665a3d20553a6642e10c60c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Deep",
    artist: "East 17",
    id: 428735302,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2280647332bb148928df8a38d468bdfb-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/16ec934f7df7de09a7f96433606543ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Step On",
    artist: "Happy Mondays",
    id: 428806912,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-416d3ae119deda882f1ba0d3ab3f3d90-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6cb24a32422ad9e9c52db98475eb800b/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Exterminate (Endzeit 7) [feat. Niki Haris]",
    artist: "Snap!",
    id: 434215212,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14c62f4654aafa95bd0c9bec06ec7dcf-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9836fcf60102662b686f93d01bcda69/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Power",
    artist: "Snap!",
    id: 434218692,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-521d383df9a396a2864851dd6d6fee74-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22b5838370473301c18c6454a4dd9a6e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ooops Up",
    artist: "Snap!",
    id: 434218702,
    release_date: "1990-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0998c5515d1c5171bb1ae00acdc80b7a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22b5838370473301c18c6454a4dd9a6e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Delicate",
    artist: "Taylor Swift",
    id: 435491482,
    release_date: "2017-11-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffcc9c3bc91ee70e3fca6b7a88ac94fa-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6f3afd8a5c3d8ea797f458694166e47/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Look What You Made Me Do",
    artist: "Taylor Swift",
    id: 435491492,
    release_date: "2017-11-17",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ceb7cb4362bceb7e4f362bf9b5c04bb8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6f3afd8a5c3d8ea797f458694166e47/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Harmony",
    artist: "The Beloved",
    id: 442403542,
    release_date: "1993-04-05",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9df89a69c7f382761f91e9ef303e365-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/754bf822e1097b41c167292a1eb86e50/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Love Will Lead You Back",
    artist: "Taylor Dayne",
    id: 444737912,
    release_date: "1989-10-07",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-adc4723b804fd7a8ed1fa775acf12acd-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ac25e8eff2e693ab9d94211e0ffad56/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Never Be the Same",
    artist: "Camila Cabello",
    id: 447098062,
    release_date: "2018-01-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fcd42a4b12f09cba0ea11bc5a87ad711-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/824145a71e397185d8a1abe57842430c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Havana (feat. Young Thug)",
    artist: "Camila Cabello",
    id: 447098092,
    release_date: "2018-01-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99ddaabde2802242249f3bf7a06fce94-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/824145a71e397185d8a1abe57842430c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Two Steps Behind (String / Acoustic Version)",
    artist: "Def Leppard",
    id: 447556912,
    release_date: "1992-01-19",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ecc0c235a02de647340144284af0239-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6351d7a113318ff475712e8a539f9a75/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Say Something (feat. Chris Stapleton)",
    artist: "Justin Timberlake",
    id: 455564382,
    release_date: "2018-02-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92300805847b6f42340da0f6bfe4dd64-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de01a2810fe1e119b502b380c8ce00eb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "FRIENDS",
    artist: "Marshmello",
    id: 456379902,
    release_date: "2018-02-09",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8791a67d3b9ddc54f55d797a8bd6c9d6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b57e2db906a8af3c54b4485313bf18c2/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "1, 2, 3 (feat. Jason Derulo & De La Ghetto)",
    artist: "Sofia Reyes",
    id: 457086912,
    release_date: "2018-02-16",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a249e359cdf7f05123d1b0b74d328e7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a39202efe2d8d686e7cf7b36afc240a3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Lies",
    artist: "Khalid",
    id: 460570702,
    release_date: "2018-02-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-36d17b49dde7e82bd2971d5b69167a91-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fa933d7f3523f7249129d0675dd8893/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "American Boy",
    artist: "Estelle",
    id: 462849482,
    release_date: "2008-03-31",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6386d0c950936178c59ee1cb5dee073e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f15abe0f32a2d4b61e53e5b8126daae/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "X",
    artist: "Nicky Jam",
    id: 466856262,
    release_date: "2018-03-02",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7846659119db2e8323b9678d51c8b79d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dancing On My Own",
    artist: "Calum Scott",
    id: 468232772,
    release_date: "2016-03-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d1c1b8fda49876aedf036cf7f961173-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/afba3ab4a93cfdcd4357a51868904da3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Remind Me to Forget",
    artist: "Kygo",
    id: 470287772,
    release_date: "2018-03-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b3201f05c756f54511e8f740069a996-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7805200f0899af981550e427105a0890/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Alien",
    artist: "Sabrina Carpenter",
    id: 471581512,
    release_date: "2018-03-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f203a5de33745d0af19811891c26c0b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c73b93fd964b0b5674260ec27020fda/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Disappear",
    artist: "INXS",
    id: 480061582,
    release_date: "1989-09-11",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a514fa980fc09f1784395df275b4f955-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4da6268e94854a45b401201a68b5d31e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bodak Yellow",
    artist: "Cardi B",
    id: 482986812,
    release_date: "2018-04-06",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e76ee59ec6ac7c49907468f524348728-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9617334140532d2a13584042f38e67fa/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "One Kiss",
    artist: "Calvin Harris",
    id: 482990352,
    release_date: "2018-04-06",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c60c73eea404a623174c7a694881e27-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0397baea24f861db7ee63fb1c70391f9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Youngblood",
    artist: "5 Seconds of Summer",
    id: 483399272,
    release_date: "2018-04-12",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8460f7255fac2d864d56d09d75b21c6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3bbfddbac4bcfc7e2b19fb86619d7ee5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Axel F (Radio Mix)",
    artist: "Crazy Frog",
    id: 484231112,
    release_date: "2005-06-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-488b810e58472e2ad76fc54e5b4d3130-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e24d2924e6475cd59ad987fd0a34bd3/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "lovely",
    artist: "Billie Eilish",
    id: 486928932,
    release_date: "2018-04-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec9a718a9ec1eef461ee3131c9c76102-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/910033ba5cd64291a921061c8e8ba85a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Psycho",
    artist: "Post Malone",
    id: 491446962,
    release_date: "2018-02-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cd8b2fdc990b2ff1dd7283936815aa62-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c000a4d39f31f3716bf3f11aa5fab080/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Better Now",
    artist: "Post Malone",
    id: 491446972,
    release_date: "2018-04-27",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fd1e7b41ec6ffae1e8f3520a96ab3e47-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c000a4d39f31f3716bf3f11aa5fab080/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Make Me Feel",
    artist: "Janelle Monáe",
    id: 492390782,
    release_date: "2018-04-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9beff5c3dff88c1adf4a9397882672a7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc49bde7963ee33be2d027466700eb2e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Girls Like You (Cardi B Version)",
    artist: "Maroon 5",
    id: 498130042,
    release_date: "2018-05-31",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d236c9015b64e0803116b7e1897e5c7-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b4d59645e2d1cf7a692782983cee963/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Attention",
    artist: "Charlie Puth",
    id: 498386892,
    release_date: "2018-05-11",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8a46e0405865ae101f9de13b9abedd0e-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8a3333f8b5382b07190a415e16248b4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Audio (feat. Sia, Diplo & Labrinth)",
    artist: "LSD",
    id: 498525792,
    release_date: "2018-05-10",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96ce54c8885e0ac0ac257ecbcdcb88cf-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cee9902d75371aeb14e32621bc47a2df/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Back To You",
    artist: "Selena Gomez",
    id: 499988792,
    release_date: "2018-05-18",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-44ed7d2f9672cba23c75d2b3f88fa78c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19b14fa5b494e0e74332f7dbf8dab87d/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Start Again",
    artist: "OneRepublic",
    id: 499988812,
    release_date: "2018-05-18",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72cf015389d62002f5dc92ab00705c14-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19b14fa5b494e0e74332f7dbf8dab87d/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Jackie Chan",
    artist: "Tiësto",
    id: 499994962,
    release_date: "2018-05-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-920d19d2a590a6a87b7d2e81c21f7ae7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bab7fb0d0bfd1e98756aa70f8efe9128/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Forget to Forget",
    artist: "Shy Martin",
    id: 502559712,
    release_date: "2018-05-25",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aa1822b00e17741c2ee81f2f355dcc4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dc4926be0cb07226279884273dc4d42/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In My Blood",
    artist: "Shawn Mendes",
    id: 504135662,
    release_date: "2018-05-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-89994d9bcd9d2fad8c80317f9c0b9140-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a7bbe4434bb38821b20c96cfa1c35d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Let Me Down Slowly",
    artist: "Alec Benjamin",
    id: 504313072,
    release_date: "2018-05-25",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fac34b0cefa663b53a312316a9af8c29-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a2ff1ad7241739d524583d6f775c379/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In My Mind",
    artist: "Dynoro",
    id: 505204262,
    release_date: "2018-06-08",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-704c1595f88a2ba22598d1e24c53d8b7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27170198c97ac7e37f8a62cf5cae4299/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Knockin' On Heaven's Door",
    artist: "Guns N' Roses",
    id: 505510232,
    release_date: "1973-05-23",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1e5c25f330651f35585d0ff93e9ed8d8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c32f893b8670d5a3d9b1e70078bbc8fa/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Girls Like You",
    artist: "Maroon 5",
    id: 511053902,
    release_date: "2018-05-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9cd90f25a24fc80ffafc4cf83829c341-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/768d869cc8b4ca2990c76e742f6d4f8e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Wanna Know",
    artist: "Maroon 5",
    id: 511053952,
    release_date: "2016-10-12",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10dca5e64d3f2222ae7f0d4e2e37a6b3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/768d869cc8b4ca2990c76e742f6d4f8e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ocean (feat. Khalid)",
    artist: "Martin Garrix",
    id: 511515822,
    release_date: "2018-06-15",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1842824ba79d911883917f3a0f548c41-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f28287a0225e1105cfae80bea658ab1f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Turn Around",
    artist: "Phats & Small",
    id: 511594332,
    release_date: "2014-03-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95ca44519887347d7399569dac3a487d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f8a3141c23104125afa20e1b67a07c60/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "I'm a Mess",
    artist: "Bebe Rexha",
    id: 511875602,
    release_date: "2018-06-22",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d43367795fdbe907cb9cfd50a0f5b904-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93df12633104eb53d68c82981d150270/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "High Hopes",
    artist: "Panic! At The Disco",
    id: 516351532,
    release_date: "2018-06-22",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96ffb18328959b3974b0d6b4f116c493-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61a2e3b5a084305f503ea641e2841ecb/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Mad Love",
    artist: "Sean Paul",
    id: 518466662,
    release_date: "2018-06-29",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-332828984cfe25256b4b3ef985a2cec6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1fdd524dacaaa389d6d416570882fdd7/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "Happy Now",
    artist: "Zedd",
    id: 527894901,
    release_date: "2018-07-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4b3c1ba3b94da21ca402d2a3e54d168-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6e0f9fade33fb548b959fa5f5516084a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Believer",
    artist: "Imagine Dragons",
    id: 528330441,
    release_date: "2018-07-18",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0240787bdb9f62b6be064e9c3ef7fee6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/247b228179aea3b083eef43522b78b45/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Thunder",
    artist: "Imagine Dragons",
    id: 528330501,
    release_date: "2018-07-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-feaedd2bba31907712980c795aa395dc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/247b228179aea3b083eef43522b78b45/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "All Falls Down",
    artist: "Kanye West",
    id: 528869461,
    release_date: "2004-02-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b59acf97ab17b101002127d319aaf587-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/069a5dba671436da9301aad36fc9a983/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Jesus Walks",
    artist: "Kanye West",
    id: 528869491,
    release_date: "2004-05-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b5055ad1f608f15a83dca27109d1f33-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/069a5dba671436da9301aad36fc9a983/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Knew You Were Trouble.",
    artist: "Taylor Swift",
    id: 529117801,
    release_date: "2012-10-09",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-48d1036bfc032a768e52934cea0569ce-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56a83d3002710bf2044b86c01db665aa/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "We Are Never Ever Getting Back Together",
    artist: "Taylor Swift",
    id: 529117841,
    release_date: "2018-07-17",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0626ba04fcb56c0ffdb97bc5b64ae823-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56a83d3002710bf2044b86c01db665aa/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Body",
    artist: "Loud Luxury",
    id: 529677191,
    release_date: "2018-07-20",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14e1a0d0deb278ac11da12f33746e422-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/10c2f51050aaa840b36aa75fcd46a8d5/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "If I Ever Lose My Faith In You",
    artist: "Sting",
    id: 530979431,
    release_date: "1993-08-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-977374aaad3ce51de92057cb609e9658-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2491c22fb19c154e46b449ff7aa7a62/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Brainer (feat. Justin Bieber, Chance the Rapper & Quavo)",
    artist: "DJ Khaled",
    id: 533193682,
    release_date: "2018-07-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f0db669777f0f425a4ff7f502aeee84-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb3c040271bf9e6eb9fa81f74038e937/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "God's Plan",
    artist: "Drake",
    id: 533609232,
    release_date: "2018-01-19",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9990cd5b96f85daf3f3dc57ed801a899-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b69d3bcbd130ad4cc9259de543889e30/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "In My Feelings",
    artist: "Drake",
    id: 533609392,
    release_date: "2018-06-29",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-47141e650cbf2aaa54979a5c1fc145b7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b69d3bcbd130ad4cc9259de543889e30/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Whatta Man",
    artist: "Salt-N-Pepa",
    id: 537989532,
    release_date: "1993-07-20",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1b48c0b67a981b2975b5bc3e62d595c2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5e0b9c239ffe88c9e5230589ecf4dcc/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Shoop",
    artist: "Salt-N-Pepa",
    id: 537989562,
    release_date: "1993-06-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dcd80cc17e9e172af4521cdd51cad602-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5e0b9c239ffe88c9e5230589ecf4dcc/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Sweet but Psycho",
    artist: "Ava Max",
    id: 539562302,
    release_date: "2018-08-17",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-05f4ca3b835698c1e615bf2d150c1358-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27b9039fed11398a5a3034129656f8fa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Do Me Right",
    artist: "Guy",
    id: 540164132,
    release_date: "2010-01-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-50545912b9d7204122255121ff4dcd3a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f526ab46724c1cc38ed2112b8f8ceadb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Save Up All Your Tears",
    artist: "Cher",
    id: 540169362,
    release_date: "2018-08-10",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e1159c46275d1e2e2d5390d234c86a82-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/06cd15444c800fc985d53489b8ca4a13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love And Understanding",
    artist: "Cher",
    id: 540169382,
    release_date: "1989-06-13",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7cb7ad754158e26c34e428e4705a2db2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/06cd15444c800fc985d53489b8ca4a13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Give It To You",
    artist: "Jordan Knight",
    id: 540495122,
    release_date: "2018-08-17",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6fbab8a95c1758f1a0eadfcb3fc0a51-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e6f21b10be888cfa1d2096618dd9a06/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Happier",
    artist: "Marshmello",
    id: 540512132,
    release_date: "2018-08-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa4032fd88bc35381db17f3f08db2d2c-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0eb55131974547285df9468b653b6f21/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Promises",
    artist: "Calvin Harris",
    id: 542335432,
    release_date: "2018-08-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-440ac78f42d93c4764d78d7fa685a2ea-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0462e8724b04151a5d347b0b06579272/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "God is a woman",
    artist: "Ariana Grande",
    id: 542389502,
    release_date: "2018-08-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-599bbde5612f749a92faaad455ab3a02-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a5d4fa5c1b97100bf128909d766f06f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "no tears left to cry",
    artist: "Ariana Grande",
    id: 542389552,
    release_date: "2018-04-20",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-17865a85f4dc60b89b764634348bf887-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a5d4fa5c1b97100bf128909d766f06f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My My My!",
    artist: "Troye Sivan",
    id: 546233492,
    release_date: "2018-08-31",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3784542d28c341b5c61a1adc765123a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0be7837c3339df62564ac6c296d6ae4b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Blood",
    artist: "Twenty One Pilots",
    id: 546720982,
    release_date: "2018-08-27",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f90fd322b3a7e51703b60279171ce8a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a87326124012a9f24354a6d5626c0338/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Electricity (feat. Diplo & Mark Ronson)",
    artist: "Silk City",
    id: 550232732,
    release_date: "2018-09-06",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e628f149ed0413087cd01dcec713c93-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f948ee6c4844c0451ad64c75e19c028f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Leave Me Alone (feat. Anne-Marie)",
    artist: "David Guetta",
    id: 553056552,
    release_date: "2018-09-14",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe9efc52c7b1a460fc06c0bfc35f2b66-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4df59dea8621bdb8b9fc1046d795adfb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Let You Love Me",
    artist: "Rita Ora",
    id: 556352852,
    release_date: "2018-09-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-55e40b6af51efece67dc1ee2b58f4357-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/78b27cf165dc6e52424ef13994dafa30/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Taki Taki",
    artist: "DJ Snake",
    id: 558528302,
    release_date: "2018-09-28",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c21987a7e4e0a9c60351c9e183613375/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Without Me",
    artist: "Halsey",
    id: 561756292,
    release_date: "2018-10-04",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1788ab6f1baa29026ffe2e948234a076-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fce4df7f3dd3f3daf83ffff8015c005f/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Shallow",
    artist: "Lady Gaga",
    id: 561855992,
    release_date: "2018-10-05",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f806a00ec51b92b1d69947481786632-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88a8288e14f61ffa39c14ac2ef9210d8/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Son of Man",
    artist: "Phil Collins",
    id: 561875042,
    release_date: "2018-10-05",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-75e837893a0bdfb99de85192fac5f0ca-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f48385948df5ed9a8e00c647a87e3a9/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Strangers Like Me",
    artist: "Phil Collins",
    id: 561875072,
    release_date: "2018-10-05",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0531b7833fefc11daef531f15738331-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f48385948df5ed9a8e00c647a87e3a9/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Falling",
    artist: "Trevor Daniel",
    id: 563077282,
    release_date: "2018-10-05",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2a2c3f13954a6e14dee0dbca17db6e0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "3 Nights",
    artist: "Dominic Fike",
    id: 564326642,
    release_date: "2018-10-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e50295600ce12ece0d007bcc190f0667-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fea07231e297ee0c926aad963fc333bd/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "MIA (feat. Drake)",
    artist: "Bad Bunny",
    id: 565423972,
    release_date: "2018-10-11",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af64be5b206f58d571988f228b51a63c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d4cc336b7729e83ea9b47c09647e1221/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Missing U",
    artist: "Robyn",
    id: 571860802,
    release_date: "2018-10-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42971b0d0c7fcd36a1ad18454ce4143f-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/76bd80e6bea27764c47ec3426ed69a9f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "thank u, next",
    artist: "Ariana Grande",
    id: 579496662,
    release_date: "2018-11-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b267d0922cf04ba2cef9b697e8f5759b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/47bd32cfe1f46923ed5029aba49f245d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Woman Like Me (feat. Nicki Minaj)",
    artist: "Little Mix",
    id: 580705262,
    release_date: "2018-11-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95dea8eb546780808a408d605382a266-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed87e7ae07ecddd9262291aede4fee50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Someone You Loved",
    artist: "Lewis Capaldi",
    id: 582143242,
    release_date: "2018-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3001fef0ed7833e91971be2ee2d7927-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9a49265adb09ef5c1ae4920f8420e5ae/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "It Feels So Good",
    artist: "Sonique",
    id: 582327622,
    release_date: "2000-08-24",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-93b9aecb625739bbeb536552f26060e5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de2de22557cf1e920ce7cd436561b774/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Solo (feat. Demi Lovato)",
    artist: "Clean Bandit",
    id: 595082272,
    release_date: "2018-11-30",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f94cef0fe82aee4f094f6ea71550c00b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de1907943d4311c6ed79d2ee2ffe159a/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Eastside",
    artist: "benny blanco",
    id: 598081672,
    release_date: "2018-07-13",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-362b034c4b9e0a9719a7b193e505c201-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ea35770114bec280983affb73bd49fc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "New Soul",
    artist: "Yael Naim",
    id: 605635722,
    release_date: "2007-01-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9b3d11da350fc1c7a47bbd1026f716f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f8269b9fbd0aff5c364b6a6ce80def7d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boom Boom Boom",
    artist: "The Outhere Brothers",
    id: 606658802,
    release_date: "1994-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e9b62ac7b5b5cfbfe1b34e5f32d10c0a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/83103cfde860b3655be09f78c0279e8e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "It's Alright (The Guvnor Mix)",
    artist: "East 17",
    id: 608745242,
    release_date: "2017-12-15",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4f686776f979176d9ee2026877f27ddd-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4b61d780e7bc7b3de6e594baba9f6f4c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Grillz",
    artist: "Nelly",
    id: 610858502,
    release_date: "2005-11-29",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-882a5ebf997a5755cd1531450a50da65-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75907f5ba8338802b66a0e15eb050808/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Get Right",
    artist: "Jennifer Lopez",
    id: 611716572,
    release_date: "2005-03-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe17b68b801b704d65e67e396cd2c255-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67dfac3d3c48b6d2006c040129767492/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dancing With A Stranger",
    artist: "Sam Smith",
    id: 613138092,
    release_date: "2019-01-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-483c4483e719a8a518387a8d42a73406-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0d05906fbe4effeae6bfb8797939342/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "i'm so tired...",
    artist: "Lauv",
    id: 613488992,
    release_date: "2019-01-24",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-de79108ade1aae4d7504278cd878ee3a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b3e8c9e8eec753a98a90837321cae305/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Giant",
    artist: "Calvin Harris",
    id: 614756502,
    release_date: "2019-01-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-15e4e2a199e63125dc948902795fc6d4-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/faa77ed242019d1c534e87da6bf153b0/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "The World I Know",
    artist: "Collective Soul",
    id: 616357332,
    release_date: "1995-06-13",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c1bcdbf4f02ddb3fb6bf30529f5cfc45/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Call Me Up",
    artist: "Mabel",
    id: 616963582,
    release_date: "2019-01-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d1d78be207a920646ea6e8cf6e337d2-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cd7b09177d233e7c11bd93bcaf466e5e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Con Calma",
    artist: "Daddy Yankee",
    id: 619949882,
    release_date: "2019-01-24",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c6324a902bac745ea0dbd12257b15d3e-23.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/25a6edea15aa8e093b90773ac06ceeaa/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Separated",
    artist: "Avant",
    id: 620269712,
    release_date: "2019-01-25",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f360bef0746a97f5e76c3c5c5f3ac60c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/50e6c3de54dda5b6c1e5b07130ad2f78/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "The Hardest Thing",
    artist: "98º",
    id: 620285952,
    release_date: "2019-01-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a87f5b4e07d3eb2cb28b352ce83cdfd-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82170ba478600fa56ed6915bba5bd7c1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Piece Of Your Heart",
    artist: "MEDUZA",
    id: 623723282,
    release_date: "2019-02-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3bad6a21bf3726daa6050ba6f522a85-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2aa3a5de3aef945681ee002d3dbde756/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Applause",
    artist: "Lady Gaga",
    id: 629561092,
    release_date: "2019-02-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f73e028244ff0cfd6ecac3a3c20fb43-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2355b9a023e34665e347afa8b53fd9e1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "7 rings",
    artist: "Ariana Grande",
    id: 629899842,
    release_date: "2019-01-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b0cfc898bd5b7455084955f306afa45b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49e86e935da829b44cb5ffae16826e55/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "break up with your girlfriend, i'm bored",
    artist: "Ariana Grande",
    id: 629899862,
    release_date: "2019-02-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08d58eecb01ea451762726fa05788dc6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49e86e935da829b44cb5ffae16826e55/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We R Who We R",
    artist: "Ke$ha",
    id: 630085482,
    release_date: "2010-10-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-574bda6938dee538a9b88fa576f6bc5b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9bfec16708a9c22fea00707580070014/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flashing Lights",
    artist: "Kanye West",
    id: 630827302,
    release_date: "2007-10-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e40c39e48b300d75e86e6b3d0370c8f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c6578a2099561992fb7544e6826f767/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Best I Ever Had",
    artist: "Drake",
    id: 633377802,
    release_date: "2009-02-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96fa4736e78fccb7ac8284d79ae6c314-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55fb57cfe1b96220bc9688a1ede36bd4/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Can't Stop This Thing We Started",
    artist: "Bryan Adams",
    id: 638497402,
    release_date: "1991-09-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5efed4328b59432f8403b17b5263313-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b7ac3a89574d965e2ea7e735340724e4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Please Forgive Me",
    artist: "Bryan Adams",
    id: 638497442,
    release_date: "1993-10-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-32b4b00858bf0ac7d483fa52ac5f4866-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b7ac3a89574d965e2ea7e735340724e4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All Day And Night",
    artist: "Jax Jones",
    id: 653689002,
    release_date: "2019-03-28",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a4eb915f1308be1e8813a17eb8b847d3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb6b4dec86fe2f6af318d69bafa1e54d/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "bad guy",
    artist: "Billie Eilish",
    id: 655095912,
    release_date: "2019-03-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be6c763188230b2bdd6c97ec5badb09e-28.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6630083f454d48eadb6a9b53f035d734/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "when the party's over",
    artist: "Billie Eilish",
    id: 655095962,
    release_date: "2018-10-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85892fd1be1400c8847a177929e63086-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6630083f454d48eadb6a9b53f035d734/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "21 Seconds",
    artist: "So Solid Crew",
    id: 657204592,
    release_date: "2001-08-06",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31e32f8ad337be3763af2a3881bcdcdb-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37ec77714bd672c9c41251b810453fcf/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Old Town Road (feat. Billy Ray Cyrus) (Remix)",
    artist: "Lil Nas X",
    id: 660051472,
    release_date: "2019-04-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-532e3267c857873cc1344ac4501004af-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e20107f84c7830f1edfedbbb0dd95201/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Let Me Think About It",
    artist: "Fedde Le Grand",
    id: 661837522,
    release_date: "2007-07-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2bc8b44f997e9d747170db53a01e399e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0657020873c9e293845dad0767777dbc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Truth Hurts",
    artist: "Lizzo",
    id: 674416352,
    release_date: "2019-04-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c66bd76c752ff0c198ba723d1b495934-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ca3ef91da07e9b9a2e9671a6583a01f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "SOS",
    artist: "Avicii",
    id: 675656892,
    release_date: "2019-06-06",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9e7ed82d29f549bc186e47ded51501a-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4587f72f5f04f20b033140d578f3758f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Party For One",
    artist: "Carly Rae Jepsen",
    id: 678689072,
    release_date: "2019-05-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a68f343c5dc0dbbf7c9ce0f0039dde0-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af2306b975e7a762f36dbb37d9e8bc05/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wish You Well",
    artist: "Sigala",
    id: 680950222,
    release_date: "2019-05-24",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-45c2f6e1e511064343e263a5d6faf9e0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea114f4d82187b7aefd0371525bd6b65/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Post Malone (feat. RANI)",
    artist: "Sam Feldt",
    id: 682799032,
    release_date: "2019-05-24",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0c823b498bae7804c5295618c6c55a7f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/655b1a09abafa3cf0ed7e5e0593677bb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The London (feat. J. Cole & Travis Scott)",
    artist: "Young Thug",
    id: 684538942,
    release_date: "2019-05-23",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-133ee51bcc744a9704bf4057176e823d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35c08dcc0ec7871b020b0591291eb9ef/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Ritual",
    artist: "Tiësto",
    id: 685843722,
    release_date: "2019-06-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e15444809425fa17dad17ec8da75143-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e0101413ddcf2c692b8c81647ff3c9e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Never Really Over",
    artist: "Katy Perry",
    id: 687131312,
    release_date: "2019-05-31",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-221822b1f5f1e94ca5ac67dbb1915565-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c1a86211260449a1e893890768735506/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ransom",
    artist: "Lil Tecca",
    id: 690610892,
    release_date: "2019-06-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b80a7e5811cdcfafb97bbb0d90ef68b2-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e921ba46a5327b8c165ec407c82740ee/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Sucker",
    artist: "Jonas Brothers",
    id: 692002952,
    release_date: "2019-06-07",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c51c742fb7dd3839c75b9e812f1d9e20-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/89834d35dd6320600b90f044e9bbf467/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "On A Roll",
    artist: "Ashley O",
    id: 696053222,
    release_date: "2019-06-14",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1c4f17d3a231348fdd4d85b7873dda4f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a488df725503905cba5dd0d239533676/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing Breaks Like a Heart (feat. Miley Cyrus)",
    artist: "Mark Ronson",
    id: 696580272,
    release_date: "2019-06-21",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7f01371acd0289a86e3cb9698ca7ad91-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20c1edddaf2d221052f0989623663a38/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Señorita",
    artist: "Shawn Mendes",
    id: 698905582,
    release_date: "2019-06-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-58c2f2bd81ee0d60e70310831737a910-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4426e8a06d8d0cd96263094c6178bbf9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Old Town Road",
    artist: "Lil Nas X",
    id: 699056262,
    release_date: "2019-06-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00f5ada432d79bcedcc9d4e4c6856888-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3cfd6900fd2b342ec0e478faf300f6a2/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Instagram",
    artist: "David Guetta",
    id: 699922432,
    release_date: "2019-07-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-57dcc3475f90adb9a0f9063a4d54534b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f693c75e8953597f8b667d3bc4889560/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Higher Love",
    artist: "Kygo",
    id: 701281342,
    release_date: "2019-06-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cf5a3004c08c39957a3de6d3b33edbc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f45ec14c6cc67e1428a60e3548be46c/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Lalala",
    artist: "Y2K",
    id: 703842302,
    release_date: "2019-06-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-510d65e154449ca34109ea9a56df4e34-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57f9e0aa71f8831dc2150fa8e281496f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "One Day / Reckoning Song (Wankelmut Remix)",
    artist: "Asaf Avidan",
    id: 705022722,
    release_date: "2012-08-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-815e413c3c68f3316012822e15fe4ce3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/758c53b7f72dbfbed8c5ffc882bf6a7c/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Ride It",
    artist: "Regard",
    id: 708907602,
    release_date: "2019-07-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fde64f262a06f8958b41e74dddb61e0b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c7abf65b190c825054f29b55372619b3/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Beautiful People (feat. Khalid)",
    artist: "Ed Sheeran",
    id: 710164302,
    release_date: "2019-07-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-972dee2305330e6172e439ffba1d18f3-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d809f545fb9ecea849cf45fc8bcc681/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Don't Care",
    artist: "Ed Sheeran",
    id: 710164352,
    release_date: "2019-05-10",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3fa0c2e63cd74b2c9effd429c44fd63-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d809f545fb9ecea849cf45fc8bcc681/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Killer",
    artist: "Adamski",
    id: 712072122,
    release_date: "1989-07-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0235140e320ffb5995999b2b5af33218-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9dc3e8c756bd3e9c6aad4a85cae47f07/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "How Do You Sleep?",
    artist: "Sam Smith",
    id: 712113642,
    release_date: "2019-07-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ea4d4c6f755ddc81fde901a4382e23e5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8e20716603c6d72c5d15cb3f9f013108/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Like You",
    artist: "Ghita",
    id: 713044362,
    release_date: "2019-07-19",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8751a1ab31ff71aa283bc1127d1f6001/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "China",
    artist: "Anuel AA",
    id: 714210042,
    release_date: "2019-07-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce92e2a4ecc9282c9c92a9cad6172ab9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9792592cb08a52adafe49e0d93c5c177/250x250-000000-80-0-0.jpg",
    genre: "Latin Music",
  },
  {
    title: "Where Does My Heart Beat Now",
    artist: "Céline Dion",
    id: 714264732,
    release_date: "1990-04-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-926e4ac94dc9c5016e13a8436ec62afb-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ddd1746ade549a4a86cc4d4cf12cba52/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Loco Contigo",
    artist: "DJ Snake",
    id: 716383902,
    release_date: "2019-07-25",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6e1829b10c7d5c33e298cf10c8efb8e4-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8d55951e5b20dbb211998d29be970be2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Innuendo",
    artist: "Queen",
    id: 716513502,
    release_date: "1991-07-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99436e8b5d688d4186146b4116876510-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/780b4f2e2ed49cafc20e88c029062c2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Show Must Go On",
    artist: "Queen",
    id: 716513602,
    release_date: "2019-07-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-185018b3dd45084f3132a7f1553bc87c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/780b4f2e2ed49cafc20e88c029062c2c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hot Girl Summer (feat. Nicki Minaj & Ty Dolla $ign)",
    artist: "Megan Thee Stallion",
    id: 726785832,
    release_date: "2019-08-09",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a407ea49a7c10e8b89b563ca13dce8f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc456b76e9ce22a1ec5ec1cdb3f25721/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dance Monkey",
    artist: "Tones And I",
    id: 727924692,
    release_date: "2019-08-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-afc87c284ff12b8a80947bbce13b408b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d7b540eb85c84a37cd5bf53740991cb/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Yo x Ti, Tu x Mi",
    artist: "ROSALÍA",
    id: 731498882,
    release_date: "2019-08-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7f73cdd6614537802bd13d6c897192aa-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/536c7d0c86cc2a779c0f63b12c616ee7/250x250-000000-80-0-0.jpg",
    genre: "Latin Music",
  },
  {
    title: "hot girl bummer",
    artist: "blackbear",
    id: 734497752,
    release_date: "2019-08-23",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45027772023daecc4ad704e7eb6be50b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Poker Face",
    artist: "Lady Gaga",
    id: 734508762,
    release_date: "2008-09-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7cdc166de0302fad76a8d8943eb5adf-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fad7de079aa103d60ec1e2d1582c2281/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Teeth",
    artist: "5 Seconds of Summer",
    id: 737208652,
    release_date: "2019-08-23",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85122a39a927077b80d3d397776143bd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fc9e57d4d48014e1cc68e574d532299/250x250-000000-80-0-0.jpg",
    genre: "Films/Games",
  },
  {
    title: "Lover",
    artist: "Taylor Swift",
    id: 737967302,
    release_date: "2019-08-23",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d5c941adecf0331e10a06e7c7d50604d-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6111c5ab9729c8eac47883e4e50e9cf8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Need To Calm Down",
    artist: "Taylor Swift",
    id: 737967412,
    release_date: "2019-08-23",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5f0ef5757f88c16acd6f714f03335b0c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6111c5ab9729c8eac47883e4e50e9cf8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Never Seen The Rain",
    artist: "Tones And I",
    id: 739870832,
    release_date: "2019-08-29",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf8945fef34a8b3fe59b2872dda96734-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5636a569737f8dad9a928f3d907c493a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Circles",
    artist: "Post Malone",
    id: 742744952,
    release_date: "2019-08-30",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7abc04371a4ab5926c3659ac05f3c69b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fb46005a49df7aeba49f1ca117f3710/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Wanted",
    artist: "OneRepublic",
    id: 743847102,
    release_date: "2019-09-06",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5fe8770a3f680e9b7d1cab2e7668fa8d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68d9911b5aacde47ea07ab9bdef72f6b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Liar",
    artist: "Camila Cabello",
    id: 744266592,
    release_date: "2019-09-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2cc28cadce1c55e5121f4740c4d2aea2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/25cd3ea786c37a421a3950d8ba3f35cf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Goodbyes",
    artist: "Post Malone",
    id: 747399432,
    release_date: "2019-09-06",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-47af1d8848cc1071fde7ce75b2981846-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4642b8e3e0a89f92a6e2bfed13d8f31c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Heartbreak Hotel (feat. Faith Evans & Kelly Price)",
    artist: "Whitney Houston",
    id: 747726862,
    release_date: "2000-05-16",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8338c3429b2631359b3f096c432a5f8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68e683629703c4602fa5c0dde472a843/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Graveyard",
    artist: "Halsey",
    id: 750145322,
    release_date: "2019-09-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4079118063e5f373eaa4e346c09d0c94-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/97df2575e251a12aaa4aca7f632411b5/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Stack It Up",
    artist: "Liam Payne",
    id: 754038312,
    release_date: "2019-09-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a4a652a45ae888dba33b7180a1af855-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bf48daea99c1d5c92512a10189af5509/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Memories",
    artist: "Maroon 5",
    id: 755405702,
    release_date: "2019-09-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31226a95b8efc1809f5f74b816c4eef4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/535b9b868b048d4c9ee460f1c0120365/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Trampoline",
    artist: "SHAED",
    id: 759831102,
    release_date: "2019-09-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b043dccc7938f32bb0b479024e05742d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55fbdbd22ff0bfdacbc354b727564961/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Playing Games",
    artist: "Summer Walker",
    id: 765794412,
    release_date: "2019-10-04",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-749322b9de2ed2f0504ac4a658bec511-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b63fec3a3108a1a346cc27a51af6c840/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Outnumbered",
    artist: "Dermot Kennedy",
    id: 765796912,
    release_date: "2019-10-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d533f8b71b51eb10183b81b98146efd2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fc120d55b9340ff8057028d19859fe0c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Do Me!",
    artist: "Bell Biv DeVoe",
    id: 765887532,
    release_date: "1990-02-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b26ad4ef2affd6c14fb421d9b7fd57d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9b13732b4372ea0d63801c910d993992/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "HIGHEST IN THE ROOM",
    artist: "Travis Scott",
    id: 767583072,
    release_date: "2019-10-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ef769e9f4cee0120fe3f8df41189e667-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fa05e7d74819963577a92a8c6ad4979/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Lose Control",
    artist: "MEDUZA",
    id: 769757652,
    release_date: "2019-10-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-169a811e404025cebbfe37a7666a8f5d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/453595cce92efa85b6cade031f59cad6/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Git Up",
    artist: "Blanco Brown",
    id: 770754302,
    release_date: "2019-10-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f67dff70c7a95b7c258dfe90ba1e9c3e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a578fd926bb27ae3a35b8d8bbd5ec7d9/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "RITMO (Bad Boys For Life)",
    artist: "The Black Eyed Peas",
    id: 772603752,
    release_date: "2019-10-12",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28e600a0c5b065e088e80875e4527389-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/39e98d2bd759fe5bf9dc95d0e8553424/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Make It Right (feat. Lauv)",
    artist: "BTS",
    id: 776433672,
    release_date: "2019-10-18",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5740689303d6abea5bd387387566bce/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lose You To Love Me",
    artist: "Selena Gomez",
    id: 782281832,
    release_date: "2019-10-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b0ee5ff8d153ddc8d93bdb20cca5b140-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86193ca4aafbb86708b8040a25c22d51/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Hurts The Most",
    artist: "Rascal Flatts",
    id: 783033602,
    release_date: "2006-07-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a9234a783de99b0922df9337be56e70-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a33e99fdcbbf7db5a0361afd6ce4250/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Takeaway (feat. Lennon Stella)",
    artist: "The Chainsmokers",
    id: 794938292,
    release_date: "2019-11-08",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e67bd505a5e561a608c40eef13a8cbfc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/32d3144799e5f4790c59161e7750e111/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Tusa",
    artist: "KAROL G",
    id: 796342592,
    release_date: "2019-11-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-34633f193e8fb77fce1f54d8e6033bd8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7f4412829dda081518fcad597601c778/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Say So",
    artist: "Doja Cat",
    id: 797228462,
    release_date: "2019-11-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7bc96d31b485a05a8867d0f957ff8649-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e0d4359a328f8b0ea3563e8623a09aa/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "It's You",
    artist: "Ali Gatie",
    id: 797479512,
    release_date: "2019-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3a835728e070f75d37b335399b592ad-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9bd3c2d43ebdb7676b705e96df91ed82/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "everything i wanted",
    artist: "Billie Eilish",
    id: 803010392,
    release_date: "2019-11-13",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-595dad24a42df6f1678cf26a56db71b1-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0da7f3792b522921eb9aa5a44f0a4ce7/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "ROXANNE",
    artist: "Arizona Zervas",
    id: 806698322,
    release_date: "2019-10-10",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-78260ab48cb79b4edb8b18a25eb767d5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1306297f553065dfd70701837045b41f/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Before You Go",
    artist: "Lewis Capaldi",
    id: 807205422,
    release_date: "2019-11-19",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9822669620effa773586c95efd709590-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/327a13284c291832cf01622a414847c9/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Better Off Alone",
    artist: "Alice Deejay",
    id: 810585662,
    release_date: "2019-11-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d09a33f53d11b6be02e4b35de8202c8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec8f13cfcd69cf0c98c2830b7c4308f4/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Baila Baila Baila",
    artist: "Ozuna",
    id: 820200292,
    release_date: "2019-11-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beb07b02d601bf28550c50d532e43586-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53a787af01844391a3f59f2dc1b59e9a/250x250-000000-80-0-0.jpg",
    genre: "Latin Music",
  },
  {
    title: "Nothin' My Love Can't Fix",
    artist: "Joey Lawrence",
    id: 822662142,
    release_date: "2019-12-06",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d71bffa5f01bb90e6e815db935eef248-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ec0c708c0fc944a27eb7778a6b82c9bd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Box",
    artist: "Roddy Ricch",
    id: 825986992,
    release_date: "2019-12-06",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f46b78fac27f1c0593f84930fb38988-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4cecb47ae25837b9dd022004b5cacbdc/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Alone, Pt. II",
    artist: "Alan Walker",
    id: 829185532,
    release_date: "2019-12-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e5b31ece84f453a6c6e3581a0e8a1437-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5950c85f789a083d4be4dfaba9054483/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Watermelon Sugar",
    artist: "Harry Styles",
    id: 830336922,
    release_date: "2019-12-13",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1dad0ee9a3cc34130171fea60b07cdd7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/346c524c15ecccbc4a8a78e8972a352c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Adore You",
    artist: "Harry Styles",
    id: 830336932,
    release_date: "2019-12-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-435160235bd2876046e83dcf62f7b65e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/346c524c15ecccbc4a8a78e8972a352c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breaking Me",
    artist: "Topic",
    id: 831917182,
    release_date: "2019-12-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85f2dd7f4e7a963a0e83a97458aea8d5-20.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb227e15bc9947de08a5ae26e62bb3b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Another Chance",
    artist: "Roger Sanchez",
    id: 839264762,
    release_date: "2001-07-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-58759cf8b868a20844cc8659ce4c4bbe-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2cd34219232eb3122b5c8a700e65284f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Life Is Good (feat. Drake)",
    artist: "Future",
    id: 847699482,
    release_date: "2020-01-09",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b72606bdafcd026cfd222a16153750b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b37cb4509f1e4bb8a4a4dfa5e55c4a8c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Pray",
    artist: "Take That",
    id: 848872242,
    release_date: "1993-10-25",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-24087461eaf762880e9d6fc54054a6ca-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0068fc2123277ef700493d6fa6c0fc34/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Relight My Fire (feat. Lulu)",
    artist: "Take That",
    id: 848872262,
    release_date: "1993-10-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-363499c4955410eb3f190f3178bda118-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0068fc2123277ef700493d6fa6c0fc34/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Babe",
    artist: "Take That",
    id: 848872352,
    release_date: "1993-10-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-becff66141503f57d4354211ad4c30d2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0068fc2123277ef700493d6fa6c0fc34/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heartless",
    artist: "Diplo",
    id: 862201652,
    release_date: "2020-01-31",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-03ea53ebca3e0c72514cebbb9b997426-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/25c25645b8bb7772d47219498f5792ac/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Know Your Worth",
    artist: "Khalid",
    id: 862847042,
    release_date: "2020-02-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed2573aabf067da82f640fa7f67b444e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34cb1dcadca75c2d57a4a2bcb2d9f0fd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "death bed (coffee for your head)",
    artist: "Powfu",
    id: 871124582,
    release_date: "2020-02-08",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8d3593a371dc66e92d7229f215485a64-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/85380bbb010f1b675c29ac06c6e343ea/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Love to Hate You",
    artist: "Erasure",
    id: 874017852,
    release_date: "1991-10-14",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beac3d1add387c424128a98ceba1ed79-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c22d746953becdab3e08d57b51b45d1d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Intentions",
    artist: "Justin Bieber",
    id: 876196932,
    release_date: "2020-02-14",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d94ac5bb9d7d3eb6f5fbaa9bea2f254a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8d54ffac647e9e40cd91686c8a906de6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Yummy",
    artist: "Justin Bieber",
    id: 876196942,
    release_date: "2020-01-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f548724b668515b57bc925e6c89e33c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8d54ffac647e9e40cd91686c8a906de6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "ily (i love you baby)",
    artist: "Surf Mesa",
    id: 886766742,
    release_date: "2020-02-25",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10cae8a14da8c4bf7410cb3967685759-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81533216382c3957b8a3fda95105c7b9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Make It Rain",
    artist: "Fat Joe",
    id: 890568262,
    release_date: "2006-11-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9659ffd7c15cbb8a31ed966d7a917ff-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a1d933af006a1bb717061ae8f12269bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Kings & Queens",
    artist: "Ava Max",
    id: 899198132,
    release_date: "2020-03-12",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8939be6510b2ab912db4e29d15394c72-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/996c48926ead8a54c1d90db4e9b109fe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Blinding Lights",
    artist: "The Weeknd",
    id: 908604612,
    release_date: "2020-03-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0cb3c26f5c0be8b37d90560955431118-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fd00ebd6d30d7253f813dba3bb1c66a9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "How Do You Do!",
    artist: "Roxette",
    id: 912373352,
    release_date: "1992-08-31",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-224ac02325c2a339d5cf89f1f3d52432-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c70da2cd385c01c7c90bf185ae350a1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Almost Unreal",
    artist: "Roxette",
    id: 912428852,
    release_date: "1994-04-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1cb31626b94a277d637d371148b6f564-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/971b587c788d73cdcb99eecb1ea9a846/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fading Like A Flower (Every Time You Leave)",
    artist: "Roxette",
    id: 913092212,
    release_date: "1991-04-02",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5b8d5757fab5ff3d4659ceb64d85e5be-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e9452ee63b87b6affd59d24ffbf5c31/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Spending My Time",
    artist: "Roxette",
    id: 913092232,
    release_date: "1991-04-02",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f00947840fce5ff4cc4de9d2e5b68572-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e9452ee63b87b6affd59d24ffbf5c31/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Toosie Slide",
    artist: "Drake",
    id: 919708552,
    release_date: "2020-04-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-347595842cfc701bf3610f56c31a6b39-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2216c368d2223ebf839fa09cd90c150/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Sensitivity",
    artist: "Ralph Tresvant",
    id: 921943172,
    release_date: "1990-12-10",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e71852aa1e01b0e7de93b2dd2646c6a0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f86d1fcf9056bae9ed99deab09fa7be/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "My, My, My",
    artist: "Johnny Gill",
    id: 921971922,
    release_date: "1990-04-10",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e49b676f1a2a1841f4ddcff7c8b5325-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4b99eac10ba88cb1de078f001accf9b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "you broke me first",
    artist: "Tate McRae",
    id: 929158322,
    release_date: "2020-04-17",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6f204bf4d792a377e7f47fde8f5d8e55-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e98a0f036dce1ff55a2b1bcb9bff3dc3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "ROCKSTAR",
    artist: "DaBaby",
    id: 932661672,
    release_date: "2020-04-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1afe80e99f64107a65a730966f301ed1-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/945d96da8abf5a9457974d83298ee384/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Somebody Dance with Me",
    artist: "DJ Bobo",
    id: 946033732,
    release_date: "1993-03-25",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d76ba1eb2e88c89d4c2670d4e30068f8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/29ec2ed9adcd00fdb9d2af71662f67d1/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Loverboy",
    artist: "Mariah Carey",
    id: 961309092,
    release_date: "2003-03-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4a2b27d23164b33125b787e9457a71aa-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2eb17513f571c8447732b3bb5d927ce5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Some Say",
    artist: "Nea",
    id: 971925602,
    release_date: "2020-06-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94aeca19bd9c13346af6028753f36aab-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f817d873795e5bc0cb6e6d2e1f39ae07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stupid Love",
    artist: "Lady Gaga",
    id: 973317052,
    release_date: "2020-02-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6aa9b40f086ff21d3d000e4739fb889e-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81572deeede9e5f691e4b1889b828197/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rain On Me",
    artist: "Lady Gaga",
    id: 973317062,
    release_date: "2020-05-22",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-049ccd6c61dbdbc0de5bf2c9a0791126-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81572deeede9e5f691e4b1889b828197/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Go Let It Out",
    artist: "Oasis",
    id: 985703762,
    release_date: "2000-02-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bd849b3fd470dcfb25d51609916072d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c81b4b047a1a12743df8487357a2e7ce/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Lyla",
    artist: "Oasis",
    id: 985938012,
    release_date: "2005-05-16",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a01b87c9868ee6b1bcf595f158192eca-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7e85af53344bb44a32a45d0d455c92ae/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Savage Love (Laxed - Siren Beat)",
    artist: "Jawsh 685",
    id: 986939372,
    release_date: "2020-06-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fad919850f67c847921eee0647c068ea-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ae220905519d1bb84cddd953abbc08da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "MAMACITA",
    artist: "The Black Eyed Peas",
    id: 990604222,
    release_date: "2020-11-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3a9619304a78d965b709476d5fb56da-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5f69e64c44314ff6b1422829afe08503/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everybody's Free",
    artist: "Rozalla",
    id: 1024779992,
    release_date: "1991-04-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4d7d7c27e45fd6f1ccae671fc7f500ed-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea3a56163959ac0beed6f6b65b6a07a4/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Salt",
    artist: "Ava Max",
    id: 1077837252,
    release_date: "2020-09-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6c0f1b49a12c983a88ae04819169a39-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0eca025ac1206d710f91c32e49d6e8a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Calma",
    artist: "Pedro Capó",
    id: 1081480062,
    release_date: "2020-09-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f34c55e0549162f7d080e90ec624a73d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41534e6a39dbaa8032e9f40beafd6198/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Holy",
    artist: "Justin Bieber",
    id: 1084400712,
    release_date: "2020-09-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-64f8a4110e9cef7125175eda3c87a562-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dc2f1bf748626716e84ed32a500d4f3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waves (Robin Schulz Radio Edit)",
    artist: "Mr. Probz",
    id: 1084861832,
    release_date: "2014-02-07",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98949c54f812da676a9f333dba7c24ae-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81ae52925eae1b3b0e73dee1f2854877/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Que Calor",
    artist: "Major Lazer",
    id: 1115454692,
    release_date: "2019-07-17",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e54d13dcf9d70e411e30788e690db387-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1abb736dab8c1499e32f6078c0b58aa3/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: 'Show Me Heaven (From "Days Of Thunder")',
    artist: "Maria McKee",
    id: 1121914752,
    release_date: "2022-08-31",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-99b380e2aa5c2c8a05998d6da30c61a7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c97d56f244c9a5b69715f1c59fe79515/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Physical",
    artist: "Dua Lipa",
    id: 1124841672,
    release_date: "2020-03-27",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2cd5cfc937e441041e33598be7e7799-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0f8d853e871838a4a6d8e5b7cdd1432/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Break My Heart",
    artist: "Dua Lipa",
    id: 1124841722,
    release_date: "2020-03-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5777067955929e3f930ff6786d2f2359-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0f8d853e871838a4a6d8e5b7cdd1432/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Supalonely",
    artist: "BENEE",
    id: 1133433862,
    release_date: "2020-11-13",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-40243521844c4f22690ceead44d73b97-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a81cb7d7c54db700ee6aec6c6941b16/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Super Bass",
    artist: "Nicki Minaj",
    id: 1140884892,
    release_date: "2011-04-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-81f664581fc49db083f823e10979fe4a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93c7ca2b54d614843dfe7658106eed69/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "What Time Is Love? (Live at Trancentral)",
    artist: "The KLF",
    id: 1181472092,
    release_date: "2021-01-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a887838a3f51f25157fb4ea089c29c5-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f906742179b93091b5f089fd95cd90f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "3AM Eternal (Live at the S.S.L.)",
    artist: "The KLF",
    id: 1181472102,
    release_date: "2021-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7433c74b4c488c62e8a08291c3250f83-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f906742179b93091b5f089fd95cd90f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Last Train To Trancentral (Live From The Lost Continent)",
    artist: "The KLF",
    id: 1181472112,
    release_date: "1991-07-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00b47c24cba5de9e308a4a8da592e7b7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f906742179b93091b5f089fd95cd90f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Justified & Ancient",
    artist: "The KLF",
    id: 1181472142,
    release_date: "2021-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e27be54ec9f631356874ea51c5c9268f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f906742179b93091b5f089fd95cd90f/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Gett Off",
    artist: "Prince",
    id: 1183597212,
    release_date: "1991-06-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-484221fdb0d4d6e18c13f16615739fd4-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d413d3635279526b67d43cbf735af6e6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "See You Again",
    artist: "Miley Cyrus",
    id: 1215683092,
    release_date: "2007-05-15",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fec4db34b3f041fda4aae4a826f4036e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aac443912f3199e6582457c714a0edfd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dub Be Good To Me",
    artist: "Beats International",
    id: 1240062942,
    release_date: "2022-08-27",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8f2525f50bc90219c1023ec0397f6501-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/879525cf913b35a1060088b5a8e2b68c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Story (Taylor's Version)",
    artist: "Taylor Swift",
    id: 1241796632,
    release_date: "2021-02-12",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20349eff9e1f47a8751ced744d0725a5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cce4e99be496acc9dc2e4365c5b288fc/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Cry for Help",
    artist: "Rick Astley",
    id: 1280843412,
    release_date: "1991-02-25",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e6767cacec73f14d78286a5f6950159b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2085ecffa5c7bd36f9953f81f45c2974/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mood (feat. iann dior)",
    artist: "24kGoldn",
    id: 1283264632,
    release_date: "2021-03-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c7f1d9e8613f2b52e0e3f693f1975a5-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2bc3ee63eaf3e5515a100a4a13a64d1d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Sorry Not Sorry",
    artist: "Demi Lovato",
    id: 1352270152,
    release_date: "2017-07-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1268e48db2bbafdfe76c1cd15933d418-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6d11e6d61282fe095f730160099fffbe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nobody's Love",
    artist: "Maroon 5",
    id: 1400843182,
    release_date: "2021-06-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42126cb09037549efb86116e3f1f549c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62d6f17a706ac04fdd3dccddfdb65653/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Glad You Came",
    artist: "The Wanted",
    id: 1543600932,
    release_date: "2011-07-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13899c050edcb202175e23bbed2ad647-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1919bfb068b62200eb2157a8afa4c32/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Whoomp! There It Is",
    artist: "Tag Team",
    id: 1558003142,
    release_date: "1993-05-07",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56e7b0ce655f338445618d4b4e565205-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b2f8361c6207045ef6a36457a7eaf3d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Dynamite",
    artist: "BTS",
    id: 1560787612,
    release_date: "2020-08-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8bbb771eb13e87ab3b96aa5e40aef63c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7218df00a126bf159d852516400a1127/250x250-000000-80-0-0.jpg",
    genre: "Asian Music",
  },
  {
    title: "FAKE LOVE",
    artist: "BTS",
    id: 1576170872,
    release_date: "2018-05-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bf565b3ef74f456648fa1e58847c46ff-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/318cd3fbb62cab2bc6155848f28f34b5/250x250-000000-80-0-0.jpg",
    genre: "Asian Music",
  },
  {
    title: "Hip Hop Hooray",
    artist: "Naughty By Nature",
    id: 1584431742,
    release_date: "1993-02-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0c1122c70e3b3ef165d265a38a22dbc2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/738565cc6aa4f480f122d944cb2aabdb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Come Baby Come",
    artist: "K7",
    id: 1584432112,
    release_date: "1993-11-09",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e9b4bed098fd594a84e7f805b38178ba-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cf3c8539ecf11cf1dc40b91d3733107a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "(We Want) The Same Thing",
    artist: "Belinda Carlisle",
    id: 1653264392,
    release_date: "1989-04-22",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e45cc3ce10f8908e5083dfe76e9c7d0d-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49f3421234c10a42e8f28ccf6267b069/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mercy",
    artist: "Kanye West",
    id: 1705211167,
    release_date: "2012-04-03",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67e4600552af33268812128c094b10e8-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eceb48aaaf32bb2afb27451d1fc71d88/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Hole Hearted",
    artist: "Extreme",
    id: 1758927657,
    release_date: "2007-09-13",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-796d18a64016015858c1590cd852609a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cf195bfad5df119bd47bfcd2a764573b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Everybody Plays The Fool",
    artist: "Aaron Neville",
    id: 1762472057,
    release_date: "2008-05-08",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-81bc4224a3d40afadebb075fc3141ae7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e77dd93163d03044afa757b8acb186ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing Compares 2 U",
    artist: "Sinéad O'Connor",
    id: 1787569267,
    release_date: "1990-01-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a268e0c9c4a53825d6b5fc3e34c7d1bf-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/393b4ac52c6d958363edefbb4c009af7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The King of Wishful Thinking",
    artist: "Go West",
    id: 1787666357,
    release_date: "1992-11-14",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-214036432a49b6ec31b1388de6827060-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a64ceebaae7dddbbba477567e9cbe5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hero Of The Day (LP Version)",
    artist: "Metallica",
    id: -685289221,
    release_date: "2013-04-30",
    preview: "",
    cover: "undefined",
    genre: "Pop",
  },
  {
    title: "Eyes On You",
    artist: "Jay Sean",
    id: -594119471,
    release_date: "2007-01-30",
    preview: "",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7019e3a669461cdb26585668c120be02/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
];

export const tracksWithPreview = tracks.filter((t) =>
  trackTitlesWithPreview.includes(t.title)
);

export function getTrackTitle(track: Track): string {
  return track.title + " - " + track.artist;
}

export function sanitizeTrackTitle(trackTitle: string): string {
  return trackTitle
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
