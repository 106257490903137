import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { tracks, getTrackTitle, sanitizeTrackTitle } from "../domain/tracks";
import { useGuesses } from "../hooks/useGuesses";
import { TrackInput } from "./trackInput";
import { Share } from "./Share";
import { Guesses } from "./Guesses";
import { useTranslation } from "react-i18next";
import { SettingsData } from "../hooks/useSettings";
import { useMode } from "../hooks/useMode";
import { getDayString, useTrack } from "../hooks/useTrack";
import { player, getScore } from "../domain/audio";

const MAX_TRY_COUNT = 6;

interface GameProps {
  settingsData: SettingsData;
}

export function Game({ settingsData }: GameProps) {
  const { t, i18n } = useTranslation();
  const dayString = useMemo(
    () => getDayString(settingsData.shiftDayCount),
    [settingsData.shiftDayCount]
  );

  const trackInputRef = useRef<HTMLInputElement>(null);

  const [track] = useTrack(dayString);

  let position = 0;

  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, addGuess] = useGuesses(dayString);
  const [hideImageMode, setHideImageMode] = useMode(
    "hideImageMode",
    dayString,
    settingsData.noImageMode
  );
  const [rotationMode, setRotationMode] = useMode(
    "rotationMode",
    dayString,
    settingsData.rotationMode
  );

  const gameEnded =
    guesses.length === MAX_TRY_COUNT ||
    guesses[guesses.length - 1]?.score.title === 20;

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const guessedTrack = tracks.find(
        (track) =>
          sanitizeTrackTitle(getTrackTitle(track)) ===
          sanitizeTrackTitle(currentGuess)
      );

      if (guessedTrack == null) {
        toast.error(t("unknownTrack"));
        return;
      }

      const newGuess = {
        title: currentGuess,
        artist: guessedTrack.artist,
        genre: guessedTrack.genre,
        decade: guessedTrack.release_date,
        score: getScore(guessedTrack, track),
      };

      addGuess(newGuess);
      setCurrentGuess("");

      if (newGuess.score.title === 40) {
        toast.success(t("welldone"), { delay: 1500 });
      }
    },
    [addGuess, track, currentGuess, t]
  );

  useEffect(() => {
    if (
      guesses.length === MAX_TRY_COUNT &&
      guesses[guesses.length - 1].score.title !== 20
    ) {
      toast.info(getTrackTitle(track).toUpperCase(), {
        autoClose: false,
        delay: 1500,
      });
    }
  }, [track, guesses, i18n.resolvedLanguage]);

  player.load(track.preview);

  return (
    <div className="flex-grow flex flex-col mx-2">
      {hideImageMode && !gameEnded && (
        <button
          className="border-2 uppercase my-2 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
          type="button"
          onClick={() => setHideImageMode(false)}
        ></button>
      )}
      <div className="my-1">
        <span
          className={`max-h-52 m-auto transition-transform duration-700 ease-in text-4xl ${
            hideImageMode && !gameEnded ? "h-0" : "h-full"
          }`}
        >
          <div className="grid grid-cols-5 gap-4">
            <div className="flex items-center justify-center h-8 col-span-5">
              Guess the song&nbsp;&nbsp;
              <img
                className={"h-8 w-8 dark:invert"}
                alt="Press play to hear song backwards"
                src={"./icons/headphone-svgrepo-com.svg"}
              />
            </div>
            <div className="flex items-center justify-center h-14 col-span-2">
              <button
                className="my-1 rounded-md hover:bg-green-200 active:bg-green-300 dark:hover:bg-green-800 dark:active:bg-green-700"
                type="button"
                onClick={() => {
                  player.reverse = true;
                  player.start(0, position);
                }}
              >
                <img
                  className={"h-16 w-20 dark:invert"}
                  alt="Play reverse song"
                  src={"./icons/triangle-right-filled-svgrepo-com.svg"}
                />
              </button>
            </div>
            <div className="flex items-center justify-center h-14 col-span-1">
              <button
                className="my-1 rounded-md hover:bg-red-200 active:bg-red-300 dark:hover:bg-red-800 dark:active:bg-red-700"
                type="button"
                onClick={() => {
                  position = player.now();
                  player.stop();
                }}
              >
                <img
                  className={"h-16 w-20 dark:invert"}
                  alt="Stop song"
                  src={"./icons/pause.svg"}
                />
              </button>
            </div>
            <div className="flex items-center justify-center h-14 col-span-2">
              <button
                className="my-1 rounded-md hover:bg-yellow-200 active:bg-yellow-300 dark:hover:bg-yellow-800 dark:active:bg-yellow-700"
                type="button"
                onClick={() => {
                  position = 0;
                }}
              >
                <img
                  className={"h-16 w-20 dark:invert"}
                  alt="Rewind song"
                  src={"./icons/rewind-small.svg"}
                />
              </button>
            </div>
          </div>
        </span>
      </div>
      {rotationMode && !hideImageMode && !gameEnded && (
        <button
          className="border-2 uppercase mb-2 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
          type="button"
          onClick={() => setRotationMode(false)}
        ></button>
      )}
      <Guesses
        rowCount={MAX_TRY_COUNT}
        guesses={guesses}
        settingsData={settingsData}
        trackInputRef={trackInputRef}
      />
      <div className="my-2">
        {gameEnded ? (
          <>
            <Share
              guesses={guesses}
              dayString={dayString}
              settingsData={settingsData}
              hideImageMode={hideImageMode}
              rotationMode={rotationMode}
            />
            <div className="grid grid-cols-4 gap-2">
              <div className="relative overflow-hidden cursor-pointer col-span-2">
                <button
                  className="border-2 my-2 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
                  type="button"
                  onLoad={() => {
                    player.reverse = false;
                    player.start(0, 0);
                  }}
                  onClick={() => {
                    player.reverse = false;
                    player.start(0, 0);
                  }}
                >
                  <img
                    className="object-cover w-full h-48"
                    alt="Album cover"
                    src={track.cover}
                  />
                  <div className="absolute top-0 left-0 px-6 py-4">
                    <h4 className="mb-3 text-xl font-semibold tracking-tight text-white uppercase">
                      Play song
                    </h4>
                  </div>
                </button>
              </div>
              <div className="flex items-center text-1xl col-span-2">
                <div className="grid grid-cols-1">
                  <div className="flex items-center col-span-1">
                    Artist: {track.artist}
                  </div>
                  <div className="flex items-center col-span-1">
                    Song: {track.title}
                  </div>
                  <div className="flex items-center col-span-1">
                    Genre: {track.genre}
                  </div>
                  <div className="flex items-center col-span-1">
                    Era: {track.release_date[2]}0s
                  </div>
                  <div className="flex items-center col-span-1">
                    <a href={"https://www.deezer.com/track/" + track.id}>
                      <img
                        className={"dark:invert"}
                        alt="Listen on deezer"
                        src={"./images/en_GB-light.png"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex rounded-md flex-col">
              <TrackInput
                inputRef={trackInputRef}
                currentGuess={currentGuess}
                setCurrentGuess={setCurrentGuess}
              />
              <div className="flex items-center text-green-500 justify-center hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700 py-2">
                <img
                  className={"h-4 dark:invert"}
                  alt="Speaker icon"
                  src={"./icons/speaker-high-svgrepo-com.svg"}
                />
                <span className="mx-1 font-bold">Thousands of new songs!</span>
                <img
                  className={"h-4 dark:invert"}
                  alt="Speaker icon"
                  src={"./icons/speaker-high-svgrepo-com.svg"}
                />
              </div>
              <button
                className="flex rounded-md items-center justify-center border-2 uppercase my-0.5 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
                type="submit"
              >
                <span className="ml-1">{t("guess")}</span>
                <img
                  className={"h-4 w-5 dark:invert"}
                  alt="Semiquaver logo"
                  src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
                />
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
